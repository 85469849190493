import getMuiTheme from 'material-ui/styles/getMuiTheme';
import {fullWhite,darkBlack,grey100} from 'material-ui/styles/colors';
import { grey800 } from 'material-ui/styles/colors';


const themeDefault = getMuiTheme({
  palette: {
    textColor: grey800,
    alternateTextColor: darkBlack,
    primary1Color: grey100
  },
  appBar: {
    height: 57,
    color: fullWhite
  }
});

themeDefault.shadows = [];
export default themeDefault;