import React, { Component } from "react";

import ReactHtmlParser from "react-html-parser";
import Moment from "moment";
import { backArrowClick,searchClick,profileClick,shareClick } from "../../../../utils/commonClickActions.js";
import {
  MovieDetails,
  PostSimilarContentBasedOnCategory
} from "../../../../utils/apiUrls.js";
import YouMayAlsoLikeApiParser from "../js/tabola_youmayalsolikedfeeds.js";
import SimilarContent from "../js/similarContent.js";
import BaseImageComponent from "../../../../basecomponents/baseImageComponent.js";
import "./trendingPostDetails.css";

class trendingDetailItemApiParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post_name: "",
      post_id: "",
      postDetail: [],
      recomended_grid: []
    };
  }
  transform=(doc) =>{
    var finaldoc =doc.replace(/<a[^>]*>/g,'<p>').replace(/<\/a>/g,'</p>').replace("ALSO READ:","").replace("Also Read:","");
    return ReactHtmlParser(finaldoc);
  }
  
  getDateAndAuthor(doc) {
    if (doc.insertdate !== undefined || doc.insertdate !== null) {
      var formatedDate =
        Moment(doc.insertdate, "YYYYMMDDhms").format("MMM DD, YYYY - h:mm a") +
        " IST";
      return formatedDate;
    }
  }
  handlePlayContainerClick(id, event) {
    console.log(id);
    // var b = new Buffer(data.details_video_url);
    //     var datafinal = b.toString('base64');
    window.location = "/eventvideodetail?id=" + id + "&tab=postdetail";
  }

  componentDidMount() {
    const params = new URL(window.location.href).searchParams;
    const id = params.get("id");
    const backpressTab = params.get("tab");
    const finalUrl = MovieDetails + "/" + id;

    var thumb_imagesize = {
      width: "100vw",
      height: "100vw",
      borderRadius: "0vw",
      objectFit: "contain",
      backgroundColor: "#efefef",
      backgroundRepeat: "no-repeat"
    };
    fetch(finalUrl)
      .then(results => {
        return results.json();
      })
      .then(data => {
        // console.log(data);
        this.setState({ loading: false });
        let postDetail = data.movies_detail.docs.map(doc => {
          console.log(JSON.stringify(doc.image_thumb));
          var similarUrl =
            PostSimilarContentBasedOnCategory + "/" + doc.id + "/" + doc.type;

          this.setState({ post_name: doc.post_title, post_id: doc.id });
          var value = doc.id;
          var isVideo;
          if (
            doc.details_video_url !== null &&
            doc.details_video_url !== undefined &&
            doc.details_video_url !== ""
          ) {
            isVideo = "visible";
          } else {
            isVideo = "none";
          }
          console.log(isVideo + " " + doc.category + " " + doc.id);
          return (
            <div
              className="trending_post_detail_container_data"
              id={value}
              key={value}
            >
              <div className="post_detail_action_top_icons_div">
                <img
                  src={require("../../../../imagesvg/left-arrow_black.svg")}
                  className="action_icons_back"
                  alt="back_arrow"
                  onClick={backArrowClick.bind(this, backpressTab)}
                  style={{
                    left: "3.125vw",
                    position: "absolute",
                    width: "6vw",
                    height: "8vw"
                  }}
                />
                <img
                  src={require("../../../../imagesvg/share_black.svg")}
                  className="action_icons"
                  onClick={shareClick.bind(
                    this,
                    "https://mobi.bollywoodhungama.com/postdetail?id=" +
                    value +
                      "&tab=stories"
                  )}
                  alt="share"
                  style={{ right: "35.625vw",padding:0,position: "absolute" }}
                />
                <img
                  src={require("../../../../imagesvg/user.svg")}
                  className="action_icons_user"
                  alt="profile"
                  onClick={profileClick.bind(this)}
                  style={{ right: "19.625vw", position: "absolute" }}
                />
                <img
                  src={require("../../../../imagesvg/search.svg")}
                  className="action_icons_search"
                  alt="search"
                  onClick={searchClick.bind(this)}
                  style={{ right: "4vw", top: "0.25vw", position: "absolute" }}
                />
              </div>
              <div className="post_container">
                <img
                  src={require("../../../../imagesvg/play_copy.svg")}
                  alt="play"
                  style={{
                    position: "absolute",
                    top: "40vw",
                    left: "45vw",
                    zIndex: "10",
                    width: "15vw",
                    height: "15vw",
                    display: isVideo
                  }}
                  onClick={this.handlePlayContainerClick.bind(this, doc.id)}
                />
                <BaseImageComponent
                  image_thumb={doc.image_thumb[0]}
                  customStyle={thumb_imagesize}
                />

                <div className="post_content">
                  <img
                    src={require("../../../../imagesvg/detail_top_corner_panel.png")}
                    className="page_crop"
                    alt="page_crop"
                  />
                  <p className="news_post_detail_post_title">
                    {doc.post_title}
                  </p>
                  <p className="news_post_detail_post_date">
                    By {doc.author}
                    <br />
                    {this.getDateAndAuthor(doc)}
                  </p>
                  <div id={"ATD_BH_300x250_P16"} />
                  <div className="post_html_content">
                    {
                    this.transform(doc.post_content)
                    }
                  </div>
                </div>
                <div className="trending_you_may_also_like">
                  <div
                    style={{ marginBottom: "3.25vw", marginLeft: "3vw" }}
                    className="trending_you_may_also_like_header"
                  >
                    Recommended for you
                  </div>
                  <SimilarContent similarUrl={similarUrl} content_type="news" />
                </div>
                <div className="trending_you_may_also_like">
                  <div
                    style={{ marginBottom: "3.25vw" }}
                    className="trending_you_may_also_like_header"
                  />
                  <YouMayAlsoLikeApiParser />
                </div>
              </div>
            </div>
          );
        });
        this.setState({ postDetail: postDetail });
      });
  }
  render() {
    let data;
    const { loading } = this.state;
    if (loading) {
      data = <div className="loader" />;
    } else {
      data = (
        <div className="trending_celeb_content" style={{ textAlign: "center" }}>
          {this.state.postDetail}
        </div>
      );
    }
    return <div>{data}</div>;
  }
}
export default trendingDetailItemApiParser;
