import React from "react";
import { useSwipeable } from "react-swipeable";
import {
  Wrapper,
  CarouselContainer,
  CarouselSlot,
  PREV,
  NEXT
} from "./components";

const getOrder = ({ index, pos, numItems }) => {
  return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
};
const initialState = { pos: 0, sliding: false, dir: NEXT };

const Carousel = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const numItems = React.Children.count(props.children);
  const slide = dir => {
    dispatch({ type: dir, numItems });
    setTimeout(() => {
      dispatch({ type: "stopSliding" });
    }, 10);
  };
  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });
  console.log(window.innerWidth)
  console.log(window.innerHeight) //(((window.innerHeight - 322-56)/2)*100)
  // var customPadding = window.innerWidth/8 +"px";
  var customPadding = window.innerWidth/9 +"px";
  // var customBottomPadding = (((window.innerHeight-((window.innerHeight*52)/100)))*100)/window.innerWidth +"px";
  var customBottomPadding  ;
  if( window.innerHeight-window.innerWidth>200){
    customBottomPadding =( window.innerHeight-window.innerWidth)/parseFloat(1.38) +"px";
  }else if( window.innerHeight-window.innerWidth>190 &&(window.innerHeight-window.innerWidth)<=199 ){
    customBottomPadding =(window.innerHeight-window.innerWidth)/parseFloat(1.7)+"px";
  }
  else{
    customBottomPadding =(window.innerHeight-window.innerWidth)/parseFloat(2.08)+"px";
  }
  
  console.log(customPadding);
  console.log(customBottomPadding)//paddingTop:customPadding,paddingBottom:customBottomPadding
  return (
    <div {...handlers}>
      <Wrapper style={{paddingTop:customPadding,paddingBottom:customBottomPadding}}>
        <CarouselContainer dir={state.dir} sliding={state.sliding}>
          {React.Children.map(props.children, (child, index) => (
            <CarouselSlot
              key={index}
              order={getOrder({ index: index, pos: state.pos, numItems })}
            >
              {child}
            </CarouselSlot>
          ))}
        </CarouselContainer>
      </Wrapper>
    </div>
  );
};

function reducer(state, { type, numItems }) {
  switch (type) {
    case "reset":
      return initialState;
    case PREV:
      return {
        ...state,
        dir: PREV,
        sliding: true,
        pos: state.pos === 0 ? numItems - 1 : state.pos - 1
      };
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        pos: state.pos === numItems - 1 ? 0 : state.pos + 1
      };
    case "stopSliding":
      return { ...state, sliding: false };
    default:
      return state;
  }
}

export default Carousel;