import React, {
    Component
} from "react";
import Grid from '@material-ui/core/Grid';
import {CelebMorePhotos} from '../../../utils/apiUrls.js';
import Toolbar from '../../../../components/basecomponents/toolbar/toolbar.js';
import BaseImageComponent from '../../../basecomponents/baseImageComponent.js';
// import './gallerySectionApiParser.css';
import LazyLoad from 'react-lazy-load';

class DetailMorePhotosCelebSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            celeb_name:"",
            category_id: "",
            photos: [],
            bottomTabSelected:"",
            loading: true,
            celeb_id:""
        };
    }

    getTitle(doc) {
        if (doc != null) {
            return doc.post_title;
        }
    }

    getCategoryName(id){
        switch(id){
            case "6":
            return "Movie Stills";
            case "2":
            return "First Look";
            case "7":
            return "On The Sets";
            case "12":
            return "Parties And Events";
            case "8":
            return "Celeb Photos";
            case "3":
            return "Celeb Wallpapers";
            case "4":
            return "Movie Wallpapers";
            default: return "Photos";
        }
    }
    handleSectionClick() {
        window.location='/celebdetail?id='+this.state.celeb_id;
    }
    handlePhotoClick = (doc) => {
        console.log("doc.section : " + doc.category_id);
        console.log("doc.details_pid : " + doc.details_pid);
        console.log("doc.parent_id : " + doc.parent_id);
        console.log("doc : " + JSON.stringify(doc));
        if (doc.category_id === undefined && (this.state.category_id === 12 || this.state.category_id === 3 || this.state.category_id === 4)) {
            console.log("doc.parent_id inside : " + doc.parent_id);
            window.location = '/galleryallphotos?id=' + doc.id + "&section=" + this.state.category_id +
                    "&detailsId=" + doc.details_pid + "&parentId=" + doc.parent_id+"&tab=celebs";
        } else if (this.state.category_id === 8) {
            window.location = '/galleryallphotos?id=' + doc.id + "&section=" + this.state.category_id+"&details_title="+doc.details_title+"&tab=celebs";
        } else {
            window.location = '/galleryallphotos?id=' + doc.id + "&section=" + doc.category_id +
                    "&detailsId=" + doc.details_pid + "&parentId=" + doc.parent_id+"&tab=celebs";
        }
        // window.location='/galleryphoto?id='+doc.id+"&section="+this.state.category_id+"&detailsId="+doc.details_pid;
    };
    componentDidMount() {
        const params = new URL(window.location.href).searchParams;
        const id = params.get('id');
        const tags =params.get('tags');
        const back = params.get('back');
        this.setState({ category_id: id,celeb_name:tags ,celeb_id:back});
        
        var finalurl = CelebMorePhotos+"/"+tags+"/"+id;
        console.log("finalurl : "+finalurl);
        var thumb_imagesize ={
            width:"44.25vw",
            height:"44.25vw",
            borderRadius: "2vw",
            objectFit: "contain",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#efefef",
          }
          var  title={
            fontSize:"4.5vw",
            color:"#000000",
            fontWeight: 900,
            margin: 0,
            width: "41vw",
            textAlign: "left",
            height: "5vw",
            marginLeft:"0.5vw",
            marginTop: "10vw",
            marginBottom: "3.625vw",
            overflow:"hidden",
            textOverflow:"ellipsis",
        }

        fetch(finalurl)
        .then(results=>{
            return results.json();
        }).then(data =>{
            this.setState({loading:false});
            let photosList = data.photos.docs.map((doc, index) => {
                // console.log(doc)
                var value = doc.id;
                return (
                    <Grid key={value} item> {
                        <div  key={value} onClick={this.handlePhotoClick.bind(this,doc)}>
                            <LazyLoad height={142} offsetVertical={60}>
                            <BaseImageComponent  image_thumb={doc.image_thumb} customStyle={thumb_imagesize}/>
                            </LazyLoad>
                            <p style={title}>{this.getTitle(doc)}</p>
                        </div>
                    }</Grid>
                )
            })
            this.setState({ photos: photosList });
        });

    };
    render() {
        let data;
        const { loading } = this.state;
        if (loading) {
            data = <div className="loader"></div>
        }
        else {
            data = 
                <div>
                    <div className="toolbar" style={{ paddingBottom: "3.125vw"}}>
                        <Toolbar  callbackFromParent={"hidden"} />
                    </div>
                    <div className="gallery_section_container"style={{ background: "#F7F7F7"}} >
                        <img className="gallery_section_more_arrow" src={require('../../../imagesvg/left_red_arrow.svg')} onClick={this.handleSectionClick.bind(this)} alt="more" />
                        <p className="gallery_detail_section_photos">{this.state.celeb_name}</p>
                    </div>
                    <div className="gallery_detail_section_title" style={{ background: "#FFFFFF",marginBottom:"4.6875vw",marginTop:"38.125vw" }}>
                        <span className="section_name">{this.getCategoryName(this.state.category_id)}</span>
                    </div>
                    <Grid container justify="center" spacing={8}>
                    {this.state.photos}
                    </Grid>
                  
                </div>
        }
        return (
            <div>
                {data}
            </div>
        );


    };
}
export default DetailMorePhotosCelebSection