import React, { Component } from "react";
import "./searchPage.css";
import { SearchAllResults } from "../../../utils/apiUrls.js";
import SearchResultsCommonItem from "../js/searchResultsCommonItem.js";
import { SearchEvent } from "../../../analytics_events/js/CustomEvents";

class searchResultPage extends Component {
constructor(props) {
super(props);
this.state = {
searchtext: "",
typeOfData: [
"movies",
"videos",
"images",
"news",
"features",
"event",
"wallpapers"
],
allSearchResults: [],
loading: true
};
}
handleBackArrow() {
console.log("back arrow");
window.location = "/search?id=0";
}
showSearchResults(searchText, event) {
console.log("show results" + searchText);
}
handleMovieClickedForReviewDetail(id, event) {
window.location = "/moviereviewdetail?id=" + id + "&tab=search";
}
getImage(image_thumb) {
var customimage = image_thumb;
if (customimage === undefined || customimage === null) {
return customimage;
} else {
customimage = image_thumb.toString().replace("322x322", "165x212");
return customimage;
}
}
componentDidMount() {
const params = new URL(window.location.href).searchParams;
const searchtext = params.get("searchtext");
const category_type = params.get("category_type");
SearchEvent("Search_Result","Search Keywords",searchtext);
this.setState({ searchtext: searchtext });
var finalUrl =
SearchAllResults + category_type + "/" + searchtext + "/0/10";
console.log(finalUrl);

fetch(finalUrl)
.then(results => {
return results.json();
})
.then(async data => {
this.setState({ loading: false });
var allResults = await this.state.typeOfData.map(dataType => {
return (
  <SearchResultsCommonItem key={dataType} dataType={dataType} dataList={data} />
);
});
this.setState({ allSearchResults: allResults });
});
}
render() {
var data;
const { loading } = this.state;
if (loading) {
data =
<div className="loader" />;
} else {
data = (
<div className="search_results_container">
  <div className="search_results_more_arrow">
    <img src={require("../../../imagesvg/left_red_arrow.svg")} className="action_icons_back" alt="back_arrow"
      onClick={this.handleBackArrow} style={{
                left: "3vw",
                position: "absolute"
              }} />
    <p className="search_photo_name">
      All results for "{this.state.searchtext}"
    </p>
  </div>
  <div style={{
              background: "#FFFFFF",
              marginBottom: "4.6875vw",
              marginTop: "15vw",
              marginLeft: "1vw"
            }}>
    {this.state.allSearchResults}
  </div>
  <div id="ATD_BH_320x50_P5" style={{
              background: "rgba(247,247,247,0.4)",
              bottom: "0",
              textAlign: "center",
              width: "100%",
              position: "fixed",
              marginLeft:"3vw"
            }} />
</div>
);
}
return <div>{data}</div>;
}
}
export default searchResultPage;