import React, { Component } from "react";
import "../apiparsercomponents/login/signinSection.css";
import { LoginUser, LoginTypeValue, UserID,SigninAuthorizationKey,UserProfileData } from "../utils/apiUrls.js";
import {
backArrowClick,
forgotPasswordClick,
signUpClick,
setLoginInLocalStorage,
getAuthorizationHeader,
handleKeyDown
} from "../utils/commonClickActions.js";
import Popup from "../utils/customPopup.js";
import { LoginEvent } from "../analytics_events/js/CustomEvents";

class NewEmailPage extends Component {
constructor(props) {
super(props);
this.selectedField = "";
this.state = {
showPopup: false,
popUpText: ""
};
}
togglePopup(text) {
console.log(this.state.showPopup);
setTimeout(function() { //Start the timer
  this.setState({
    showPopup: !this.state.showPopup,
    popUpText: text
    });
}.bind(this), 300)
}
componentDidMount() {
document.addEventListener("emailbackpressed", () => {
this.props.history.push("/");
});
}

onSigninClicked = () => {
var username = document.querySelector("#username");
var password = document.querySelector("#password");

console.log(JSON.stringify(username.value));
console.log(JSON.stringify(password.value));
if (this.validateString(username) && this.validateString(password)) {

var bodyStr =
"action=login&username=" +
username.value +
"&password=" +
password.value;
fetch(LoginUser, {
method: "POST",
headers: {
"Accept":"Application/json",
"Authorization":getAuthorizationHeader(SigninAuthorizationKey),
"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
},
body: bodyStr
})
.then(results => {
console.log(results);
return results.json();
})
.then(finaldata => {
console.log(finaldata);
// alert(JSON.stringify(finaldata))
if (finaldata !== null && finaldata !== undefined) {
if (finaldata.success) {
var data = finaldata.data;
var userdata = JSON.parse(finaldata.userdata);
localStorage.setItem(UserProfileData, JSON.stringify(userdata));
// alert(JSON.stringify(userdata))
var loginResponse = {
display_name: data.display_name,
email: data.user_email,
picture: data.user_url,
id: data.ID,
accessToken: data.user_activation_key,
expiresIn: data.user_registered
};
LoginEvent("User_Email","User Email Login")
localStorage.setItem(UserID, data.ID);
setLoginInLocalStorage(
LoginTypeValue.SIGNIN,
JSON.stringify(loginResponse)
);
// HA.HAController.getInstance().onUserLoggedIn(data.ID, data.user_email,LoginTypeValue.SIGNIN);
window.location = "/";
} else {
  console.log("this "+this)
if(this!==null&& this!==undefined){
this.togglePopup("Entered credentials are not valid or blank ," + finaldata.message);
}else{
  this.togglePopup("Entered credentials are not valid or blank ," + finaldata.message);
}
username.value = "";
password.value = "";
}
}
});
} else {
this.togglePopup("Enter " + this.selectedField);
}
};

validateString = inputValue => {
this.selectedField = inputValue.id;
if (
inputValue.value !== null &&
inputValue.value !== undefined &&
inputValue.value !== ""
) {
console.log(inputValue.id);
return true;
} else {
return false;
}
};

render() {
var height = window.innerHeight + "px";
return (
<div className="main">
  <div className="logindata" style={{ width: "100%", height: height }}>
    <div className="signin_section_container">
      <img className="Signin_section_back_arrow" src={require("../../components/imagesvg/left-arrow.svg")}
        onClick={backArrowClick.bind(this, "SignInPage" )} alt="more" />
      <img src={require("../imagesvg/bh_logo.png")} style={{
                height: "9vw",
                width: "33vw",
                marginLeft: "13%",
                marginTop: "5%"
              }} alt="" />
    </div>
    <div style={{ textAlign: "center", width: "100vw", paddingTop: "20%" }}>
      <input type="text" id="username" name="Username" placeholder="Username" tabIndex="1"
        onKeyDown={handleKeyDown.bind(this)} className="loginput username" />
      <input type="password" id="password" name="Password" placeholder="Password" tabIndex="2"
        onKeyDown={handleKeyDown.bind(this)} className="loginput password" />
      <input type="submit" value="Sign in" className="submit" readOnly={true} onClick={this.onSigninClicked.bind(this)}
        onKeyDown={handleKeyDown.bind(this)} tabIndex="3" />
      <p className="forgot_pass" style={{ textAlign: "center" }} onClick={forgotPasswordClick.bind(this, 0)}>
        Forgot Password?
      </p>
      <p className="forgot_pass" style={{
                textAlign: "center",
                textDecoration: "none",
                marginTop: "25vw"
              }}>
        Don't have an account yet?
      </p>
      <input type="submit" value="Sign up" style={{ marginTop: "1vw" }} tabIndex="4" onFocus={signUpClick}
        onKeyDown={handleKeyDown.bind(this)} className="submit" onClick={signUpClick} />
    </div>
  </div>
  {this.state.showPopup ? (
  <Popup text={this.state.popUpText} closePopup={this.togglePopup.bind(this)} />
  ) : null}
</div>
);
}
}

export default NewEmailPage;