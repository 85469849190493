import React, {
    Component
} from "react";

import EventVideoUrlAPIParser from './eventVideoUrlParser.js';
import { MovieDetails } from '../../../../utils/apiUrls.js';

class EventVideoDetailApiParser extends Component {
    constructor(props) {
        super(props);;
        this.state = {
            postDetail: []
        };
    }

    componentDidMount() {
        const params = new URL(window.location.href).searchParams;
        const id = params.get('id');
        const backpresstab = params.get("tab");
        const trailer_url = params.get('data');
        const finalUrl = MovieDetails + "/" + id;
        console.log(backpresstab)

        fetch(finalUrl)
            .then(results => {
                return results.json();
            }).then(data => {
                this.setState({ loading: false });
                let postDetail = data.movies_detail.docs.map((doc) => {
                    return <EventVideoUrlAPIParser data={doc} key={doc.id} backpresstab={backpresstab} trailer_url={trailer_url} />
                });
                this.setState({ postDetail: postDetail });
            });

    };
    render() {
        let data;
        const { loading } = this.state;
        if (loading) {
            data = <div className="loader"></div>
        }
        else {
            data =
                <div className="celeb_content" style={{ textAlign: "center" }}>
                    {this.state.postDetail}
                </div>
        } return (
            <div>
                {data}
            </div>
        );
    };
};
export default EventVideoDetailApiParser;
