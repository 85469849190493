import React from "react";
import {
    Link,
    withRouter
  } from 'react-router-dom';
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import {BottomNavEvent} from '../../analytics_events/js/CustomEvents.js';
import {
    ReactComponent as Trending
  } from '../../imagesvg/trending_new.svg';
  import {
    ReactComponent as BoxOffice
  } from '../../imagesvg/boxoffice_new.svg';
  import {
    ReactComponent as Celebs
  } from '../../imagesvg/celebs_new.svg';
  import {
    ReactComponent as Movies
  } from '../../imagesvg/movies_new.svg';
  import {
    ReactComponent as Videos
  } from '../../imagesvg/videos_new.svg';

import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
      display:"table-cell",
      width:"18vw",
      minWidth:"auto",
      maxWidth:"none",
      height:"100%" ,
      margin:0,
      padding:"2.5vw 0 0",
      background: "#FFFFFF",
      fontSize:"3.5vw",
      fontWeight:700,
      // background: "#D0021B",
      opacity:0.5,
      color:"#000000",
      // color:lightWhite,
    "&$selected": {
      // color: white
      height:"100%",
      width:"18vw",
      minWidth:"auto",
      maxWidth:"none",
      fontSize:"3.5vw",
      fontWeight:700,
      color:"#000000",
      opacity:1,
      margin:0,
      padding:"2.5vw 0 0",
    }
  },
  selected: {}
};

class CustomBottomNavigation extends React.Component {
    state = {
        value: 0,
        pathMap: [
          '/',
          '/movies',
          '/video',
          '/boxoffice',
          '/celebs'
        ]
      };

      componentWillReceiveProps(newProps) {
        const {
          pathname
        } = newProps.location;
        const {
          pathMap
        } = this.state;
    
        const value = pathMap.indexOf(pathname);
    
        if (value > -1) {
          this.setState({
            value
          });
        }
      }

      handleChange = (event, value) => {
        // console.log(" event  : " +event +"  "+event.currentTarget);
        this.setState({
          value
        });

        switch (window.location.pathname) {
          case "/":
            document.dispatchEvent(new Event("trendingbackpressed"));
            BottomNavEvent("Trending_Bottom_Nav","Trending Tab")
            break;
          case "/movies":
            document.dispatchEvent(new Event("moviespressed"));
            BottomNavEvent("Movies_Bottom_Nav","Movie Tab");
            break;
          case "/video":
            document.dispatchEvent(new Event("videobackpressed"));
            BottomNavEvent("Videos _Bottom_Nav","Video Tab");
            break;
          case "/boxoffice":
            document.dispatchEvent(new Event("boxofficebackpressed"));
            BottomNavEvent("Boxoffice_Bottom_Nav","Box Office Tab");
            break;
          case "/celebs":
            document.dispatchEvent(new Event("celebsbackpressed"));
            BottomNavEvent("Celebs _Bottom_Nav","Celeb Tab");
            break;
          default:
            break;
        }
      };
  render() {
    const actionClasses = this.props.classes;
    const {
        value,
        pathMap
      } = this.state;
    return (
        <BottomNavigation
        value = {value}
        showLabels={true}
        onChange = {this.handleChange}
        >
          <BottomNavigationAction
            classes={actionClasses}
            label="Trending"
            icon={<Trending />}
            component = {
                Link
              }
              to = {
                pathMap[0]
              }
          />

         <BottomNavigationAction
            classes={actionClasses}
            label="Movies"
            icon={<Movies />}
            component = {
                Link
              }
              to = {
                pathMap[1]
              }
          />
          <BottomNavigationAction
            classes={actionClasses}
            label="Videos"
            icon={<Videos />}
            component = {
                Link
              }
              to = {
                pathMap[2]
              }
          />
          <BottomNavigationAction
            classes={actionClasses}
            label="Boxoffice"
            icon={<BoxOffice />}
            component = {
                Link
              }
              to = {
                pathMap[3]
              }
          />

          <BottomNavigationAction
            classes={actionClasses}
            label="Celebs"
            icon={<Celebs />}
            component = {
                Link
              }
              to = {
                pathMap[4]
              }
          />
        </BottomNavigation>
    );
  }
}
export default  withStyles(styles)(withRouter(CustomBottomNavigation));