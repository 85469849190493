import React, { Component } from "react";
import Moment from "moment";
import "./eventVideoDetailApiParser.css";
import {
  PlayerUrl,
  PostSimilarContentBasedOnCategory
} from "../../../../utils/apiUrls.js";
import SimilarContent from "../../stories/js/similarContent.js";
import YouMayAlsoLikeApiParser from "../../stories/js/tabola_youmayalsolikedfeeds.js";
import ReactHtmlParser from "react-html-parser";
import ReactPlayer from "react-player";
import {
  shareClick,
  backArrowClick
} from "../../../../utils/commonClickActions.js";

class EventVideoUrlAPIParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backpresstab: this.props.backpresstab,
      data: this.props.data,
      dummyVideoUrl: "",
      trailer_url: this.props.trailer_url,
      errorMessage: "Oop’s something went wrong"
    };
  }

  getDateAndAuthor(doc) {
    if (doc.insertdate !== undefined || doc.insertdate !== null) {
      var formatedDate =
        Moment(doc.insertdate, "YYYYMMDDhms").format("MMM DD, YYYY - h:mm a") +
        " IST";
      return formatedDate;
    }
  }

  getDirectUrl = url => {
    fetch(url, {
      mode: "no-cors"
    })
      .then(response => response.json())
      .then(data => {
        console.log("data : " + JSON.stringify(data));
        this.setState({ dummyVideoUrl: data.video_url.videourl });
        return data.video_url.videourl;
      });
  };
  onPlayerError = event => {
    console.log(event);
    //  alert(JSON.stringify(event));
  };

  componentDidMount() {
    console.log("componentDidMount");
    var url = PlayerUrl + "/" + this.state.data.id;
    console.log(url);
    var finalURL;
    // console.log("trailer_url : " + JSON.stringify(this.state.trailer_url));
    if (
      this.state.trailer_url === undefined ||
      this.state.trailer_url === null
    ) {
      var actual = this.state.data.details_video_url;
      // console.log("actual" + actual);
      // console.log("actual url " + actual.toString().includes("youtube"));
      if (actual.toString().includes("youtube")) {
        finalURL =
          actual + "&showinfo=0&enablejsapi=1&origin=http://localhost:3000";
        // console.log("finalURL" + finalURL);
        this.setState({ dummyVideoUrl: finalURL });
      } else {
        console.log("player url " + url);
        fetch(url)
          .then(response => response.json())
          .then(data => {
            console.log(data.video_url.videourl);
            this.setState({ dummyVideoUrl: data.video_url.videourl });
          })
          .catch(e => {
            console.log("error : " + e);
            // this.setState({errorMessage :"Oop’s something went wrong"+e});
          });
      }
    } else {
      var b = new Buffer(this.state.trailer_url, "base64");
      var decodedString = b.toString();
      console.log("decodedString url " + decodedString);
      var modified_url = decodedString.toString().replace("www.", "");
      console.log("modified_url url " + modified_url);
      actual =
        modified_url + "&showinfo=0&enablejsapi=1&origin=http://localhost:3000";
      if (actual.toString().includes("youtube")) {
        finalURL = actual;
        console.log("finalURL" + finalURL);
        this.setState({ dummyVideoUrl: finalURL });
      } else {
        console.log("player this.state.data.id" + this.state.data.id);
        var urlTrailer = PlayerUrl + "/" + this.state.data.id;
        fetch(urlTrailer)
          .then(response => response.json())
          .then(data => {
            this.setState({ dummyVideoUrl: data.video_url.videourl });
          });
        // .catch((e)=>{
        //     this.setState({errorMessage:"movie trailer not available"});
        // });
      }
    }
  }
  componentWillUnmount() {
    console.log("componentWillUnmount");
  }
  render() {
    var doc = this.state.data;
    var similarUrl =
      PostSimilarContentBasedOnCategory + "/" + doc.id + "/" + doc.type;
    // this.setState({ post_name: doc.post_title, post_id: doc.id });
    var value = doc.id;
    return (
      <div className="event_post_detail_container_data" id={value} key={value}>
        <div
          className="player_container"
          style={{
            width: "100vw",
            height: "56.25vw",
            backgroundColor: "#000000"
          }}
        >
          <ReactPlayer
            url={this.state.dummyVideoUrl}
            width="100%"
            height="100%"
            className="dummy_video"
            controls
            playing
            onError={this.onPlayerError.bind(this)}
          />
        </div>
        <div className="event_detail_action_top_icons_div">
          <img
            src={require("../../../../imagesvg/left-arrow.svg")}
            className="video_action_icons_back"
            alt="back_arrow"
            onClick={backArrowClick.bind(this, this.state.backpresstab, doc.id)}
            style={{ left: "-1vw", position: "absolute" }}
          />
          <img
            src={require("../../../../imagesvg/share_white.svg")}
            onClick={shareClick.bind(
              this,
              "https://mobi.bollywoodhungama.com/postdetail?id=" + doc.id + "&tab=events"
            )}
            className="video_action_icons_share"
            alt="share"
            style={{ right: "1.125vw", position: "absolute" }}
          />
        </div>
        <div className="video_container">
          <p className="post_detail_post_title">{doc.post_title}</p>
          <p className="post_detail_post_date">
            By {doc.author}
            <br />
            {this.getDateAndAuthor(doc)}
          </p>
          <div id={"ATD_BH_320x50_P3"} />
          <div className="event_post_content">
            {ReactHtmlParser(doc.post_content)}
            <div
              className="trending_you_may_also_like"
              style={{ paddingLeft: "-1vw" }}
            >
              <p className="trending_you_may_also_like_header">
                Recommended for you
              </p>
              <SimilarContent similarUrl={similarUrl} content_type="video" />
            </div>
            <div className="trending_you_may_also_like">
              <p className="trending_you_may_also_like_header" />
              <YouMayAlsoLikeApiParser />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EventVideoUrlAPIParser;
