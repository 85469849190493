import React from 'react';
import {handleKeyDown,getAuthorizationHeader} from '../utils/commonClickActions.js';
import {LoginTypeValue,LoginType,LoginResponse,LoginUser,UserProfileData, UserID,ProfileUpdateAuthorizationKey} from '../utils/apiUrls.js';
import { LoginEvent } from '../analytics_events/js/CustomEvents.js';
import './userProfile.css';

class UpdateProfile extends React.Component {
constructor(props) {
super(props);
this.state = {
profileImage:[]
};
}
handleBackArrow(){
console.log("back arrow");
window.location="/profile?id=0";
}
validateString = inputValue => {

  if (
  inputValue.value !== null &&
  inputValue.value !== undefined &&
  inputValue.value !== ""
  ) {
  return true;
  } else {
  return false;
  }
  };
UpdateProfile=()=>{
console.log("inside update profile")
var mobileNumber = document.querySelector("#mobile");
var dateOfBirth = document.querySelector("#dob");
var gender = document.querySelector("#gender");
var country = document.querySelector("#country");
var email = document.querySelector("#email");

console.log(dateOfBirth.value)
console.log(mobileNumber.value +" | "+dateOfBirth.value+" | "+gender.value+" | "+country.value+" | "+email.value);
if(!this.validateString(mobileNumber)){
  alert("Please Enter Mobile Number");
  mobileNumber.value="";
}else if(!this.validateString(dateOfBirth)){
  alert("Please Enter Date Of Birth");
  dateOfBirth.value="";
}else if(!this.validateString(gender)){
  alert("Please Enter Gender");
  gender.value="";
}else if(!this.validateString(country)){
  alert("Please Enter Country");
  country.value="";
}else if(!this.validateString(email)){
  alert("Please Enter Email");
  email.value="";
}else{
  console.log("has all values")
  var userID = localStorage.getItem(UserID);
  if(userID!==null && userID!==undefined){

  var bodyStr ="action=profile_update&country=" +country.value +"&gender="+gender.value+"&mobile="+
mobileNumber.value+"&dob="+dateOfBirth.value+"&user_id="+userID;
fetch(LoginUser, {
method: "POST",
headers: {
"Accept":"Application/json",
"Authorization":getAuthorizationHeader(ProfileUpdateAuthorizationKey),
"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
},
body: bodyStr
})
.then(results => {
console.log(results);
return results.json();
})
.then(finaldata => {
console.log(finaldata);
if (finaldata !== null && finaldata !== undefined) {
if (finaldata.success) {
  console.log(finaldata)
  console.log(JSON.parse(finaldata.userdata).gender)
var data = JSON.parse(finaldata.userdata);

localStorage.setItem(UserProfileData, JSON.stringify(data));

window.location = "/";
} else {
  console.log("this "+this)
alert("Please try again later.")
}
}
});
  }
};
}

componentDidMount() {
LoginEvent("Update_Profile","Update Profile Link");
var username = "Profile Name";
var userImage = require('../imagesvg/user_grey.svg');
var userEmail ="";
var userMobileNumber ="";
var userDob ="";
var userGender="";
var userCountry="";

var loginType = localStorage.getItem(LoginType);
var loginResponse = JSON.parse(localStorage.getItem(LoginResponse));
var userdata = JSON.parse(localStorage.getItem(UserProfileData));
if(loginType===LoginTypeValue.FACEBOOK){
var picture = loginResponse.picture.data.url;
console.log(loginResponse.display_name);
console.log(picture);
if(loginResponse!==null&&loginResponse!==undefined ){
username=loginResponse.display_name;
userEmail=loginResponse.email;
if(userdata!==null && userdata!==undefined){
userMobileNumber = userdata.mobile_number;
userDob =userdata.dob;
userCountry =userdata.country;
userGender =userdata.gender;
}

if(picture!==null && picture!==undefined){
userImage= picture;
}
}
}else if(loginType===LoginTypeValue.GMAIL){
if(loginResponse!==null&&loginResponse!==undefined ){
username=loginResponse.display_name;
userImage= loginResponse.picture;
userEmail=loginResponse.email;
if(userdata!==null && userdata!==undefined){
userMobileNumber = userdata.mobile_number;
userDob =userdata.dob;
userCountry =userdata.country;
userGender =userdata.gender;
}
}
}else if(loginType===LoginTypeValue.MOBILE_NUMBER){
if(loginResponse!==null&&loginResponse!==undefined ){
username=loginResponse.phone_number;
userEmail=loginResponse.email;
if(userdata!==null && userdata!==undefined){
userMobileNumber = userdata.mobile_number;
userDob =userdata.dob;
userCountry =userdata.country;
userGender =userdata.gender;
}
}
}else if(loginType===LoginTypeValue.SIGNIN){
if(loginResponse!==null&&loginResponse!==undefined ){
username=loginResponse.display_name;
userImage= loginResponse.picture;
userEmail=loginResponse.email;
if(userdata!==null && userdata!==undefined){
userMobileNumber = userdata.mobile_number;
userDob =userdata.dob;
userCountry =userdata.country;
userGender =userdata.gender;
}
}
}else{
if(loginResponse!==null&&loginResponse!==undefined ){
username=loginResponse.display_name;
userImage= loginResponse.picture;
userEmail=loginResponse.email;
if(userdata!==null && userdata!==undefined){
userMobileNumber = userdata.mobile_number;
userDob =userdata.dob;
userCountry =userdata.country;
userGender =userdata.gender;
}
}
}

console.log(window.innerHeight)

if(userImage===undefined || userImage===null ||userImage===""){
  userImage= require('../imagesvg/user_grey.svg');
}
console.log(userImage)
var userdataLogin = (
  <div className="userUpdatedata" style={{ textAlign: "left" }}>
    <table>
      <tbody style={{ overflow: "scroll", display: "block" }}>
        <tr style={{ border: "none" }}>
          <td
            style={{
              textAlign: "left",
              border: "none",
              marginTop: "15vw"
            }}
          >
            <img
              src={userImage}
              style={{
                width: " 28.125vw",
                height: "28.125vw",
                background: "#f9f9f9",
                boxShadow: " 0 2px 4px 0 rgba(0,0,0,0.30)",
                marginLeft: "4vw",
                borderRadius: "15.625vw"
              }}
              alt="user"
            />
          </td>
          <td
            style={{
              width: "85vw",
              textAlign: "left",
              border: "none",
              padding: "0vw",
              paddingTop: "8vw",
              paddingBottom: "4vw",
              fontWeight: 700,
              fontSize: "5.625vw"
            }}
          >
            USER NAME <br />
            <span
              style={{ fontWeight: "400", fontSize: "4vw", color: "#999999" }}
            >
              {username}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      style={{
        width: "90vw",
        display: "flex",
        paddingLeft: "3vw",
        paddingRight: "3vw",
        paddingTop: "8vw",
        paddingBottom: "3vw",
        textAlign: "center"
      }}
    >
      <input
        type="text"
        id="mobile_code"
        name="mobile_code"
        placeholder="+91"
        value="+91"
        readOnly={true}
        className="mobile_code"
      />
      <input
        autoComplete="false"
        style={{ width: "78vw" }}
        size={10}
        maxLength={10}
        readOnly={false}
        type="number"
        id="mobile"
        name="mobile"
        defaultValue={userMobileNumber}
        className="mobile"
        tabIndex="1"
        onKeyDown={handleKeyDown.bind(this)}
      />
    </div>
    <div className="inputData">
      <input
        autoComplete="false"
        type="text"
        id="dob"
        name="dob"
        defaultValue={userDob}
        placeholder="Date of Birth (dd-mm-yyyy)"
        className="dob"
        tabIndex="2"
        onKeyDown={handleKeyDown.bind(this)}
      />
    </div>
    <div
      className="inputData"
      tabIndex="3"
      onKeyDown={handleKeyDown.bind(this)}
      style={{ display: "flex", justifyContent: "left" }}
    >
      <select
        style={{
          width: "90vw",
          padding: "2vw",
          marginTop: "2vw",
          marginBottom: "2vw"
        }}
        // tabIndex="3"
        // onKeyDown={handleKeyDown.bind(this)}
        defaultValue={userGender}
        name="gender"
        id="gender"
      >
        <option value="male" >
          Male
        </option>
        <option value="female">Female</option>
        <option value="other">I prefer not to answer</option>{" "}
      </select>
    </div>
    <div className="inputData">
      <select
        style={{
          width: "88vw",
          padding: "2vw",
          marginTop: "2vw",
          marginBottom: "2vw"
        }}
        name="country"
        id="country"
        tabIndex="4"
        onKeyDown={handleKeyDown.bind(this)}
        defaultValue={userCountry}
      >
        <option value="0" title="Where do you live?">
          Where do you live?
        </option>
        <option value="AD" title="Andorra">
          Andorra
        </option>
        <option value="AE" title="United Arab Emirates">
          United Arab Emirates
        </option>
        <option value="AF" title="Afghanistan">
          Afghanistan
        </option>
        <option value="AG" title="Antigua and Barbuda">
          Antigua and Barbuda
        </option>
        <option value="AI" title="Anguilla">
          Anguilla
        </option>
        <option value="AL" title="Albania">
          Albania
        </option>
        <option value="AM" title="Armenia">
          Armenia
        </option>
        <option value="AN" title="Netherlands Antilles">
          Netherlands Antilles
        </option>
        <option value="AO" title="Angola">
          Angola
        </option>
        <option value="AQ" title="Antarctica">
          Antarctica
        </option>
        <option value="AR" title="Argentina">
          Argentina
        </option>
        <option value="AS" title="American Samoa">
          American Samoa
        </option>
        <option value="AT" title="Austria">
          Austria
        </option>
        <option value="AU" title="Australia">
          Australia
        </option>
        <option value="AW" title="Aruba">
          Aruba
        </option>
        <option value="AX" title="Åland Islands">
          Åland Islands
        </option>
        <option value="AZ" title="Azerbaijan">
          Azerbaijan
        </option>
        <option value="BA" title="Bosnia and Herzegovina">
          Bosnia and Herzegovina
        </option>
        <option value="BB" title="Barbados">
          Barbados
        </option>
        <option value="BD" title="Bangladesh">
          Bangladesh
        </option>
        <option value="BE" title="Belgium">
          Belgium
        </option>
        <option value="BF" title="Burkina Faso">
          Burkina Faso
        </option>
        <option value="BG" title="Bulgaria">
          Bulgaria
        </option>
        <option value="BH" title="Bahrain">
          Bahrain
        </option>
        <option value="BI" title="Burundi">
          Burundi
        </option>
        <option value="BJ" title="Benin">
          Benin
        </option>
        <option value="BM" title="Bermuda">
          Bermuda
        </option>
        <option value="BN" title="Brunei Darussalam">
          Brunei Darussalam
        </option>
        <option value="BO" title="Bolivia">
          Bolivia
        </option>
        <option value="BR" title="Brazil">
          Brazil
        </option>
        <option value="BS" title="Bahamas">
          Bahamas
        </option>
        <option value="BT" title="Bhutan">
          Bhutan
        </option>
        <option value="BV" title="Bouvet Island">
          Bouvet Island
        </option>
        <option value="BW" title="Botswana">
          Botswana
        </option>
        <option value="BY" title="Belarus">
          Belarus
        </option>
        <option value="BZ" title="Belize">
          Belize
        </option>
        <option value="CA" title="Canada">
          Canada
        </option>
        <option value="CC" title="Cocos (Keeling) Islands">
          Cocos (Keeling) Islands
        </option>
        <option value="CD" title="Congo, The Democratic Republic of The">
          Congo, The Democratic Republic of The
        </option>
        <option value="CF" title="Central African Republic">
          Central African Republic
        </option>
        <option value="CG" title="Congo">
          Congo
        </option>
        <option value="CH" title="Switzerland">
          Switzerland
        </option>
        <option value="CI" title="Cote D'ivoire">
          Cote D'ivoire
        </option>
        <option value="CK" title="Cook Islands">
          Cook Islands
        </option>
        <option value="CL" title="Chile">
          Chile
        </option>
        <option value="CM" title="Cameroon">
          Cameroon
        </option>
        <option value="CN" title="China">
          China
        </option>
        <option value="CO" title="Colombia">
          Colombia
        </option>
        <option value="CR" title="Costa Rica">
          Costa Rica
        </option>
        <option value="CU" title="Cuba">
          Cuba
        </option>
        <option value="CV" title="Cape Verde">
          Cape Verde
        </option>
        <option value="CX" title="Christmas Island">
          Christmas Island
        </option>
        <option value="CY" title="Cyprus">
          Cyprus
        </option>
        <option value="CZ" title="Czech Republic">
          Czech Republic
        </option>
        <option value="DE" title="Germany">
          Germany
        </option>
        <option value="DJ" title="Djibouti">
          Djibouti
        </option>
        <option value="DK" title="Denmark">
          Denmark
        </option>
        <option value="DM" title="Dominica">
          Dominica
        </option>
        <option value="DO" title="Dominican Republic">
          Dominican Republic
        </option>
        <option value="DZ" title="Algeria">
          Algeria
        </option>
        <option value="EC" title="Ecuador">
          Ecuador
        </option>
        <option value="EE" title="Estonia">
          Estonia
        </option>
        <option value="EG" title="Egypt">
          Egypt
        </option>
        <option value="EH" title="Western Sahara">
          Western Sahara
        </option>
        <option value="ER" title="Eritrea">
          Eritrea
        </option>
        <option value="ES" title="Spain">
          Spain
        </option>
        <option value="ET" title="Ethiopia">
          Ethiopia
        </option>
        <option value="FI" title="Finland">
          Finland
        </option>
        <option value="FJ" title="Fiji">
          Fiji
        </option>
        <option value="FK" title="Falkland Islands (Malvinas)">
          Falkland Islands (Malvinas)
        </option>
        <option value="FM" title="Micronesia, Federated States of">
          Micronesia, Federated States of
        </option>
        <option value="FO" title="Faroe Islands">
          Faroe Islands
        </option>
        <option value="FR" title="France">
          France
        </option>
        <option value="GA" title="Gabon">
          Gabon
        </option>
        <option value="GB" title="United Kingdom">
          United Kingdom
        </option>
        <option value="GD" title="Grenada">
          Grenada
        </option>
        <option value="GE" title="Georgia">
          Georgia
        </option>
        <option value="GF" title="French Guiana">
          French Guiana
        </option>
        <option value="GG" title="Guernsey">
          Guernsey
        </option>
        <option value="GH" title="Ghana">
          Ghana
        </option>
        <option value="GI" title="Gibraltar">
          Gibraltar
        </option>
        <option value="GL" title="Greenland">
          Greenland
        </option>
        <option value="GM" title="Gambia">
          Gambia
        </option>
        <option value="GN" title="Guinea">
          Guinea
        </option>
        <option value="GP" title="Guadeloupe">
          Guadeloupe
        </option>
        <option value="GQ" title="Equatorial Guinea">
          Equatorial Guinea
        </option>
        <option value="GR" title="Greece">
          Greece
        </option>
        <option value="GS" title="South Georgia and The South Sandwich Islands">
          South Georgia and The South Sandwich Islands
        </option>
        <option value="GT" title="Guatemala">
          Guatemala
        </option>
        <option value="GU" title="Guam">
          Guam
        </option>
        <option value="GW" title="Guinea-bissau">
          Guinea-bissau
        </option>
        <option value="GY" title="Guyana">
          Guyana
        </option>
        <option value="HK" title="Hong Kong">
          Hong Kong
        </option>
        <option value="HM" title="Heard Island and Mcdonald Islands">
          Heard Island and Mcdonald Islands
        </option>
        <option value="HN" title="Honduras">
          Honduras
        </option>
        <option value="HR" title="Croatia">
          Croatia
        </option>
        <option value="HT" title="Haiti">
          Haiti
        </option>
        <option value="HU" title="Hungary">
          Hungary
        </option>
        <option value="ID" title="Indonesia">
          Indonesia
        </option>
        <option value="IE" title="Ireland">
          Ireland
        </option>
        <option value="IL" title="Israel">
          Israel
        </option>
        <option value="IM" title="Isle of Man">
          Isle of Man
        </option>
        <option value="IN" title="India" selected="india">
          India
        </option>
        <option value="IO" title="British Indian Ocean Territory">
          British Indian Ocean Territory
        </option>
        <option value="IQ" title="Iraq">
          Iraq
        </option>
        <option value="IR" title="Iran, Islamic Republic of">
          Iran, Islamic Republic of
        </option>
        <option value="IS" title="Iceland">
          Iceland
        </option>
        <option value="IT" title="Italy">
          Italy
        </option>
        <option value="JE" title="Jersey">
          Jersey
        </option>
        <option value="JM" title="Jamaica">
          Jamaica
        </option>
        <option value="JO" title="Jordan">
          Jordan
        </option>
        <option value="JP" title="Japan">
          Japan
        </option>
        <option value="KE" title="Kenya">
          Kenya
        </option>
        <option value="KG" title="Kyrgyzstan">
          Kyrgyzstan
        </option>
        <option value="KH" title="Cambodia">
          Cambodia
        </option>
        <option value="KI" title="Kiribati">
          Kiribati
        </option>
        <option value="KM" title="Comoros">
          Comoros
        </option>
        <option value="KN" title="Saint Kitts and Nevis">
          Saint Kitts and Nevis
        </option>
        <option value="KP" title="Korea, Democratic People's Republic of">
          Korea, Democratic People's Republic of
        </option>
        <option value="KR" title="Korea, Republic of">
          Korea, Republic of
        </option>
        <option value="KW" title="Kuwait">
          Kuwait
        </option>
        <option value="KY" title="Cayman Islands">
          Cayman Islands
        </option>
        <option value="KZ" title="Kazakhstan">
          Kazakhstan
        </option>
        <option value="LA" title="Lao People's Democratic Republic">
          Lao People's Democratic Republic
        </option>
        <option value="LB" title="Lebanon">
          Lebanon
        </option>
        <option value="LC" title="Saint Lucia">
          Saint Lucia
        </option>
        <option value="LI" title="Liechtenstein">
          Liechtenstein
        </option>
        <option value="LK" title="Sri Lanka">
          Sri Lanka
        </option>
        <option value="LR" title="Liberia">
          Liberia
        </option>
        <option value="LS" title="Lesotho">
          Lesotho
        </option>
        <option value="LT" title="Lithuania">
          Lithuania
        </option>
        <option value="LU" title="Luxembourg">
          Luxembourg
        </option>
        <option value="LV" title="Latvia">
          Latvia
        </option>
        <option value="LY" title="Libyan Arab Jamahiriya">
          Libyan Arab Jamahiriya
        </option>
        <option value="MA" title="Morocco">
          Morocco
        </option>
        <option value="MC" title="Monaco">
          Monaco
        </option>
        <option value="MD" title="Moldova, Republic of">
          Moldova, Republic of
        </option>
        <option value="ME" title="Montenegro">
          Montenegro
        </option>
        <option value="MG" title="Madagascar">
          Madagascar
        </option>
        <option value="MH" title="Marshall Islands">
          Marshall Islands
        </option>
        <option value="MK" title="Macedonia, The Former Yugoslav Republic of">
          Macedonia, The Former Yugoslav Republic of
        </option>
        <option value="ML" title="Mali">
          Mali
        </option>
        <option value="MM" title="Myanmar">
          Myanmar
        </option>
        <option value="MN" title="Mongolia">
          Mongolia
        </option>
        <option value="MO" title="Macao">
          Macao
        </option>
        <option value="MP" title="Northern Mariana Islands">
          Northern Mariana Islands
        </option>
        <option value="MQ" title="Martinique">
          Martinique
        </option>
        <option value="MR" title="Mauritania">
          Mauritania
        </option>
        <option value="MS" title="Montserrat">
          Montserrat
        </option>
        <option value="MT" title="Malta">
          Malta
        </option>
        <option value="MU" title="Mauritius">
          Mauritius
        </option>
        <option value="MV" title="Maldives">
          Maldives
        </option>
        <option value="MW" title="Malawi">
          Malawi
        </option>
        <option value="MX" title="Mexico">
          Mexico
        </option>
        <option value="MY" title="Malaysia">
          Malaysia
        </option>
        <option value="MZ" title="Mozambique">
          Mozambique
        </option>
        <option value="NA" title="Namibia">
          Namibia
        </option>
        <option value="NC" title="New Caledonia">
          New Caledonia
        </option>
        <option value="NE" title="Niger">
          Niger
        </option>
        <option value="NF" title="Norfolk Island">
          Norfolk Island
        </option>
        <option value="NG" title="Nigeria">
          Nigeria
        </option>
        <option value="NI" title="Nicaragua">
          Nicaragua
        </option>
        <option value="NL" title="Netherlands">
          Netherlands
        </option>
        <option value="NO" title="Norway">
          Norway
        </option>
        <option value="NP" title="Nepal">
          Nepal
        </option>
        <option value="NR" title="Nauru">
          Nauru
        </option>
        <option value="NU" title="Niue">
          Niue
        </option>
        <option value="NZ" title="New Zealand">
          New Zealand
        </option>
        <option value="OM" title="Oman">
          Oman
        </option>
        <option value="PA" title="Panama">
          Panama
        </option>
        <option value="PE" title="Peru">
          Peru
        </option>
        <option value="PF" title="French Polynesia">
          French Polynesia
        </option>
        <option value="PG" title="Papua New Guinea">
          Papua New Guinea
        </option>
        <option value="PH" title="Philippines">
          Philippines
        </option>
        <option value="PK" title="Pakistan">
          Pakistan
        </option>
        <option value="PL" title="Poland">
          Poland
        </option>
        <option value="PM" title="Saint Pierre and Miquelon">
          Saint Pierre and Miquelon
        </option>
        <option value="PN" title="Pitcairn">
          Pitcairn
        </option>
        <option value="PR" title="Puerto Rico">
          Puerto Rico
        </option>
        <option value="PS" title="Palestinian Territory, Occupied">
          Palestinian Territory, Occupied
        </option>
        <option value="PT" title="Portugal">
          Portugal
        </option>
        <option value="PW" title="Palau">
          Palau
        </option>
        <option value="PY" title="Paraguay">
          Paraguay
        </option>
        <option value="QA" title="Qatar">
          Qatar
        </option>
        <option value="RE" title="Reunion">
          Reunion
        </option>
        <option value="RO" title="Romania">
          Romania
        </option>
        <option value="RS" title="Serbia">
          Serbia
        </option>
        <option value="RU" title="Russian Federation">
          Russian Federation
        </option>
        <option value="RW" title="Rwanda">
          Rwanda
        </option>
        <option value="SA" title="Saudi Arabia">
          Saudi Arabia
        </option>
        <option value="SB" title="Solomon Islands">
          Solomon Islands
        </option>
        <option value="SC" title="Seychelles">
          Seychelles
        </option>
        <option value="SD" title="Sudan">
          Sudan
        </option>
        <option value="SE" title="Sweden">
          Sweden
        </option>
        <option value="SG" title="Singapore">
          Singapore
        </option>
        <option value="SH" title="Saint Helena">
          Saint Helena
        </option>
        <option value="SI" title="Slovenia">
          Slovenia
        </option>
        <option value="SJ" title="Svalbard and Jan Mayen">
          Svalbard and Jan Mayen
        </option>
        <option value="SK" title="Slovakia">
          Slovakia
        </option>
        <option value="SL" title="Sierra Leone">
          Sierra Leone
        </option>
        <option value="SM" title="San Marino">
          San Marino
        </option>
        <option value="SN" title="Senegal">
          Senegal
        </option>
        <option value="SO" title="Somalia">
          Somalia
        </option>
        <option value="SR" title="Suriname">
          Suriname
        </option>
        <option value="ST" title="Sao Tome and Principe">
          Sao Tome and Principe
        </option>
        <option value="SV" title="El Salvador">
          El Salvador
        </option>
        <option value="SY" title="Syrian Arab Republic">
          Syrian Arab Republic
        </option>
        <option value="SZ" title="Swaziland">
          Swaziland
        </option>
        <option value="TC" title="Turks and Caicos Islands">
          Turks and Caicos Islands
        </option>
        <option value="TD" title="Chad">
          Chad
        </option>
        <option value="TF" title="French Southern Territories">
          French Southern Territories
        </option>
        <option value="TG" title="Togo">
          Togo
        </option>
        <option value="TH" title="Thailand">
          Thailand
        </option>
        <option value="TJ" title="Tajikistan">
          Tajikistan
        </option>
        <option value="TK" title="Tokelau">
          Tokelau
        </option>
        <option value="TL" title="Timor-leste">
          Timor-leste
        </option>
        <option value="TM" title="Turkmenistan">
          Turkmenistan
        </option>
        <option value="TN" title="Tunisia">
          Tunisia
        </option>
        <option value="TO" title="Tonga">
          Tonga
        </option>
        <option value="TR" title="Turkey">
          Turkey
        </option>
        <option value="TT" title="Trinidad and Tobago">
          Trinidad and Tobago
        </option>
        <option value="TV" title="Tuvalu">
          Tuvalu
        </option>
        <option value="TW" title="Taiwan, Province of China">
          Taiwan, Province of China
        </option>
        <option value="TZ" title="Tanzania, United Republic of">
          Tanzania, United Republic of
        </option>
        <option value="UA" title="Ukraine">
          Ukraine
        </option>
        <option value="UG" title="Uganda">
          Uganda
        </option>
        <option value="UM" title="United States Minor Outlying Islands">
          United States Minor Outlying Islands
        </option>
        <option value="US" title="United States">
          United States
        </option>
        <option value="UY" title="Uruguay">
          Uruguay
        </option>
        <option value="UZ" title="Uzbekistan">
          Uzbekistan
        </option>
        <option value="VA" title="Holy See (Vatican City State)">
          Holy See (Vatican City State)
        </option>
        <option value="VC" title="Saint Vincent and The Grenadines">
          Saint Vincent and The Grenadines
        </option>
        <option value="VE" title="Venezuela">
          Venezuela
        </option>
        <option value="VG" title="Virgin Islands, British">
          Virgin Islands, British
        </option>
        <option value="VI" title="Virgin Islands, U.S.">
          Virgin Islands, U.S.
        </option>
        <option value="VN" title="Viet Nam">
          Viet Nam
        </option>
        <option value="VU" title="Vanuatu">
          Vanuatu
        </option>
        <option value="WF" title="Wallis and Futuna">
          Wallis and Futuna
        </option>
        <option value="WS" title="Samoa">
          Samoa
        </option>
        <option value="YE" title="Yemen">
          Yemen
        </option>
        <option value="YT" title="Mayotte">
          Mayotte
        </option>
        <option value="ZA" title="South Africa">
          South Africa
        </option>
        <option value="ZM" title="Zambia">
          Zambia
        </option>
        <option value="ZW" title="Zimbabwe">
          Zimbabwe
        </option>
        <option value="other" title="Other">
          Other
        </option>
      </select>
    </div>
    <div className="inputData">
      <input
        autoComplete="false"
        type="email"
        color="#000000"
        value={userEmail}
        id="email"
        name="email"
        placeholder="Email"
        className="email"
        tabIndex="5"
        onKeyDown={handleKeyDown.bind(this)}
      />
    </div>
    <div
    tabIndex="5"
    onKeyDown={handleKeyDown.bind(this)}
      style={{
        width: "100vw",
        textAlign: "center",
        margin: "0",
        marginTop: "8vw"
      }}
    >
      <button
        style={{
          height: "15vw",
          width: "fit-content",
          backgroundColor: "#D94D3D",
          color: "#ffffff",
          borderRadius: "10vw",
          fontSize: "5vw",
          paddingLeft: "10vw",
          paddingRight: "10vw",
          border: "none",
          fontWeight: 400
        }}
        onClick={this.UpdateProfile.bind(this)}
      >
        Update
      </button>
    </div>
  </div>
);
this.setState({profileImage:userdataLogin})
}
render() {
var data;
const {loading} = this.state;
if (loading) {
data = <div className="loader"></div>
} else {
data =
<div className="profile_container">
  <div className="profile_arrow">
    <img src={require("../imagesvg/left_red_arrow.svg")} className="action_icons_back" alt="back_arrow"
      onClick={this.handleBackArrow} style={{
          left: "3vw",
      }} />
    <p className="profile_name">Profile</p>
  </div>
  <div style={{
                background: "#FFFFFF",
                marginBottom: "4.6875vw",
                paddingTop: "11.625vw",
                width:"100vw",
                // marginLeft:"3vw",
                marginRight:"3vw",
                textAlign:"center",
            }}>
    {this.state.profileImage}
  </div>
</div>
};
return (<div>
  {data}
</div>);
}
}


export default UpdateProfile;