import React from 'react';
import './eventCardView.css';
import Grid from '@material-ui/core/Grid';
import {IndexDB,TopPartiesAndEventsSection} from '../../../../utils/apiUrls.js';
import BaseImageComponent from '../../../../basecomponents/baseImageComponent.js';

class EventCardView extends React.Component {
constructor(props) {
    super(props);
    this.state = {
    top_events:[],
    latest_events:[],
    extra_events:[],
    data:this.props.data,
    section_name:props.section_name,
    start :10,
    limit :props.limit,
    error: false,
    hasMore: true,
    isLoading: false,
    };
     // Binds our scroll event handler
     window.onscroll = () => {
      const {
         loadTopVideos,
          state: {
          error,
          isLoading,
          hasMore,
        },
      } = this;

      // Bails early if:
      // * there's an error
      // * it's already loading
      // * there's nothing left to load
      if (error || isLoading || !hasMore) return;
      // console.log("loadTopVideos window.innerHeight"+window.innerHeight);
      // console.log("loadTopVideos document.documentElement.scrollTop"+document.documentElement.scrollTop);
      // console.log("loadTopVideos document.documentElement.offsetHeight"+document.documentElement.offsetHeight);
      // Checks that the page has scrolled to the bottom
      if (
        window.innerHeight + document.documentElement.scrollTop
        >= document.documentElement.offsetHeight -200
      ) {
       setTimeout(loadTopVideos(),1000);
      }
    };
}

loadTopVideos = () => {
  var currentTimeStamp = new Date().getTime();

  this.setState({ isLoading: true }, () => {
      var finalUrl =TopPartiesAndEventsSection +"/trending/"+this.state.start+"/"+this.state.limit;
      console.log(this.state.start)
      if(this.state.start === 10){
        IndexDB.home_top_music_api.get(finalUrl).then((data)=>{
          if(data === undefined){
            fetch(finalUrl)
            .then(results=>{
              return results.json();
            })
            .then((data) => {
              this.createUIComponent(data);
              IndexDB.home_top_music_api.put({ID:finalUrl,data:data,timestamp:currentTimeStamp});
            }).catch((err) => {
              this.setState({
                error: err.message,
                isLoading: false,
                });
            });
          }else{
            var difference = (currentTimeStamp-data.timestamp)/60000;
            console.log(difference);
            if(difference>=30){
              fetch(finalUrl)
              .then(results=>{
                return results.json();
            })
            .then(async(data) => {
              console.log("inside data");
              var updated= await IndexDB.home_top_music_api.update(finalUrl, {ID:finalUrl,data:data,timestamp:currentTimeStamp})
              if(updated&& this !== undefined && this!== null){
                this.createUIComponent(data)
              }
            });
            }else{
              this.createUIComponent(data.data);
            }
          }
        }).catch(function (e) {
          console.log("Error: " + (e.stack || e));
      });
      }else{
        fetch(finalUrl)
            .then(results=>{
              return results.json();
            })
            .then((data) => {
              this.createUIComponent(data);
            }).catch((err) => {
              this.setState({
                error: err.message,
                isLoading: false,
                });
            });
      }
      
  });
  }

handlePlayContainerClick(data,event){
window.location="/eventvideodetail?id="+data.id+"&tab=events";
}
createUIComponent(data){
  var video_small_thumb_imagesize ={
    width: "42vw",
    height: "23.63vw",
   borderRadius: "2vw",
    backgroundColor: "#efefef",
    objectFit: "contain",
    backgroundRepeat: "no-repeat",
  }

  const totalCount = data.photos.numFound;
  let next_extra_events =data.photos.docs.map((photo)=>{
    var data = photo
        return (
            <Grid key={data.id} item> {
                <div className="small_video_container_data" key={data.id} style={{paddingRight:"3.125vw"}} onClick={this.handlePlayContainerClick.bind(this,data)}>
                <img className="event_small_play_icon" src={require('../../../../imagesvg/play_copy.svg')} alt="play"/>
                <BaseImageComponent expectedRatio={"354x199"} image_thumb={this.getImage(data.image_thumb[0])} customStyle={video_small_thumb_imagesize}/>
                <div className="event_video_title">{data.post_title}</div>
                </div>  
            }</Grid>
        )                         
  });
this.setState({
  start:(this.state.start +this.state.limit),
  hasMore: (this.state.extra_events.length < totalCount),
  isLoading: false,
  extra_events: [
    ...this.state.extra_events,
    ...next_extra_events,
  ],
});
}

getImage(image_thumb) {
  var customimage = image_thumb;
  if(customimage === undefined || customimage === null){
    return customimage;
  }else{
    customimage = image_thumb.toString().replace("322x322","354x199");
    return customimage;
  }
}
componentDidMount(){//componentWillMount
  var large_image_style={
    width: "85vw",
    height: "47.81vw",
    backgroundSize: "contain",
    backgroundColor: "#efefef",
    backgroundRepeat: "no-repeat",
   borderRadius: "2vw",
  }

  var extralarge_thumb_image={
    width: "78vw",
    height: "78vw",
    backgroundSize: "contain",
    backgroundColor: "#efefef",
    backgroundRepeat: "no-repeat",
   borderRadius: "2vw"
  }

  var video_small_thumb_image= {
    width: "42vw",
    height: "23.63vw",
    backgroundSize: "contain",
    backgroundColor: "#efefef",
    backgroundRepeat: "no-repeat",
   borderRadius: "2vw"
}
  //TODO: need to change photos count
  if(this.state.section_name==="Top Parties & Events"){
    let top_events = this.state.data.docs.map((photo)=>{
      var data = photo;
      return (                          
        <div className="video_container_data" key={data.id} onClick={this.handlePlayContainerClick.bind(this,data)}>  
          <img className="event_play_icon" src={require('../../../../imagesvg/play_copy.svg')} alt="play"/>
          <BaseImageComponent expectedRatio={"354x199"} image_thumb={this.getImage(data.image_thumb[0])} customStyle={large_image_style}/>
          <div className="eventCardtopVideoTitle">{data.post_title}</div>
        </div>
      )
  });
  this.setState({top_events:top_events});
}   
else if (this.state.section_name==="Parties & Events"){
// console.log("this.state.section_name"+this.state.section_name);
    let latest_events = this.state.data.docs.map((photo)=>{
      var data = photo;
      return (   
        <div className="event_video_container_data" key={data.id}  onClick={this.handlePlayContainerClick.bind(this,data)}>  
        <BaseImageComponent expectedRatio={"354x199"} image_thumb={data.image_thumb[0]} customStyle={extralarge_thumb_image}/>
        <div className="eventCardVideoTitle">{data.post_title}</div>
        </div>
          )
        });
        this.setState({latest_events:latest_events});
}else{
let extra_events = this.state.data.docs.map((photo)=>{
  var data = photo
      return (
          <Grid key={data.id} item> {
              <div className="small_video_container_data" key={data.id} style={{paddingRight:"3.125vw"}} onClick={this.handlePlayContainerClick.bind(this,data)}>
              <img className="event_small_play_icon" src={require('../../../../imagesvg/play_copy.svg')} alt="play"/>
              <BaseImageComponent expectedRatio={"354x199"} image_thumb={this.getImage(data.image_thumb[0])} customStyle={video_small_thumb_image}/>
              <div className="event_video_title">{data.post_title}</div>
              </div>  
          }</Grid>
      )                         
});
console.log("inside extra_events")
this.setState({extra_events:extra_events})
}
}

render() {
  const {
    extra_events,
    error,
    hasMore,
    isLoading,
  } = this.state;

  return (
    <div>
    <section className="section_card" style={{display:"flex",overflowX: "auto",background:"#ffffff",marginLeft:"2vw"}}>
    {this.state.top_events}
    </section>
    <section className="section_card" style={{display:"flex",overflowX: "auto",overflowY:"hidden",background:"#ffffff",marginLeft:"2vw"}}>
    {this.state.latest_events}
    </section>
    <section style={{marginLeft:"2vw"}} >
    <Grid container className="grid" justify="flex-start"spacing={8}>
    {extra_events}
    </Grid>
        {error &&
          <div style={{ color: '#900',textAlign:"center",padding:"3.125vw"  }}>
            {error}
          </div>
        }
        {isLoading &&
          <div style={{textAlign:"center",padding:"3.125vw",fontWeight:"700",paddingBottom:"10vw"}}>Loading...</div>
        }
        {!hasMore &&
          <div style={{textAlign:"center",padding:"3.125vw"}}>You did it! You reached the end!</div>
        }
    </section>
    </div>
  );
}
}

export default EventCardView;