import React, {
    Component
} from "react";
import {IndexDB} from '../../../../utils/apiUrls.js';
import './videoAllApiParser.css';
import CelebsInterviewApiParser from '../../celebinterview/videoCelebInterviewApi.js';
import EventRowApiParser from '../../../trending_section/events/js/eventRowApiParser.js';
import {CelebsInterview,Events } from '../../../../utils/apiUrls.js';
import {bottomNavigationColor,blackColor} from '../../../../utils/color.js';
import VideosAllSectionApiParser from '../../topvideo/js/VideoAllSectionApiParser.js';
import { VideoEvent } from "../../../../analytics_events/js/CustomEvents.js";

class VideosAllApiParser extends Component {
constructor(props) {
    super(props);
    this.state = {
        home_tabs:[],
        loading: true,
        top_videos : [],
        watch_now_videos:[],
        most_liked_videos:[],
        bottomTabSelected:props.tab_selected,
        url:props.url,
        active:null,
        subtabs:[]
    };
}
getImage(doc) {
    if(doc!=null){
        if(doc=== undefined || doc=== null){
        return require('../../../../imagesvg/placeholder.png');
        }else{
        return doc;
        }
        }
    }
getTitle(doc){
    if(doc!=null){
        return doc.post_title;
    }
}

handleSubTabClick(index,event) {
    this.setState({active : index});
    console.log('this.state.active after', this.state.active +" index : "+index);
    this.setStyle(index);
    if(event!==null && event.currentTarget!==undefined && event.currentTarget.id!==undefined){
    let container_id =event.currentTarget.id;
    this.setState({home_tabs_selected_id:container_id});
    console.log("container : "+event.currentTarget.id);
    if(container_id ==="TOP VIDEOS"){
        console.log(" inside container TOP VIDEOS : "+event.currentTarget.id);
        window.location="/video";
    }else if(container_id ==="CELEB INTERVIEW"){
        console.log(" inside containerCELEB INTERVIEW : "+event.currentTarget.id);
        var celeb_videos =  <CelebsInterviewApiParser tab_selected ={"videos"} url ={CelebsInterview}/>
        this.setState({subtabs:celeb_videos});
    }else{
        console.log(" inside container EVENT : "+event.currentTarget.id);
        var party_videos =  <EventRowApiParser data ={"videos"} url ={Events}/>
        this.setState({subtabs:party_videos});
    }
}
}

getDetailCOntent(){
    let listTabs=this.state.home_tabs;
    let tab = listTabs[0];
    if(tab!==null && tab!==undefined && tab.props.id==="TOP VIDEOS"){
        let ele = document.getElementById(tab.props.id);
       if(ele!==undefined && ele!==null){
        ele.style.color =bottomNavigationColor;
        ele.style.opacity=1;
       }
    }
  this.setState({active:0});
}

setStyle(index){
let listTabs=this.state.home_tabs;

let tab = listTabs[index];
// console.log("detail_Tabs : "+JSON.stringify(listTabs[0].props.id));
let ele = document.getElementById(tab.props.id);

listTabs.map((item)=>{
    let ele = document.getElementById(item.props.id);
    ele.style.color =blackColor;
    ele.style.opacity=0.2;
    return "";
});
if(ele!==undefined){
    ele.style.color =bottomNavigationColor;
    ele.style.opacity=1;
}
}
headerTitleCliked(){
    window.location="/video";
}
componentDidMount(){
    VideoEvent("Videos_Tab_Top_Videos","Video Tab");
let title_style={
    color:blackColor,
    fontWeight: 900,
    textDecoration: "none",
    fontSize: "9.375vw",
    marginLeft: "3.125vw",
    whiteSpace: "nowrap"
    }
    
let heading =  document.querySelectorAll(".toolbar__title");
heading[0].innerHTML="Videos";
heading[0].onclick= this.headerTitleCliked.bind(this);
for (var property in title_style) {
heading[0].style[property] = title_style[property];
}

var currentTimeStamp = new Date().getTime();
IndexDB.video_top_section_api.get(this.state.url).then((data)=>{
    if(data === undefined){
        fetch(this.state.url)
    .then(results=>{
        return results.json();
    })
    .then((data) => {
    this.createUIComponent(data);
    IndexDB.video_top_section_api.put({ID:this.state.url,data:data,timestamp:currentTimeStamp});
    });
    }else{
    var difference = (currentTimeStamp-data.timestamp)/60000;
    console.log(difference);
        if(difference>=30){
        fetch(this.state.url)
        .then(results=>{
            return results.json();
        }).then(async(data) => {
            var updated= await IndexDB.video_top_section_api.update(this.state.url, {ID:this.state.url,data:data,timestamp:currentTimeStamp});
            console.log(updated);
            if(updated && this !== undefined && this!== null){
            this.createUIComponent(data)
            }
        });
        }else{
        this.createUIComponent(data.data);
        }
    }
}).catch(function (e) {
    console.log("Error: " + (e.stack || e));
});

};
createUIComponent=(data)=>{
    this.setState({ loading: false });
    // console.log("videos : "+JSON.stringify(data.section_videos[0]));
    if(data.home_tabs!==undefined && data.home_tabs!== null){
    let home_tabs = data.home_tabs.map((home_tab,index)=>{
        return <button className="homelinks" key={index} id={home_tab} onClick={this.handleSubTabClick.bind(this,index)}>{home_tab}</button>
    });
    this.setState({home_tabs:home_tabs});
}
    this.getDetailCOntent();
    var sections =   <VideosAllSectionApiParser data ={data} />
    this.setState({subtabs:sections});
}
render()  {       
        let data;
        const { loading } = this.state;
            if(loading) {
            data =<div className="loader"></div>
            } 
            else{      
                data =
                <div>
                    <div className="home_tabs">
                {this.state.home_tabs}
                </div>
                <div className="api_content" key={"video"+Math.random()} style={{paddingTop:"15.625vw"}}>
                    {this.state.subtabs}
                </div>
                </div>
        }
        return(
            <div>
                {data}
            </div>
            );
    };
};
export default VideosAllApiParser;