import React, { Component } from "react";
import { createBrowserHistory } from "history";
import Moment from "moment";
import { IndexDB } from "../../../../utils/apiUrls.js";
import StarRatingComponent from "react-star-rating-component";
import "../../update/js/boxOfficeApiParse.css";
import { BoxOfficeEvent } from "../../../../analytics_events/js/CustomEvents.js";

require("../../starpower/js/boxOfficeTabsUI.css");

class BoxOfiiceCollectionAPIParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section_tab: "",
      sections: [],
      collection_domestic: [],
      collection_international: [],
      bottomTabSelected: props.tab_selected,
      url: props.url,
      activeTab: props.label,
      loading: true,
      content: [],
      active: null
    };
  }
  getImage(doc) {
    if (doc.image_thumb === undefined || doc.image_thumb === null) {
      return require("../../../../imagesvg/placeholder.png");
    } else {
      return doc.image_thumb;
    }
  }
  getCurrentDate(date) {
    //   console.log("moment"+Moment(date,'YYYYMMDDhms').format("DD MMM YYYY").toUpperCase());
    var formatedDate =
      Moment(date, "YYYYMMDDhms")
        .format("DD MMM")
        .toUpperCase() + "\n";
    //   var year = Moment(date,'YYYYMMDDhms').format("YYYY").toUpperCase()
    return formatedDate;
  }
  getCurrentYear(date) {
    //   console.log("moment"+Moment(date,'YYYYMMDDhms').format("DD MMM YYYY").toUpperCase());
    //   var formatedDate =Moment(date,'YYYYMMDDhms').format("DD MMM").toUpperCase()+ "\n";
    var year = Moment(date, "YYYYMMDDhms")
      .format("YYYY")
      .toUpperCase();
    return year;
  }
  getTitle(doc) {
    if (doc != null) {
      return doc.post_title;
    }
  }
  handleMovieClickedForReviewDetail(id, event) {
    window.location = "/moviereviewdetail?id=" + id + "&tab=boxoffice";
  }
  handleSubTabClick(index, event) {
    this.setState({ active: index });
    this.setStyle(index);
    let container_id = event.currentTarget.id;
    // this.setState({subtab_selected:container_id});
    // console.log("container : "+event.currentTarget.id);
    if (container_id === "DOMESTIC") {
      this.setState({ content: this.state.collection_domestic });
    } else {
      this.setState({ content: this.state.collection_international });
    }
  }
  getDetailCOntent() {
    let listTabs = this.state.sections;
    let tab = listTabs[0];
    // console.log("tab insede : "+tab.props.id);
    if (tab.props.id === "DOMESTIC") {
      let ele = document.getElementById(tab.props.id);
      // console.log("tab inside"+JSON.stringify(tab.style));
      ele.style.color = "#D0021B";
      ele.style.opacity = 1;
      ele.style.fontWeight = 900;
      ele.style.borderBottom = " 0.625vw solid #D0021B";
    }
    // var article =  <DetailArticleApiParser tab_selected ={"celeb"} url ={CelebArticlesBasedOnTagAndType} tag={this.state.post_name} type="news" />;
    this.setState({ active: 0 });
  }

  setStyle(index) {
    let listTabs = this.state.sections;

    let tab = listTabs[index];
    // console.log("detail_Tabs : "+JSON.stringify(listTabs[0].props.id));
    let ele = document.getElementById(tab.props.id);

    listTabs.map(item => {
      let ele = document.getElementById(item.props.id);
      ele.style.color = "#000000";
      ele.style.opacity = 0.5;
      ele.style.fontWeight = 900;
      ele.style.borderBottom = "0.3125vw solid #ccc";
      return "";
    });
    if (ele !== undefined) {
      ele.style.color = "#D0021B";
      ele.style.opacity = 1;
      ele.style.fontWeight = 900;
      ele.style.borderBottom = "0.625vw solid #D0021B";
    }
  }

  onClickTabItem = tab => {
    // console.log("inside click : "+tab);
    this.setState({ activeTab: tab });
  };

  componentDidMount() {
    const history = createBrowserHistory();

    // Get the current location.
    const location = history.location;
    console.log(location);
    const unlisten = history.listen((location, action) => {
      console.log(action, location.pathname, location.state);
    });
    history.push({ pathname: "/boxoffice" });
    window.onpopstate = () => {
      window.location = "/boxoffice";
    };
    unlisten();

    BoxOfficeEvent("Boxoffice_Tab_Box_Office_Collection", "Box Office Tab");
    document.addEventListener("long-press", function(e) {
      e.preventDefault();
    });
    var currentTimeStamp = new Date().getTime();
    IndexDB.boxoffice_collection_api
      .get(this.state.url)
      .then(data => {
        if (data === undefined) {
          fetch(this.state.url)
            .then(results => {
              return results.json();
            })
            .then(data => {
              this.createUIComponent(data);
              IndexDB.boxoffice_collection_api.put({
                ID: this.state.url,
                data: data,
                timestamp: currentTimeStamp
              });
            });
        } else {
          var difference = (currentTimeStamp - data.timestamp) / 60000;
          console.log(difference);
          if (difference >= 30) {
            fetch(this.state.url)
              .then(results => {
                return results.json();
              })
              .then(async data => {
                var updated = await IndexDB.boxoffice_collection_api.update(
                  this.state.url,
                  {
                    ID: this.state.url,
                    data: data,
                    timestamp: currentTimeStamp
                  }
                );
                console.log(updated);
                if (updated && this !== undefined && this !== null) {
                  this.createUIComponent(data);
                }
              });
          } else {
            this.createUIComponent(data.data);
          }
        }
      })
      .catch(function(e) {
        console.log("Error: " + (e.stack || e));
      });
  }
  createUIComponent = data => {
    this.setState({ loading: false });
    var sections = data.sub_section_tabs.map((section_tab, index) => {
      this.setState({ section_tab: section_tab });
      data.section_collection.map(section_collection => {
        if (section_collection.section_name === "DOMESTIC") {
          this.setState({ section_national: section_collection.section_name });
          let collection_domestic = section_collection.collection.docs.map(
            doc => {
              return (
                <div
                  className="boxoffice_container_data"
                  key={doc.id}
                  onClick={this.handleMovieClickedForReviewDetail.bind(
                    this,
                    doc.id
                  )}
                >
                  <img
                    className="box_extralarge_thumb_image"
                    src={require("../../../../imagesvg/box_office_collection_bg.png")}
                    alt="thumb_image"
                  />
                  <table>
                    <tbody>
                      <tr>
                        <td
                          colSpan="2"
                          style={{ textAlign: "center", padding: "0vw" }}
                        >
                          <p className="ticket_title">{this.getTitle(doc)}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p
                            style={{
                              fontSize: "5vw",
                              color: "#000000",
                              margin: 0,
                              fontWeight: "400"
                            }}
                          >
                            &#8377;
                            <b style={{ fontSize: "7vw" }}>
                              {doc.movie_collection_d}
                            </b>{" "}
                            Cr.
                          </p>
                        </td>
                        <td>
                          <p
                            style={{
                              fontSize: "3.75vw",
                              color: "rgb(104, 93, 93)",
                              margin: 0,
                              fontWeight: "400"
                            }}
                          >
                            Release date:
                            <br />
                            <b style={{ fontSize: "4.25vw" }}>
                              {this.getCurrentDate(doc.releasedate)}
                              <br />
                              {this.getCurrentYear(doc.releasedate)}
                            </b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" style={{ textAlign: "center" }}>
                          <StarRatingComponent
                            className="rating"
                            name="rating"
                            starCount={5}
                            value={doc.average_d}
                            starColor="rgb(133, 50, 50)"
                            emptyStarColor="rgb(104, 93, 93)"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="box_review">REVIEW</p>
                </div>
              );
            }
          );
          this.setState({ collection_domestic: collection_domestic });
        } else {
          this.setState({ section_tab: section_collection.section_name });
          let collection_international = section_collection.collection.docs.map(
            doc => {
              return (
                <div
                  className="boxoffice_container_data"
                  key={doc.id}
                  onClick={this.handleMovieClickedForReviewDetail.bind(
                    this,
                    doc.id
                  )}
                >
                  <img
                    className="box_extralarge_thumb_image"
                    src={require("../../../../imagesvg/box_office_collection_bg.png")}
                    alt="thumb_image"
                  />
                  <table>
                    <tbody>
                      <tr>
                        <td
                          colSpan="2"
                          style={{ textAlign: "center", padding: "0vw" }}
                        >
                          <p className="ticket_title">{this.getTitle(doc)}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p
                            style={{
                              fontSize: "5vw",
                              color: "#000000",
                              margin: 0,
                              fontWeight: "400"
                            }}
                          >
                            $
                            <b style={{ fontSize: "7vw" }}>
                              {doc.overseas_collection_d}
                            </b>{" "}
                            M
                          </p>
                        </td>
                        <td>
                          <p
                            style={{
                              fontSize: "3.75vw",
                              color: "rgb(104, 93, 93)",
                              margin: 0,
                              fontWeight: "400"
                            }}
                          >
                            Release date:
                            <br />
                            <b style={{ fontSize: "4.25vw" }}>
                              {this.getCurrentDate(doc.releasedate)}
                              <br />
                              {this.getCurrentYear(doc.releasedate)}
                            </b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan="2"
                          style={{ textAlign: "center", fontSize: "4vw" }}
                        >
                          <StarRatingComponent
                            className="rating"
                            name="rating"
                            starCount={5}
                            value={doc.average_d}
                            starColor="rgb(133, 50, 50)"
                            emptyStarColor="rgb(104, 93, 93)"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="box_review">REVIEW</p>
                </div>
              );
            }
          );
          this.setState({ collection_international: collection_international });
        }
        return "";
      });

      // console.log("section"+JSON.stringify(this.state.sections));
      if (this.state.collection_domestic !== null) {
        this.setState({ content: this.state.collection_domestic });
        // console.log("section_tab : "+section_tab);
      }
      return (
        <button
          className="tablinks"
          style={{
            paddingBottom: "2.125vw",
            paddingTop: "6vw",
            fontWeight: "900"
          }}
          key={index}
          id={section_tab}
          onClick={this.handleSubTabClick.bind(this, index)}
        >
          {section_tab}
        </button>
      );
    });
    this.setState({ sections: sections });
    this.getDetailCOntent();
  };
  render() {
    let data;
    const { loading } = this.state;
    if (loading) {
      data = <div className="loader" />;
    } else {
      data = (
        <div
          style={{
            width: "100vw",
            height: "100vw",
            backgroundColor: "#FFFFFF"
          }}
        >
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "fit-content",
              zIndex: "1"
            }}
          >
            <div className="collection_tab">{this.state.sections}</div>
          </div>
          <div
            className="boxoffice_content"
            style={{
              paddingTop: "18.625vw",
              paddingBottom: "18.25vw",
              paddingRight: "3.125vw",
              marginTop: "3vw",
              backgroundColor: "#FFFFFF"
            }}
          >
            {this.state.content}
          </div>
        </div>
      );
    }
    return <div>{data}</div>;
  }
}
export default BoxOfiiceCollectionAPIParser;
