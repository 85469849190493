import React, {Component} from "react";
import {IndexDB} from '../../../utils/apiUrls.js';
import CelebsCardView from '../js/celebsCardView.js';
import InfiniteScroll from 'react-infinite-scroller';
import '../../trending_section/gallery/js/RowView.css';
import './celebsApiParser.css';
import { CelebEvent } from "../../../analytics_events/js/CustomEvents.js";


class CelebsApiParser extends Component {
    constructor(props) {
        super(props);
        this.timeout = null;
        this._isMounted = false;
        this.state = {
            loading: true,
            movies: [],
            bottomTabSelected: props.tab_selected,
            url: props.url,
            start: 0,
            limit: 10,
            error: false,
            hasMore: false,
            isLoading: false,
            IsFavouriteAddedOrRemoved: "add"
        };
    }

 
    setTitleStyle(elId, propertyObject) {
        for (var property in propertyObject) {
            elId.style[property] = propertyObject[property];
        }
    }
    headerTitleCliked() {
        window.location = "/celebs";
    }

    componentDidMount() {
        CelebEvent("Celebs_Tab_Celebrities","Celeb Tab");
        let title_style = {
            color: "#000000",
            fontWeight: 900,
            textDecoration: "none",
            fontSize: "8.5vw",
            marginLeft: "3.125vw",
            whiteSpace: "nowrap",
        }
        let toolbar = document.querySelectorAll(".toolbar");
        toolbar[0].style['paddingBottom']="3vw";
        let heading = document.querySelectorAll(".toolbar__title");
        heading[0].innerHTML = "Celebrities";
        heading[0].onclick = this
            .headerTitleCliked
            .bind(this);
        for (var property in title_style) {
            heading[0].style[property] = title_style[property];
        }
        this._isMounted = true;
        this._isMounted && this.loadCelebs();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadCelebs = () => {
        var finalUrl = this.state.url + "/" + this.state.start + "/" + this.state.limit;
        var currentTimeStamp = new Date().getTime();
        if (this.state.start === 0) {
            IndexDB
                .celeb_section_api
                .get(finalUrl)
                .then((data) => {
                    if (data === undefined) {
                        fetch(finalUrl)
                            .then(results => {
                                return results.json();
                            })
                            .then((data) => {
                                this.createUIComponent(data);
                                IndexDB
                                    .celeb_section_api
                                    .put({ID: finalUrl, data: data, timestamp: currentTimeStamp});
                            });
                    } else {
                        var difference = (currentTimeStamp - data.timestamp) / 60000;
                        console.log(difference);
                        if (difference >= 30) {
                            fetch(finalUrl)
                                .then(results => {
                                    return results.json();
                                })
                                .then(async (data) => {
                                    var updated = await IndexDB
                                        .celeb_section_api
                                        .update(finalUrl, {
                                            ID: finalUrl,
                                            data: data,
                                            timestamp: currentTimeStamp
                                        });
                                    console.log(updated);
                                    if (updated && this !== undefined && this !== null) {
                                        this.createUIComponent(data)
                                    }
                                });
                        } else {
                            this.createUIComponent(data.data);
                        }

                    }
                })
                .catch(function (e) {
                    console.log("Error: " + (
                        e.stack || e
                    ));
                });
        } else {
            fetch(finalUrl)
                .then(results => {
                    return results.json();
                })
                .then(data => {
                    this.createUIComponent(data);
                })
        }
    };
   
    createUIComponent = (data) => {
        var thumb_imagesize = {
            width: "100vw",
            height: "56.25vw",
            borderRadius: "0vw",
            backgroundColor: "#e7e7e7",
            objectFit: "contain",
            backgroundRepeat: "no-repeat"
        }
        let totalCount = data.top_celebs.numFound;
        var adsCount =12;
        let movies =
            data
            .top_celebs
            .docs
            .map((doc,index) => {
                if (this.state.start ===0 && index> 2 && index % 3 === 0) {
                    ++adsCount;
                    console.log(adsCount)
                    return(
                        <div>
                        <div id={"ATD_BH_300x250_P"+adsCount}></div> 
                    <CelebsCardView data={doc} key={Math.random()} thumb_imagesize={thumb_imagesize} />
                    </div>);
                  } else {
                    return(<CelebsCardView data={doc} key={Math.random()} thumb_imagesize={thumb_imagesize} />);
                  }
            });
        this._isMounted && this.setState({
            loading: false,
            start: (this.state.start + this.state.limit),
            hasMore: (this.state.movies.length < totalCount),
            isLoading: false,
            movies: [
                ...this.state.movies,
                ...movies
            ]
        })
    }

    render() {
        const {loading, error, hasMore, isLoading, movies} = this.state;
        let data;
        if (loading) {
            data = <div className="loader"></div>
        } else {
            data = <div
                className="celeb_content"
                style={{
                    marginTop: "20.5vw",
                    paddingBottom: "15.625vw"
                }}>
                {movies}
            </div>
        }
        return (
            <div>
                <div>
                    <InfiniteScroll
                        initialLoad={false}
                        pageStart={this.state.start}
                        loadMore={!isLoading
                            ? this.loadCelebs
                            : () => {}}
                        hasMore={hasMore && !isLoading}
                        loader={<div className = "loader2" style = {{textAlign:"center",padding:"3.125vw",fontWeight:"700"}}key = {
                            0
                        } > Loading ...</div>}
                        useWindow={true}>
                        {data}
                    </InfiniteScroll>
                </div>
                {
                    error && <div
                            style={{
                                color: '#900',
                                textAlign: "center",
                                padding: "3.125vw"
                            }}>
                            {error}
                        </div>
                }
                {
                    isLoading && <div
                            style={{
                                textAlign: "center",
                                padding: "3.125vw"
                            }}>Loading...</div>
                }
                {
                    !hasMore && <div
                            style={{
                                textAlign: "center",
                                padding: "3.125vw"
                            }}></div>
                }
            </div>
        );
    };
};
export default CelebsApiParser;