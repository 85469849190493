import Dexie from "dexie";
var DATABASE_NAME = "Bh_PWA_DB";
var DATABASE_VERSION = 1.0;

var ADS_320x50 = "div-gpt-ad-1562907743939-0";
var ADS_300x250 = "div-gpt-ad-1562908096966-0";

// var FACEBOOK_APP_ID = "763461184028573";
var FACEBOOK_APP_ID = "375612886705685";

var SigninAuthorizationKey ="Bollywood@Entertainment$mobisignin_0";
var RegisterAuthorizationKey="Bollywood@Entertainment$mobiregister_1";
var WatchPollAuthorizationKey ="Bollywood@Entertainment$mobiwillwatch_2";
var UserFavouriteAuthorizationKey ="Bollywood@Entertainment$mobifavorites_3";
var SearchTrendingTagsAuthorizationKey ="Bollywood@Entertainment$mobitoptrendingtags_4";
var ProfileUpdateAuthorizationKey ="Bollywood@Entertainment$mobiupdate_5";
var UserRatedMovieAuthorizationKey ="Bollywood@Entertainment$mobimovierating_6";

var IsDeviceIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform); 


const IndexDB = new Dexie(DATABASE_NAME);
IndexDB.version(DATABASE_VERSION).stores({
    "home_stories_api": "ID,data,timestamp",
    "home_gallery_api": "ID,data,timestamp",
    "home_events_api": "ID,data,timestamp",
    "home_top_music_api": "ID,data,timestamp",
    "movie_review_api": "ID,data,timestamp",
    "movie_release_date_api": "ID,data,timestamp",
    "movie_top_grosser_api": "ID,data,timestamp",
    "video_top_section_api": "ID,data,timestamp",
    "video_celeb_interview_api": "ID,data,timestamp",
    "video_more_celeb_interview_api": "ID,data,timestamp",
    "boxoffice_update_api": "ID,data,timestamp",
    "boxoffice_collection_api": "ID,data,timestamp",
    "boxoffice_star_power": "ID,data,timestamp",
    "celeb_section_api": "ID,data,timestamp",

});
var IsUserLoggedIn = "IS_USER_LOGGED_IN";
var UserID = "USER_ID";
var LoginType = "LOGIN_TYPE";
var LoginResponse = "LOGIN_RESPONSE";
var UserProfileData="USER_PROFILE_DATA";
const LoginTypeValue = { GMAIL: "Gmail", FACEBOOK: "FB", MOBILE_NUMBER: "mobile_number", SIGNIN: "signin", RESIGTER: "signup" };
var UserFavourites = "USER_FAVOURITES"; //TODO:userId add
var FBOTPToken = "FACEBOOK_ACCOUNT_KIT_TOKEN";
var FBOTPMe = "FACEBOOK_ACCOUNT_KIT_ME";
//update BaseUrl for Prod 
const BaseUrl = 'https://mobi.bollywoodhungama.com/bh/pwa/api/v1';

//update BaseUrl for localhost 
// const LocalBaseUrl = 'http://localhost:3001/bh/pwa/api/v1';

const TrendingStories = BaseUrl + "/trending/storieslisting";

const Gallery = BaseUrl + "/trending/gallerylisting";
const GalleryBucket = BaseUrl + "/gallery/gallerybucket";
const GalleryMoviePhotos = BaseUrl + "/gallery/galleryallmoviesphotos";
const GalleryMoviePhotosBasedOnTitle = BaseUrl + "/gallery/galleryallmoviesphotosbasedontitle";

const Events = BaseUrl + "/trending/eventsListing";
const TopPartiesAndEventsSection = BaseUrl + "/event/topbucket";

const TopMoviesBasedOnViews = BaseUrl + '/movie/topbasedonviews';
const LatestMovie = BaseUrl + "/movie/latestlisting";
const MovieReleaseDate = BaseUrl + "/movie/releasedatelisting";
const MovieDetails = BaseUrl + "/movie/detaillanding";
const MovieReviews = BaseUrl + "/movie/reviewlisting";
const MovieMusic = BaseUrl + "/movie/musiclisting";
const TopMoviesBasedOnCollection = BaseUrl + '/movie/topbasedoncollection';
const MovieNewEntranceInTop100 = BaseUrl + '/movie/newentranceintop100';

const TopVideos = BaseUrl + '/video/allsectiontopvideoslisting';
const CelebsInterview = BaseUrl + '/video/interviewslisting';
const LatestCelebInterviews = BaseUrl + '/video/latestinterview';

const BoxOfficeUpdateNews = BaseUrl + '/boxoffice/news/update';
const BoxOfficeOverseasNews = BaseUrl + '/boxoffice/news/overseas';
const BoxOfficeSpecialFeaturesNews = BaseUrl + '/boxoffice/news/specialfeatures';
const BoxOfficeCurrentYearCollection = BaseUrl + '/boxoffice/collection/currentyearlisting';
const BoxOfficeActorActoressDirectorCollectionBasedOnYear = BaseUrl + '/boxoffice/collection/top/startpowerlisting';

const CelebMusicAndVideos = BaseUrl + '/movie/musicvideosListing';
const CelebArticlesBasedOnTagAndType = BaseUrl + '/movie/celeb/detaillanding';
const CelebCollectionByID = BaseUrl + '/celeb/collection/domesticandoverseasbyid';
const CelebCollectionByTypeAndId = BaseUrl + '/celeb/collection/domesticandoverseas';
const CelebTopBasedOnWeight = BaseUrl + '/celeb/top/basedonweightlisting';
const CelebOrMovieDetails = BaseUrl + '/movie/detaillanding';
const CelebFilmography = BaseUrl + '/celeb/filmography';
const CelebAllMovies = BaseUrl + '/celeb/movie/startcast';

const LatestAndTopBollywoodNews = BaseUrl + '/news/topLatestbollywood';
const PostDetailBasedOnCategory = BaseUrl + '/post/detaillanding';
const PostSimilarContentBasedOnCategory = BaseUrl + '/trending/post/similarcontent';


const PlayerUrl = BaseUrl + '/event/eventvideourl';
const DownloadImage = BaseUrl + '/gallery/download/photo';
const CelebMorePhotos = BaseUrl + "/celeb/morephotos";
const GalleryAllMoviePhotos = BaseUrl + "/gallery/moviesectionphotos";

const searchTrendingTags = "https://www.bollywoodhungama.com/pwaapp/top_trending_keywords.php";
const SearchAutoSuggest = BaseUrl + "/search/searchautosuggest/";
const SearchAllResults = BaseUrl + "/search/searchallresults/";

// const LoginUser = 'http://localhost:3001/bh/pwa/api/v1/login/useravailable';
const LoginUser = "https://www.bollywoodhungama.com/pwaapp/check.php";
const RegisterSocialLogin = "https://www.bollywoodhungama.com/pwaapp/check_social_login.php";
const FavouriteMovieOrCeleb = "https://www.bollywoodhungama.com/pwaapp/add_to_favorites.php";
const UserRating = BaseUrl + "/userrating/setuserrating";
const WatchNotInterestedMoviePoll = "https://www.bollywoodhungama.com/pwaapp/watch_or_not.php";
const IsUserRatedMovie ="https://www.bollywoodhungama.com/pwaapp/check_user_rating.php";

//const PlayerUrl ="https://www.bollywoodhungama.com/hlsurl/bh_streampath_rssfeeds/";
//const PlayerUrl ='https://www.bollywoodhungama.com/pwa_hlsurl.php?type=bh_streampath_rssfeeds&filepath=';
// const CastAndCrewUrl ="http://52.221.218.76/bh-english/api/search_details.php?exact=true&retrn=jsn&query=fl=id,post_title,details_p2p,genre,details_data--bh-movie-cast-crew-365331";
//const CastAndCrewUrl ="http://52.221.218.76/bh-english/api/search_details.php?exact=true&retrn=jsn&query=fl=id,post_title,details_p2p,genre,details_data--bh-movie-cast-crew-&id:";
//const PlayerUrl ="https://www.bollywoodhungama.com/hlsurl1.php?type=bh_streampath_rssfeeds&filepath=";




export {
    IndexDB,
    DATABASE_NAME,
    DATABASE_VERSION,
    BaseUrl,
    TrendingStories,
    Gallery,
    GalleryBucket,
    GalleryMoviePhotos,
    GalleryMoviePhotosBasedOnTitle,
    Events,
    TopPartiesAndEventsSection,
    TopMoviesBasedOnViews,
    LatestMovie,
    MovieReviews,
    MovieMusic,
    MovieReleaseDate,
    MovieDetails,
    TopMoviesBasedOnCollection,
    MovieNewEntranceInTop100,
    TopVideos,
    CelebsInterview,
    BoxOfficeUpdateNews, BoxOfficeOverseasNews, BoxOfficeSpecialFeaturesNews, BoxOfficeCurrentYearCollection,
    BoxOfficeActorActoressDirectorCollectionBasedOnYear, CelebCollectionByID, CelebTopBasedOnWeight, CelebOrMovieDetails,
    CelebFilmography, CelebAllMovies, CelebArticlesBasedOnTagAndType, LatestAndTopBollywoodNews, PostDetailBasedOnCategory,
    PlayerUrl, CelebCollectionByTypeAndId, CelebMusicAndVideos, PostSimilarContentBasedOnCategory, DownloadImage,
    CelebMorePhotos,
    GalleryAllMoviePhotos,
    LatestCelebInterviews,
    LoginUser,
    searchTrendingTags,
    SearchAutoSuggest,
    SearchAllResults,
    IsUserLoggedIn,
    LoginType,
    LoginTypeValue,
    LoginResponse,
    FavouriteMovieOrCeleb,
    UserRating,
    WatchNotInterestedMoviePoll,
    UserFavourites,
    FBOTPToken, FBOTPMe,
    RegisterSocialLogin,
    UserID,
    ADS_320x50,
    ADS_300x250,
    FACEBOOK_APP_ID,
    SigninAuthorizationKey,
    RegisterAuthorizationKey,
    WatchPollAuthorizationKey,
    UserFavouriteAuthorizationKey,
    SearchTrendingTagsAuthorizationKey,
    ProfileUpdateAuthorizationKey,
    UserRatedMovieAuthorizationKey,
    UserProfileData,
    IsUserRatedMovie,
    IsDeviceIOS
}