import React, { Component } from "react";

import "./starBoxOfficeCollectionApiParser.css";
import StarRatingComponent from "react-star-ratings";
import {
  CelebOrMovieDetails,
  UserFavourites
} from "../../../../utils/apiUrls.js";
import {
  setUserRating,
  shareClick,
  searchClick,
  profileClick,
  favouriteIconClick
} from "../../../../utils/commonClickActions.js";
import CelebCollectionDetail from "./celebCollectionDetail.js";
import CelebInternationalCollectionDetailApiParser from "./celebInternationalCollectionDetail.js";
import BaseImageComponent from "../../../../basecomponents/baseImageComponent.js";
import Popup from "../../../../utils/customPopup.js";

class starBoxOfficeCollectionApiParser extends Component {
  constructor(props) {
    super(props);
    const params = new URL(window.location.href).searchParams;
    this.id = params.get("id");
    this.favs = JSON.parse(localStorage.getItem(UserFavourites));
    this.state = {
      celeb_id: this.id,
      postDetail: [],
      detail_tabs: [],
      detail_content: [],
      domestic_collection: [],
      international_collection: [],
      active: null,
      userRating: 0,
      favouriteIcon:
        this.favs !== null && this.favs.includes(this.id)
          ? require("../../../../imagesvg/favourite_fill_black_border.svg")
          : require("../../../../imagesvg/favourite_black.svg"),
      IsFavouriteAddedOrRemoved:
        this.favs !== null && this.favs.includes(this.id) ? "remove" : "add",
      showPopup: false,
      popUpText: ""
    };
  }
  togglePopup(text) {
    console.log(this.state.showPopup);
    this.setState({
      showPopup: !this.state.showPopup,
      popUpText: text
    });
  }

  favouriteResonse = data => {
    if (data !== null && data !== undefined) {
      if (data.success) {
        if (this.state.IsFavouriteAddedOrRemoved === "add") {
          this.togglePopup("Marked Favourite!");
          if (this.favs !== null) {
            if (!this.favs.includes(this.state.celeb_id)) {
              localStorage.setItem(
                UserFavourites,
                JSON.stringify(this.favs + "," + this.state.celeb_id)
              );
            }
          } else {
            localStorage.setItem(
              UserFavourites,
              JSON.stringify(this.favs + "," + this.state.celeb_id)
            );
          }
          this.setState({
            IsFavouriteAddedOrRemoved: "remove",
            favouriteIcon: require("../../../../imagesvg/favourite_fill_black_border.svg")
          });
        } else {
          this.togglePopup("Removed Favourite!");
          if (
            this.favs !== null &&
            this.favs.includes("," + this.state.celeb_id)
          ) {
            var updated = this.favs.replace("," + this.state.celeb_id, "");
            localStorage.setItem(UserFavourites, JSON.stringify(updated));
          }
          this.setState({
            IsFavouriteAddedOrRemoved: "add",
            favouriteIcon: require("../../../../imagesvg/favourite_black.svg")
          });
        }
        window.location = "/startcollectiondetail?id=" + this.id;
      } else {
        this.togglePopup("Oops ! Please try again.");
      }
    } else {
      this.togglePopup("Oops ! Please try again.");
    }
  };
  getImage(doc) {
    if (doc.image_thumb === undefined || doc.image_thumb === null) {
      return require("../../../../imagesvg/placeholder.png");
    } else {
      if (doc.image_thumb.toString().includes("jpg")) {
        return doc.image_thumb;
      } else {
        return require("../../../../imagesvg/placeholder.png");
      }
    }
  }
  getDetailTabs() {
    let detailTabs = ["DOMESTIC", "INTERNATIONAL"];
    let tabs = detailTabs.map((tab, index) => {
      return (
        <button
          className="tablinks"
          style={{ fontWeight: "900" }}
          key={index}
          id={tab}
          onClick={this.handleSubTabClick.bind(this, index)}
        >
          {tab}
        </button>
      );
    });
    this.setState({ detail_tabs: tabs });
  }

  getDetailCOntent() {
    let listTabs = this.state.detail_tabs;
    let tab = listTabs[0];
    if (tab.props.id === "DOMESTIC") {
      let ele = document.getElementById(tab.props.id);
      ele.style.color = "#D0021B";
      ele.style.backgroundColor = "#FFFFFF";
      ele.style.opacity = "1";
      ele.style.borderBottom = " 0.625vw solid #D0021B";
    }
    var article = (
      <CelebCollectionDetail
        tab_selected={"boxoffice"}
        celeb_id={this.state.celeb_id}
        collection_type="domestic"
      />
    );
    this.setState({ detail_content: article, active: 0 });
  }
  _onError(ev) {
    ev.target.src = require("../../../../imagesvg/placeholder.png");
  }
  setStyle(index) {
    let listTabs = this.state.detail_tabs;
    let tab = listTabs[index];
    let ele = document.getElementById(tab.props.id);

    listTabs.map(item => {
      let ele = document.getElementById(item.props.id);
      ele.style.color = "#000000";
      ele.style.backgroundColor = "#FFFFFF";
      ele.style.borderBottom = "0.3125vw solid #ccc";
      ele.style.opacity = "0.5";
      return "";
    });
    if (ele !== undefined) {
      ele.style.color = "#D0021B";
      ele.style.backgroundColor = "#FFFFFF";
      ele.style.borderBottom = " 0.625vw solid #D0021B";
      ele.style.opacity = "1";
    }
  }
  handleSubTabClick(index, event) {
    this.setState({ active: index });
    this.setStyle(index);

    if (
      event !== null &&
      event.currentTarget !== undefined &&
      event.currentTarget.id !== undefined
    ) {
      let container_id = event.currentTarget.id;
      this.setState({ subtab_selected: container_id });
      if (container_id === "DOMESTIC") {
        var article = (
          <CelebCollectionDetail
            tab_selected={"boxoffice"}
            celeb_id={this.state.celeb_id}
            collection_type="domestic"
          />
        );
        this.setState({ detail_content: article });
      } else {
        var music = (
          <CelebInternationalCollectionDetailApiParser
            tab_selected={"boxoffice"}
            celeb_id={this.state.celeb_id}
            collection_type="international"
          />
        );
        this.setState({ detail_content: music });
      }
    }
  }
  handleBackArrow() {
    window.location = "/boxoffice";
  }
  onStarClick(id, rating) {
    console.log(rating + "  " + id);
    this.setState({ userRating: rating });
    setUserRating(id, rating, "post");
  }

  componentDidMount() {
    this.getDetailTabs();
    const params = new URL(window.location.href).searchParams;
    const id = params.get("id");
    this.setState({ celeb_id: id });
    const finalUrl = CelebOrMovieDetails + "/" + id;
    var thumb_imagesize = {
      width: "100vw",
      height: "100vw",
      objectFit: "contain",
      backgroundRepeat: " no-repeat",
      WebkitMaskImage:
        "linear-gradient(180deg, #FFFFFF 60%, rgba(247,247,247,0) 100%)"
    };
    fetch(finalUrl)
      .then(results => {
        return results.json();
      })
      .then(data => {
        this.setState({ loading: false });
        let postDetail = data.movies_detail.docs.map(doc => {
          this.getDetailCOntent();
          this.setState({
            userRating: doc.rating_celeb,
            favouriteIcon:
              this.favs !== null && this.favs.includes(doc.id)
                ? require("../../../../imagesvg/favourite_fill_black_border.svg")
                : require("../../../../imagesvg/favourite_black.svg")
          });
          var value = doc.id;
          return (
            <div className="celeb_detail_container_data" id={value} key={value}>
              <BaseImageComponent
                image_thumb={doc.image_thumb}
                customStyle={thumb_imagesize}
              />
              <div className="star_detail_action_top_icons_div">
                <img
                  src={require("../../../../imagesvg/left-arrow_black.svg")}
                  className="action_icons_back"
                  alt="back_arrow"
                  onClick={() => this.handleBackArrow()}
                  style={{
                    left: "3.125vw",
                    position: "absolute",
                    marginTop: "5.25vw"
                  }}
                />
                <img
                  src={require("../../../../imagesvg/user.svg")}
                  className="action_icons_user"
                  alt="profile"
                  onClick={profileClick.bind(this)}
                  style={{
                    right: "20.625vw",
                    position: "absolute",
                    marginTop: "5.25vw"
                  }}
                />
                <img
                  src={require("../../../../imagesvg/search.svg")}
                  className="action_icons_search"
                  alt="search"
                  onClick={searchClick.bind(this)}
                  style={{
                    right: "6.25vw",
                    position: "absolute",
                    marginTop: "5.25vw"
                  }}
                />
              </div>
              <div className="celeb_details">
                <p
                  className="celeb_detail_post_title"
                  style={{ paddingBottom: "2vw" }}
                >
                  {doc.post_title}
                </p>
                <p className="celeb_detail_rate">Rate it</p>
                <div className="ratingbar">
                  <StarRatingComponent
                    rating={this.state.userRating}
                    starRatedColor="rgb(248, 190, 28)"
                    changeRating={this.onStarClick.bind(this, doc.id)}
                    numberOfStars={5}
                    name="rating"
                    isSelectable={true}
                    starHoverColor="rgb(208, 2, 27)"
                    starSelectingHoverColor="rgb(208, 2, 27)"
                    starDimension="6vw"
                    starSpacing="0.5vw"
                    starEmptyColor="rgb(141, 141, 141)"
                  />
                </div>
                <div className="celeb_detail_action_icons_div">
                  <img
                    src={this.state.favouriteIcon}
                    onClick={favouriteIconClick.bind(
                      this,
                      doc.id,
                      this.state.IsFavouriteAddedOrRemoved,
                      "celebrity",
                      this.favouriteResonse
                    )}
                    className="action_icons"
                    alt="favourite"
                    style={{ width: "7.5vw" }}
                  />
                  <img
                    src={require("../../../../imagesvg/share_black.svg")}
                    onClick={shareClick.bind(
                      this,
                      "https://mobi.bollywoodhungama.com/startcollectiondetail?id=" +
                        doc.id
                    )}
                    className="action_icons"
                    alt="share"
                    style={{ width: "6.5625vw" }}
                  />
                </div>
              </div>
            </div>
          );
        });
        this.setState({ postDetail: postDetail });
      });
  }
  render() {
    let data;
    const { loading } = this.state;
    if (loading) {
      data = <div className="loader" />;
    } else {
      data = (
        <div>
          <div className="celeb_content" style={{ textAlign: "center" }}>
            {this.state.postDetail}
          </div>
          <div
            style={{
              position: "absolute",
              width: "100vw",
              height: "fit-content",
              zIndex: "1",
              marginTop: "8vw"
            }}
          >
            <section
              className="celeb_collection_detail_tabs"
              style={{
                display: "flex",
                overflowX: "auto",
                paddingRight: "3.125vw",
                background: "#ffffff"
              }}
            >
              {this.state.detail_tabs}
            </section>
          </div>
          <div
            className="celeb_collection_detail_content"
            style={{ marginTop: "20vw" }}
          >
            {this.state.detail_content}
          </div>
          {this.state.showPopup ? (
            <Popup
              text={this.state.popUpText}
              closePopup={this.togglePopup.bind(this)}
            />
          ) : null}
        </div>
      );
    }
    return <div>{data}</div>;
  }
}
export default starBoxOfficeCollectionApiParser;
