import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { IndexDB } from "../../../../utils/apiUrls.js";
import BaseImageComponent from "../../../../basecomponents/baseImageComponent.js";
import DirectorComponent from "./directorComponent.js";
import "./boxOfficeTabsUI.css";
import { BoxOfficeEvent } from "../../../../analytics_events/js/CustomEvents.js";

class BoxOfficeTabsApiParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subtab_selected: "",
      section_tab: "",
      content: [],
      sections: [],
      top_actor: [],
      top_actoress: [],
      top_director: [],
      bottomTabSelected: props.tab_selected,
      url: props.url,
      loading: true,
      selected: 2,
      active: null,
      celeb_id: "",
      actor_bg_color: [
        "#063060",
        "#123D6F",
        "#1E4B7F",
        "#2B598F",
        "#37679F",
        "#4475AF",
        "#5083BF",
        "#5D91CF",
        "#699FDF",
        "#76ADEF",
        "#76ADEF",
        "#76ADEF",
        "#76ADEF",
        "#76ADEF",
        "#76ADEF",
        "#76ADEF",
        "#76ADEF",
        "#76ADEF",
        "#76ADEF",
        "#76ADEF"
      ],
      actress_bg_color: [
        "#580423",
        "#691333",
        "#7A2243",
        "#8C3152",
        "#9D4063",
        "#AF5073",
        "#C05F83",
        "#D26E93",
        "#E37DA3",
        "#F48DB3",
        "#F48DB3",
        "#F48DB3",
        "#F48DB3",
        "#F48DB3",
        "#F48DB3",
        "#F48DB3",
        "#F48DB3",
        "#F48DB3",
        "#F48DB3",
        "#F48DB3"
      ]
    };
    // window.onpopstate = () => {
    //   window.location = "/boxoffice";
    // };
  }
  getImage(doc, section) {
    var image_thumb = [];
    var actordetail = [];
    if (doc != null) {
      if (section === "TOP ACTOR") {
        image_thumb = doc.image_thumb;
      } else if (section === "TOP ACTRESS") {
        image_thumb = doc.image_thumb;
      } else if (section === "TOP DIRECTOR") {
        if (doc.directorf !== undefined && doc.directorf !== "") {
          var director_name_array = doc.directorf.toString().split(",");
          if (director_name_array.length > 1) {
            director_name_array.map(actor => {
              var actor_detail = actor.toString().split("|");
              actordetail.push(actor_detail);
              return "";
              // if(actor_detail[3]==="male"){     actordetail.push(actor_detail); }
            });
          } else {
            var director_detail = director_name_array[0].toString().split("|");
            actordetail.push(director_detail);
          }
          image_thumb = actordetail[0];
        }
      }
      if (image_thumb !== undefined || image_thumb !== null) {
        var actorImage = image_thumb[image_thumb.length - 1];
        if (actorImage === null || actorImage === undefined) {
          return require("../../../../imagesvg/placeholder.png");
        } else {
          if (actorImage.length > 50) {
            return actorImage;
          } else {
            return require("../../../../imagesvg/placeholder.png");
          }
        }
      } else {
        return require("../../../../imagesvg/placeholder.png");
      }
    }
  }

  getImageBasedOnGender(doc, actor_type) {
    var actoress = [];
    if (doc.actorf !== undefined && doc.actorf !== "") {
      var actress_name_array = doc.actorf.toString().split(",");
      if (actress_name_array.length > 1) {
        if (actor_type === "actoress") {
          actress_name_array.map(actor => {
            var actor_detail = actor.toString().split("|");
            if (actor_detail[3] === "female") {
              actoress.push(actor_detail);
            }
            return actoress;
          });
        }
      } else {
        var actor_detail = actress_name_array[0].toString().split("|");
        if (actor_detail[3] === "female") {
          actoress.push(actor_detail);
        }
      }
      return actoress;
    }
  }
  getTitle(doc, titleOrId, section) {
    var actor_name = "";
    var actordetail = [];
    var celebActualId = "";
    if (doc != null) {
      if (section === "TOP ACTOR") {
        actor_name = doc.post_title;
        celebActualId = doc.id;
        this.setState({ celeb_id: doc.id });
      } else if (section === "TOP ACTRESS") {
        actor_name = doc.post_title;
        celebActualId = doc.id;
        this.setState({ celeb_id: doc.id });
      } else {
        if (doc.directorf !== undefined && doc.directorf !== "") {
          var director_name_array = doc.directorf.toString().split(",");
          if (director_name_array.length > 1) {
            director_name_array.map(actor => {
              var actor_detail = actor.toString().split("|");
              actordetail.push(actor_detail);
              return "";
            });
          } else {
            var director_detail = director_name_array[0].toString().split("|");
            actordetail.push(director_detail);
          }
          actor_name = actordetail[0][1];
          celebActualId = actordetail[0][0];
          this.setState({ celeb_id: actordetail[0][0] });
        }
      }
      if (titleOrId === "ID") {
        return celebActualId;
      } else {
        return actor_name;
      }
    }
  }
  handleStarCollectionMoreCicked(doc, section, event) {
    var id = this.getTitle(doc, "ID", section);
    window.location = "/startcollectiondetail?id=" + id;
  }
  handleSubTabClick(index, event) {
    this.setState({ active: index });
    this.setStyle(index);
    var container_id = event.currentTarget.id;
    this.setState({ subtab_selected: container_id });
    if (container_id === "TOP ACTOR") {
      this.setState({ content: this.state.top_actor });
    } else if (container_id === "TOP ACTRESS") {
      this.setState({ content: this.state.top_actoress });
    } else {
      this.setState({ content: this.state.top_director });
    }
  }
  getDetailCOntent() {
    var listTabs = this.state.sections;
    var tab = listTabs[0];
    if (tab.props.id === "TOP ACTOR") {
      var ele = document.getElementById(tab.props.id);
      ele.style.color = "#D0021B";
      ele.style.opacity = 1;
      ele.style.borderBottom = "0.625vw solid #D0021B";
    }
    this.setState({ active: 0 });
  }

  setStyle(index) {
    var listTabs = this.state.sections;
    var tab = listTabs[index];
    var ele = document.getElementById(tab.props.id);

    listTabs.map(item => {
      var ele = document.getElementById(item.props.id);
      ele.style.color = "#000000";
      ele.style.opacity = 0.5;
      ele.style.borderBottom = "0.3125vw solid #ccc";
      return "";
    });
    if (ele !== undefined) {
      ele.style.color = "#D0021B";
      ele.style.opacity = 1;
      ele.style.borderBottom = "0.625vw solid #D0021B";
    }
  }
  componentDidMount() {
    const history = createBrowserHistory();

    // Get the current location.
    const location = history.location;
    console.log(location);
    const unlisten = history.listen((location, action) => {
      // location is an object like window.location
      console.log(action, location.pathname, location.state);
    });

    // Use push, replace, and go to navigate around.
    history.push({ pathname: "/boxoffice" });
    window.onpopstate = () => {
      window.location = "/boxoffice";
    };

    // To stop listening, call the function returned from listen().
    unlisten();
    BoxOfficeEvent("Boxoffice_Tab_Star_Power", "Box Office Tab");
    var currentTimeStamp = new Date().getTime();
    IndexDB.boxoffice_star_power
      .get(this.state.url)
      .then(data => {
        if (data === undefined) {
          fetch(this.state.url)
            .then(results => {
              return results.json();
            })
            .then(data => {
              console.log(data);
              this.createUIComponent(data);
              IndexDB.boxoffice_star_power.put({
                ID: this.state.url,
                data: data,
                timestamp: currentTimeStamp
              });
            });
        } else {
          var difference = (currentTimeStamp - data.timestamp) / 60000;
          console.log(difference);
          if (difference >= 30) {
            fetch(this.state.url)
              .then(results => {
                return results.json();
              })
              .then(async data => {
                var updated = await IndexDB.boxoffice_star_power.update(
                  this.state.url,
                  {
                    ID: this.state.url,
                    data: data,
                    timestamp: currentTimeStamp
                  }
                );
                console.log(updated);
                if (updated && this !== undefined && this !== null) {
                  this.createUIComponent(data);
                }
              });
          } else {
            this.createUIComponent(data.data);
          }
        }
      })
      .catch(function(e) {
        console.log("Error: " + (e.stack || e));
      });
  }
  createUIComponent = data => {
    var thumb_imagesize = {
      width: "32vw",
      height: "32.5vw",
      borderRadius: "15.625vw",
      backgroundImage: "url:'require('../../../../imagesvg/placeholder.png')'",
      backgroundColor: "#f7f7f7",
      objectFit: "cover"
    };
    this.setState({ loading: false });
    var sections = data.sub_section_tabs.map((section_tab, index) => {
      this.setState({ section_tab: section_tab });
      data.section_celebs.map(section_celeb => {
        if (section_celeb.section_name === "TOP ACTOR") {
          this.setState({ subtab_selected: section_celeb.section_name });
          var top_actor = section_celeb.celebs.docs.map((doc, index) => {
            return (
              <div key={doc.id}>
                <div
                  className="box_office_star_container_data"
                  style={{ backgroundColor: this.state.actor_bg_color[index] }}
                  onClick={this.handleStarCollectionMoreCicked.bind(
                    this,
                    doc,
                    section_celeb.section_name
                  )}
                >
                  <div>
                    <div
                      className="rank"
                      style={{
                        backgroundColor: "#4A90E2"
                      }}
                    >
                      {parseInt(index) + 1}
                    </div>
                    <BaseImageComponent
                      expectedRatio={"322x322"}
                      image_thumb={this.getImage(
                        doc,
                        section_celeb.section_name
                      )}
                      customStyle={thumb_imagesize}
                    />
                  </div>
                  <div className="box_office_star_sub_container">
                    <table
                      style={{
                        border: "none"
                      }}
                    >
                      <tbody
                        style={{
                          border: "none"
                        }}
                      >
                        <tr>
                          <td colSpan="2">
                            <p className="star_title">
                              {this.getTitle(
                                doc,
                                "title",
                                section_celeb.section_name
                              )}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontWeight: "normal",
                              color: "#ffffff",
                              borderRight: "0.3125vw solid #ccc",
                              paddingRight: "2vw",
                              width: "10vw"
                            }}
                          >
                            <b
                              style={{
                                color: "#ffffff",
                                fontSize: "4.375vw"
                              }}
                            >
                              {doc.count_movie}
                            </b>
                            <br />
                            Mov
                          </td>
                          <td
                            style={{
                              fontWeight: "normal",
                              color: "#ffffff"
                            }}
                          >
                            <b
                              style={{
                                color: "#ffffff",
                                fontSize: "4.375vw",
                                fontWeight: 700
                              }}
                            >
                              {parseFloat(doc.total_collection_d).toFixed(2)}
                            </b>
                            <br />
                            Cumulative (in Cr)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <img
                    className="star_more_arrow"
                    style={{
                      paddingRight: "3.125vw"
                    }}
                    src={require("../../../../imagesvg/right-arrow_white.svg")}
                    alt="more"
                  />
                </div>
              </div>
            );
          });
          this.setState({ top_actor: top_actor });
        } else if (section_celeb.section_name === "TOP ACTRESS") {
          this.setState({ subtab_selected: section_celeb.section_name });
          var top_actoress = section_celeb.celebs.docs.map((doc, index) => {
            return (
              <div key={index}>
                <div
                  className="box_office_star_container_data"
                  style={{
                    backgroundColor: this.state.actress_bg_color[index]
                  }}
                  onClick={this.handleStarCollectionMoreCicked.bind(
                    this,
                    doc,
                    section_celeb.section_name
                  )}
                >
                  <div>
                    <div
                      className="rank"
                      style={{
                        backgroundColor: "#F4508B"
                      }}
                    >
                      {parseInt(index) + 1}
                    </div>
                    <BaseImageComponent
                      expectedRatio={"322x322"}
                      image_thumb={this.getImage(
                        doc,
                        section_celeb.section_name
                      )}
                      customStyle={thumb_imagesize}
                    />
                  </div>
                  <div className="box_office_star_sub_container">
                    <table
                      style={{
                        border: "none"
                      }}
                    >
                      <tbody
                        style={{
                          border: "none"
                        }}
                      >
                        <tr>
                          <td colSpan="2">
                            <p
                              className="star_title"
                              style={{
                                color: "#FFFFFF"
                              }}
                            >
                              {this.getTitle(
                                doc,
                                "title",
                                section_celeb.section_name
                              )}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontWeight: "normal",
                              color: "#ffffff",
                              borderRight: "0.3125vw solid #ccc",
                              paddingRight: "2vw",
                              width: "10vw"
                            }}
                          >
                            <b
                              style={{
                                color: "#ffffff",
                                fontSize: "4.375vw"
                              }}
                            >
                              {doc.count_movie}
                            </b>
                            <br />
                            Mov
                          </td>
                          <td
                            style={{
                              fontWeight: "normal",
                              color: "#ffffff"
                            }}
                          >
                            <b
                              style={{
                                color: "#ffffff",
                                fontSize: "4.375vw"
                              }}
                            >
                              {parseFloat(doc.total_collection_d).toFixed(2)}
                            </b>
                            <br />
                            Cumulative (in Cr)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <img
                    className="star_more_arrow"
                    style={{
                      paddingRight: "3.125vw"
                    }}
                    src={require("../../../../imagesvg/right-arrow_white.svg")}
                    alt="more"
                  />
                </div>
              </div>
            );
          });
          this.setState({ top_actoress: top_actoress });
        } else {
          this.setState({ subtab_selected: section_celeb.section_name });
          var top_director = section_celeb.celebs.docs.map((doc, index) => {
            return (
              <DirectorComponent
                bg_color={this.state.actor_bg_color}
                index={index}
                thumb_imagesize={thumb_imagesize}
                onClick={this.handleStarCollectionMoreCicked.bind(
                  this,
                  doc,
                  section_celeb.section_name
                )}
                image_thumb={this.getImage(doc, section_celeb.section_name)}
                title={this.getTitle(doc, "title", section_celeb.section_name)}
                movie_count={1}
                total_collection={doc.movie_collection_d}
              />
            );
          });
          this.setState({ top_director: top_director });
        }
        return "";
      });
      if (this.state.top_actor !== null) {
        this.setState({ content: this.state.top_actor });
      }
      return (
        <button
          style={{
            paddingBottom: "2.125vw",
            paddingTop: "6vw",
            fontWeight: "900"
          }}
          className="tablinks"
          key={index}
          id={section_tab}
          onClick={this.handleSubTabClick.bind(this, index)}
        >
          {section_tab}
        </button>
      );
    });
    this.setState({ sections: sections });
    this.getDetailCOntent();
  };
  render() {
    var data;
    const { loading } = this.state;
    if (loading) {
      data = <div className="loader" />;
    } else {
      data = (
        <div
          style={{
            width: "100%",
            height: "100%"
          }}
        >
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "fit-content",
              zIndex: "1"
            }}
          >
            <div className="tab_box_office_star">{this.state.sections}</div>
          </div>
          <div
            className="boxoffice_content"
            style={{
              paddingTop: "18.625vw"
            }}
          >
            {this.state.content}
          </div>
        </div>
      );
    }
    return <div>{data}</div>;
  }
}
export default BoxOfficeTabsApiParser;
