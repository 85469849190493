const blackColor ="#000000";
const whiteColor ="#FFFFFF";
const featureOrangeColor ="#F9A825";
const newsCyanBlueColor ="#00BCD4";
const yellowCelebColor ="#F8E71C";
const pinkVideosColor ="#e60065";
const storiesSelectedColor = "#1C8CCB";
const moviesSelectedColor = "#24B0A6";
const videosstoriesSelectedColor = "#ECBC00";
const boxofficeSelectedColor = "#F07E19";
const celebsSelectedColor = "#1C8CCB";
const commonBackgroundColor ="#efefef";
const commonBorderColor ="#ccc";
const maroornErrorColor ="#900";
const bottomNavigationColor ="#D0021B";
const galleryTitleHighlightRedColor="#D0021B";
const ratingSelectedColor ="#F8BE1C";
const ratingDesectedColor="#8D8D8D";


export{
    blackColor,
    whiteColor,
    bottomNavigationColor,
    featureOrangeColor,
    newsCyanBlueColor,
    yellowCelebColor,
    pinkVideosColor,
    storiesSelectedColor,
    moviesSelectedColor,
    videosstoriesSelectedColor,
    boxofficeSelectedColor,
    celebsSelectedColor,
    commonBackgroundColor,
    maroornErrorColor,
    galleryTitleHighlightRedColor,
    ratingSelectedColor,
    ratingDesectedColor,
    commonBorderColor
}