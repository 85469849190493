import Moment from "moment";
import {
  IsUserLoggedIn,
  UserID,
  LoginType,
  LoginResponse,
  FavouriteMovieOrCeleb,
  UserRating,
  RegisterSocialLogin,
  UserFavourites,
  RegisterAuthorizationKey,
  UserFavouriteAuthorizationKey,
  FBOTPToken,
  FBOTPMe,
  UserProfileData,
  FACEBOOK_APP_ID,
  LoginTypeValue
} from "../utils/apiUrls.js";
import {
  LoginEvent,
  TrendingEvent
} from "../analytics_events/js/CustomEvents.js";
var md5 = require("md5");
/****
 * @type celebrity/movie
 */
var favouriteIconClick = (post_id, action, type, callback) => {
  console.log(action + " " + type + " " + post_id);

  if (
    localStorage.getItem(IsUserLoggedIn) === "true" &&
    localStorage.getItem(LoginType) !== undefined &&
    localStorage.getItem(LoginType) !== null &&
    localStorage.getItem(LoginResponse) !== null &&
    localStorage.getItem(LoginResponse) !== undefined
  ) {
    // var userID = "556554";
    var userID = localStorage.getItem(UserID);
    var bodyStr =
      "action=" +
      action +
      "&type=" +
      type +
      "&post_id=" +
      post_id +
      "&" +
      "user_id=" +
      userID;
    console.log(bodyStr);
    fetch(FavouriteMovieOrCeleb, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        Authorization: getAuthorizationHeader(UserFavouriteAuthorizationKey),
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: bodyStr
    })
      .then(results => {
        console.log(results);
        return results.json();
      })
      .then(data => {
        callback(data, null);
      });
  } else {
    window.location = "/login?id=0";
  }
};

var signInClick = id => {
  console.log(id);
  window.location = "/signin?id=" + id;
};

var signUpClick = id => {
  console.log(id);
  window.location = "/signup?id=" + id;
};
var skipClick = id => {
  LoginEvent("User_Login_Later", "Login Later ");
  console.log(id);
  window.location = "/";
};
var forgotPasswordClick = id => {
  console.log(id);
  window.location = "/forgotpassword?id=" + id;
};
var shareClick = url => {
  TrendingEvent("Trending_Share", "Trending_Tab");
  console.log("share url " + url);
  if (url !== null && url !== undefined) {
    if (navigator.share) {
      navigator.share({
        title: 'BollywoodHungama',
        text: 'Check out https://mobi.bollywoodhungama',
        url: "https://www.facebook.com/dialog/share?app_id=+"+FACEBOOK_APP_ID+"&display=popup&href="+url+"&redirect_uri=https://mobi.bollywoodhungama.com",
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }else{
      window.location ="https://www.facebook.com/dialog/share?app_id=+"+FACEBOOK_APP_ID+"&display=popup&href="+url+"&redirect_uri=https://mobi.bollywoodhungama.com";
    }
  } else {
    window.location = "/";
  }
};

var handleKeyDown = e => {
  if (e.key === "Enter") {
    console.log(e.target);
    e.preventDefault();
    const inputs = Array.prototype.slice.call(
      document.querySelectorAll("input")
    );
    console.log(inputs);
    const index = (inputs.indexOf(document.activeElement) + 1) % inputs.length;
    console.log(index);
    const input = inputs[index];
    console.log(input);
    input.focus();
  }
};

var backArrowClick = (calledFrom, id, event) => {
  var redirectUrl = "/";
  console.log(calledFrom);
  switch (calledFrom) {
    case "stories":
    case "news":
    case "gallery":
    case "events":
      redirectUrl = "/";
      break;

    case "video":
      redirectUrl = "/video";
      break;
    case "boxoffice":
      redirectUrl = "/boxoffice";
      break;
    case "movies":
      redirectUrl = "/movies";
      break;
    case "celebs":
    case "CelebsDetailPost":
      redirectUrl = "/celebs";
      break;
    case "search":
      redirectUrl = "/search?id=0";
      break;
    case "MobileLoginOTP":
    case "ForgotPassword":
    case "SignUpPage":
      redirectUrl = "/signin?id=0";
      break;
    case "MobileLogin":
    case "SignInPage":
      redirectUrl = "/login?id=0";
      break;
    case "moviereviewdetail":
      redirectUrl = "/moviereviewdetail?id=" + id;
      break;
    case "postdetail":
      redirectUrl = "/postdetail?id=" + id;
      break;
    case "startcollectiondetail":
      redirectUrl = "/startcollectiondetail?id=" + id;
      break;
    case "gallerysectiondetail":
      redirectUrl = "/gallerysectiondetail?id=" + id;
      break;
    case "galleryallphotos":
      redirectUrl = "/galleryallphotos?id=" + id;
      break;
    default:
      redirectUrl = "/";
      break;
  }
  window.location = redirectUrl;
};

var searchClick = () => {
  window.location = "/search?id=0";
};
var profileClick = () => {
  if (
    localStorage.getItem(IsUserLoggedIn) === "true" &&
    localStorage.getItem(LoginType) !== undefined &&
    localStorage.getItem(LoginType) !== null &&
    localStorage.getItem(LoginResponse) !== null &&
    localStorage.getItem(LoginResponse) !== undefined
  ) {
    window.location = "/profile?id=0";
  } else {
    window.location = "/login?id=0";
  }
};

var setLoginInLocalStorage = (loginType, loginResponse) => {
  localStorage.setItem(IsUserLoggedIn, true);
  localStorage.setItem(LoginType, loginType);
  localStorage.setItem(LoginResponse, loginResponse);
};
var getLoginFromLocalStorage = () => {};

var logout = () => {
console.log(window.FB);
  localStorage.setItem(IsUserLoggedIn, false);
  localStorage.removeItem(LoginType);
  localStorage.removeItem(LoginResponse);
  localStorage.removeItem(UserID);
  localStorage.removeItem(UserFavourites);
  localStorage.removeItem(FBOTPToken);
  localStorage.removeItem(FBOTPMe);
  localStorage.removeItem(UserProfileData);
  if(window.FB!==null && window.FB!==undefined){
  window.FB.logout()
  }
  if(localStorage.getItem(LoginType) !== undefined &&
    localStorage.getItem(LoginType) !== null && localStorage.getItem(LoginType)=== LoginTypeValue.GMAIL){

      if (window.gapi) {
          const auth2 = window.gapi.auth2.getAuthInstance()
          if (auth2 != null) {
              auth2.signOut().then(
                  auth2.disconnect().then(this.props.onLogoutSuccess)
              )
          } else {
            this.props.onLogoutFailure()
         }
      }
    }
  window.location = "/";
};
//'user_id', 'post_id','rating_value','rating_date','rating_type'
var setUserRating = (post_id, rating_value, rating_type) => {
  if (
    localStorage.getItem(IsUserLoggedIn) === "true" &&
    localStorage.getItem(LoginType) !== undefined &&
    localStorage.getItem(LoginType) !== null &&
    localStorage.getItem(LoginResponse) !== null &&
    localStorage.getItem(LoginResponse) !== undefined
  ) {
    var user_id = localStorage.getItem(UserID);
    // var user_id = "556554";
    var rating_date = Moment().format("YYYY-MM-DD hh:mm:ss");
    var bodyStr =
      "post_id=" +
      post_id +
      "&user_id=" +
      user_id +
      "&rating_value=" +
      rating_value +
      "&rating_date=" +
      JSON.stringify(rating_date) +
      "&rating_type=" +
      JSON.stringify(rating_type);
    // console.log(bodyStr)
    fetch(UserRating, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      mode: "cors",
      body: bodyStr
    })
      .then(results => {
        console.log(results);
        return results.json();
      })
      .then(data => {
        console.log(data);
      });
  } else {
    window.location = "/login?id=0";
  }
};

var RegisterSocialLoginInWordPress = (
  display_name,
  email,
  identifier,
  provider,
  callback
) => {
  var bodyStr =
    "display_name=" +
    display_name +
    "&email=" +
    email +
    "&identifier=" +
    identifier +
    "&provider=" +
    provider;
  console.log(bodyStr);
  fetch(RegisterSocialLogin, {
    method: "POST",
    headers: {
      Accept: "Application/json",
      Authorization: getAuthorizationHeader(RegisterAuthorizationKey),
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    },
    body: bodyStr
  })
    .then(result => {
      return result.json();
    })
    .then(data => {
      console.log(JSON.stringify(data));
      if (data !== null && data !== undefined && data.success) {
        console.log(data.data.data.ID);
        localStorage.setItem(UserID, data.data.data.ID);
        var dataResult = JSON.parse(data.userdata);

        localStorage.setItem(UserProfileData, JSON.stringify(dataResult));
      }
      callback(data);
    });
};

var getAuthorizationHeader = key => {
  return md5(key);
};
export {
  backArrowClick,
  favouriteIconClick,
  signInClick,
  signUpClick,
  skipClick,
  forgotPasswordClick,
  shareClick,
  searchClick,
  profileClick,
  setLoginInLocalStorage,
  getLoginFromLocalStorage,
  logout,
  setUserRating,
  RegisterSocialLoginInWordPress,
  getAuthorizationHeader,
  handleKeyDown
};
