import React from 'react';
import {logout} from '../utils/commonClickActions.js';
import {LoginTypeValue,LoginType,LoginResponse,UserProfileData} from '../utils/apiUrls.js';
import { LoginEvent } from '../analytics_events/js/CustomEvents.js';
import './userProfile.css';

class UserProfile extends React.Component {
constructor(props) {
super(props);
this.state = {
profileImage:[]
};
}
handleBackArrow(){
console.log("back arrow");
window.location="/";
}
editProfile=()=>{
console.log("edit")
window.location="/updateprofile?id=0"
}
componentDidMount() {
LoginEvent("Profile","Profile Link");
var username = "Profile Name";
var userImage = require('../imagesvg/user_grey.svg');
var userEmail ="";
var userMobile="";
var userDOB="";
var userCountry="";
var userGender="";

// var userImage = "https://media2.bollywoodhungama.in/wp-content/uploads/2019/06/Sara-Ali-Khan-1.jpg";
var loginType = localStorage.getItem(LoginType);
var loginResponse = JSON.parse(localStorage.getItem(LoginResponse));
if(loginType===LoginTypeValue.FACEBOOK){
var picture = loginResponse.picture.data.url;
console.log(loginResponse.display_name);
console.log(picture);
if(loginResponse!==null&&loginResponse!==undefined ){
username=loginResponse.display_name;
userEmail=loginResponse.email;
if(picture!==null && picture!==undefined){
userImage= picture;
}
}
}else if(loginType===LoginTypeValue.GMAIL){
if(loginResponse!==null&&loginResponse!==undefined ){
username=loginResponse.display_name;
userImage= loginResponse.picture;
userEmail=loginResponse.email;
}
}else if(loginType===LoginTypeValue.MOBILE_NUMBER){
if(loginResponse!==null&&loginResponse!==undefined ){
username=loginResponse.phone_number;
}
}else if(loginType===LoginTypeValue.SIGNIN){
if(loginResponse!==null&&loginResponse!==undefined ){
username=loginResponse.display_name;
userImage= loginResponse.picture;
userEmail=loginResponse.email;
}
}else{
if(loginResponse!==null&&loginResponse!==undefined ){
username=loginResponse.display_name;
userImage= loginResponse.picture;
userEmail=loginResponse.email;
}
}
var userProfileData = JSON.parse(localStorage.getItem(UserProfileData));
console.log(userProfileData)
if(userProfileData!==null&& userProfileData!==undefined){
  userGender = userProfileData.gender;
  userMobile=userProfileData.mobile_number;
  userDOB=userProfileData.dob;
  userCountry= userProfileData.country;
}
if(userImage===undefined || userImage===null ||userImage===""){
userImage= require('../imagesvg/user_grey.svg');
}
console.log(userImage)
console.log(window.innerHeight)
console.log(window.innerWidth)
var userdata =
<div className="userdata" style={{alignContent:"center"}}>
  <table>
    <tbody style={{height:"130vw", overflow:'scroll', display: 'block'}}>
      <tr style={{border:"none"}}>
        <td style={{
          textAlign:"left",
          border:"none",
          marginTop:"15vw"
        }}>
          <img src={userImage} style={{width:"28.125vw",
        height: "28.125vw",
        background:"#f9f9f9",
        boxShadow:" 0 2px 4px 0 rgba(0,0,0,0.30)",
        marginLeft:"4vw",
        borderRadius: "15.625vw"}} alt="user" />
        </td>
        <td
          style={{width:"85vw",textAlign:"left",border:"none",padding:"0vw",paddingTop:"8vw",paddingBottom:"4vw",fontWeight:700,fontSize:"5.625vw"}}>
          USER NAME <br /><span style={{fontWeight:"400",fontSize:"4vw",color:"#999999"}}>{username}</span></td>
      </tr>
      <tr>
        <td style={{border:"none",padding:"0vw",paddingTop:"4vw",paddingBottom:"4vw",paddingLeft:"3vw"}}>Mobile</td>
        <td style={{border:"none",padding:"0vw",paddingTop:"4vw",paddingBottom:"4vw",paddingLeft:"3vw"}}>: {userMobile}
        </td>
      </tr>
      <tr>
        <td style={{border:"none",padding:"0vw",paddingTop:"4vw",paddingBottom:"4vw",paddingLeft:"3vw"}}>Date Of Birth
        </td>
        <td style={{border:"none",padding:"0vw",paddingTop:"4vw",paddingBottom:"4vw",paddingLeft:"3vw"}}>: {userDOB}
        </td>
      </tr>
      <tr>
        <td style={{border:"none",padding:"0vw",paddingTop:"4vw",paddingBottom:"4vw",paddingLeft:"3vw"}}>Gender</td>
        <td style={{width:"50vw" ,border:"none",padding:"0vw",paddingTop:"4vw",paddingBottom:"4vw",paddingLeft:"3vw"}}>: {userGender}</td>
      </tr>
      <tr>
        <td style={{border:"none",padding:"0vw",paddingTop:"4vw",paddingBottom:"4vw",paddingLeft:"3vw"}}>Country</td>
        <td style={{border:"none",padding:"0vw",paddingTop:"4vw",paddingBottom:"4vw",paddingLeft:"3vw"}}>: {userCountry}
        </td>
      </tr>
      <tr>
        <td style={{border:"none",padding:"0vw",paddingTop:"4vw",paddingBottom:"4vw",paddingLeft:"3vw"}}>Email</td>
        <td
          style={{border:"none",padding:"0vw",paddingTop:"4vw",paddingBottom:"4vw" ,paddingLeft:"3vw",wordBreak: "break-all"}}>
          : {userEmail}</td>
      </tr>
      <tr style={{border:"none"}}>
        <td
          style={{border:"none",textAlign:"center",padding:"0vw",paddingTop:"4vw",paddingBottom:"4vw",paddingLeft:"3vw"}}>
          <button style={{
              height: "15vw",
              width: "fit-content",
              backgroundColor: "#D94D3D",
              color: "#ffffff",
              fontWeight:400,
              borderRadius: "10vw",
              fontSize: "5vw",
              paddingLeft: "10vw",
              paddingRight: "10vw",
              border:"none",
              letterSpacing: 0,
              textAlign: "center",
              lineHeight:"6vw",
              display:"inline-flex",
            }} onClick={this.editProfile.bind(this)}>
            Edit
            <img style={{marginLeft:"2vw"}} src={require("../imagesvg/edit.svg")} type="image" alt="edit" />
          </button>

        </td>
        <td style={{border:"none",textAlign:"center",padding:"0vw",paddingTop:"4vw",paddingBottom:"4vw"}}>
          <button style={{
              height: "15vw",
              width: "fit-content",
              backgroundColor: "#D94D3D",
              color: "#ffffff",
              borderRadius: "10vw",
              fontSize: "5vw",
              fontWeight:400,
              paddingLeft: "10vw",
              paddingRight: "10vw",
              border:"none",
              letterSpacing: 0,
              textAlign: "center",
              lineHeight:"6vw",
              display:"inline-flex",
            }} onClick={logout}>
            Logout
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
this.setState({profileImage:userdata})
}
render() {
var data;
const {loading} = this.state;
if (loading) {
data = <div className="loader"></div>
} else {
data =
<div className="profile_container">
  <div className="profile_arrow">
    <img src={require("../imagesvg/left_red_arrow.svg")} className="action_icons_back" alt="back_arrow"
      onClick={this.handleBackArrow} style={{
          left: "3vw",
      }} />
    <p className="profile_name">Profile</p>
  </div>
  <div style={{
                background: "#FFFFFF",
                marginBottom: "4.6875vw",
                paddingTop: "11.625vw",
                width:"100vw",
                // marginLeft:"3vw",
                marginRight:"3vw",
                textAlign:"center",
            }}>
    {this.state.profileImage}
  </div>
</div>
};
return (<div>
  {data}
</div>);
}
}


export default UserProfile;