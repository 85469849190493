import React from "react";
import { withRouter } from "react-router-dom";
import ItemCardView from "../js/itemCardView.js";
import InfiniteScroll from "react-infinite-scroller";
import PhotosRowApiParser from "../../../../apiparsercomponents/trending_section/gallery/js/galleryHomeApiParser.js";
import EventRowApiParser from "../../../../apiparsercomponents/trending_section/events/js/eventRowApiParser.js";
import { IndexDB, Gallery, Events } from "../../../../utils/apiUrls.js";
import {TrendingEvent} from '../../../../analytics_events/js/CustomEvents.js';
import {
  blackColor,
  whiteColor,
  maroornErrorColor,
  galleryTitleHighlightRedColor
} from "../../../../utils/color.js";

class APIParser extends React.Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this._isMounted = false;
    // Sets up our initial state
    this.state = {
      home_tabs: [],
      home_tabs_selected_id: 0,
      loading: true,
      start: 0,
      limit: 5,
      pictures: [],
      bottomTabSelected: props.data,
      url: props.url,
      error: false,
      hasMore: false,
      isLoading: false,
      active: 0,
      calledOnce: false
    };
  }

  handleSubTabClick(index, event) {
    this._isMounted && this.setState({ active: index });
    this.setStyle(index);
    if (
      event !== null &&
      event.currentTarget !== undefined &&
      event.currentTarget.id !== undefined
    ) {
      let container_id = event.currentTarget.id;
      this._isMounted && this.setState({ home_tabs_selected_id: container_id });
      if (container_id === "STORIES") {
        window.location = "/";
      } else if (container_id === "GALLERY") {
        var photos = (
          <PhotosRowApiParser
            data={"trending"}
            url={Gallery}
            isMounted={this._isMounted}
          />
        );
        if (this._isMounted) {
          this.setState({ pictures: photos, hasMore: false });
          this._isMounted = false;
        } else {
          this.setState({ pictures: photos });
          this._isMounted = true;
        }
      } else {
        var events = <EventRowApiParser data={"trending"} url={Events} />;
        if (this._isMounted) {
          this.setState({ pictures: events, hasMore: false });
          this._isMounted = false;
        } else {
          this.setState({ pictures: events });
          this._isMounted = false;
        }
      }
    }
  }

  setStyle(index) {
    let listTabs = this.state.home_tabs;
    let tab = listTabs[index];
    let ele = document.getElementById(tab.props.id);

    listTabs.map(item => {
      let ele = document.getElementById(item.props.id);
      ele.style.color = blackColor;
      ele.style.opacity = 0.2;
      return "";
    });
    if (ele !== undefined) {
      ele.style.color = galleryTitleHighlightRedColor;
      ele.style.opacity = 1;
    }
  }

  componentDidMount() {
    TrendingEvent("Trending_Tab Stories","Trending Tab");
    this._isMounted = true;
    this.getToolbarTitle();
    this._isMounted && this.loadStories();
    // console.log('Component DID MOUNT! stories')
  }

  componentWillUnmount() {
    // console.log('Component WILL UNMOUNT!')
    this._isMounted = false;
  }
  handleHeaderClick() {
    window.location = "/";
  }
  getToolbarTitle() {
    let title_style = {
      color: blackColor,
      fontWeight: 900,
      textDecoration: "none",
      fontSize: "9.375vw",
      marginLeft: "1.125vw",
      whiteSpace: "nowrap"
    };

    let heading = document.querySelectorAll(".toolbar__title");
    heading[0].innerHTML = "Trending";
    heading[0].onclick = this.handleHeaderClick.bind(this);
    for (var property in title_style) {
      heading[0].style[property] = title_style[property];
    }
  }
  loadStories = () => {
    var finalUrl =
      this.state.url + "/" + this.state.start + "/" + this.state.limit;
    var currentTimeStamp = new Date().getTime();
    if (this.state.start === 0) {
      IndexDB.home_stories_api
        .get(finalUrl)
        .then(data => {
          if (data === undefined) {
            fetch(finalUrl)
              .then(results => {
                return results.json();
              })
              .then(data => {
                this.createUIComponent(data);
                // console.log("home_stories_api.put: ")
                IndexDB.home_stories_api.put({
                  ID: finalUrl,
                  data: data,
                  timestamp: currentTimeStamp
                });
              });
          } else {
            var difference = (currentTimeStamp - data.timestamp) / 60000;
            // console.log(difference);
            if (difference >= 30) {
              fetch(finalUrl)
                .then(results => {
                  return results.json();
                })
                .then(async data => {
                  var updated = await IndexDB.home_stories_api.update(
                    finalUrl,
                    { ID: finalUrl, data: data, timestamp: currentTimeStamp }
                  );
                  console.log(updated);
                  if (updated && this !== undefined && this !== null) {
                    this.createUIComponent(data);
                  }
                });
            } else {
              this.createUIComponent(data.data);
            }
          }
        })
        .catch(function(e) {
          console.log("Error: " + (e.stack || e));
        });
    } else {
      fetch(finalUrl)
        .then(results => {
          return results.json();
        })
        .then(data => {
          this.createUIComponent(data);
        });
    }
  };

  createUIComponent = data => {
    let home_tabs = data.home_tabs.map((home_tab, index) => {
      if (index === 0) {
        return (
          <button
            className="homelinks"
            key={index}
            style={{ color: galleryTitleHighlightRedColor, opacity: 1 }}
            id={home_tab}
            onClick={this.handleSubTabClick.bind(this, index)}
          >
            {home_tab}
          </button>
        );
      } else {
        return (
          <button
            className="homelinks"
            key={index}
            style={{ color: blackColor, opacity: 0.2 }}
            id={home_tab}
            onClick={this.handleSubTabClick.bind(this, index)}
          >
            {home_tab}
          </button>
        );
      }
    });
    this._isMounted && this.setState({ home_tabs: home_tabs });
    const totalCount = data.stories.numFound;
    var adsCount =0;
    let pictures = data.stories.docs.map((image_thumb, index) => {
      if (this.state.start ===0 && index> 1 && index % 2 === 0) {
        ++adsCount;
        console.log(adsCount)
        return (
          <div key={"stories" + image_thumb.id + Math.random()}>
            <div id={"ATD_BH_300x250_P"+adsCount}></div> 
            <ItemCardView pic={image_thumb} />
          </div>
        );

      } else {
        return (
          <div key={"stories" + image_thumb.id + Math.random()}>
            <ItemCardView pic={image_thumb} />
          </div>
        );
      }
    });
    // Merges the next stories into our existing stories
    this._isMounted &&
      this.setState({
        loading: false,
        start: this.state.start + this.state.limit,
        hasMore: this.state.pictures.length < totalCount,
        isLoading: false,
        pictures: [...this.state.pictures, ...pictures]
      });
  };

  render() {
    const { loading, error, hasMore, isLoading, pictures } = this.state;

    let data;
    if (loading) {
      data = <div className="loader" />;
    } else {
      data = (
        <div>
          <div className="home_tabs">{this.state.home_tabs}</div>
          <div
            className="api_content"
            key={"stories" + Math.random()}
            style={{
              paddingTop: "15.625vw",
              paddingBottom: "8.625vw",
              backgroundColor: whiteColor
            }}
          >
            <InfiniteScroll
              initialLoad={false}
              pageStart={this.state.start}
              loadMore={!isLoading ? this.loadStories : () => {}}
              hasMore={hasMore && !isLoading}
              loader={
                <div
                  className="loader"
                  style={{
                    textAlign: "center",
                    padding: "3.125vw",
                    fontWeight: "700"
                  }}
                  key={0}
                >
                  Loading ...
                </div>
              }
              useWindow={true}
            >
              {pictures}
            </InfiniteScroll>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div>{data}</div>
        {error && (
          <div
            style={{
              color: maroornErrorColor,
              textAlign: "center",
              padding: "3.125vw",
              backgroundColor: whiteColor
            }}
          >
            {}
          </div>
        )}
        {isLoading && (
          <div
            style={{
              textAlign: "center",
              padding: "3.125vw",
              backgroundColor: whiteColor
            }}
          >
            Loading...
          </div>
        )}
        {!hasMore && (
          <div
            style={{
              textAlign: "center",
              padding: "3.125vw",
              backgroundColor: whiteColor
            }}
          />
        )}
      </div>
    );
  }
}
// Array.prototype.insert = function(index, item) {
//   this.splice(index, 0, item);
// };
export default withRouter(APIParser);
