import React, { Component } from "react";
import BoxOfficeUpdateApiParser from '../apiparsercomponents/boxoffice_section/update/js/boxOfficeUpdateApiParser.js';
import {BoxOfficeUpdateNews} from '../utils/apiUrls.js';
class BoxOfficePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="main">
          <div className="data" style={{textAlign:"center"}}>
          <BoxOfficeUpdateApiParser tab_selected ={"boxoffice"} url ={BoxOfficeUpdateNews} />
          </div>
      </div>
    );
  }
}

export default BoxOfficePage;
