import React, {
    Component
} from "react";
import  Moment from "moment";
import BaseImageComponent from '../../../../basecomponents/baseImageComponent.js';
import '../../../boxoffice_section/update/js/boxOfficeApiParse.css';

class DetailArticleAPIParser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tag:props.tag,
            type:props.type,
            news:[],
            details : [],
            bottomTabSelected:props.tab_selected,
            url:props.url,
            loading: true,
        };
    }
      getCurrentDate(date){
        //   console.log("moment"+Moment(date,'YYYYMMDDhms').format("MMM DD, YYYY - h:mm a")+" IST");
          var formatedDate =Moment(date,'YYYYMMDDhms').format("MMM DD, YYYY - h:mm a")+" IST";
          return formatedDate;
      }
      getTitle(doc){
        if(doc!=null){
            return doc.post_title;
        }
    }

    handleNewsClick =(id)=>{
        window.location='/postdetail?id='+id+"&tab="+this.state.bottomTabSelected;
      };
      getImage(image_thumb) {
        var customimage = image_thumb;
        if(customimage === undefined || customimage === null){
          return customimage;
        }else{
          customimage = image_thumb.toString().replace("322x322","354x199");
          return customimage;
        }
    }
      componentDidMount(){
        var thumb_imagesize ={
            width:"100%",
            height:"53.44vw",
            borderTopLeftRadius:"2vw",
            borderTopRightRadius:"2vw",
            objectFit: "contain",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#efefef",
          }
          var finalurl = this.state.url+"/"+this.state.tag+"/"+this.state.type;
        //   console.log("finalurl : "+finalurl);
                fetch(finalurl)
                .then(results=>{
                    return results.json();
                }).then(data =>{
                    this.setState({loading:false});
                    if(data.articles.numFound !==0){
                    let news= data.articles.docs.map((doc)=>{
                                return (
                                    <div className="detail_article_container_data" key={doc.id} onClick={this.handleNewsClick.bind(this,doc.id)} style={{marginTop:"3.125vw",marginLeft:"2.8vw",width:"95vw"}}>
                                    <BaseImageComponent expectedRatio={"354x199"}  image_thumb={this.getImage(doc.image_thumb)} customStyle={thumb_imagesize}/>
                                    <div style={{padding:"3.125vw"}} >
                                    <p className="container_title">{this.getTitle(doc)}</p>
                                    <p className="container_date">{this.getCurrentDate(doc.insertdate)}</p>
                                    </div>
                                    </div>
                                )
                            })
                            this.setState({details:news});
                        }else{
                            let news= (
                                <div>
                                <p>Sorry currently there are no article available for this movie.</p>
                                </div>
                                )  
                            this.setState({details:news}); 
                        }
                });
    };
    render()  {
        let data;
        const { loading } = this.state;
        // console.log(" render loading"+loading);
        // console.log(" render data"+JSON.stringify(this.state.type));
            if(loading) {
            data =<div className="subloader"></div>
            }
            else{     
                data =
            <div key={this.state.details.results}>
            {this.state.details}
            </div>
        }
        return(
            
            <div>
                {data}
            </div>
            );
       
    };
}
export default DetailArticleAPIParser