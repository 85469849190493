import React, { Component } from "react";

import StarRatingComponent from "react-star-rating-component";
import Grid from "@material-ui/core/Grid";
import InfiniteScroll from "react-infinite-scroller";
import "../../../trending_section/gallery/js/RowView.css";
import "./movieReviewApiParser.css";
import MovieReleaseDateApiParser from "../../releasedate/js/movieReleaseDateParser.js";
import MovieTopCollectionApiParser from "../../topgrosser/js/movieTopCollectionParser.js";
import {
  IndexDB,
  TopMoviesBasedOnCollection,
  MovieReleaseDate
} from "../../../../utils/apiUrls.js";
import {
  bottomNavigationColor,
  blackColor,
  ratingSelectedColor,
  ratingDesectedColor,
  maroornErrorColor
} from "../../../../utils/color.js";
import BaseImageComponent from "../../../../basecomponents/baseImageComponent.js";
import { MovieEvent } from "../../../../analytics_events/js/CustomEvents";

class MovieReviewApiParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      movies: [],
      nonGridMovies: [],
      bottomTabSelected: props.tab_selected,
      url: props.url,
      active: null,
      start: 0,
      limit: 10,
      error: false,
      hasMore: false,
      isLoading: false
    };
  }
  componentWillUnmount() {
    console.log("Component WILL UNMOUNT!");
    this._isMounted = false;
  }
  handleSubTabClick(index, event) {
    this._isMounted && this.setState({ active: index });
    this.setStyle(index);
    if (
      event !== null &&
      event.currentTarget !== undefined &&
      event.currentTarget.id !== undefined
    ) {
      let container_id = event.currentTarget.id;
      this._isMounted && this.setState({ home_tabs_selected_id: container_id });
      if (container_id === "REVIEW") {
        window.location = "/movies";
      } else if (container_id === "RELEASE DATE") {
        var movies_release = (
          <MovieReleaseDateApiParser
            tab_selected={"movies"}
            url={MovieReleaseDate}
          />
        );
        console.log("isLoading", this.state.isLoading);
        if (this._isMounted) {
          this.setState({ movies: movies_release, hasMore: false });
          this._isMounted = false;
        } else {
          this.setState({ movies: movies_release });
          this._isMounted = true;
        }
      } else {
        var movies_top_collection = (
          <MovieTopCollectionApiParser
            customstyle={"-3vw"}
            tab_selected={"movies"}
            url={TopMoviesBasedOnCollection}
          />
        );

        if (this._isMounted) {
          this.setState({ movies: movies_top_collection, hasMore: false });
          this._isMounted = false;
        } else {
          this.setState({ movies: movies_top_collection });
          this._isMounted = false;
        }
      }
    }
  }
  getDetailCOntent() {
    let listTabs = this.state.home_tabs;
    if (listTabs !== undefined) {
      let tab = listTabs[0];
      if (tab.props.id === "REVIEW") {
        let ele = document.getElementById(tab.props.id);
        ele.style.color = bottomNavigationColor;
        ele.style.opacity = 1;
      }
      this._isMounted && this.setState({ active: 0 });
    }
  }

  setStyle(index) {
    let listTabs = this.state.home_tabs;

    let tab = listTabs[index];
    let ele = document.getElementById(tab.props.id);

    listTabs.map(item => {
      let ele = document.getElementById(item.props.id);
      ele.style.color = blackColor;
      ele.style.opacity = 0.2;
      return "";
    });
    if (ele !== undefined) {
      ele.style.color = bottomNavigationColor;
      ele.style.opacity = 1;
    }
  }

  handleMovieClickedForReviewDetail(id, event) {
    window.location = "/moviereviewdetail?id=" + id + "&tab=movies";
  }
  headerTitleCliked() {
    window.location = "/movies";
  }

  componentDidMount() {
    // this._isMounted = true;
    let title_style = {
      color: blackColor,
      fontWeight: 900,
      textDecoration: "none",
      fontSize: "7.5vw",
      marginLeft: "0.125vw",
      whiteSpace: "nowrap"
    };

    let heading = document.querySelectorAll(".toolbar__title");
    heading[0].innerHTML = "Movies";
    heading[0].onclick = this.headerTitleCliked.bind(this);
    for (var property in title_style) {
      heading[0].style[property] = title_style[property];
    }
  }

  componentWillMount() {
    MovieEvent("Movies_Tab_Review","Movie Tab");
    this._isMounted = true;
    this._isMounted && this.loadMovieReviews();
  }

  getImage(image_thumb) {
    var customimage = image_thumb;
    if (customimage === undefined || customimage === null) {
      return customimage;
    } else {
      customimage = image_thumb.toString().replace("322x322", "165x212");
      return customimage;
    }
  }

  loadMovieReviews = () => {
    var finalUrl =
      this.state.url + "/" + this.state.start + "/" + this.state.limit;
    var currentTimeStamp = new Date().getTime();
    if (this.state.start === 0) {
      IndexDB.movie_review_api
        .get(finalUrl)
        .then(data => {
          if (data === undefined) {
            fetch(finalUrl)
              .then(results => {
                return results.json();
              })
              .then(data => {
                this.createUIComponent(data);
                IndexDB.movie_review_api.put({
                  ID: finalUrl,
                  data: data,
                  timestamp: currentTimeStamp
                });
              });
          } else {
            var difference = (currentTimeStamp - data.timestamp) / 60000;
            console.log(difference);
            if (difference >= 30) {
              fetch(finalUrl)
                .then(results => {
                  return results.json();
                })
                .then(async data => {
                  var updated = await IndexDB.movie_review_api.update(
                    finalUrl,
                    { ID: finalUrl, data: data, timestamp: currentTimeStamp }
                  );
                  console.log(updated);
                  if (updated && this !== undefined && this !== null) {
                    this.createUIComponent(data);
                  }
                });
            } else {
              this.createUIComponent(data.data);
            }
          }
        })
        .catch(function(e) {
          console.log("Error: " + (e.stack || e));
        });
    } else {
      fetch(finalUrl)
        .then(results => {
          return results.json();
        })
        .then(data => {
          this.createUIComponent(data);
        });
    }
  };
  onStarClick(id, rating) {
    console.log(rating + "  " + id);
  }
  createUIComponent = data => {
    var thumb_imagesize = {
      width: "44vw",
      height: "56.57vw",
      borderRadius: "2vw",
      backgroundColor: blackColor,
      objectFit: "contain",
      backgroundRepeat: "no-repeat"
    };
    let home_tabs = data.home_tabs.map((home_tab, index) => {
      if (index === 0) {
        return (
          <button
            className="homelinks"
            style={{ color: bottomNavigationColor, opacity: 1 }}
            key={index}
            id={home_tab}
            onClick={this.handleSubTabClick.bind(this, index)}
          >
            {home_tab}
          </button>
        );
      } else {
        return (
          <button
            className="homelinks"
            style={{ color: blackColor, opacity: 0.2 }}
            key={index}
            id={home_tab}
            onClick={this.handleSubTabClick.bind(this, index)}
          >
            {home_tab}
          </button>
        );
      }
    });
    this._isMounted && this.setState({ home_tabs: home_tabs });
    let totalCount = data.latest_movies.numFound;

    let movies = data.latest_movies.docs.map(doc => {
      console.log(doc)
      return (
        <Grid key={doc.id} item>
          <div
            className="movie_review_container_data"
            onClick={this.handleMovieClickedForReviewDetail.bind(this, doc.id)}
          >
            <BaseImageComponent
              expectedRatio={"165x212"}
              image_thumb={this.getImage(doc.image_thumb)}
              customStyle={thumb_imagesize}
            />
            <div className="movie_review_title">{doc.post_title}</div>
            <div className="movie_review_rating">
              <StarRatingComponent
                editing={false}
                name="default_rating"
                starCount={5}
                value={doc.critic_rating_d}
                starColor={ratingSelectedColor}
                emptyStarColor={ratingDesectedColor}
                fontSize="5.625vw"
                onStarClick={this.onStarClick.bind(this, doc.id)}
              />
            </div>
          </div>
        </Grid>
      );
    });
    this._isMounted &&
      this.setState({
        loading: false,
        start: this.state.start + this.state.limit,
        hasMore: this.state.movies.length < totalCount,
        isLoading: false,
        movies: [...this.state.movies, ...movies]
      });
  };
  render() {
    const { loading, error, hasMore, isLoading, movies } = this.state;

    let data;

    if (loading) {
      data = <div className="loader" />;
    } else {
      data = (
        <div>
          <div className="home_tabs">{this.state.home_tabs}</div>
          <div
            className="api_content"
            style={{
              paddingTop: "15.75vw",
              paddingBottom: "15.625vw",
              position: "relative",
              zIndex: "0"
            }}
          >
            <InfiniteScroll
              initialLoad={false}
              pageStart={this.state.start}
              loadMore={!isLoading ? this.loadMovieReviews : () => {}}
              hasMore={hasMore && !isLoading}
              loader={
                <div
                  className="loader"
                  style={{
                    textAlign: "center",
                    padding: "3.125vw",
                    fontWeight: "700"
                  }}
                  key={0}
                >
                  Loading ...
                </div>
              }
              useWindow={true}
            >
              {" "}
              <div id="ATD_BH_320x50_P2" />
              <Grid
                container
                className="grid"
                style={{ marginTop: "1vw" }}
                justify="center"
                spacing={8}
              >
                {movies}
              </Grid>
            </InfiniteScroll>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div>{data}</div>
        {error && (
          <div
            style={{
              color: maroornErrorColor,
              textAlign: "center",
              padding: "3.125vw"
            }}
          >
            {error}
          </div>
        )}
        {isLoading && (
          <div style={{ textAlign: "center", padding: "3.125vw" }}>
            Loading...
          </div>
        )}
        {!hasMore && (
          <div style={{ textAlign: "center", padding: "3.125vw" }} />
        )}
      </div>
    );
  }
}
export default MovieReviewApiParser;
