import React from "react";
import "./customPopup.css";

class Popup extends React.Component {
  render() {
    return (
      <div className="popup">
        <div className="popup_inner">
          <p>{this.props.text}</p>
          <button onClick={this.props.closePopup}>OK</button>
        </div>
      </div>
    );
  }
}

export default Popup;
