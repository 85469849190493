import React, {Component} from "react";

import Grid from '@material-ui/core/Grid';
import InfiniteScroll from 'react-infinite-scroller';
import {GalleryBucket} from '../../../../utils/apiUrls.js';
import Toolbar from '../../../../basecomponents/toolbar/toolbar.js';
import BaseImageComponent from '../../../../basecomponents/baseImageComponent.js';
import './gallerySectionApiParser.css';

class GallerySectionApiParser extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            loading: true,
            section_id: "",
            gallerySection: [],
            start: 0,
            limit: 10,
            totalCount: "",
            error: false,
            hasMore: false,
            isLoading: false
        };
        //*100 / resolusion width
    }

    getCategoryName() {
        switch (parseInt(this.state.section_id)) {
            case 6:
                return "Movie Stills";
            case 2:
                return "First Look";
            case 7:
                return "On The Sets";
            case 12:
                return "Parties And Events";
            case 8:
                return "Celeb Photos";
            case 3:
                return "Celeb Wallpapers";
            case 4:
                return "Movie Wallpapers";
            default:
                return "null";
        }
    }
    handleSectionClick() {
        window.location = '/';
    }
    handleMovieClick(data, event) {
        var parentId;
        if(this.state.section_id === "12"){
            parentId =data[0].parent_id;
        }else{
            parentId=data.parent_id;
        }
        console.log("data.details_pid : " + JSON.stringify(data));
        console.log("data.details_pid : " + data.details_pid);
        if (data.category_id === undefined && (this.state.section_id === "12" || this.state.section_id === "3"|| this.state.section_id === "4")) {
            // console.log("data.parent_id inside : " + data[0].parent_id);
            window.location = '/galleryallphotos?id=' + data.id + "&section=" + this.state.section_id +
                    "&detailsId=" + data.details_pid + "&parentId=" +parentId+"&tab=gallerysectiondetail";
        }else if (this.state.section_id === "8") {
            window.location = '/galleryallphotos?id=' + data.id + "&section=" + this.state.section_id+"&details_title="+data.details_title+"&tab=gallerysectiondetail";
        } else {
            window.location = '/galleryallphotos?id=' + data.id + "&section=" + this.state.section_id +
                    "&detailsId=" + data.details_pid + "&parentId=" + data.parent_id+"&tab=gallerysectiondetail";
        }

    }
    getGalleryGridContainer = (gallerySection) => {
        var conatinerData;
        if (this.state.section_id === "2") {
            conatinerData = <section
                className="section_card"
                style={{
                    padding: 0,
                    backgroundColor: "#FFFFFF"
                }}>
                <Grid
                    container="container"
                    className="gallery_section_grid"
                    justify="center"
                    spacing={8}>
                    {gallerySection}
                </Grid>
            </section>
        } else {
            conatinerData = <div
                className="gallery_content"
                style={{
                    textAlign: "center"
                }}>
                {gallerySection}
            </div>
        }
        return conatinerData;
    }
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.loadGallery();
    }
    componentWillUnmount() {
        console.log('Component WILL UNMOUNT!')
        this._isMounted = false;
    }
    getGridUI(doc) {
        var image_thumb_one = {
            width: "100%",
            height: "100%",
            borderRadius: "2vw",
            objectFit: "contain",
            backgroundColor: " #F7F7F7",
            backgroundRepeat: "no-repeat"
        }

        var image_thumb_two = {
            width: "100%",
            height: "100%",
            borderRadius: "2vw",
            objectFit: "contain",
            backgroundColor: " #F7F7F7",
            backgroundRepeat: "no-repeat"
        }
        if (doc.length === 1) {
            return (
                <div className="grid-container">
                    <div className="item1">
                        <BaseImageComponent
                            className="image_thumb_one"
                            expectedRatio={"322x322"}
                            image_thumb={this.getImage(doc[0], 1)}
                            customStyle={image_thumb_one}/>
                    </div>
                    <div className="item2">
                        <BaseImageComponent
                            className="image_thumb_two"
                            expectedRatio={"165x165"}
                            image_thumb={this.getImage("undefined", 2)}
                            customStyle={image_thumb_two}/>
                    </div>
                    <div className="item3">
                        <BaseImageComponent
                            className="image_thumb_two"
                            expectedRatio={"165x165"}
                            image_thumb={this.getImage("undefined", 2)}
                            customStyle={image_thumb_two}/>
                    </div>
                </div>
            )
        } else if (doc.length === 2) {
            return (
                <div className="grid-container">
                    <div className="item1">
                        <BaseImageComponent
                            className="image_thumb_one"
                            expectedRatio={"322x322"}
                            image_thumb={this.getImage(doc[0], 1)}
                            customStyle={image_thumb_one}/>
                    </div>
                    <div className="item2">
                        <BaseImageComponent
                            className="image_thumb_two"
                            expectedRatio={"165x165"}
                            image_thumb={this.getImage(doc[1], 2)}
                            customStyle={image_thumb_two}/>
                    </div>
                    <div className="item3">
                        <BaseImageComponent
                            className="image_thumb_two"
                            expectedRatio={"165x165"}
                            image_thumb={this.getImage("undefined", 2)}
                            customStyle={image_thumb_two}/>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="grid-container">
                    <div className="item1">
                        <BaseImageComponent
                            className="image_thumb_one"
                            expectedRatio={"322x322"}
                            image_thumb={this.getImage(doc[0], 1)}
                            customStyle={image_thumb_one}/>
                    </div>
                    <div className="item2">
                        <BaseImageComponent
                            className="image_thumb_two"
                            expectedRatio={"165x165"}
                            image_thumb={this.getImage(doc[1], 2)}
                            customStyle={image_thumb_two}/>
                    </div>
                    <div className="item3">
                        <BaseImageComponent
                            className="image_thumb_two"
                            expectedRatio={"165x165"}
                            image_thumb={this.getImage(doc[2], 2)}
                            customStyle={image_thumb_two}/>
                    </div>
                </div>
            )
        }
    }
    getImage(image_thumb, size) {
        var customimage = image_thumb;
        if (customimage === undefined || customimage === null) {
            return customimage;
        } else {
            if (this.state.section_id === "2") {
                customimage = image_thumb
                    .toString()
                    .replace("322x322", "165x212");
            } else if (size === "1") {
                customimage = image_thumb;
            } else {
                customimage = image_thumb
                    .toString()
                    .replace("322x322", "165x165");
            }
            return customimage;
        }
    }

    loadGallery = () => {
        const params = new URL(window.location.href).searchParams;
        const id = params.get('id');
        const finalUrl = GalleryBucket + "/" + id + "/" + this.state.start + "/" +
                this.state.limit;
        fetch(finalUrl)
            .then(results => {
                return results.json();
            })
            .then(data => {
                this._isMounted && this.setState(
                    {section_id: id, totalCount: data.data.total_count}
                );
                let gallerySection;
                if (data.data.photos !== undefined && data.data.photos !== null) {
                    gallerySection = data
                        .data
                        .photos
                        .map((photo) => {
                            var value = photo.data.id;
                            var galleryImageStyle = {
                                width: "100%",
                                height: "57.86vw",
                                borderRadius: "2vw",
                                objectFit: "contain",
                                backgroundColor: " #000000",
                                backgroundRepeat: "no-repeat"
                            }
                            if (this.state.section_id === "2") {
                                return (
                                    <Grid
                                        key={value}
                                        style={{
                                            width: "48vw",
                                            display: "inline-block"
                                        }}
                                        item="item">
                                        {
                                            <div
                                                    className="gallery_container_data_movies"
                                                    key={value}
                                                    onClick={this
                                                        .handleMovieClick
                                                        .bind(this, photo.data)}>
                                                    <BaseImageComponent
                                                        className="gallery_movie_image"
                                                        expectedRatio={"165x212"}
                                                        image_thumb={this.getImage(photo.images[0], 0)}
                                                        customStyle={galleryImageStyle}/>
                                                    <p className="gallery_movie_data_title" style={{paddingBottom:"1vw"}}>{photo.data.post_title}</p>
                                                    <p
                                                        className="gallery_photo_count"
                                                        style={{
                                                            textAlign: "left",
                                                            marginLeft: "1vw"
                                                        }}>{photo.total_image_count +` photos`}</p>
                                                </div>
                                        }</Grid>
                                )
                            } else {
                                return (
                                    <div
                                        className="gallery_container_data"
                                        onClick={this
                                            .handleMovieClick
                                            .bind(this, photo.data)}
                                        style={{
                                            // padding: "3.125vw"
                                        }}
                                        id={value}
                                        key={value}>
                                        <p className="gallery_title">{photo.data.post_title}</p>
                                        <p className="gallery_photo_count">{photo.total_image_count +` photos`}</p>
                                        {this.getGridUI(photo.images)}
                                    </div>
                                )
                            }
                        });
                } else {
                    console.log("gallery section down : ");
                    if (data.section_id === "12") {
                        // console.log(data);
                        gallerySection = data
                            .data.retVal
                            .map((photos) => {
                                var value = photos.data.id;
                                var image_thumb_one = {
                                    width: "100%",
                                    height: "100%",
                                   borderRadius: "2vw",
                                    objectFit: "contain",
                                    backgroundColor: " #F7F7F7",
                                    backgroundRepeat: "no-repeat"
                                }

                                var image_thumb_two = {
                                    width: "100%",
                                    height: "100%",
                                   borderRadius: "2vw",
                                    objectFit: "contain",
                                    backgroundColor: " #F7F7F7",
                                    backgroundRepeat: "no-repeat"
                                }
                                return (
                                    <div
                                        className="gallery_container_data"
                                        // style={{
                                        //     padding: "3.125vw"
                                        // }}
                                        onClick={this
                                            .handleMovieClick
                                            .bind(this, photos.data)}
                                        id={value}
                                        key={value}>
                                        <p
                                            className="gallery_title"
                                           >{
                                                photos
                                                    .data[0]
                                                    .post_title
                                            }</p>
                                        <p className="gallery_photo_count">{photos.total_image_count+`  photos`}</p>
                                        <div className="grid-container">
                                            <div className="item1">
                                                <BaseImageComponent
                                                    className="image_thumb_one"
                                                    expectedRatio={"322x322"}
                                                    image_thumb={this.getImage(photos.data[0].image_thumb, 1)}
                                                    customStyle={image_thumb_one}/>
                                            </div>
                                            <div className="item2">
                                                <BaseImageComponent
                                                    className="image_thumb_two"
                                                    expectedRatio={"165x165"}
                                                    image_thumb={this.getImage(photos.data[1].image_thumb, 2)}
                                                    customStyle={image_thumb_two}/>
                                            </div>
                                            <div className="item3">
                                                <BaseImageComponent
                                                    className="image_thumb_two"
                                                    expectedRatio={"165x165"}
                                                    image_thumb={this.getImage(photos.data[2].image_thumb, 2)}
                                                    customStyle={image_thumb_two}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                    } else {
                        console.log("gallery section down not 12: ");
                        gallerySection = data
                            .data
                            .map((photo) => {
                                var value = photo.data.id;
                                var image_thumb_one = {
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "2vw",
                                    objectFit: "contain",
                                    backgroundColor: " #F7F7F7",
                                    backgroundRepeat: "no-repeat"
                                }

                                var image_thumb_two = {
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "2vw",
                                    objectFit: "contain",
                                    backgroundColor: " #F7F7F7",
                                    backgroundRepeat: "no-repeat"
                                }
                                return (
                                    <div
                                        className="gallery_container_data"
                                        // style={{
                                        //     padding: "3.125vw"
                                        // }}
                                        id={value}
                                        key={value}>
                                        <p
                                            className="gallery_title"
                                            onClick={this
                                                .handleMovieClick
                                                .bind(this, photo.data)}>{photo.data.post_title}</p>
                                        <p className="gallery_photo_count" style={{margin:"0vw"}}>{photo.total_image_count +` photos`}</p>
                                        <div className="grid-container">
                                            <div className="item1">
                                                <BaseImageComponent
                                                    className="image_thumb_one"
                                                    expectedRatio={"322x322"}
                                                    image_thumb={this.getImage(photo.data.image_thumb, 1)}
                                                    customStyle={image_thumb_one}/>
                                            </div>
                                            <div className="item2">
                                                <BaseImageComponent
                                                    className="image_thumb_two"
                                                    expectedRatio={"165x165"}
                                                    image_thumb={this.getImage(photo.images[1], 2)}
                                                    customStyle={image_thumb_two}/>
                                            </div>
                                            <div className="item3">
                                                <BaseImageComponent
                                                    className="image_thumb_two"
                                                    expectedRatio={"165x165"}
                                                    image_thumb={this.getImage(photo.images[2], 2)}
                                                    customStyle={image_thumb_two}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            });
                    }
                }

                // Merges the next stories into our existing stories
                console.log(this.state.gallerySection.length < this.state.totalCount)
                this._isMounted && this.setState({
                    loading: false,
                    start: (this.state.start + this.state.limit),
                    hasMore: (this.state.gallerySection.length < this.state.totalCount),
                    isLoading: false,
                    gallerySection: [
                        ...this.state.gallerySection,
                        ...gallerySection
                    ]
                });

            })
            .catch((err) => {
                this._isMounted && this.setState({error: err.message, isLoading: false});
            });
    };
    render() {
        const {loading, error, hasMore, isLoading, gallerySection} = this.state;

        let data;
        if (loading) {
            data = <div className="loader"></div>
        } else {
            data = <div>
                <div className="toolbar">
                    <Toolbar callbackFromParent={"hidden"}/>
                </div>
                <div
                    className="gallery_section_container"
                    style={{
                        backgroundColor: "#f7f7f7"
                    }}>
                    <img
                        className="gallery_section_more_arrow"
                        src={require('../../../../imagesvg/left_red_arrow.svg')}
                        onClick={this.handleSectionClick}
                        alt="more"/>
                    <p className="gallery_detail_section_photos" onClick={this.handleSectionClick}>GALLERY</p>
                </div>
                <div
                    className="gallery_detail_section_title"
                    style={{
                        backgroundColor: "#FFFFFF",
                        padding: "3.125vw 0",
                        paddingBottom:"4vw"
                    }}>
                    <span className="section_name">{this.getCategoryName()}</span>
                </div>
                {this.getGalleryGridContainer(gallerySection)}
            </div>
        }
        return (
            <div style={{
                    backgroundColor: "#FFFFFF"
                }}>
                <div>
                    <InfiniteScroll
                        initialLoad={false}
                        pageStart={this.state.start}
                        loadMore={!isLoading
                            ? this.loadGallery
                            : () => {}}
                        hasMore={hasMore && !isLoading}
                        loader={this.state.totalCount > 10 && <div
                            className="loader"
                            style={{
                                textAlign: "center",
                                // padding: "3.125vw",
                                fontWeight: "700"
                            }}
                            key={0}>Loading ...</div>}
                        useWindow={true}>
                        {data}
                    </InfiniteScroll>
                </div>
                {
                    error && <div
                            style={{
                                color: '#900',
                                textAlign: "center",
                                // padding: "3.125vw"
                            }}>
                            {error}
                        </div>
                }
                {
                    isLoading && <div
                            style={{
                                textAlign: "center",
                                // padding: "3.125vw"
                            }}>Loading...</div>
                }
                {
                    !hasMore && <div
                            style={{
                                textAlign: "center",
                                // padding: "3.125vw"
                            }}></div>
                }
            </div>
        );
    };
};
export default GallerySectionApiParser;