import React, { Component } from "react";
// import AccountKit from "react-facebook-account-kit";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"; //working
// import GoogleLogin from "react-google-login";
import {
  skipClick,
  signInClick,
  setLoginInLocalStorage,
  RegisterSocialLoginInWordPress
} from "../utils/commonClickActions.js";
import {
  LoginTypeValue,
  // FBOTPToken,
  // FBOTPMe,
  // UserID,
  FACEBOOK_APP_ID,
  UserProfileData
} from "../utils/apiUrls.js";
import Popup from "../utils/customPopup.js";
import "../apiparsercomponents/login/newLogin.css";
import { LoginEvent } from "../analytics_events/js/CustomEvents.js";
// const Querystring = require("querystring");
// const me_endpoint_base_url = "https://graph.accountkit.com/v1.0/me";
// const token_exchange_base_url =
//   "https://graph.accountkit.com/v1.0/access_token";

class NewLoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      showPopup: false,
      popUpText: ""
    };
  }
  togglePopup(text) {
    console.log(this.state.showPopup);
    this.setState({
      showPopup: !this.state.showPopup,
      popUpText: text
    });
  }
  componentDidMount() {
    LoginEvent("User_Login", "Login page");
    document.addEventListener("loginbackpressed", () => {
      this.props.history.push("/");
    });
  }

  // responseGoogle = response => {
  //   console.log(JSON.stringify(response));
  //   if (
  //     response !== null &&
  //     response !== "undefined" &&
  //     response !== undefined &&
  //     response.Zi !== null &&
  //     response.Zi.access_token !== null
  //   ) {
  //     var loginResponse = {
  //       display_name: response.profileObj.name,
  //       email: response.profileObj.email,
  //       picture: response.profileObj.imageUrl,
  //       id: response.profileObj.googleId,
  //       accessToken: response.Zi.access_token,
  //       expiresIn: response.Zi.expires_in
  //     };
  //     LoginEvent("Google_Plus","Google Plus Login");
  //     setLoginInLocalStorage(
  //       LoginTypeValue.GMAIL,
  //       JSON.stringify(loginResponse)
  //     );

  //     RegisterSocialLoginInWordPress(
  //       response.profileObj.name,
  //       response.profileObj.email,
  //       response.profileObj.googleId,
  //       "google",
  //       function isRegistered(finalResponse) {
  //         if (
  //           finalResponse !== null &&
  //           finalResponse !== undefined &&
  //           finalResponse.success
  //         ) {
  //           window.location = "/";
  //         } else {
  //           this.togglePopup("Oop's not registered , Please try again later!");
  //         }
  //       }
  //     );
  //   } else {
  //     this.togglePopup("Error Loggin via Gmail ");
  //   }
  // };

  FBLoginFailed = response => {
    console.log(response);
    this.togglePopup("Oop's Error in login , Try again later.");
  };

  responseFacebook = response => {
    // alert(response);
    console.log(JSON.stringify(response));
    if (
      response !== null &&
      response !== undefined &&
      response.accessToken != null
    ) {
      LoginEvent("Facebook", "Facebook Login");
      var loginResponse = {
        display_name: response.name,
        email: response.email,
        picture: response.picture,
        id: response.id,
        accessToken: response.accessToken,
        expiresIn: response.expiresIn
      };
      setLoginInLocalStorage(
        LoginTypeValue.FACEBOOK,
        JSON.stringify(loginResponse)
      );
      RegisterSocialLoginInWordPress(
        response.name,
        response.email,
        response.id,
        "facebook",
        function isRegistered(finalResponse) {
          // alert(finalResponse);
          if (
            finalResponse !== null &&
            finalResponse !== undefined &&
            finalResponse.success
          ) {
            var data = JSON.parse(finalResponse.userdata);
            localStorage.setItem(UserProfileData, JSON.stringify(data));
            window.location = "/";
          } else {
            this.togglePopup("Oop's not registered , Please try again later!");
          }
        }
      );
    } else {
      this.togglePopup("Error Loggin via FB ");
    }
  };

  // login callback
  // loginCallback(response) {
  //   console.log(response);
  //   console.log(JSON.stringify(response));
  //   if (response.status === "PARTIALLY_AUTHENTICATED") {
  //     var app_access_token = [
  //       "AA",
  //       FACEBOOK_APP_ID,
  //       "16216e9b88d04b092c607de46659d347"
  //     ].join("|");
  //     var code = response.code;
  //     var csrf = response.state;
  //     console.log(
  //       code + " csrf " + csrf + " response status " + response.status
  //     );
  //     var params = {
  //       grant_type: "authorization_code",
  //       code: code,
  //       access_token: app_access_token
  //     };
  //     var token_exchange_url =
  //       token_exchange_base_url + "?" + Querystring.stringify(params);
  //     fetch(token_exchange_url)
  //       .then(result => {
  //         return result.json();
  //       })
  //       .then(data => {
  //         if (data != null) {
  //           var view = {
  //             user_access_token: data.access_token,
  //             expires_at: data.expires_at,
  //             user_id: data.id
  //           };
  //           localStorage.setItem(FBOTPToken, JSON.stringify(data));
  //           this.togglePopup(JSON.stringify(view));
  //           console.log("view data " + view);
  //           // get account details at /me endpoint
  //           var me_endpoint_url =
  //             me_endpoint_base_url + "?access_token=" + data.access_token;
  //           fetch(me_endpoint_url)
  //             .then(result => {
  //               return result.json();
  //             })
  //             .then(data => {
  //               localStorage.setItem(FBOTPMe, JSON.stringify(data));
  //               console.log("data endurl: " + JSON.stringify(data));
  //               this.togglePopup(JSON.stringify(data));
  //               // window.location="/";
  //               console.log("response" + JSON.stringify(response));
  //               if (data !== null) {
  //                 var access_token = localStorage.getItem(FBOTPToken)
  //                   .access_token;
  //                 var expires_in = localStorage.getItem(FBOTPToken)
  //                   .token_refresh_interval_sec;
  //                 console.log("access_token ", access_token);
  //                 console.log("expires_in ", expires_in);
  //                 var loginResponse = {
  //                   id: data.id,
  //                   accessToken: access_token,
  //                   expiresIn: expires_in,
  //                   phone_number: data.phone.number
  //                 };
  //                 LoginEvent("Mobile_Number", "Mobile Number Login");
  //                 console.log("loginResponse ", JSON.stringify(loginResponse));
  //                 localStorage.setItem(UserID, data.id);
  //                 setLoginInLocalStorage(
  //                   LoginTypeValue.MOBILE_NUMBER,
  //                   JSON.stringify(loginResponse)
  //                 );
  //                 // HA.HAController.getInstance().onUserLoggedIn(data.id, data.phone.number,LoginTypeValue.MOBILE_NUMBER);
  //                 RegisterSocialLoginInWordPress(
  //                   data.phone.national_number,
  //                   "",
  //                   data.id,
  //                   "mobile_number",
  //                   function isRegistered(finalResponse) {
  //                     if (
  //                       finalResponse !== null &&
  //                       finalResponse !== undefined &&
  //                       finalResponse.success
  //                     ) {
  //                       var data = JSON.parse(finalResponse.userdata);
  //                       localStorage.setItem(
  //                         UserProfileData,
  //                         JSON.stringify(data)
  //                       );
  //                       window.location = "/";
  //                     } else {
  //                       this.togglePopup(
  //                         "Oop's not registered , Please try again later!"
  //                       );
  //                     }
  //                   }
  //                 );
  //               } else {
  //                 this.togglePopup("Error Loggin via Mobile OTP ");
  //               }
  //             });
  //         }
  //       });
  //     // Send code to server to exchange for access token
  //   } else if (response.status === "NOT_AUTHENTICATED") {
  //     // handle authentication failure
  //     console.log("response status " + response.status);
  //   } else if (response.status === "BAD_PARAMS") {
  //     console.log("response status " + response.status);
  //     // handle bad parameters
  //   }
  // }

  mobileCLick=(event)=>{
window.location="/mobilenumber?id=0"
    // this.state.signInWithPhoneNumber();
    console.log(event)
    // var node = (
    //   <
    //         appId={FACEBOOK_APP_ID} // Update this!
    //         version="v1.0" // Version must be in form v{major}.{minor}
    //         onResponse={resp => this.loginCallback(resp)}
    //         csrf="qwerty" // Required for security
    //         countryCode="+91" // eg. +60
    //         loginType="PHONE"
    //         language="en_US"
    //         fbAppEventsEnabled={true}
    //         Content-Type="application/x-www-form-urlencoded"
    //       ></AccountKit>
    // )
  //  var ele= event.currentTarget;
  //  ele.
  // AccountKit.onClick();
  }
  render() {
    var width = window.innerWidth + "px";
    var height = window.innerHeight + "px";
    return (
      <div className="main">
        <div
          className="logindata"
          style={{
            width: width,
            height: height
            // marginTop: "-6vw"
          }}
        >
          <p
            style={{
              color: "#ffffff",
              fontWeight: "400",
              fontSize: "6vw",
              textAlign: "right",
              marginRight: "5vw",
              paddingTop: "-6vw"
            }}
            onClick={skipClick}
          >
            Skip
          </p>
          <img
            src={require("../imagesvg/bh_logo.png")}
            style={{
              height: "23.375vw",
              width: "80.68vw",
              marginLeft: "8%"
            }}
            alt=""
          />

          <p
            style={{
              color: "#ffffff",
              fontWeight: "700",
              fontSize: "6.25vw",
              textAlign: "center",
              paddingTop: "35vw"
            }}
          >
            Sign Up or Signin with
          </p>
              {/* <button
                style={{
                  height: "15vw",
                  width: "fit-content",
                  textAlign: "center",
                  marginLeft: "22%",
                  marginTop: "6vw",
                  backgroundColor: "#DF440D",
                  color: "#ffffff",
                  borderRadius: "10vw",
                  fontSize: "5vw",
                  paddingLeft: "10vw",
                  paddingRight: "10vw",
                  border: "none"
                }}
                onClick={this.mobileCLick.bind(this)}
                id="submit"
                className="submit"
              >Mobile Number</button> */}
          <div
            className="socialIcons"
            style={{
              display: "flex",
              marginLeft: "32%",
              marginTop: "18vw",
              width: "fit-content"
            }}
          >
            <FacebookLogin
              appId={FACEBOOK_APP_ID}
              fields="name,email,picture"
              callback={this.responseFacebook}
              onFailure={this.FBLoginFailed}
              autoLoad={false}
              render={renderProps => (
                <img
                  src={require("../../components/imagesvg/fbIcon.png")}
                  style={{
                    height: "15vw",
                    width: "15vw",
                    marginRight: "8vw"
                  }}
        
                  alt=""
                  onClick={renderProps.onClick}
                />
              )}
            />
            {/* <GoogleLogin
              clientId="662059264115-e4af4adf1gntqbb0qqn1v1l8j2n1gadn.apps.googleusercontent.com"
              render={renderProps => (
                <img
                  src={require("../../components/imagesvg/gplusIcon.png")}
                  style={{
                    height: "15vw",
                    width: "15vw",
                    marginRight: "8vw"
                  }}
                  alt=""
                  onClick={renderProps.onClick}
                />
              )}
              buttonText="Login"
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogle}
              cookiePolicy={"single_host_origin"}
            /> */}
            <img
              src={require("../../components/imagesvg/atIcon.png")}
              style={{
                height: "15vw",
                width: "15vw"
              }}
              alt=""
              onClick={signInClick}
            />
          </div>
        </div>
        {this.state.showPopup ? (
          <Popup
            text={this.state.popUpText}
            closePopup={this.togglePopup.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

export default NewLoginPage;
