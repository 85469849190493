import React, { Component } from "react";
import {
searchTrendingTags,
SearchAutoSuggest,
SearchTrendingTagsAuthorizationKey
} from "../../../utils/apiUrls.js";
import {getAuthorizationHeader} from "../../../utils/commonClickActions.js"
import "./searchPage.css";
import {SearchEvent} from '../../../analytics_events/js/CustomEvents.js';
import { List, ListItem } from "material-ui";
class SearchPage extends Component {
constructor(props) {
super(props);
this.state = {
query: "",
showOverlay: false,
trendingTagsList: [],
filterList: []
};
}

handleInputChange = () => {
this.setState({
query: this.search.value
});
console.log(this.search.value);

if (this.search.value.length != null && this.search.value.length >= 3) {
var finalurl = SearchAutoSuggest + this.search.value + "/0/6";
console.log(finalurl);
fetch(finalurl)
.then(results => {
return results.json();
})
.then(async data => {
console.log(data.RESPONSE);
if (data.RESPONSE !== null && data.RESPONSE !== undefined) {
var filter = data.RESPONSE.map(autotext => {
var text = autotext.name + " - [" + autotext.type + "]";
return (
<ListItem key={autotext.id} style={{
                    background: "#F9F9F9",
                    borderBottom: "0.2vw solid #cccccc"
                  }} onClick={this.showSearchResults.bind( this, text, "from autosuggest" , autotext.type )}>
  {text}
</ListItem>
);
});
this.setState({ showOverlay: true, filterList: filter });
}
});
} else {
this.setState({ showOverlay: false });
}
};

handleBackArrow() {
console.log("back arrow");
window.location = "/";
}
showSearchResults = (searchText, calledFrom, category_type, event) => {
console.log(searchText + " " + calledFrom);
var inputSearch = document.querySelectorAll("#searchtext");
if (event.key === "Enter") {
window.location =
"/searchresults?id=0&searchtext=" +
inputSearch[0].value +
"&category_type=" +
category_type;
} else if (calledFrom !== "from search") {
window.location =
"/searchresults?id=0&searchtext=" +
searchText +
"&category_type=" +
category_type;
}
};

componentDidMount() {
SearchEvent("Search","Search on Tab","");
fetch(searchTrendingTags,{
method: "POST",
headers: {
"Accept":"Application/json",
"Authorization": getAuthorizationHeader(SearchTrendingTagsAuthorizationKey),
"Content-Type": "application/x-www-form-urlencoded"
}})
.then(results => {
return results.json();
})
.then(data => {
var tags = data.map(tag => {
return (
<div key={tag} onClick={this.showSearchResults.bind( this, tag, "from tag" , "default" )}
  className="search_trending_tags">
  {tag}
</div>
);
});
this.setState({ trendingTagsList: tags });
});
}
render() {
var data;
const { loading } = this.state;
if (loading) {
data =
<div className="loader" />;
} else {
data = (
<div>
  <div className="search_container">
    <div className="search_more_arrow">
      <img src={require("../../../imagesvg/left_red_arrow.svg")} className="action_icons_back" alt="back_arrow"
        onClick={this.handleBackArrow} style={{
                  left: "0vw",
                  position: "absolute"
                }} />
      <p className="search_photo_name">Search</p>
    </div>
    <div style={{
                display: "absolute",
                textAlign: "center"
              }}>
      <input type="search" id="searchtext" name="searchtext" placeholder="search" autoComplete="on" ref={input=>
      (this.search = input)}
      onChange={this.handleInputChange}
      returnkeytype="search"
      autoFocus={true}
      value={this.props.searchName}
      onKeyPress={this.showSearchResults.bind(
      this,
      this.value,
      "from search",
      "default"
      )}
      clearbuttonmode="while-editing"
      style={{
                  boxSizing: "border-box",
                  border: " 0.3vw solid #CCCCCC",
                  width: "80%",
                  height: "12.875vw",
                  borderRadius: "10vw",
                  marginTop: "20vw",
                  paddingLeft: "3vw",
                  paddingRight: "3vw",
                  backgroundColor: "#F9F9F9"
                }}
      />
      <div id="overlay" style={{ display: this.state.showOverlay ? "block" : "none" }}>
        <List key={Math.random()} style={{
                    marginLeft: "15%",
                    width: "70%",
                    marginTop: "-1.8vw"
                  }}>
          {this.state.filterList}
        </List>
      </div>
    </div>
    <div className="search_trending" style={{
                position: "absolute",
                backgroundColor: "#FFFFFF",
                padding: "3.125vw 0",
                marginLeft: "5vw",
                marginTop: "10vw"
              }}>
      <span className="section_name">Trending</span>
      <div style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "2vw",
                  margin: "5vw",
                  overflow: "auto",
                  textAlign: "center"
                }}>
        {this.state.trendingTagsList}
      </div>
    </div>
  </div>
  <div id="ATD_BH_320x50_P4" style={{
              background: "rgba(247,247,247,0.4)",
              bottom: "0",
              textAlign: "center",
              width: "100%",
              position: "absolute"
            }} />
</div>
);
}
return <div>{data}</div>;
}
}
export default SearchPage;