import React, { Component } from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import './App.css';
import Toolbar from './components/basecomponents/toolbar/toolbar.js';
import withFirebaseAuth from 'react-with-firebase-auth'
import * as firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from './components/utils/firebaseConfig.js';
import CustomBottomNavigation from './components/basecomponents/bottomtabnavigation/customBottomNavigation.js';
import ThemeDefault from './components/basecomponents/default_theme/themeDefault.js';

import TrendingPage from "./components/views/trendingPage.jsx";
import MoviesPage from "./components/views/moviesPage.jsx";
import VideoPage from "./components/views/videoPage.jsx";
import BoxOfficePage from "./components/views/boxOfficePage.jsx";
import CelebsPage from "./components/views/celebsPage.jsx";
import HealthCheck from "./components/views/health.jsx";
import BH_Terms from "./components/views/bh_terms.jsx";
import TrendingPostDetails from './components/apiparsercomponents/trending_section/stories/views/trendingPostDetails.jsx';

import GallerySectionPage from "./components/apiparsercomponents/trending_section/gallery/views/gallerySectionApiParser.jsx";
import GalleryPhotoApiParser from "./components/apiparsercomponents/trending_section/gallery/views/galleryPhoto.jsx";
import GalleryAllPhotosApiParser from "./components/apiparsercomponents/trending_section/gallery/views/galleryMoviePhotosSection.jsx";
import GalleryPhotoWithUrlApiParser from "./components/apiparsercomponents/trending_section/gallery/views/galleryPhotoWIthUrl.jsx";

import EventVideoDetailApiParser from './components/apiparsercomponents/trending_section/events/views/eventVideoDetailApiParser.jsx';

import MovieDetailApiParser from './components/apiparsercomponents/movies_section/reviews/views/MovieDetailApiParser.jsx';
import DetailLyricsApiParser from './components/apiparsercomponents/movies_section/reviews/views/detailLyricsApiParser.jsx';

import starBoxOfficeCollectionApiParser from './components/apiparsercomponents/boxoffice_section/starpower/views/starBoxOfficeCollectionApiParser.jsx';

import CelebDetailsPage from "./components/apiparsercomponents/celeb_section/views/celebsdetailPost.jsx";
import DetailMorePhotosCelebSection from './components/apiparsercomponents/celeb_section/views/detailMorePhotosSection.jsx';
import LoginPage from './components/views/newLogin.jsx';
import EmailLogin from './components/views/newEmailLogin.jsx';
import ForgotPasswordPage from './components/views/forgotPassword.jsx';
import SignUpPage from './components/views/signupPage.jsx';
import MobileNumberLoginPage from './components/views/mobileLogin.jsx';
import MobileOTPPage from './components/views/mobileLoginOTP.jsx';

import SearchPage from './components/apiparsercomponents/search/views/searchPage.jsx';
import SearchAllResultsPage from './components/apiparsercomponents/search/views/searchResultPage.jsx';

import Profile from './components/views/userProfile.jsx';
import UpdateProfile from './components/views/UpdateProfile.jsx';
const firebaseApp = firebase.initializeApp(firebaseConfig);

class App extends Component {
  constructor() {
    super();
    this.state = {
        showSearch :"hidden",
        showUserProfile:"hidden",
        showContent:"visible",
        showSearchField:"hidden",
        bottomTabSelected:"trending",
        showiDetailPage:"none",
        showAppPage:"",
    }
};

  componentDidMount(){
    const params = new URL(window.location.href).searchParams;
    let id =params.get('id');
    if(id!==null){
      this.setState({
        showiDetailPage:"block",
        showAppPage:"none"
      });
    }else{
      this.setState({
        showiDetailPage:"none",
        showAppPage:"block"
      });
    }
  }
  componentWillUnmount(){
    console.log("inside appjs componentWillUnmount")
  }
  handleBottomNavigation=tabSelected=>{
   this.setState({
    bottomTabSelected:tabSelected.currentTarget.id
   });
  };
  render() {
    const {
      signInWithPhoneNumber
    } = this.props;
      return (
        <Router>
        <MuiThemeProvider muiTheme={ThemeDefault}>
        <div>
        <div className="fullScreen" style={{display:this.state.showiDetailPage,width:"100%",height:"100%"}}>
        <Route path="/celebdetail" component={CelebDetailsPage} />
        <Route path="/postdetail" component={TrendingPostDetails}/> 
        <Route path="/gallerysectiondetail" component={GallerySectionPage}/>
        <Route path="/galleryphoto" component={GalleryPhotoApiParser}/>
        <Route path="/galleryallphotos" component={GalleryAllPhotosApiParser}/>
        <Route path="/gallerysectiondetailwithurl" component={GalleryPhotoWithUrlApiParser}/>
        <Route path="/moviereviewdetail" component={MovieDetailApiParser}/>
        <Route path="/musiclyrics" component={DetailLyricsApiParser}/>
        <Route path="/startcollectiondetail" component={starBoxOfficeCollectionApiParser}/>
        <Route path="/celebmorephotos"  component={DetailMorePhotosCelebSection}/>
        <Route path="/login" component={LoginPage} />
        <Route path="/signin" component={EmailLogin} />
        <Route path="/forgotpassword"component={ForgotPasswordPage}/>
        <Route path="/signup"component={SignUpPage}/>
        <Route path="/mobilenumber"component={()=><MobileNumberLoginPage signInWithPhoneNumber={signInWithPhoneNumber} firebase={firebase}/>}/>
        <Route path="/mobileotp"component={MobileOTPPage}/>
        <Route path="/search" component ={SearchPage}/>
        <Route path="/searchresults" component ={SearchAllResultsPage}/>
        <Route path="/eventvideodetail" component={EventVideoDetailApiParser}/>
       <Route path="/profile" component={Profile}/>
       <Route path="/updateprofile" component={UpdateProfile}/>
        </div>
        <div className="head" style={{display:this.state.showAppPage}}>
            <div className="toolbar">
              <Toolbar  callbackFromParent={this.showSearchField}/>
            </div>
            <div className="content" style={{backgroundColor:"#ffffff",marginTop:"17.5vw"}}>
            <Route path="/" exact component={TrendingPage} />
            <Route path="/movies" component={MoviesPage} />
            <Route path="/video" component={VideoPage} />
            <Route path="/boxoffice" component={BoxOfficePage} />
            <Route path="/celebs" component={CelebsPage} />
            <Route path="/health.html" component={HealthCheck}/>
            <Route path="/terms.html" component={BH_Terms}/>
            </div>
            <div className="bottom_navigation">
            <div id="ATD_BH_320x50_P1" style={{background:"rgba(247,247,247,0.4)",textAlign:"center"}}></div>
            <CustomBottomNavigation onClick={this.handleBottomNavigation.bind(this)}/>
            </div>
        </div>
        </div>
        </MuiThemeProvider>
        </Router>
      );
  }
}
const firebaseAppAuth = firebaseApp.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};
export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
