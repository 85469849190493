import React, { Component } from "react";
import CelebsApiParser from "../apiparsercomponents/celeb_section/js/celebsApiParser.js";
import { CelebTopBasedOnWeight } from "../utils/apiUrls.js";

class CelebsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="main">
        <div
          className="data"
          style={{ textAlign: "center", background: "#ffffff" }}
        >
          <CelebsApiParser tab_selected={"celeb"} url={CelebTopBasedOnWeight} />
        </div>
      </div>
    );
  }
}

export default CelebsPage;
