import React, { Component } from "react";
import Moment from "moment";
import "./detailCastAndCrewApiParser.css";
var serialize = require("node-serialize");
var phpUnserialize = require("phpunserialize");

class DetailCastAndCrewViaApiParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bottomTabSelected: props.tab_selected,
      loading: true,
      data: props.data,
      castncrew: [],
      unserializeData: ""
    };
  }
  getCurrentDate(date) {
    var formatedDate = Moment(date, "YYYYMMDDhms").format("DD MMM YYYY");
    return formatedDate;
  }
  getTitle(doc) {
    if (doc != null) {
      return doc.post_title;
    }
  }

  getDeserializedData(key) {
    switch (key) {
      case "censor_date":
        let censor_date = serialize.unserialize(this.state.data).censor_date;
        if (censor_date) {
          return censor_date;
        } else {
          return "Not Available";
        }
      case "certificate_no":
        let certificate_no = serialize.unserialize(this.state.data)
          .certificate_no;
        if (certificate_no) {
          return certificate_no;
        } else {
          return "Not Available";
        }
      case "runtime":
        let runtime = serialize.unserialize(this.state.data).runtime;
        if (runtime) {
          return runtime;
        } else {
          return "Not Available";
        }
      case "censor_certification":
        let censor_certification = serialize.unserialize(this.state.data)
          .censor_certification;
        if (censor_certification) {
          return censor_certification;
        } else {
          return "Not Available";
        }
      case "reels":
        let reels = serialize.unserialize(this.state.data).reels;
        if (reels) {
          return reels;
        } else {
          return "Not Available";
        }
      case "movie_length":
        let movie_length = serialize.unserialize(this.state.data).movie_length;
        if (movie_length) {
          return movie_length;
        } else {
          return "Not Available";
        }
      case "shooting_location":
        let shooting_location = serialize.unserialize(this.state.data)
          .shooting_location;
        if (shooting_location) {
          return phpUnserialize(shooting_location);
        } else {
          return "Not Available";
        }
      default:
        return "Not Available";
    }
  }
  _onError(imgSource, ev) {
    if (imgSource === undefined || imgSource === null) {
      ev.target.src = require("../../../../imagesvg/placeholder.png");
    } else {
      ev.target.src = imgSource;
    }
  }
  getImage(image_thumb) {
    var customimage = image_thumb;
    if (customimage === undefined || customimage === null) {
      return customimage;
    } else {
      customimage = image_thumb.toString().replace(".jpg", "-165x165.jpg");
      return customimage;
    }
  }

  getImageAndName(data) {
    if (data !== undefined && data.length >= 1) {
      var dataItems = data.toString().split(",");
      var dataimgname = dataItems.map((datdataItem, index) => {
        var dataimg = datdataItem.toString().split("|");
        if (dataimg !== undefined || dataimg !== null) {
          if (
            dataimg.length >= 1 &&
            dataimg[dataimg.length - 1].toString().includes("jpg")
          ) {
            return (
              <div style={{ paddingRight: "5vw" }} key={index}>
                <img
                  className="actor_image"
                  src={this.getImage(dataimg[dataimg.length - 1])}
                  alt="actor"
                  onError={this._onError.bind(
                    this,
                    dataimg[dataimg.length - 1]
                  )}
                />
                <p style={{ fontSize: "5.625vw", fontWeight: "400" }}>
                  {dataimg[1]}
                </p>
              </div>
            );
          } else {
            return (
              <div style={{ paddingRight: "5vw" }} key={index}>
                <img
                  className="actor_image"
                  src={require("../../../../imagesvg/placeholder.png")}
                  style={{ backgroundColor: "#f9f9f9" }}
                  alt="actor"
                />
                <p style={{ fontSize: "5.625vw", fontWeight: "400" }}>
                  {dataimg[1]}
                </p>
              </div>
            );
          }
        }
        return "";
      });
      return (
        <div
          style={{ display: "flex", overflowX: "scroll", textAlign: "center" }}
        >
          {dataimgname}
        </div>
      );
    } else {
      return "Not Available";
    }
  }
  getActorImage(data) {
    var actrImage = "";
    if (data !== undefined) {
      var actor = data.toString().split(",");
      var img = actor[0].toString().split("|");
      if (img.length >= 1 && img[img.length - 1].toString().includes("jpg")) {
        actrImage = img[img.length - 1];
      } else {
        actrImage = img[0];
      }
    }
    return actrImage;
  }

  getDataVlaueIfPresent(key) {
    var p2p = this.state.data.details_p2p;
    //   console.log("p2p : "+JSON.stringify(p2p));
    if (p2p !== "" && p2p !== undefined && p2p !== null) {
      var unserializeDataList = serialize.unserialize(p2p);
      // console.log("unserializeDataList : "+JSON.stringify(unserializeDataList));
      switch (key) {
        case "movie-celebrity-banner":
          var banner = unserializeDataList["movie-celebrity-banner"];
          let bannerList = [];
          if (banner !== undefined && banner.length >= 1) {
            banner.map((listdata, index) => {
              bannerList.push(<p key={index}>{listdata.post_title}</p>);
              return "";
            });
            return bannerList;
          } else {
            return "Not Available";
          }
        case "genre":
          let genre = this.state.data.genre;
          if (genre !== undefined && genre.length >= 1) {
            let genreList = [];
            genre.map(listdata => {
              genreList.push("." + listdata);
              return "";
            });
            return genreList;
          } else {
            return "Not Available";
          }
        case "producerf":
          let producerf = this.state.data.producerf;
          // console.log(" inside key "+producerf);
          if (producerf !== undefined && producerf.length >= 1) {
            var producerf0 = producerf.toString().split(",");
            var name = producerf0[0].toString().split("|");
            if (name !== undefined || name !== null) {
              return name[1];
            }
            return producerf0[0];
          } else {
            return "Not Available";
          }
        case "actorf":
          let actorf = this.state.data.actorf;
          if (actorf !== undefined && actorf.length >= 1) {
            var actor0 = actorf.toString().split(",");
            var actorname = actor0[0].toString().split("|");
            if (actorname !== undefined || actorname !== null) {
              return actorname[1];
            }
            return actor0[0];
          } else {
            return "Not Available";
          }
        case "movie-celebrity-background-music":
          var background_music =
            unserializeDataList["movie-celebrity-background-music"];
          let background_musicList = [];
          if (background_music !== undefined && background_music.length >= 1) {
            background_music.map((listdata, index) => {
              background_musicList.push(
                <p key={index}>{listdata.post_title}</p>
              );
              return "";
            });
            return background_musicList;
          } else {
            return "Not Available";
          }
        case "movie-celebrity-costume-designer":
          var costume_designer =
            unserializeDataList["movie-celebrity-costume-designer"];
          let costume_designerList = [];
          if (costume_designer !== undefined && costume_designer.length >= 1) {
            costume_designer.map((listdata, index) => {
              costume_designerList.push(
                <p key={index}>{listdata.post_title}</p>
              );
              return "";
            });
            return costume_designerList;
          } else {
            return "Not Available";
          }
        case "movie-celebrity-publicity-pro":
          var publicity_pro =
            unserializeDataList["movie-celebrity-publicity-pro"];
          let publicity_proList = [];
          if (publicity_pro !== undefined && publicity_pro.length >= 1) {
            publicity_pro.map((listdata, index) => {
              publicity_proList.push(<p key={index}>{listdata.post_title}</p>);
              return "";
            });
            return publicity_proList;
          } else {
            return "Not Available";
          }
        case "movie-celebrity-co-producer":
          var co_producer = unserializeDataList["movie-celebrity-co-producer"];
          let co_producerList = [];
          if (co_producer !== undefined && co_producer.length >= 1) {
            co_producer.map((listdata, index) => {
              co_producerList.push(<p key={index}>{listdata.post_title}</p>);
              return "";
            });
            return co_producerList;
          } else {
            return "Not Available";
          }
        case "lang":
          let lang = this.state.data.lang;
          if (lang !== undefined && lang.length >= 1) {
            return lang;
          } else {
            return "Not Available";
          }
        case "directorf":
          let directorf = this.state.data.directorf;
          if (directorf !== undefined && directorf.length >= 1) {
            var directorf0 = directorf.toString().split(",");
            var directorname = directorf0[0].toString().split("|");
            if (directorname !== undefined || directorname !== null) {
              return directorname[1];
            }
            return directorf0[0];
          } else {
            return "Not Available";
          }
        case "movie-celebrity-editor":
          var editor = unserializeDataList["movie-celebrity-editor"];
          let editorList = [];
          if (editor !== undefined && editor.length >= 1) {
            editor.map((listdata, index) => {
              editorList.push(<p key={index}>{listdata.post_title}</p>);
              return "";
            });
            return editorList;
          } else {
            return "Not Available";
          }
        case "cinematographerf":
          let cinematographerf = this.state.data.cinematographerf;
          if (cinematographerf !== undefined && cinematographerf.length >= 1) {
            var cinematographerf0 = cinematographerf.toString().split(",");
            var cinematographername = cinematographerf0[0]
              .toString()
              .split("|");
            if (
              cinematographername !== undefined ||
              cinematographername !== null
            ) {
              return cinematographername[1];
            }
            return cinematographerf0[0];
          } else {
            return "Not Available";
          }
        case "movie-celebrity-screenplay":
          var screenplay = unserializeDataList["movie-celebrity-screenplay"];
          let screenplayList = [];
          if (screenplay !== undefined && screenplay.length >= 1) {
            screenplay.map((listdata, index) => {
              screenplayList.push(<p key={index}>{listdata.post_title}</p>);
              return "";
            });
            return screenplayList;
          } else {
            return "Not Available";
          }
        case "movie-celebrity-dialogue-writer":
          var dialouge = unserializeDataList["movie-celebrity-dialogue-writer"];
          let dialougeList = [];
          if (dialouge !== undefined && dialouge.length >= 1) {
            dialouge.map((listdata, index) => {
              dialougeList.push(<p key={index}>{listdata.post_title}</p>);
              return "";
            });
            return dialougeList;
          } else {
            return "Not Available";
          }
        case "movie-celebrity-publicity-design":
          var publicity_design =
            unserializeDataList["movie-celebrity-publicity-design"];
          let publicity_designList = [];
          if (publicity_design !== undefined && publicity_design.length >= 1) {
            publicity_design.map((listdata, index) => {
              publicity_designList.push(
                <p key={index}>{listdata.post_title}</p>
              );
              return "";
            });
            return publicity_designList;
          } else {
            return "Not Available";
          }
        case "movie-celebrity-visual-effects":
          var visual_effects =
            unserializeDataList["movie-celebrity-visual-effects"];
          let visual_effectsList = [];
          if (visual_effects !== undefined && visual_effects.length >= 1) {
            visual_effects.map((listdata, index) => {
              visual_effectsList.push(<p key={index}>{listdata.post_title}</p>);
              return "";
            });
            return visual_effectsList;
          } else {
            return "Not Available";
          }
        case "movie-celebrity-shooting-studios":
          var shooting_studios =
            unserializeDataList["movie-celebrity-shooting-studios"];
          let shooting_studiosList = [];
          if (shooting_studios !== undefined && shooting_studios.length >= 1) {
            shooting_studios.map((listdata, index) => {
              shooting_studiosList.push(
                <p key={index}>{listdata.post_title}</p>
              );
              return "";
            });
            return shooting_studiosList;
          } else {
            return "Not Available";
          }
        case "movie-celebrity-sound":
          var sound = unserializeDataList["movie-celebrity-sound"];
          let soundList = [];
          if (sound !== undefined && sound.length >= 1) {
            sound.map((listdata, index) => {
              soundList.push(<p key={index}>{listdata.post_title}</p>);
              return "";
            });
            return soundList;
          } else {
            return "Not Available";
          }
        case "movie-celebrity-music-company":
          var music_company =
            unserializeDataList["movie-celebrity-music-company"];
          let music_companyList = [];
          if (music_company !== undefined && music_company.length >= 1) {
            music_company.map((listdata, index) => {
              music_companyList.push(<p key={index}>{listdata.post_title}</p>);
              return "";
            });
            return music_companyList;
          } else {
            return "Not Available";
          }
        case "movie-celebrity-production-designer":
          var production_designer =
            unserializeDataList["movie-celebrity-production-designer"];
          let production_designerList = [];
          if (
            production_designer !== undefined &&
            production_designer.length >= 1
          ) {
            production_designer.map((listdata, index) => {
              production_designerList.push(
                <p key={index}>{listdata.post_title}</p>
              );
              return "";
            });
            return production_designerList;
          } else {
            return "Not Available";
          }
        case "movie-celebrity-casting-director":
          var casting_director =
            unserializeDataList["movie-celebrity-casting-director"];
          let casting_directorList = [];
          if (casting_director !== undefined && casting_director.length >= 1) {
            casting_director.map((listdata, index) => {
              casting_directorList.push(
                <p key={index}>{listdata.post_title}</p>
              );
              return "";
            });
            return casting_directorList;
          } else {
            return "Not Available";
          }
        case "movie-celebrity-action-director":
          var action_director =
            unserializeDataList["movie-celebrity-action-director"];
          let action_directorList = [];
          if (action_director !== undefined && action_director.length >= 1) {
            action_director.map((listdata, index) => {
              action_directorList.push(
                <p key={index}>{listdata.post_title}</p>
              );
              return "";
            });
            return action_directorList;
          } else {
            return "Not Available";
          }
        case "movie-celebrity-choreographers":
          var choreographers =
            unserializeDataList["movie-celebrity-choreographers"];
          let choreographersList = [];
          if (choreographers !== undefined && choreographers.length >= 1) {
            choreographers.map((listdata, index) => {
              choreographersList.push(<p key={index}>{listdata.post_title}</p>);
              return "";
            });
            return choreographersList;
          } else {
            return "Not Available";
          }
        case "movie-celebrity-playback-singers":
          let playbacksingerf = this.state.data.playbacksingerf;
          if (playbacksingerf !== undefined && playbacksingerf.length >= 1) {
            var playbacksingerf0 = playbacksingerf.toString().split(",");
            var playbacksingername = playbacksingerf0[0].toString().split("|");
            if (
              playbacksingername !== undefined ||
              playbacksingername !== null
            ) {
              return playbacksingername[1];
            }
            return playbacksingerf0[0];
          } else {
            return "Not Available";
          }
        default:
          return "Not Available";
      }
    }
  }

  componentDidMount() {
    this.setState({ loading: false });

    let castncrewData = this.state.data;
    let castncrew = (
      <div className="castncrew_container_data" key={castncrewData.id}>
        <p className="castncrewfield">BANNER:</p>
        <div key={castncrewData.post_title} className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-banner")}
        </div>
        <p className="castncrewfield">STATUS:</p>
        <p className="castncrewdata">{castncrewData.details_status}</p>
        <p className="castncrewfield">RELEASE DATE:</p>
        <p className="castncrewdata">
          {this.getCurrentDate(castncrewData.releasedate)}
        </p>
        <p className="castncrewfield">GENRE:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("genre")}
        </div>
        <p className="castncrewfield">PRODUCER:</p>
        <div className="castncrewdata">
          {this.getImageAndName(castncrewData.producerf)}
        </div>
        <p className="castncrewfield">CO-PRODUCER:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-co-producer")}
        </div>
        <p className="castncrewfield">STAR CAST:</p>
        <div className="castncrewdata">
          {this.getImageAndName(castncrewData.actorf)}
        </div>
        <p className="castncrewfield">WRITER:</p>
        <div className="castncrewdata">
          {this.getImageAndName(castncrewData.writerf)}
        </div>
        <p className="castncrewfield">BACKGROUND MUSIC:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-background-music")}
        </div>
        <p className="castncrewfield">COSTUME DESIGNER:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-costume-designer")}
        </div>
        <p className="castncrewfield">PUBLICITY PRO:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-publicity-pro")}
        </div>
        <p className="castncrewfield">CENSOR DETAILS:</p>
        <p className="castncrewsubfield">CENSOR DATES:</p>
        <p className="castncrewsubdata">
          {this.getDeserializedData("censor_date")}
        </p>
        <p className="castncrewsubfield">CENSOR CERTIFICATE NO:</p>
        <p className="castncrewsubdata">
          {this.getDeserializedData("certificate_no")}
        </p>
        <p className="castncrewsubfield">RUNTIME:</p>
        <p className="castncrewsubdata">
          {this.getDeserializedData("runtime")}
        </p>
        <p className="castncrewsubfield">CERTIFICATION:</p>
        <p className="castncrewsubdata">
          {this.getDeserializedData("censor_certification")}
        </p>
        <p className="castncrewsubfield">REELS:</p>
        <p className="castncrewsubdata">{this.getDeserializedData("reels")}</p>
        <p className="castncrewsubfield">LENGTH IN METRES:</p>
        <p className="castncrewdata">
          {this.getDeserializedData("movie_length")}
        </p>
        <p className="castncrewfield">MUSIC DIRECTOR:</p>
        <div className="castncrewdata">
          {this.getImageAndName(castncrewData.musicdirectorf)}
        </div>
        <p className="castncrewfield">LANGUAGE:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("lang")}
        </div>
        <p className="castncrewfield">DIRECTOR:</p>
        <div className="castncrewdata">
          {this.getImageAndName(castncrewData.directorf)}
        </div>
        <p className="castncrewfield">LYRICIST:</p>
        <div className="castncrewdata">
          {this.getImageAndName(castncrewData.lyricistf)}
        </div>
        <p className="castncrewfield">EDITOR:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-editor")}
        </div>
        <p className="castncrewfield">CINEMATOGRAPHER:</p>
        <div className="castncrewdata">
          {this.getImageAndName(castncrewData.cinematographerf)}
        </div>
        <p className="castncrewfield">SCREENPLAY:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-screenplay")}
        </div>
        <p className="castncrewfield">DIALOUGE:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-dialogue-writer")}
        </div>
        <p className="castncrewfield">PUBLICITY DESIGN:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-publicity-design")}
        </div>
        <p className="castncrewfield">VISUAL EFFECTS:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-visual-effects")}
        </div>
        <p className="castncrewfield">SHOOTING LOCATION:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-shooting-studios")}
        </div>
        <p className="castncrewfield">SOUND:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-sound")}
        </div>
        <p className="castncrewfield">MUSIC COMPANY:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-music-company")}
        </div>
        <p className="castncrewfield">PRODUCTION DESIGNER:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-production-designer")}
        </div>
        <p className="castncrewfield">CASTING DIRECTOR:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-casting-director")}
        </div>
        <p className="castncrewfield">ACTION DIRECTOR:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-action-director")}
        </div>
        <p className="castncrewfield">CHOREOGRAPHERS:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-choreographers")}
        </div>
        <p className="castncrewfield">PLAYBACK SINGERS:</p>
        <div className="castncrewdata">
          {this.getDataVlaueIfPresent("movie-celebrity-playback-singers")}
        </div>
      </div>
    );

    this.setState({ castncrew: castncrew });
  }
  render() {
    let data;
    const { loading } = this.state;
    if (loading) {
      data = <div className="subloader" />;
    } else {
      data = <div className="castncrew_content">{this.state.castncrew}</div>;
    }
    return <div>{data}</div>;
  }
}
export default DetailCastAndCrewViaApiParser;
