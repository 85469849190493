import React, { Component } from "react";
import { createBrowserHistory } from "history";
import "./eventRow.css";
import EventCardView from "./eventCardView.js";
import { IndexDB } from "../../../../utils/apiUrls.js";
import { TrendingEvent ,VideoEvent} from "../../../../analytics_events/js/CustomEvents";

class EventRowApiParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      sections: [],
      bottomTabSelected: props.data,
      url: props.url,
      start: 0,
      limit: 10
    };
  }

  componentDidMount() {

    const history = createBrowserHistory();

    // Get the current location.
    const location = history.location;
    console.log(location);
    const unlisten = history.listen((location, action) => {
      // location is an object like window.location
      console.log(action, location.pathname, location.state);
    });

 
    if (this.state.bottomTabSelected === "trending") {
      TrendingEvent("Trending_Tab_Events","Trending Tab");
         // Use push, replace, and go to navigate around.
    history.push({ pathname: "/" });
    window.onpopstate = () => {
      window.location = "/";
    };
    unlisten();
    } else {
      VideoEvent("Videos_Tab_Parties_&_Events  ","Video Tab");
         // Use push, replace, and go to navigate around.
    history.push({ pathname: "/video" });
    window.onpopstate = () => {
      window.location = "/video";
    };
    unlisten();
    }
    this.loadEvents();
  }

  loadEvents = () => {
    var currentTimeStamp = new Date().getTime();
    console.log("currentTimeStamp : " + currentTimeStamp);

    var finalUrl =
      this.state.url + "/" + this.state.start + "/" + this.state.limit;
    if (this.state.start === 0) {
      IndexDB.home_events_api
        .get(finalUrl)
        .then(data => {
          // console.log(data);
          if (data === undefined) {
            fetch(finalUrl)
              .then(results => {
                return results.json();
              })
              .then(data => {
                console.log("data");
                this.createUIComponent(data);
                IndexDB.home_events_api.put({
                  ID: finalUrl,
                  data: data,
                  timestamp: currentTimeStamp
                });
              });
          } else {
            var difference = (currentTimeStamp - data.timestamp) / 60000;
            console.log(difference);
            if (difference >= 30) {
              fetch(finalUrl)
                .then(results => {
                  return results.json();
                })
                .then(async data => {
                  console.log("inside data");
                  var updated = await IndexDB.home_events_api.update(finalUrl, {
                    ID: finalUrl,
                    data: data,
                    timestamp: currentTimeStamp
                  });
                  if (updated && this !== undefined && this !== null) {
                    this.createUIComponent(data);
                  }
                });
            } else {
              this.createUIComponent(data.data);
            }
          }
        })
        .catch(function(e) {
          console.log("Error: " + (e.stack || e));
        });
    } else {
      fetch(finalUrl)
        .then(results => {
          return results.json();
        })
        .then(data => {
          console.log("data");
          this.createUIComponent(data);
        });
    }
  };

  createUIComponent = data => {
    this.setState({ loading: false }); // className="section_header"
    let sections = data.section_photos.map((section_photo, index) => {
      if (index > 1 && index % 2 === 0) {
        console.log(8);
        return (
          <div key={"gallery" + Math.random()}>
            <div id={"ATD_BH_300x250_P8"} />
            <div
              key={index}
              className="header"
              style={{ marginTop: "3.625vw", marginBottom: "10vw" }}
            >
              <div className="section_title" style={{ marginLeft: "5vw" }}>
                <span className="section_name">
                  {this.getSectionHeading(section_photo.title)}{" "}
                </span>
              </div>
              <div
                key={section_photo.photos}
                className="section--content"
                style={{ backgroundColor: "#ffffff", marginBottom: "8vw" }}
              >
                <EventCardView
                  data={section_photo.photos}
                  start={this.state.start}
                  limit={this.state.limit}
                  section_name={section_photo.title}
                />
              </div>
            </div>
            />
          </div>
        );
      } else {
        return (
          <div
            key={index}
            className="header"
            style={{ marginTop: "3.625vw", marginBottom: "10vw" }}
          >
            <div className="section_title" style={{ marginLeft: "5vw" }}>
              <span className="section_name">
                {this.getSectionHeading(section_photo.title)}{" "}
              </span>
            </div>
            <div
              key={section_photo.photos}
              className="section--content"
              style={{ backgroundColor: "#ffffff", marginBottom: "8vw" }}
            >
              <EventCardView
                data={section_photo.photos}
                start={this.state.start}
                limit={this.state.limit}
                section_name={section_photo.title}
              />
            </div>
          </div>
        );
      }
    });
    this.setState({ sections: sections });
  };

  getSectionHeading(title) {
    return title;
  }

  render() {
    const { loading, sections } = this.state;
    let data;
    if (loading) {
      data = <div className="loader" />;
    } else {
      data = <div>{sections}</div>;
    }
    return <div style={{ backgroundColor: "#ffffff" }}>{data}</div>;
  }
}
export default EventRowApiParser;
