import React, { Component } from "react";
import "../apiparsercomponents/login/signinSection.css";
import { backArrowClick } from "../utils/commonClickActions.js";
import Popup from "../utils/customPopup.js";
class MobileOTPPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      popUpText: ""
    };
  }
  togglePopup(text) {
    console.log(this.state.showPopup);
    this.setState({
      showPopup: !this.state.showPopup,
      popUpText: text
    });
  }
  componentDidMount() {
    document.addEventListener("emailbackpressed", () => {
      this.props.history.push("/");
    });
  }

  onMobileOTPClicked = () => {
    // var username = document.querySelector('#username');
    var emailaddress = document.querySelector("#emailaddress");

    // console.log(JSON.stringify(username.value))//this.validateString(username) || +username.value
    console.log(JSON.stringify(emailaddress.value));

    if (this.validateString(emailaddress)) {
      // var bodyStr ="action=forgot_password&user_name="+"&user_email="+emailaddress.value;
      // fetch(LoginUser,{
      //   method: 'POST',
      //   headers: {
      //     'Accept': 'application/json',
      //     'Content-Type': 'application/x-www-form-urlencoded'
      //   },
      //    body:bodyStr
      // }
      //   )
      // .then(results=>{
      //   console.log(results)
      //   return results.json();
      // })
      // .then((data) => {
      //   console.log(data);
      //   if(data!==null&& data!==undefined){
      //     if(data.success){
      //       alert(data.message);
      //     // localStorage.setItem("jwtToken", data.data[0].user_activation_key);
      //       window.location="/";
      //     }else{
      //       alert("Entered username/email are not valid or blank"+data.message);
      //       // username.value ="";
      //       emailaddress.value ="";
      //     }
      //   }
      // });
    } else {
      this.togglePopup("Enter " + this.selectedField);
    }
  };

  validateString = inputValue => {
    this.selectedField = inputValue.id;
    if (
      inputValue.value !== null &&
      inputValue.value !== undefined &&
      inputValue.value !== ""
    ) {
      console.log(inputValue.id);
      return true;
    } else {
      return false;
    }
  };

  render() {
    var height = window.innerHeight + "px";
    return (
      <div className="main">
        <div className="logindata" style={{ width: "100%", height: height }}>
          <div className="signin_section_container">
            <img
              className="Signin_section_back_arrow"
              src={require("../../components/imagesvg/left-arrow.svg")}
              onClick={backArrowClick.bind(this, "MobileLoginOTP")}
              alt="more"
            />
            {/* <p className="Signin_section">Forgot Password</p> */}
          </div>
          <div
            style={{ textAlign: "center", width: "100vw", paddingTop: "20%" }}
          >
            <p
              className="forgot_pass"
              style={{
                textAlign: "center",
                textDecoration: "none",
                fontSize: "7vw",
                fontWeight: "400"
              }}
            >
              Entered mobile number
            </p>
            <p
              style={{
                fontSize: "4.5vw",
                fontWeight: "400",
                color: "#ffffff",
                marginTop: "-4%"
              }}
            >
              +91XXXXXX3243
            </p>
            <input
              type="email"
              id="emailaddress"
              name="emailaddress"
              placeholder="Enter OTP"
              className="loginput"
            />
            <input
              type="submit"
              value="Continue"
              style={{ marginTop: "20vw" }}
              className="submit"
              onClick={this.onMobileOTPClicked}
            />
            <p
              className="forgot_pass"
              style={{
                textAlign: "center",
                fontSize: "5vw",
                marginTop: "8vw",
                fontWeight: "400"
              }}
            >
              I didn't receive a code
            </p>
            <p
              style={{
                fontSize: "5vw",
                fontWeight: "400",
                color: "#ffffff",
                marginTop: "10vw",
                paddingLeft: "4vw",
                paddingRight: "4vw",
                paddingBottom: "10vw"
              }}
            >
              Tap Continue to accept Facebook's Terms, Data policy, cookie use
              and the Privacy Policy and Terms of Service of Hungama.
            </p>
          </div>
        </div>
        {this.state.showPopup ? (
          <Popup
            text={this.state.popUpText}
            closePopup={this.togglePopup.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

export default MobileOTPPage;
