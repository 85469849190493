import React, {
    Component
} from "react";
require('./similarContent.css');

class YouMayAlsoLikeApiParser extends Component {
constructor(props) {
    super(props);
    this.state = {
        you_may_like:[],
    };
}
componentDidMount(){
    let you_may_like = 
         (
                <div id="taboola-below-article-thumbnails"></div>
        );                     
this.setState({you_may_like:you_may_like})
}
render(){       
    return (
        <div style={{margin:"5vw"}}>
        {this.state.you_may_like}
        </div>
      );
};
};
export default YouMayAlsoLikeApiParser;