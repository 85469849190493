import React, {
    Component
} from "react";
import  Moment from "moment";
import InfiniteScroll from 'react-infinite-scroller';
import './boxOfficeApiParse.css';
import {bottomNavigationColor,blackColor,commonBackgroundColor,whiteColor,maroornErrorColor} from '../../../../utils/color.js';
import BoxOfficeCollectionApiParser from '../../collection/js/boxOfficeCollectionApiParser.js';
import MovieTopCollectionApiParser from '../../../movies_section/topgrosser/js/movieTopCollectionParser.js';
import BoxOfficeTabsApiParser from '../../starpower/js/boxOfficeTabsApiParser.js';
import {IndexDB,BoxOfficeCurrentYearCollection,BoxOfficeActorActoressDirectorCollectionBasedOnYear ,TopMoviesBasedOnCollection} from '../../../../utils/apiUrls.js';
import BaseImageComponent from '../../../../basecomponents/baseImageComponent.js';
import { BoxOfficeEvent } from "../../../../analytics_events/js/CustomEvents";

class BoxOfiiceAPIParser extends Component {
constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
        news : [],
        home_tabs:[],
        bottomTabSelected:props.tab_selected,
        url:props.url,
        loading:true,
        active:null,
        start :0,
        limit :10,
        error: false,
        hasMore: false,
        isLoading: false,
    };  
}
    getCurrentDate(date){
        var formatedDate =Moment(date,'YYYYMMDDhms').format("MMM DD, YYYY - h:mm a")+" IST";
        return formatedDate;
    }
    getTitle(doc){
    if(doc!=null){
        return doc.post_title;
    }
}
handleSubTabClick(index,event) {
    this._isMounted &&    this.setState({active : index});
    this.setStyle(index);
    if(event!==null && event.currentTarget!==undefined && event.currentTarget.id!==undefined){
    let container_id =event.currentTarget.id;
    this._isMounted &&    this.setState({home_tabs_selected_id:container_id});
    if(container_id ==="BOX OFFICE UPDATE"){
        window.location="/boxoffice";
    }else if(container_id ==="BOX OFFICE COLLECTION"){
        var boxoffice_collection =   <BoxOfficeCollectionApiParser tab_selected ={"boxoffice"} url ={BoxOfficeCurrentYearCollection} label="DOMESTIC" />
        if(this._isMounted){   
            this.setState({news:boxoffice_collection,hasMore:false});
            this._isMounted = false;
          }else{
            this.setState({news:boxoffice_collection});
                this._isMounted = true;
          }
    }else if(container_id === "STAR POWER"){
        var boxoffice_star_power =  <BoxOfficeTabsApiParser tab_selected ={"boxoffice"} url ={BoxOfficeActorActoressDirectorCollectionBasedOnYear} />
        if(this._isMounted){
            this.setState({news:boxoffice_star_power,hasMore:false});
            this._isMounted =false;
          }else{
            this.setState({news:boxoffice_star_power});
            this._isMounted =false;
          }
    }else{
        var boxoffice_top_movies =   <MovieTopCollectionApiParser customstyle={"4vw"} tab_selected ={"boxoffice"} url ={TopMoviesBasedOnCollection} />
        if(this._isMounted){
            this.setState({news:boxoffice_top_movies,hasMore:false});
            this._isMounted =false;
          }else{
            this.setState({news:boxoffice_top_movies});
            this._isMounted =false;
          }
    }
}
}
    setStyle(index){
    let listTabs=this.state.home_tabs;
    
    let tab = listTabs[index];
    let ele = document.getElementById(tab.props.id);

    listTabs.map((item)=>{
        let ele = document.getElementById(item.props.id);
        ele.style.color =blackColor;
        ele.style.opacity=0.2;
        return "";
    });
    if(ele!==undefined){
        ele.style.color =bottomNavigationColor;
        ele.style.opacity=1;
    }
    }

    handleNewsClick =(id)=>{
    window.location='/postdetail?id='+id+"&tab=boxoffice";
    };
headerTitleCliked(){
window.location="/boxoffice";
}

componentWillMount(){
    this._isMounted = true;
    this._isMounted && this.loadNews();
}
componentWillUnmount(){
    console.log('Component WILL UNMOUNT!')
    this._isMounted = false;
  }
componentDidMount(){
BoxOfficeEvent("Boxoffice_Tab_Box_Office_Update","Box Office Tab");
let title_style={
    color:blackColor,
    fontWeight: 900,
    textDecoration: "none",
    fontSize: "8.5vw",
    marginLeft: "0.125vw",
    whiteSpace: "nowrap"
    }
    
    let heading =  document.querySelectorAll(".toolbar__title");
    heading[0].innerHTML="Box Office";
    heading[0].onclick= this.headerTitleCliked.bind(this);
    for (var property in title_style) {
    heading[0].style[property] = title_style[property];
    }
}

getImage(image_thumb) {
    var customimage = image_thumb;
    if(customimage === undefined || customimage === null){
      return customimage;
    }else{
      customimage = image_thumb.toString().replace("322x322","354x199");
      return customimage;
    }
  }
loadNews=()=>{
    console.log("inside news")
    var finalUrl =this.state.url +"/"+this.state.start+"/"+this.state.limit;
    var currentTimeStamp = new Date().getTime();
    if(this.state.start === 0){
        IndexDB.boxoffice_update_api.get(finalUrl).then((data)=>{
          if(data === undefined){
             fetch(finalUrl)
            .then(results=>{
              return results.json();
          })
          .then((data) => {
            this.createUIComponent(data);
            IndexDB.boxoffice_update_api.put({ID:finalUrl,data:data,timestamp:currentTimeStamp});
          }).catch((err) => {
            this.setState({
              error: err.message,
              isLoading: false,
              });
          });;
          }else{
            var difference = (currentTimeStamp-data.timestamp)/60000;
            console.log(difference);
            if(difference>=30){
              fetch(finalUrl)
              .then(results=>{
                return results.json();
            }).then(async(data) => {
                var updated= await IndexDB.boxoffice_update_api.update(finalUrl, {ID:finalUrl,data:data,timestamp:currentTimeStamp});
                console.log(updated);
                if(updated && this !== undefined && this!== null){
                  this.createUIComponent(data)
                }
            });
            }else{
              this.createUIComponent(data.data);
            }
    
          }
        }).catch(function (e) {
          console.log("Error: " + (e.stack || e));
      });
      }else{
        fetch(finalUrl)
        .then(results=>{
          return results.json();
        })
        .then((data) => {
          this.createUIComponent(data);
        });
      }
};
createUIComponent=(data)=>{
    var thumb_imagesize ={
      width:"100%",
      height:"53.44vw",
        objectFit: "contain",
        backgroundRepeat: "no-repeat",
        borderTopLeftRadius:"2vw",
        borderTopRightRadius:"2vw",
        backgroundColor: commonBackgroundColor,
      }
      let home_tabs = data.home_tabs.map((home_tab,index)=>{
        if(index===0){
        return <button className="homelinks" key={index} id={home_tab} style={{color:bottomNavigationColor,opacity:1}} onClick={this.handleSubTabClick.bind(this,index)}>{home_tab}</button>
        }else{
            return <button className="homelinks" key={index} id={home_tab} style={{color:blackColor,opacity:0.2}}  onClick={this.handleSubTabClick.bind(this,index)}>{home_tab}</button>
        }
    });
    this._isMounted && this.setState({home_tabs:home_tabs});
    var totalCount = data.news.numFound;//borderBottom:"0.3125vw solid #ccc" style={{padding:"3.125vw"}} 
    var adsCount =9;
    let news= data.news.docs.map((doc,index)=>{
      if (this.state.start ===0 && index> 2 && index % 3 === 0) {
        ++adsCount;
        console.log(adsCount)
        return (
          <div key={"boxoffice" + doc.id + Math.random()}>
            <div id={"ATD_BH_300x250_P"+adsCount}></div> 
            <div className="box_container_data" onClick={this.handleNewsClick.bind(this,doc.id)} key={doc.id} style={{marginTop:"7.125vw",width:"95vw"}}>
                <BaseImageComponent expectedRatio={"354x199"} image_thumb={this.getImage(doc.image_thumb)} customStyle={thumb_imagesize}/>
                <div style={{padding:"3.125vw"}} >
                <p className="container_date">{this.getCurrentDate(doc.insertdate)}</p>
                <p className="container_title" >{this.getTitle(doc)}</p>
                </div>

                </div>
          </div>
        );

      } else {
            return (
                <div className="box_container_data" onClick={this.handleNewsClick.bind(this,doc.id)} key={doc.id} style={{marginTop:"7.125vw",width:"95vw"}}>
                <BaseImageComponent expectedRatio={"354x199"} image_thumb={this.getImage(doc.image_thumb)} customStyle={thumb_imagesize}/>
                <div style={{padding:"3.125vw"}} >
                <p className="container_date">{this.getCurrentDate(doc.insertdate)}</p>
                <p className="container_title" >{this.getTitle(doc)}</p>
                </div>

                </div>
            )
      }
        })
        this._isMounted && this.setState({
            loading: false,
            start:(this.state.start +this.state.limit),
            hasMore: (this.state.news.length < totalCount),
            isLoading: false,
            news: [
                ...this.state.news,
                ...news,
            ],
        });
}
    render()  {    
        const {
            loading,
            error,
            hasMore,
            isLoading,
            news,
          } = this.state;   
        let data;
            if(loading) {
            data =<div className="loader"></div>
            }
            else{      
                data =
                <div>
                <div className="home_tabs">
                {this.state.home_tabs}
                </div>
                <div className="api_content" key={news.results} style={{paddingTop:"10.75vw",paddingBottom:"15.625vw",paddingRight:"2vw",paddingLeft:"2vw",position:"relative",zIndex:"0",backgroundColor:whiteColor}}>
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={this.state.start}
                  loadMore={!isLoading ? this.loadNews : () => {}}
                  hasMore={hasMore && !isLoading}
                  loader={<div className="loader"style={{textAlign:"center",padding:"3.125vw",fontWeight:"700"}}  key={0}>Loading ...</div>}
                  useWindow={true}
                  >
                      {news}
                  </InfiniteScroll>
                </div>
            </div>
        } return(
            <div>
               <div >
                {data}
                </div>
                {error &&
                <div style={{ color: maroornErrorColor,textAlign:"center",padding:"3.125vw"  }}>
                    {}
                </div>
                }
                {isLoading &&
                <div style={{textAlign:"center",padding:"3.125vw"}}>Loading...</div>
                }
                {!hasMore &&
                <div style={{textAlign:"center",padding:"3.125vw"}}></div>
                }
            </div>
            );
    };
}
export default BoxOfiiceAPIParser