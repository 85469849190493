import React, {
    Component
} from "react";
import  Moment from "moment";
import BaseImageComponent from '../../../basecomponents/baseImageComponent.js';
import '../../boxoffice_section/update/js/boxOfficeApiParse.css';

class DetailFilmographyAPIParser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            celeb_name:props.celeb_name,
            celeb_id:props.celeb_id,
            news : [],
            bottomTabSelected:props.tab_selected,
            url:props.url,
            loading:true
        };
        // console.log("url : "+this.state.url);
    }

      getCurrentDate(date){
        //   console.log("moment"+Moment(date,'YYYYMMDDhms').format("MMM DD, YYYY - h:mm a")+" IST");
          var formatedDate =Moment(date,'YYYYMMDDhms').format("MMM DD, YYYY - h:mm a")+" IST";
          return formatedDate;
      }
      getTitle(doc){
        if(doc!=null){
            return doc.post_title;
        }
    }
    handleNewsClick =(id)=>{
        window.location='/postdetail?id='+id+"&tab=celebs";
      };
      getImage(image_thumb) {
        var customimage = image_thumb;
        if(customimage === undefined || customimage === null){
          return customimage;
        }else{
          customimage = image_thumb.toString().replace("322x322","354x199");
          return customimage;
        }
    }
      componentDidMount(){
        var thumb_imagesize ={
          width:"100%",
          height:"53.44vw",
          borderTopLeftRadius:"2vw",
          borderTopRightRadius:"2vw",
            objectFit: "contain",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#efefef",
          }
          var finalurl = this.state.url+"/"+this.state.celeb_name+"/"+this.state.celeb_id;
        //   console.log("finalurl : "+finalurl);
                fetch(finalurl)
                .then(results=>{
                    return results.json();
                }).then(data =>{
                    this.setState({loading:false});
                    let news= data.movie_filmography.filmography.docs.map((doc,index)=>{
                                return (
                                    <div className="detail_article_container_data" style={{marginTop:"3.125vw",marginLeft:"2.8vw",width:"95vw"}} key={index} onClick={this.handleNewsClick.bind(this,doc.id)}>
                                  <BaseImageComponent expectedRatio={"354x199"} image_thumb={this.getImage(doc.image_thumb)} customStyle={thumb_imagesize}/>
                                  <p className="container_title" style={{textAlign:"left",height:"4.25vw",marginLeft:"4.125vw",marginTop:"3.125vw"}}  >{this.getTitle(doc)}</p>
                                    <p className="container_date" style={{textAlign:"left",marginLeft:"4.125vw",marginTop:"0.125vw",paddingBottom:"3.625vw"}} >{this.getCurrentDate(doc.insertdate)}</p>
                                    </div>
                                )
                            })
                            this.setState({news:news});
                });
    };
    render()  {       
        let data;
        const { loading } = this.state;
            if(loading) {
            data =<div className="subloader"></div>
            }
            else{      
                data =
            <div key={this.state.news.results}>
            {this.state.news}
            </div>
        }
        return(
            <div>
                {data}
            </div>
            );
       
       
    };
}
export default DetailFilmographyAPIParser