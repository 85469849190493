import React, { Component } from "react";
import {
  GalleryAllMoviePhotos,
  MovieDetails,
  GalleryMoviePhotos,
  GalleryMoviePhotosBasedOnTitle
} from "../../../../utils/apiUrls.js";
import "./galleryPhoto.css";
import GalleryPhotoView from "./galleryPhotoView.js";
import Carousel from "../../gallery/js/carousel.js";
import Popup from "../../../../utils/customPopup.js";

class GalleryPhotoApiParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details_title: "",
      postDetail: [],
      section_id: "",
      firstImage: require("../../../../imagesvg/logBg.png"),
      IsFavouriteAddedOrRemoved: "add",
      backpressTab: "",
      backpressData: "",
      showPopup: false,
      popUpText: ""
    };
  }

  getCategoryName() {
    // console.log(this.state.section_id ==="6");
    switch (this.state.section_id) {
      case "6":
        return "Movie Stills";
      case "2":
        return "First Look";
      case "7":
        return "On The Sets";
      case "12":
        return "Parties And Events";
      case "8":
        return "Celeb Photos";
      case "3":
        return "Celeb Wallpapers";
      case "4":
        return "Movie Wallpapers";
      default:
        return "null";
    }
  }

  componentDidMount() {
    const params = new URL(window.location.href).searchParams;
    const id = params.get("id");

    const section_id = params.get("section");
    const detailsId = params.get("detailsId");
    const details_title = params.get("details_title");
    const tab = params.get("tab");
    var backpressPhotosData;
    this.setState({
      section_id: section_id,
      photos_id: id,
      detailsId: detailsId,
      backpressTab: tab
    });
    console.log(section_id + "<= " + this.state.section_id);
    if (
      section_id !== "undefined" &&
      section_id !== null &&
      detailsId !== null &&
      detailsId !== "undefined"
    ) {
      backpressPhotosData =
        id +
        "&section=" +
        section_id +
        "&detailsId=" +
        detailsId +
        "&parentId=undefiend";
      const finalUrl =
        GalleryAllMoviePhotos + "/" + section_id + "/" + detailsId + "/0/20";

      console.log(finalUrl);

      fetch(finalUrl)
        .then(results => {
          return results.json();
        })
        .then(data => {
          // console.log(data);
          this.setState({
            loading: false,
            firstImage: data.photos.docs[0].image_thumb[0],
            section_id: section_id
          });
          let postDetail = data.photos.docs.map(doc => {
            return (
              <GalleryPhotoView
                key={doc.id}
                data={doc}
                sectionId={section_id}
                detailsId={detailsId}
                popupClick={this.togglePopup.bind(this)}
              />
            );
          });
          this.setState({
            postDetail: postDetail,
            backpressData: backpressPhotosData
          });
        });
    } else if (section_id === "8" && details_title !== "undefined") {
      backpressPhotosData =
        id + "&section=" + section_id + "&details_title=" + details_title;

      const finalUrl =
        GalleryMoviePhotosBasedOnTitle +
        "/" +
        section_id +
        "/" +
        details_title +
        "/0/20";
      // console.log(finalUrl)
      fetch(finalUrl)
        .then(results => {
          return results.json();
        })
        .then(photos => {
          // console.log(photos.doclist.docs)
          var data = photos.doclist;
          // const totalCount = data.numFound;
          this.setState({
            loading: false,
            firstImage: data.docs[0].image_thumb[0],
            section_id: section_id
          });
          let postDetail = data.docs.map(doc => {
            // console.log(doc)
            return (
              <GalleryPhotoView
                key={doc.id}
                data={doc}
                sectionId={section_id}
                detailsId={undefined}
                parentId={undefined}
                popupClick={this.togglePopup.bind(this)}
              />
            );
          });
          this.setState({
            postDetail: postDetail,
            backpressData: backpressPhotosData
          });
        });
    } else {
      backpressPhotosData =
        id + "&section=" + section_id + "&detailsId=undefined&parentId=" + id;
      const finalUrl = MovieDetails + "/" + id;
      fetch(finalUrl)
        .then(results => {
          return results.json();
        })
        .then(data => {
          console.log(data);
          var post = data.movies_detail.docs.map(doc => {
            var categoryID = doc.category_id;
            var parentID = doc.details_pid;

            if (doc.details_pid === undefined) {
              parentID = doc.parent_id;
              var galleryUrl = GalleryMoviePhotos + "/" + parentID + "/0/20";
              fetch(galleryUrl)
                .then(results => {
                  return results.json();
                })
                .then(data => {
                  console.log(data);
                  this.setState({
                    loading: false,
                    firstImage: data.docs[0].image_thumb[0],
                    section_id: section_id
                  });
                  let postDetail = data.docs.map(doc => {
                    return (
                      <GalleryPhotoView
                        key={doc.id}
                        data={doc}
                        sectionId={categoryID}
                        detailsId={undefined}
                        parentId={parentID}
                        popupClick={this.togglePopup.bind(this)}
                      />
                    );
                  });
                  this.setState({
                    postDetail: postDetail,
                    backpressData: backpressPhotosData
                  });
                });
            } else {
              var galleryUrlSub =
                GalleryAllMoviePhotos +
                "/" +
                categoryID +
                "/" +
                parentID +
                "/0/20";
              fetch(galleryUrlSub)
                .then(results => {
                  return results.json();
                })
                .then(data => {
                  console.log(data);
                  this.setState({
                    loading: false,
                    firstImage: data.photos.docs[0].image_thumb[0],
                    section_id: section_id
                  });
                  let postDetail = data.photos.docs.map(doc => {
                    return (
                      <GalleryPhotoView
                        key={doc.id}
                        data={doc}
                        sectionId={categoryID}
                        detailsId={parentID}
                        popupClick={this.togglePopup.bind(this)}
                      />
                    );
                  });
                  this.setState({ postDetail: postDetail });
                });
            }

            return "";
          });
          console.log(post);
        });
    }
  }
  handleBackpress(Events) {
    if (this.state.backpressTab === "galleryallphotos") {
      window.location = "/galleryallphotos?id=" + this.state.backpressData;
    } else {
      window.location = "/";
    }
  }
  togglePopup(text) {
    console.log(this.state.showPopup);
    this.setState({
      showPopup: !this.state.showPopup,
      popUpText: text
    });
  }
  render() {
    let data;
    // var
    // month_tag_bg=require('../../../../imagesvg/logBg.png');"url("+month_tag_bg+")"
    const { loading, firstImage, section_id } = this.state;
    // console.log(section_id);
    if (loading) {
      data = <div className="loader" />;
    } else {
      console.log(section_id);
      // id={value}
      // key={value},height:window.innerHeight+"px"backgroundImage:"url("+firstImage+")",WebkitMaskImage:"linear-gradient(0deg,
      // #000000 0%, rgba(255, 255, 255, 0) 100%)"
      //            WebkitMaskImage:"linear-gradient(-180deg, rgba(0,0,0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%)",
      data = (
        <div
          style={{
            background: "rgba(0,0,0,10)",
            backgroundImage: "url(" + firstImage + ")",
            height: window.innerHeight + "px",
            backgroundSize: "100% 100%"
          }}
        >
          <div className="gallery_photo_container_data">
            <div className="photo_action_top_icons_div">
              <img
                src={require("../../../../imagesvg/left_red_arrow.svg")}
                className="action_icons_back"
                alt="back_arrow"
                onClick={this.handleBackpress.bind(this)}
                style={{
                  top: "2vw",
                  left: "0vw",
                  position: "absolute"
                }}
              />
              <p
                className="gallery_detail_section_photos"
                style={{ marginLeft: "16vw" }}
              >
                {this.getCategoryName()}
              </p>
            </div>
            <div style={{backgroundImage:"linear-gradient(180deg, rgba(0,0,0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%)"}}>
              <Carousel title="Carousel">{this.state.postDetail}</Carousel>
            </div>
          </div>
{this.state.showPopup ? (
  <Popup
    text={this.state.popUpText}
    closePopup={this.togglePopup.bind(this)}
  />
) : null}
        </div>
      );
    }
    return <div>{data}</div>;
  }
}
export default GalleryPhotoApiParser;
