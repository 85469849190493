// import firebase from "firebase/app";
// import HA from '../src/components/analytics_events/libs/ha.min.js'
// import { askForPermissioToReceiveNotifications, IS_NOTITICATION_PERMITTED, USER_FCM_NOTIFICATION_TOKEN } from './components/notification/pushNotification.js';
// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA
var CACHE_NAME = "bh_pwa_v1.0";
//
var URLS_TO_CACHE = [
  "/",
  "/movies",
  "/video",
  "/boxoffice",
  "/celebs",
  "/gallerysection",
  "/gallerysectiondetail",
  "/health.html",
  "/terms.html",
  "/celebdetail",
  "/postdetail",
  "/galleryphoto",
  "/galleryallphotos",
  "/gallerysectiondetailwithurl",
  "/moviereviewdetail",
  "/musiclyrics",
  "/startcollectiondetail",
  "/celebmorephotos",
  "/login",
  "/signin",
  "/forgotpassword",
  "/signup",
  "/mobilenumber",
  "/mobileotp",
  "/search",
  "/searchresults",
  "/eventvideodetail",
  "/profile",
  "/updateprofile"
];

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// function urlBase64ToUint8Array(base64String) {
//   const padding = '='.repeat((4 - base64String.length % 4) % 4);
//   const base64 = (base64String + padding)
//     .replace(/-/g, '+')
//     .replace(/_/g, '/');

//   const rawData = window.atob(base64);
//   const outputArray = new Uint8Array(rawData.length);

//   for (let i = 0; i < rawData.length; ++i) {
//     outputArray[i] = rawData.charCodeAt(i);
//   }
//   return outputArray;
// }

export function register(config) {
  // initializeFirebase();
  console.log("ENV : " + process.env.NODE_ENV + " navigator : " + navigator);
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    console.log("publicUrl " + publicUrl);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener("load", () => {
      console.log("load", process.env.PUBLIC_URL);
      console.log("isLocalhost", isLocalhost);
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(registration => {
          console.log(registration);
          // console.log(firebase.messaging());
          console.log(
            "This web app is being served cache-first by a service " +
              "worker. To learn more, visit https://bit.ly/CRA-PWA"
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
    window.oncontextmenu = function(event) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };
  }
}

function registerValidSW(swUrl, config) {
  console.log("registerValidSW : " + swUrl);
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      console.log(registration);
      // if (firebase.messaging.isSupported()) {
        // console.log(firebase.messaging());
        // firebase.messaging().useServiceWorker(registration);
        // localStorage.setItem(
        //   "ServiceWorker_Registered",
        //   JSON.stringify(registration)
        // );
        console.log("inside being served cache-first : " + registration);
        // var Ispermitted = localStorage.getItem(IS_NOTITICATION_PERMITTED);
        // var userToken = localStorage.getItem(USER_FCM_NOTIFICATION_TOKEN);
        // console.log(Ispermitted + " " + userToken);
        // console.log((Ispermitted === null || Ispermitted === false) && (userToken === null));
        // if ((Ispermitted === null || Ispermitted === false) && (userToken === null)) {
        //   var response = askForPermissioToReceiveNotifications();
        //   console.log(response);
        //   console.log(response === undefined);
        // }
        // const subscribeOptions = {
        //   userVisibleOnly: true,
        //   applicationServerKey:
        //     urlBase64ToUint8Array('BPmtNU1RlBhV9A4GlyZDBDLuZvU7ES5Dg9pnnLtV--JNvuugp9Jitgl1b6YyuXJELGsS8rqlMcI-aT_gefASid0')

        // };

        registration.onupdatefound = () => {
          console.log("onupdatefound");
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === "installed") {
              console.log("installed");
              if (navigator.serviceWorker.controller) {
                console.log("navigator.serviceWorker.controller");
                // At this point, the updated precached content has been fetched,
                // but the previous service worker will still serve the older
                // content until all client tabs are closed.
                console.log(
                  "New content is available and will be used when all " +
                    "tabs for this page are closed. See https://bit.ly/CRA-PWA."
                );
                //update not woking as required
                // displayUpdateNotification(); 
                
                // Execute callback
                if (config && config.onUpdate) {
                  config.onUpdate(registration);
                  console.log("onUpdate");
                }
              } else {
                caches.open(CACHE_NAME).then(cache => {
                  // console.log(cache);
                  return cache.addAll(URLS_TO_CACHE);
                });
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // "Content is cached for offline use." message.
                console.log("Content is cached for offline use.");

                // Execute callback
                if (config && config.onSuccess) {
                  console.log(
                    "config.onSuccess :" + JSON.stringify(registration)
                  );
                  config.onSuccess(registration);
                }
              }
            }
          };
        };
      // }
    })
    .catch(error => {
      console.error("Error during service worker registration:", error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  console.log("checkValidServiceWorker");
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type");
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    });
}

// function displayUpdateNotification() {
//   const link = document.createElement("a");
//   console.log(link);
//   link.classList.add("update-notification");
//   link.setAttribute("href", "#");
//   link.innerHTML = "Update is available. Click here to install.";

//   link.addEventListener("click", e => {
//     e.preventDefault();
//     console.log("inside click reload");
//     window.location.reload();
//   });

//   document.querySelector("body").appendChild(link);
// }
export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
