import React, {
    Component
} from "react";
import  Moment from "moment";
import BaseImageComponent from '../../../../basecomponents/baseImageComponent.js';
import '../../../boxoffice_section/update/js/boxOfficeApiParse.css';

class DetailPhotosAPIParser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tag:props.tag,
            type:props.type,
            details : [],
            bottomTabSelected:props.tab_selected,
            url:props.url,
            loading: true,
        };
        // console.log("url : "+this.state.url);
        //TODO:https://developers.google.com/web/tools/lighthouse/audits/no-js
          // "start_url": ".",
    }
      getCurrentDate(date){
        //   console.log("moment"+Moment(date,'YYYYMMDDhms').format("MMM DD, YYYY - h:mm a")+" IST");
          var formatedDate =Moment(date,'YYYYMMDDhms').format("MMM DD, YYYY - h:mm a")+" IST";
          return formatedDate;
      }
      getTitle(doc){
        if(doc!=null){
            return doc.post_title;
        }
    }

    handlePlayContainerClick(data,event){
        window.location="/eventvideodetail?id="+data.id+"&tab="+this.state.bottomTabSelected;
        }

        getImage(image_thumb) {
            var customimage = image_thumb;
            if(customimage === undefined || customimage === null){
              return customimage;
            }else{
              customimage = image_thumb.toString().replace("322x322","354x199");
              return customimage;
            }
        }
      componentDidMount(){
          var finalurl = this.state.url+"/"+this.state.tag+"/"+this.state.type;
          var thumb_imagesize ={
            width:"100%",
            height:"53.44vw",
            borderTopLeftRadius:"2vw",
            borderTopRightRadius:"2vw",
            objectFit: "contain",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#efefef",
          }
                fetch(finalurl)
                .then(results=>{
                    return results.json();
                }).then(data =>{
                    this.setState({loading:false});
                    let videos= data.articles.docs.map((doc)=>{
                        return (
                                <div className="detail_article_container_data"  style={{marginTop:"3.125vw",marginLeft:"2.8vw",width:"95vw"}}key={doc.id} onClick={this.handlePlayContainerClick.bind(this,doc)}>  
                                <BaseImageComponent  expectedRatio={"354x199"}  image_thumb={this.getImage(doc.image_thumb)} customStyle={thumb_imagesize}/>
                                <img className="detail_video_play_icon" src={require('../../../../imagesvg/play_copy.svg')} style={{textAlign:"left",left:"3.625vw",position:"relative",bottom:"0",width:"10vw"}}alt="play"/>
                                <p className="container_title" style={{textAlign:"left",marginLeft:"3.625vw",paddingBottom:"4vw",lineHeight:"1.3em",height: "8.75vw",marginTop:"-8vw"}}>{doc.post_title}</p>
                                </div>
                        )
                    });
                        this.setState({details:videos});
                });
    };
    render()  {
  
        let data;
        const { loading } = this.state;
        // console.log(" render loading"+loading);
        // console.log(" render data"+JSON.stringify(this.state.type));
            if(loading) {
            data =<div className="subloader"></div>
            }
            else{     
                data =
            <div key={this.state.details.results}>
            {this.state.details}
            </div>
        }
        return(
            
            <div>
                {data}
            </div>
            );
       
    };
}
export default DetailPhotosAPIParser