const firebaseConfig = {
    apiKey: "AIzaSyCXv042g343Kcvd5yDMlO6ZWSY-KcgadcY",
    authDomain: "bollywoodhungamapwa.firebaseapp.com",
    databaseURL: "https://bollywoodhungamapwa.firebaseio.com",
    projectId: "bollywoodhungamapwa",
    storageBucket: "bollywoodhungamapwa.appspot.com",
    messagingSenderId: "662059264115",
    appId: "1:662059264115:web:210878b1feb1454c262206",
    measurementId: "G-ZHWFRH3M1E"
  };
export default firebaseConfig;