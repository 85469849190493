import React from 'react';
import Grid from '@material-ui/core/Grid';
import BaseImageComponent from '../../../../basecomponents/baseImageComponent.js';
require('./similarContent.css');

class SimilarContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          similarUrl:props.similarUrl,
          recomended_grid:[],
          content_type:props.content_type,
          video_icon_visibility:"none",
          titleStyle:"2vw"
        };
    }
handlePlayContainerClick(data,event){
  console.log(this.state.content_type)
if(this.state.content_type ==="video"){
window.location="/eventvideodetail?id="+data.id+"&tab="+this.state.content_type;
}else if(this.state.content_type === "photos"){
    var b = new Buffer(data.image_thumb);
        var datafinal = b.toString('base64');
        // window.location='/galleryphoto?id='+id+"&section=" + this.state.section_id +"&detailsId="+this.state.detailsId;
        window.location = '/gallerysectiondetailwithurl?id=' + datafinal;
}
else{
  //TODO: can be news or video
  window.location='/postdetail?id='+data.id+"&tab="+this.state.content_type;
}
}
getImage(image_thumb) {
  var customimage = image_thumb;
  if(customimage === undefined || customimage === null){
    return customimage;
  }else{
    customimage = image_thumb.toString().replace("322x322","165x165");
    return customimage;
  }
}
    componentDidMount(){
      if(this.state.content_type === "video"){
        this.setState({video_icon_visibility:"block",titleStyle:"-8vw"})
      }else{
        this.setState({video_icon_visibility:"none",titleStyle:"2vw"})
      }
      var similar_thumb_imagesize ={
        width:"44vw",
        height:"44vw",
        borderRadius: "2vw",
        backgroundColor: "#efefef",
        objectFit:"contain",
        // backgroundRepeat: "no-repeat",
      }

     fetch(this.state.similarUrl)
          .then(results => {
              return results.json();
          }).then(data => {
            let recomended_grid= <div></div>;
            if(data!==null && data.post_data!==null && data.post_data!==undefined &&data.post_data.similar_content!==null &&data.post_data.similar_content!==undefined&& data.post_data.similar_content.length >0){
               recomended_grid = data.post_data.similar_content.map((detail,index)=>{
                return (
                    <Grid key={index} item> {
                        <div className="similar_container_data" key={detail.id} onClick={this.handlePlayContainerClick.bind(this,detail)}>
                        <BaseImageComponent expectedRatio={"165x165"}  image_thumb={this.getImage(detail.image_thumb)} customStyle={similar_thumb_imagesize}/>
                        <div>
                        <img className="similar_event_small_play_icon" style={{display:this.state.video_icon_visibility}} src={require('../../../../imagesvg/play_copy.svg')} alt="play"/>
                        <p className="similar_title" style={{marginTop:this.state.titleStyle}}>{detail.post_title}</p>
                        </div>

                        </div>  
                    }</Grid>
                    )                         
                  });
                }
          this.setState({recomended_grid:recomended_grid})
        });
}
  render() {
    return (
      <div>
      <Grid container className="similar_grid" justify="center"spacing={8}>
      {this.state.recomended_grid}
      </Grid>
      </div>
    );
  }
}

export default SimilarContent;