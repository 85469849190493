import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { IndexDB } from "../../../../utils/apiUrls.js";
import "./movieTopCollectionParser.css";
import StarRatingComponent from "react-star-rating-component";
import BaseImageComponent from "../../../../basecomponents/baseImageComponent.js";
import {
  MovieEvent,
  BoxOfficeEvent
} from "../../../../analytics_events/js/CustomEvents.js";

class MovieTopCollectionApiParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      section_name: "",
      movies: [],
      customstyle: props.customstyle,
      tab_selected: props.tab_selected,
      url: props.url,
      customMargin: "3vw"
    };
    console.log("tab_selected " + this.state.tab_selected);
  }
  handleMovieCollection(doc) {
    window.location =
      "/moviereviewdetail?id=" + doc.id + "&tab=" + this.state.tab_selected;
  }
  getImage(image_thumb) {
    var customimage = image_thumb;
    if (customimage === undefined || customimage === null) {
      return customimage;
    } else {
      customimage = image_thumb.toString().replace("322x322", "165x212");
      return customimage;
    }
  }
  componentDidMount() {
    const history = createBrowserHistory();

    // Get the current location.
    const location = history.location;
    console.log(location);
    const unlisten = history.listen((location, action) => {
      console.log(action, location.pathname, location.state);
    });

    if (this.state.tab_selected === "boxoffice") {
      BoxOfficeEvent("Boxoffice_Tab_Top_Grosser", "Box Office Tab");
      history.push({ pathname: "/boxoffice" });
      window.onpopstate = () => {
        window.location = "/boxoffice";
      };
    } else {
      MovieEvent("Movies_Tab_Top_Movies", "Movie Tab");
      history.push({ pathname: "/movies" });
      window.onpopstate = () => {
        window.location = "/movies";
      };
    }
    unlisten();
    var currentTimeStamp = new Date().getTime();
    IndexDB.movie_top_grosser_api
      .get(this.state.url)
      .then(data => {
        if (data === undefined) {
          fetch(this.state.url)
            .then(results => {
              return results.json();
            })
            .then(data => {
              this.createUIComponent(data);
              IndexDB.movie_top_grosser_api.put({
                ID: this.state.url,
                data: data,
                timestamp: currentTimeStamp
              });
            });
        } else {
          var difference = (currentTimeStamp - data.timestamp) / 60000;
          console.log(difference);
          if (difference >= 30) {
            fetch(this.state.url)
              .then(results => {
                return results.json();
              })
              .then(async data => {
                var updated = await IndexDB.movie_top_grosser_api.update(
                  this.state.url,
                  {
                    ID: this.state.url,
                    data: data,
                    timestamp: currentTimeStamp
                  }
                );
                console.log(updated);
                if (updated && this !== undefined && this !== null) {
                  this.createUIComponent(data);
                }
              });
          } else {
            this.createUIComponent(data.data);
          }
        }
      })
      .catch(function(e) {
        console.log("Error: " + (e.stack || e));
      });
  }
  createUIComponent = data => {
    var count = 0;
    var thumb_imagesize = {
      width: "26vw",
      height: "33.43",
      borderRadius: "2vw",
      backgroundColor: "#000000",
      objectFit: "contain",
      backgroundRepeat: "no-repeat",
      marginLeft: "3.125vw",
      float: "left",
      marginTop: "-3.125vw",
      marginBottom: "-3.125vw"
    };
    var _customMargin;
    if (this.state.tab_selected === "boxoffice") {
      _customMargin = "3vw";
    } else {
      _customMargin = "6vw";
    }
    this.setState({ loading: false, customMargin: _customMargin });
    this.setState({ section_name: data.bucket_title });
    let movies = data.movies.docs.map((doc, index) => {
      count = count + 1;
      return (
        <div key={index}>
          <div
            className="top_container_data"
            onClick={this.handleMovieCollection.bind(this, doc)}
          >
            <BaseImageComponent
              expectedRatio={"165x212"}
              image_thumb={this.getImage(doc.image_thumb)}
              customStyle={thumb_imagesize}
            />
            <div className="top_grosser_sub_container">
              <p className="top_grosser_title">{doc.post_title}</p>
              <StarRatingComponent
                className="top_grosser_ratingbar"
                name="rating"
                starCount={5}
                value={doc.average_d}
                starColor="#F8BE1C"
                emptyStarColor="#8D8D8D"
              />
            </div>
            <div className="count">{count}</div>
          </div>
        </div>
      );
    });
    this.setState({ movies: movies });
  };
  render() {
    let data;
    const { loading } = this.state;
    if (loading) {
      data = <div className="loader"></div>;
    } else {
      data = (
        <div className="header">
          <div
            className="section_title"
            style={{
              marginTop: this.state.customstyle,
              marginLeft: this.state.customMargin
            }}
          >
            <span className="section_name">{this.state.section_name}</span>
          </div>
          <div key={this.state.movies.id} style={{ margin: "3.125vw" }}>
            {this.state.movies}
          </div>
        </div>
      );
    }
    return <div style={{ width: "100%", paddingTop: "2vw" }}>{data}</div>;
  }
}
export default MovieTopCollectionApiParser;
