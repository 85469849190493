import React from "react";

import Grid from "@material-ui/core/Grid";
import "../topvideo/js/videoAllApiParser.css";
import { createBrowserHistory } from "history";
import { IndexDB, LatestCelebInterviews } from "../../../utils/apiUrls.js";
import BaseImageComponent from "../../../basecomponents/baseImageComponent.js";
import { VideoEvent } from "../../../analytics_events/js/CustomEvents";

class VideosCelebInterviewApiParser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      top_section_name: "",
      latest_section_name: "",
      top_interview: [],
      latest_interview: [],
      bottomTabSelected: props.tab_selected,
      url: props.url,
      start: 10,
      limit: 10,
      error: false,
      hasMore: true,
      isLoading: false
    };
    // Binds our scroll event handler
    window.onscroll = () => {
      const {
        loadCelebInterviews,
        state: { error, isLoading, hasMore }
      } = this;

      // Bails early if:
      // * there's an error
      // * it's already loading
      // * there's nothing left to load
      if (error || isLoading || !hasMore) return;
      // Checks that the page has scrolled to the bottom
      // console.log("loadCelebInterviews window.innerHeight"+window.innerHeight);
      // console.log("loadCelebInterviews document.documentElement.scrollTop"+document.documentElement.scrollTop);
      // console.log("loadCelebInterviews document.documentElement.offsetHeight"+document.documentElement.offsetHeight);
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 200
      ) {
        setTimeout(loadCelebInterviews(), 500);
      }
    };
  }
  getTitle(doc) {
    if (doc != null) {
      return doc.post_title;
    }
  }
  handlePlayContainerClick(data, event) {
    window.location =
      "/eventvideodetail?id=" +
      data.id +
      "&tab=" +
      this.state.bottomTabSelected;
  }
  loadCelebInterviews = () => {
    this.setState({ isLoading: true }, () => {
      var finalUrl =
        LatestCelebInterviews + "/" + this.state.start + "/" + this.state.limit;
      var currentTimeStamp = new Date().getTime();
      if (this.state.start === 10) {
        IndexDB.video_more_celeb_interview_api
          .get(finalUrl)
          .then(data => {
            if (data === undefined) {
              fetch(finalUrl)
                .then(results => {
                  return results.json();
                })
                .then(data => {
                  this.getMoreVideos(data);
                  IndexDB.video_more_celeb_interview_api.put({
                    ID: finalUrl,
                    data: data,
                    timestamp: currentTimeStamp
                  });
                })
                .catch(err => {
                  this.setState({
                    error: err.message,
                    isLoading: false
                  });
                });
            } else {
              var difference = (currentTimeStamp - data.timestamp) / 60000;
              console.log(difference);
              if (difference >= 30) {
                fetch(finalUrl)
                  .then(results => {
                    return results.json();
                  })
                  .then(async data => {
                    // console.log("up "+this);
                    var updated = await IndexDB.video_more_celeb_interview_api.update(
                      finalUrl,
                      { ID: finalUrl, data: data, timestamp: currentTimeStamp }
                    );
                    console.log(updated);
                    // console.log("after"+this);
                    if (updated && this !== undefined && this !== null) {
                      this.getMoreVideos(data);
                    }
                  });
              } else {
                this.getMoreVideos(data.data);
              }
            }
          })
          .catch(function(e) {
            console.log("Error: " + (e.stack || e));
          });
      } else {
        fetch(finalUrl)
          .then(results => {
            return results.json();
          })
          .then(data => {
            this.getMoreVideos(data);
          });
      }
    });
  };
  getMoreVideos = data => {
    var video_small_thumb_image = {
      width: "40vw",
      height: "22.50vw",
      objectFit: "contain",
      backgroundRepeat: "no-repeat",
      borderRadius: "2vw"
    };
    const totalCount = data.data.numFound;
    let latest_interview = data.data.docs.map(doc => {
      return (
        <Grid key={doc.id} item>
          {" "}
          {
            <div
              className="small_video_container_data"
              key={doc.id}
              style={{ paddingRight: "3.125vw" }}
              onClick={this.handlePlayContainerClick.bind(this, doc)}
            >
              <BaseImageComponent
                expectedRatio={"354x199"}
                image_thumb={this.getImage(doc.image_thumb[0])}
                customStyle={video_small_thumb_image}
              />
              <img
                className="event_small_play_icon"
                src={require("../../../imagesvg/play_copy.svg")}
                alt="play"
              />
              <p className="event_video_title">{this.getTitle(doc)}</p>
            </div>
          }
        </Grid>
      );
    });
    this.setState({
      start: this.state.start + this.state.limit,
      hasMore: this.state.latest_interview.length < totalCount,
      isLoading: false,
      latest_interview: [...this.state.latest_interview, ...latest_interview]
    });
  };
  getImage(image_thumb) {
    var customimage = image_thumb;
    if (customimage === undefined || customimage === null) {
      return customimage;
    } else {
      customimage = image_thumb.toString().replace("322x322", "354x199");
      return customimage;
    }
  }
  componentWillMount() {
    const history = createBrowserHistory();

    // Get the current location.
    const location = history.location;
    console.log(location);
    const unlisten = history.listen((location, action) => {
      // location is an object like window.location
      console.log(action, location.pathname, location.state);
    });

    // Use push, replace, and go to navigate around.
    history.push({ pathname: "/video" });
    window.onpopstate = () => {
      window.location = "/video";
    };
    unlisten();
    VideoEvent("Videos_Tab_Celeb_Interview ","Video Tab");
    var currentTimeStamp = new Date().getTime();
    IndexDB.video_celeb_interview_api
      .get(this.state.url)
      .then(data => {
        if (data === undefined) {
          fetch(this.state.url)
            .then(results => {
              return results.json();
            })
            .then(data => {
              this.createUIComponent(data);
              IndexDB.video_celeb_interview_api.put({
                ID: this.state.url,
                data: data,
                timestamp: currentTimeStamp
              });
            });
        } else {
          var difference = (currentTimeStamp - data.timestamp) / 60000;
          console.log(difference);
          if (difference >= 30) {
            fetch(this.state.url)
              .then(results => {
                return results.json();
              })
              .then(async data => {
                var updated = await IndexDB.video_celeb_interview_api.update(
                  this.state.url,
                  {
                    ID: this.state.url,
                    data: data,
                    timestamp: currentTimeStamp
                  }
                );
                console.log(updated);
                if (updated && this !== undefined && this !== null) {
                  this.createUIComponent(data);
                }
              });
          } else {
            this.createUIComponent(data.data);
          }
        }
      })
      .catch(function(e) {
        console.log("Error: " + (e.stack || e));
      });
  }

  createUIComponent = data => {
    var video_large_thumb_image = {
      width: "85vw",
      height: "47.81vw",
      objectFit: "contain",
      backgroundColor: "#efefef",
      backgroundRepeat: "no-repeat",
      borderRadius: "2vw"
    };

    var video_small_thumb_image = {
      width: "42vw",
      height: "23.63vw",
      objectFit: "contain",
      backgroundRepeat: "no-repeat",
      borderRadius: "2vw"
    };
    this.setState({ loading: false });
    data.section_videos.map(section_video => {
      this.setState({ top_section_name: section_video.section_name });
      if (this.state.top_section_name === "Top Celeb Interview") {
        let top_interview = section_video.videos.docs.map(doc => {
          return (
            <div
              className="video_container_data"
              key={doc.id}
              style={{ paddingRight: "3.125vw" }}
              onClick={this.handlePlayContainerClick.bind(this, doc)}
            >
              <BaseImageComponent
                expectedRatio={"354x199"}
                image_thumb={this.getImage(doc.image_thumb[0])}
                customStyle={video_large_thumb_image}
              />
              <img
                className="event_play_icon"
                src={require("../../../imagesvg/play_copy.svg")}
                alt="play"
              />
              <p className="eventCelebLargeTitle">{this.getTitle(doc)}</p>
            </div>
          );
        });
        this.setState({ top_interview: top_interview });
      } else {
        this.setState({
          loading: false,
          latest_section_name: section_video.section_name
        });
        // console.log("latest_section_name"+section_video.section_name);
        let latest_interview = section_video.videos.docs.map(doc => {
          return (
            <Grid key={doc.id} item>
              {" "}
              {
                <div
                  className="small_video_container_data"
                  key={doc.id}
                  style={{ paddingRight: "3.125vw" }}
                  onClick={this.handlePlayContainerClick.bind(this, doc)}
                >
                  <BaseImageComponent
                    expectedRatio={"354x199"}
                    image_thumb={this.getImage(doc.image_thumb[0])}
                    customStyle={video_small_thumb_image}
                  />
                  <img
                    className="event_small_play_icon"
                    src={require("../../../imagesvg/play_copy.svg")}
                    alt="play"
                  />
                  <p className="event_video_title">{this.getTitle(doc)}</p>
                </div>
              }
            </Grid>
          );
        });
        this.setState({ latest_interview: latest_interview });
      }
      return "";
    });
  };
  render() {
    const { loading, latest_interview, error, hasMore, isLoading } = this.state;

    let data;
    if (loading) {
      data = <div className="loader" />;
    } else {
      data = (
        <div
          className="header"
          style={{
            marginLeft: "4.25vw",
            marginTop: "3.625vw",
            marginBottom: "10vw"
          }}
        >
          <div className="section_title">
            <span className="section_name">
              Top Celeb Interview
              {/* <img className="video_section_more_arrow" src={require('../imagesvg/right_arrow_red.svg')} alt="more"/> */}
            </span>
          </div>
          <div key={this.state.top_interview.id}>
            <section
              className="section_card"
              style={{
                display: "flex",
                overflowX: "auto",
                paddingRight: "3.125vw",
                marginBottom: "6.25vw"
              }}
            >
              {this.state.top_interview}
            </section>
          </div>
          {/* <div> */}
          <div id="ATD_BH_300x250_P9" />
          <div className="section_title">
            <span className="section_name">
              {this.state.latest_section_name}
              {/* <img className="video_section_more_arrow" src={require('../imagesvg/right_arrow_red.svg')} alt="more"/> */}
            </span>
          </div>
          <div key={latest_interview.id}>
            <Grid container className="grid" justify="center" spacing={8}>
              {latest_interview}
            </Grid>
            {error && (
              <div
                style={{
                  color: "#900",
                  textAlign: "center",
                  padding: "3.125vw"
                }}
              >
                {error}
              </div>
            )}
            {isLoading && (
              <div
                style={{
                  textAlign: "center",
                  padding: "3.125vw",
                  paddingBottom: "10vw",
                  fontWeight: "700"
                }}
              >
                Loading...
              </div>
            )}
            {!hasMore && (
              <div style={{ textAlign: "center", padding: "3.125vw" }}>
                You did it! You reached the end!
              </div>
            )}
          </div>
        </div>
      );
      // </div>
    }
    return <div>{data}</div>;
  }
}
export default VideosCelebInterviewApiParser;
