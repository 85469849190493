import React from "react";
class HealthCheck extends React.Component {
  render() {
    return (
      <div className="health">
          <head> 
            <title> Hello World</title>
          </head>
          <body>
            <div style={{textAlign:"center",fontSize:"5vw",fontWeight:"400"}}>
            <h2>Hello World</h2>
            <h3>Today is: {new Date().toString()}</h3>
            <h3>BH PWA React Frontend is working fine.</h3>
            </div>
          </body>
      </div>
    )
  }
}

export default HealthCheck;