import React, {
    Component
} from "react";
import  Moment from "moment";
import ReactHtmlParser from 'react-html-parser';
import StarRatingComponent from "react-star-ratings";
require ('./detailMovieReviewsApiParser.css');


class DetailMovieReviewsApiParser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            section_tab:"",
            sections:[],
            review_critics : [],
            review_users : [],
            bottomTabSelected:props.tab_selected,
            url:props.url,
            loading: true,
            content:[],
            active:null,
            critic_rating:props.critic_rating,
            showCriticRating:"none",
        };
    }

    handleReviewSubTabClick(index,event) {
        console.log("handleReviewSubTabClick : "+index);
        this.setState({active : index});
        this.setStyle(index);
        var container_id =event.currentTarget.id;
        // this.setState({subtab_selected:container_id});
        console.log("container : "+event.currentTarget.id);
        if(container_id ==="Critic Movie Review"){
            this.setState({content:this.state.review_critics});
        }else{
            this.setState({content:this.state.review_users});
        }
    }
  
    getDetailCOntent(){
        let listTabs=this.state.sections;
        let tab = listTabs[0];
       console.log("tab insede : "+tab.props.id);
        if(tab.props.id==="Critic Movie Review"){
            let ele = document.getElementById(tab.props.id);
            ele.style.color ="#1870B7";
            ele.style.opacity=1;
            ele.style.borderBottom=" 0.625vw solid #1870B7";
        }
        this.setState({active:0});
    }

      setStyle(index){
        let listTabs=this.state.sections;
     
        let tab = listTabs[index];
        // console.log("detail_Tabs : "+JSON.stringify(listTabs[0].props.id));
        let ele = document.getElementById(tab.props.id);

        listTabs.map((item)=>{
            let ele = document.getElementById(item.props.id);
            ele.style.color ="#000000";
            ele.style.opacity=0.2;
            ele.style.borderBottom="0.3125vw solid #ccc";
            return "";
        });
        if(ele!==undefined){
            ele.style.color ="#1870B7";
            ele.style.opacity=1;
            ele.style.borderBottom="0.625vw solid #1870B7";
        }
      }

    getCurrentDate(date){
        var formatedDate =Moment(date,'YYYYMMDDhms').format("MMM DD, YYYY -h:mm a")+" IST";
        return formatedDate;
        // var nextLine = formatedDate.split("-");
        // console.log(nextLine[0]+"\n"+nextLine[1]);
        // return nextLine[0]+"\n"+nextLine[1];
    }

    displayCriticRating(rating){
        if(rating!==undefined && rating!==null){
            this.setState({showCriticRating:"flex"});
        }else{
            this.setState({showCriticRating:"none"});
        }

    }
      componentDidMount(){
        console.log(this.state.url);
                fetch(this.state.url)
                .then(results=>{
                    return results.json();
                }).then(data =>{
                    this.setState({ loading: false });
                    var sections=data.sub_section_tabs.map((section_tab,index)=>{
                        this.setState({section_tab:section_tab});
                        data.section_reviews.map((section_review)=>{
                        if(section_review.section_name === "Critic Movie Review"){
                            if(section_review.data.docs.length>=1){
                        let review_critics= section_review.data.docs.map((doc)=>{
                            this.displayCriticRating(this.state.critic_rating);
                            console.log("doc.critic_rating_d : "+JSON.stringify(this.state.critic_rating))
                                    return (
                                        <div className="review_container_data" style={{paddingRight:"3.125vw"}} key={doc.id}>
                                        <p className="review_movie_title">{doc.post_title}</p>
                                        <p className="review_by">Reviewed By <br/><span className="review_author_name">{doc.author}</span></p>
                                        {/* <div style={{display:"inline-flex",width:"100%",textAlign:"center"}}> */}
                                        <p className="review_date" style={{textAlign:"left",padding:0,margin:0,marginLeft:"1.5vw",marginTop:"-4vw"}}>{this.getCurrentDate(doc.insertdate)}</p>
                                        <div style={{width:"60vw",margin:0,padding:0,display:"flex",textAlign:"center",marginLeft:"1.5vw",marginBottom:"-3vw"}}>
                                        <p style={{fontSize:"4vw",fontWeight:900,whiteSpace:'nowrap',marginRight:"1.5vw"}}>{`User Rating`}</p>
                                        <div style={{marginTop:"4.1vw"}}>
                                          <StarRatingComponent
                                            rating={this.state.critic_rating}
                                            starRatedColor="rgb(248, 190, 28)"
                                            numberOfStars={5}
                                            name="rating"
                                            isSelectable={false}
                                            starDimension="4vw"
                                            starSpacing="0.5vw"
                                            starEmptyColor="rgb(141, 141, 141)"
                                        />
                                        </div>
                                        
                                        {/* </div> */}
                                        </div>
                                        
                                        <div style={{width:"100%",marginTop:"5vw"}} >
                                        <div className="review_content">{ReactHtmlParser(doc.post_content)}</div>
                                        </div>
                                        </div>
                                    )
                                })
                                this.setState({review_critics:review_critics});
                            }else{
                                let review_critics = (
                                <div>
                                <p>Sorry currently there are no critic reviews available for this movie.</p>
                                </div>
                                )
                                this.setState({review_critics:review_critics});
                            }
                        }else{
                            this.setState({section_tab:section_review.section_name});
                            console.log(section_review.data.docs.length)
                            if(section_review.data.docs.length>=1){
                            let review_users= section_review.data.docs.map((doc)=>{
                                return (
                                    <div className="review_container_data" style={{display:"flex"}} key={doc.id}>
                                        {/* <BaseImageComponent  image_thumb={"undefined"} customStyle={user_thumb_imagesize}/> */}
                                        <div style={{width:"100%",textAlign:"left"}} >
                                        <span className="user_review_author_name">{doc.author}</span>
                                        <div className="user_review_content">{ReactHtmlParser(doc.post_content)}</div>
                                        </div>
                                        </div>
                                )
                            })
                            this.setState({review_users:review_users});
                            }else{
                                let review_users= (
                                    <div>
                                    <p>Sorry currently there are no user reviews available for this movie.</p>
                                    </div>
                                    )  
                                this.setState({review_users:review_users});
                            }
                        }
                        return "";   
                    });
                        
                    console.log("section"+JSON.stringify(this.state.sections));
                    // if(this.state.review_critics!==null){
                        this.setState({content:this.state.review_critics});
                         console.log("section_tab : "+section_tab);   
                        // }
                        return(
                            <button className="tablinks" key={index} id={section_tab}  onClick={this.handleReviewSubTabClick.bind(this,index)}>{section_tab}</button>
                    )       
                    });
                    console.log(sections);
                    //uncomment for user review section
                    // this.setState({sections:sections});
                    // this.getDetailCOntent();
                });    
    };
    render()  {   
        let data;
        const { loading } = this.state;
            if(loading) {
            data =<div className="subloader"></div>
            }
            else{      
                data =
                <div style={{width:"100%", height:"100%"}}>
                    {/* <div style={{width:"100%", height:"fit-content",marginTop:"6vw"}}>
                        <div className="review_tab">
                        {this.state.sections}
                        </div>
                    </div> */}
                    <div className="review_content" style={{marginTop:"-6.125vw"}}>
                    {this.state.content}
                    </div>
                </div>
             }
             return(
                <div>
                    {data}
                </div>
                );
    };
}
export default DetailMovieReviewsApiParser