import React, { Component } from "react";
import StoriesApiParser from "../apiparsercomponents/trending_section/stories/js/storiesApiParser.js";
import { TrendingStories } from "../utils/apiUrls.js";
import "./trendingPage.css";
// import {IS_NOTITICATION_PERMITTED,USER_FCM_NOTIFICATION_TOKEN} from '../notification/pushNotification.js';

class TrendingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: ""
    };
    // console.log(" trending constructor")
  }

  componentDidMount() {
    // console.log(" trending componentDidMount")
    // var Ispermitted = localStorage.getItem(IS_NOTITICATION_PERMITTED);
    // var userToken = localStorage.getItem(USER_FCM_NOTIFICATION_TOKEN);
    // console.log(Ispermitted +" "+userToken);
    const params = new URL(window.location.href).searchParams;
    const page = params.get("page");
    this.setState({ page: page });
    document.addEventListener("trendingbackpressed", () => {
      this.props.history.push("/");
    });
  }

  render() {
    return (
      <div className="main">
        <div className="data" style={{ textAlign: "center" }}>
          <StoriesApiParser data={"trending"} url={TrendingStories} />
        </div>
      </div>
    );
  }
}

export default TrendingPage;
