import React, { Component } from "react";
import MovieReviewApiParser from '../apiparsercomponents/movies_section/reviews/js/movieReviewApiParser.js';
import {LatestMovie} from '../utils/apiUrls.js';
class MoviePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="main">
        <div className="data" style={{ textAlign:"center",backgroundColor:"#ffffff"}}>
          <MovieReviewApiParser tab_selected={"movie"} url={LatestMovie} />
        </div>
      </div>
    );
  }
}

export default MoviePage;
