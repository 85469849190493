import React from 'react';
import BaseImageComponent from '../../../../basecomponents/baseImageComponent.js';

class DirectorComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bg_color:props.bg_color,
            index:props.index,
            thumb_imagesize:props.thumb_imagesize,
            top_director: [],
            handleStarCollectionMoreCicked:props.onClick,
            image_thumb:props.image_thumb,
            title:props.title,
            movie_count:props.movie_count,
            total_collection:props.total_collection
        }
    };
    render() {
        const { index,thumb_imagesize,handleStarCollectionMoreCicked,image_thumb,title,movie_count,total_collection } = this.state;
        return(
            <div key={index}>
                                <div className="box_office_star_container_data" key={index} style={{backgroundColor:this.state.bg_color[index]}} onClick={handleStarCollectionMoreCicked}>
                                <div>
                                    <div className="rank">{parseInt(index)+1}</div>
                                    <BaseImageComponent expectedRatio={"322x322"} image_thumb={image_thumb} customStyle={thumb_imagesize}/>
                                </div>
                                <div className="box_office_star_sub_container">
                                <table style={{border:"none"}}>
                                    <tbody style={{border:"none"}}>
                                        <tr>
                                        <td colSpan="2"><p className="star_title">{title}</p></td>
                                        </tr>
                                        <tr>
                                        <td style={{fontWeight:"normal",color:"#ffffff",borderRight:"0.3125vw solid #ccc",width:"10vw",paddingRight :"2vw"}}><b style={{color:"#ffffff",fontSize:"4.375vw"}} >{movie_count}</b><br/>Mov</td>
                                        <td style={{fontWeight:"normal",color:"#ffffff"}}><b style={{color:"#ffffff",fontSize:"4.375vw"}} >{total_collection}</b><br/>Cumulative (in Cr)</td>
                                        </tr>
                                    </tbody>
                                </table>   
                                </div>
                                <img className="star_more_arrow"  style={{paddingRight:"3.125vw"}} src={require('../../../../imagesvg/right-arrow_white.svg')} alt="more" />
                                </div>
                            </div>
        )
    }
}
    export default DirectorComponent;
