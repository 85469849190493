import React, { Component } from "react";

import Moment from "moment";
import "./movieDetailApiParser.css";
import ReactHtmlParser from "react-html-parser";
import StarRatingComponent from "react-star-ratings";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Toolbar from "../../../../basecomponents/toolbar/toolbar.js";
import { bottomNavigationColor } from "../../../../utils/color.js";
import Popup from "../../../../utils/customPopup.js";
import {
  setUserRating,
  backArrowClick,
  getAuthorizationHeader
} from "../../../../utils/commonClickActions.js";
import {
  CelebArticlesBasedOnTagAndType,
  MovieDetails,
  MovieReviews,
  CelebMusicAndVideos,
  IsUserLoggedIn,
  LoginType,
  LoginResponse,
  WatchNotInterestedMoviePoll,
  UserID,
  WatchPollAuthorizationKey,
  IsUserRatedMovie,
  UserRatedMovieAuthorizationKey
} from "../../../../utils/apiUrls.js";
import DetailArticleApiParser from "../views/detailArticleApiParser.js";
import DetailMovieReviewsApiParser from "../views/detailMovieReviewsApiParser.js";
import DetailCastAndCrewViaApiParser from "../views/detailCastAndCrewViaApiParser.js";
import DetailMusicAPIParser from "../views/detailMusicApiParser.js";
import BaseImageComponent from "../../../../basecomponents/baseImageComponent.js";
import { MovieEvent } from "../../../../analytics_events/js/CustomEvents";

class MovieDetailItemApiParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailData: [],
      post_name: "",
      post_id: "",
      postDetail: [],
      detail_tabs: [],
      detail_content: [],
      active: null,
      avgRating: 0,
      userRating: 0,
      criticRating:0,
      userRatedMovieRating: 0,
      marginTopForPoll: "50%",
      showWatchPollUpcomingMovie: "none",
      backpresstab: "",
      celeb_id: "",
      synopsis_visibility: "none",
      showPopup: false,
      popUpText: ""
    };
  }
  togglePopup(text) {
    console.log(this.state.showPopup);
    this.setState({
      showPopup: !this.state.showPopup,
      popUpText: text
    });
  }
  getDetailTabs() {
    let detailTabs = ["CAST & CREW", "REVIEW", "ARTICLE", "VIDEOS"]; //,"PHOTOS","MUSIC & VIDEOS"
    let tabs = detailTabs.map((tab, index) => {
      return (
        <button
          className="tablinks"
          key={index}
          id={tab}
          onClick={this.handleSubTabClick.bind(this, index)}
        >
          {tab}
        </button>
      );
    });
    this.setState({ detail_tabs: tabs });
  }

  showWatchPollForUpcomingMovie(releaseDate) {
    var currentDate = Moment().format("YYYYMMDD");
    console.log(currentDate);
    if (releaseDate > currentDate) {
      this.setState({
        showWatchPollUpcomingMovie: "block",
        marginTopForPoll: "63%"
      });
    }
  }

  getUserRating = id => {
    console.log(localStorage.getItem(IsUserLoggedIn));
    if (
      localStorage.getItem(IsUserLoggedIn) === "true" &&
      localStorage.getItem(LoginType) !== undefined &&
      localStorage.getItem(LoginType) !== null &&
      localStorage.getItem(LoginResponse) !== null &&
      localStorage.getItem(LoginResponse) !== undefined
    ) {
      var userID = localStorage.getItem(UserID);
      var bodyStr =
        "action=movie_user_rating&movie_id=" + id + "&user_id=" + userID;
      console.log(bodyStr);
      fetch(IsUserRatedMovie, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          Authorization: getAuthorizationHeader(UserRatedMovieAuthorizationKey),
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: bodyStr
      })
        .then(results => {
          console.log(results);
          return results.json();
        })
        .then(data => {
          console.log("user rating response " + JSON.stringify(data));
          console.log(
            "rating : " + data.movie_user_rating + " " + data.success
          );
          if (data.success) {
            console.log("inside success");
            this.setState({ userRatedMovieRating: data.movie_user_rating });
            console.log(
              "userRatedMovieRating : " + this.state.userRatedMovieRating
            );
          }
        });
    }
  };

  getDetailCOntent() {
    let listTabs = this.state.detail_tabs;
    let tab = listTabs[0];
    // if (this.state.backpresstab === "boxoffice") {
      this.setStyle(1);
      var finalUrl = MovieReviews + "/" + this.state.detailData.id;
      var criticanduserreviews = (
        <DetailMovieReviewsApiParser
          tab_selected={"movies"}
          url={finalUrl}
          celeb_name={encodeURIComponent(this.state.post_name)}
          celeb_id={this.state.post_id}
          critic_rating={this.state.avgRating}
          type="news"
        />
      );
      this.setState({ detail_content: criticanduserreviews });
    // } 
    // else {
    //   if (tab.props.id === "CAST & CREW") {
    //     let ele = document.getElementById(tab.props.id);
    //     ele.style.color = "#D0021B";
    //     ele.style.opacity = 1;
    //     ele.style.fontWeight = 900;
    //     ele.style.borderBottom = " 0.625vw solid #D0021B";
    //   }
    //   var castandcrew = (
    //     <DetailCastAndCrewViaApiParser
    //       tab_selected={"movies"}
    //       data={this.state.detailData}
    //       key={0}
    //     />
    //   );
    //   this.setState({ detail_content: castandcrew, active: 0 });
    // }
  }

  setStyle(index) {
    let listTabs = this.state.detail_tabs;
    let tab = listTabs[index];
    let ele = document.getElementById(tab.props.id);
    listTabs.map(item => {
      let ele = document.getElementById(item.props.id);
      ele.style.color = "#000000";
      ele.style.borderBottom = "0.3125vw solid #ccc";
      ele.style.opacity = 0.5;
      ele.style.fontWeight = 900;
      return "";
    });
    if (ele !== undefined) {
      ele.style.color = "#D0021B";
      ele.style.fontWeight = 900;
      ele.style.borderBottom = "0.625vw solid #D0021B";
      ele.style.opacity = 1;
    }
  }
  handleSubTabClick(index, event) {
    this.setState({ active: index });
    this.setStyle(index);

    if (
      event !== null &&
      event.currentTarget !== undefined &&
      event.currentTarget.id !== undefined
    ) {
      let container_id = event.currentTarget.id;
      this.setState({ subtab_selected: container_id });
      if (container_id === "CAST & CREW") {
        var castandcrew = (
          <DetailCastAndCrewViaApiParser
            tab_selected={"movies"}
            data={this.state.detailData}
          />
        );
        this.setState({ detail_content: castandcrew });
      } else if (container_id === "REVIEW") {
        var finalUrl = MovieReviews + "/" + this.state.detailData.id;
        var criticanduserreviews = (
          <DetailMovieReviewsApiParser
            tab_selected={"movies"}
            url={finalUrl}
            celeb_name={encodeURIComponent(this.state.post_name)}
            celeb_id={this.state.post_id}
            critic_rating={this.state.criticRating}
            type="news"
          />
        );
        this.setState({ detail_content: criticanduserreviews });
      } else if (container_id === "ARTICLE") {
        var article = (
          <DetailArticleApiParser
            tab_selected={"movies"}
            url={CelebArticlesBasedOnTagAndType}
            tag={encodeURIComponent(this.state.post_name)}
            type="news"
          />
        );
        this.setState({ detail_content: article });
      } else {
        var music = (
          <DetailMusicAPIParser
            tab_selected={"movies"}
            url={CelebMusicAndVideos}
            tag={encodeURIComponent(this.state.post_name)}
          />
        );
        this.setState({ detail_content: music });
      }
    }
  }
  /***
* 1) user_id
2) post_id
3) action : will_watch / not_watch

*/
  handleWatchStatusClick = (item, post_id, event) => {
    console.log("watch bitton clicked index " + item);
    var selected_item = document.querySelectorAll("#will_watch");
    var rejected_item = document.querySelectorAll("#not_watch");
    // console.log(item === "will_watch")
    if (
      localStorage.getItem(IsUserLoggedIn) === "true" &&
      localStorage.getItem(LoginType) !== undefined &&
      localStorage.getItem(LoginType) !== null &&
      localStorage.getItem(LoginResponse) !== null &&
      localStorage.getItem(LoginResponse) !== undefined
    ) {
      console.log("watch button clicked index inside" + JSON.stringify(item));
      // var userID = "556554";
      var userID = localStorage.getItem(UserID);
      var bodyStr =
        "action=" + item + "&post_id=" + post_id + "&user_id=" + userID;
      console.log(bodyStr);
      fetch(WatchNotInterestedMoviePoll, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          Authorization: getAuthorizationHeader(WatchPollAuthorizationKey),
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: bodyStr
      })
        .then(results => {
          console.log(results);
          return results.json();
        })
        .then(data => {
          //{"success":true,"count":0}
          console.log(item + " response " + JSON.stringify(data));
          if (data.success) {
            this.togglePopup("your vote added to the poll");
            selected_item[0].style.backgroundColor = "rgb(0,128,0)";
            rejected_item[0].style.backgroundColor = "rgb(255,0,0)";
          } else {
            this.togglePopup("Error in adding your vote ! Try again later.");
          }
        });
    } else {
      window.location = "/login?id=0";
    }
  };
  getToolbarTitle() {
    let title_style = {
      color: "#000000",
      fontWeight: 900,
      textDecoration: "none",
      fontSize: "9.375vw",
      marginLeft: "3.125vw",
      whiteSpace: "nowrap"
    };

    let heading = document.querySelectorAll(".toolbar__title");
    heading[0].innerHTML = "Movies";
    heading[0].onclick = this.handleBackArrow.bind(this);
    for (var property in title_style) {
      heading[0].style[property] = title_style[property];
    }
  }
  getCurrentDate(date) {
    var formatedDate = Moment(date, "YYYYMMDD").format("DD MMMM, YYYY");
    return formatedDate;
  }
  handleBackArrow() {
    window.location = "/movies";
  }
  handlePlayTrailer(doc, event) {
    var ListUrls = "";
    var url =
      CelebArticlesBasedOnTagAndType + "/" + doc.post_title + "/trailer";

    if (
      doc.attribute_promo_urls !== undefined &&
      doc.attribute_promo_urls !== null &&
      doc.attribute_promo_urls.length > 0
    ) {
      console.log("doc.attribute_promo_urls : " + doc.attribute_promo_urls);
      var b = new Buffer(doc.attribute_promo_urls[0]);
      var datafinal = b.toString("base64");
      window.location =
        "/eventvideodetail?id=" +
        doc.id +
        "&data=" +
        datafinal +
        "&tab=moviereviewdetail";
    } else {
      fetch(url, {
        mode: "no-cors"
      })
        .then(result => {
          console.log("result : " + JSON.stringify(result));
          if (result !== null && result !== undefined && result > 0) {
            return result.json();
          } else {
            return result;
          }
        })
        .then(data => {
          if (data.length > 0) {
            ListUrls = data.articles.docs.map(doc => {
              return doc.details_video_url;
            });
            if (ListUrls.length > 0) {
              console.log("trailer url movie : " + ListUrls[0]);
              var b = new Buffer(ListUrls[0]);
              var datafinal = b.toString("base64");
              window.location = window.location =
                "/eventvideodetail?id=" +
                doc.id +
                "&data=" +
                datafinal +
                "&tab=moviereviewdetail";
            }
          } else {
            this.togglePopup("Sorry, no trailer available!");
          }
        });
    }
  }

  handleMoreDropdownClick() {
    var arraow = document.querySelectorAll(".synopsis_arrow");
    var ele = document.querySelectorAll(".celeb_content");
    var synopsis = document.querySelectorAll(".synopsis_more_content");
    // console.log(arraow);
    // console.log(ele[0].style["height"]);
    // console.log(synopsis[0].style["display"]);
    // console.log(this.state.synopsis_visibility);

    if (this.state.synopsis_visibility === "none") {
      arraow[0].src = require("../../../../imagesvg/up_arrow.svg");
      ele[0].style["height"] = "150vw";
      synopsis[0].style["display"] = "block";
      this.setState({ synopsis_visibility: "block" });
    } else {
      arraow[0].src = require("../../../../imagesvg/down_arrow.svg");
      ele[0].style["height"] = "105vw";
      synopsis[0].style["display"] = "none";
      this.setState({ synopsis_visibility: "none" });
    }
  }
  onStarClick(id, rating) {
    MovieEvent("Movie_User_Rating", "Movie Tab");
    console.log(rating + " " + id);
    this.setState({ userRating: rating });
    setUserRating(id, rating, "post");
  }
  componentDidMount() {
    this.getToolbarTitle();
    this.getDetailTabs();
    const params = new URL(window.location.href).searchParams;
    const id = params.get("id");
    const backpresstab = params.get("tab");
    const celeb_id = params.get("celeb_id");
    console.log("backpresstab " + backpresstab);
      this.creatUI(id, backpresstab, celeb_id);
  }
  creatUI = async (id, backpresstab, celeb_id) => {
      var userID = localStorage.getItem(UserID);
      var bodyStr =
        "action=movie_user_rating&movie_id=" + id + "&user_id=" + userID;
      console.log(bodyStr);
      await fetch(IsUserRatedMovie, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          Authorization: getAuthorizationHeader(UserRatedMovieAuthorizationKey),
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: bodyStr
      })
        .then(results => {
          console.log(results);
          return results.json();
        })
        .then(data => {
          console.log("user rating response " + JSON.stringify(data));
          console.log(
            "rating : " + data.movie_user_rating + " " + data.success
          );
          if (data.success) {
            console.log("inside success");
            this.setState({ userRatedMovieRating: data.movie_user_rating });
            console.log(
              "userRatedMovieRating : " + this.state.userRatedMovieRating
            );
          }
        });

    const finalUrl = MovieDetails + "/" + id;
    var thumb_imagesize = {
      width: "100vw",
      height: "100vw",
      borderRadius: "0vw",
      margin: 0,
      backgroundColor: "#efefef",
      objectFit: "contain",
      backgroundRepeat: "no-repeat",
      WebkitMaskImage:
        "-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))",
      MaskImage:
        "linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))"
    };
    await  fetch(finalUrl)
      .then(results => {
        return results.json();
      })
      .then(data => {
        console.log(this.state.userRatedMovieRating);
        this.setState({ loading: false });
        let postDetail = data.movies_detail.docs.map((doc, index) => {
          // console.log(JSON.stringify(doc))
          this.showWatchPollForUpcomingMovie(doc.releasedate);
          this.setState({
            post_name: doc.post_title,
            post_id: doc.id,
            detailData: doc,
            criticRating:doc.critic_rating_d,
            avgRating:doc.average_d,
            userRating: doc.average_d,
            backpresstab: backpresstab,
            celeb_id: celeb_id
          });
          this.getDetailCOntent();
          var value = doc.id;
          return (
            <div className="movie_detail_container_data" id={value} key={value}>
              <BaseImageComponent
                image_thumb={doc.image_thumb}
                customStyle={thumb_imagesize}
                customClick={this.handleCardClick}
              />
              <div className="trailer_container">
                <img
                  className="movie_trailer_play_icon"
                  src={require("../../../../imagesvg/play_copy.svg")}
                  style={{
                    zIndex: "1",
                    width: "17.1875vw",
                    marginBottom: "3.125vw"
                  }}
                  onClick={this.handlePlayTrailer.bind(this, doc)}
                  alt="play"
                />
                <p className="movie_release_title">{doc.post_title}</p>
                <p className="movie_release_date">
                  Release Date : {this.getCurrentDate(doc.releasedate)}
                </p>
                {/* <p
                  className="movie_release_date"
                  style={{ marginTop: "6vw", marginBottom: "1vw" }}
                >
                  USERS RATED
                </p> */}
                <div style={{ textAlign: "center", display: "inline-flex" }}>
                  <p
                    style={{
                      fontWeight: "900",
                      fontSize: "5vw",
                      marginTop: "1vw",
                      marginRight: "2vw"
                    }}
                  >
                    {/* {(this.state.avgRating).toFixed(1)} */}
                  </p>
                  <div style={{width:"60vw",padding:"2vw",display:"flex"}}>
                                        <p style={{fontSize:"4vw",fontWeight:900,whiteSpace:'nowrap',marginLeft:"10vw",marginRight:"2vw"}}>{`Critic Rating`}</p>
                  <div style={{height:"14vw",width:"14vw"}}>
                                        <CircularProgressbar value={this.state.criticRating} maxValue={5} text={(this.state.criticRating).toFixed(1)} 
                                        styles={{
                                            root: {},
                                            path: {
                                              // Path color
                                              stroke: `rgba(248, 190, 28, ${this.state.criticRating})`,
                                              strokeWidth:9,
                                              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                              strokeLinecap: 'butt',
                                              // Customize transition animation
                                              transition: 'stroke-dashoffset 0.8s ease 0s',
                                              // Rotate the path
                                              transform: 'rotate(0.9turn)',
                                              transformOrigin: 'center center',
                                            },
                                            // Customize the circle behind the path, i.e. the "total progress"
                                            trail: {
                                              // Trail color
                                              stroke: '#d6d6d6',
                                              strokeWidth:9,
                                              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                              strokeLinecap: 'butt',
                                              // Rotate the trail
                                              transform: 'rotate(0.9turn)',
                                              transformOrigin: 'center center',
                                            },
                                            // Customize the text
                                            text: {
                                              // Text color
                                              fill: 'rgb(248, 190, 28)',
                                              // Text size
                                              fontSize: '10vw',
                                              fontWeight:900
                                            },
                                            // Customize background - only used when the `background` prop is true
                                            background: {
                                              fill: 'rgb(248, 190, 28)',
                                            },
                                          }}
                                        />
                                        </div>
                                        </div>
                </div>
                <div
                  className="watch_status_container"
                  style={{ display: this.state.showWatchPollUpcomingMovie }}
                >
                  <button
                    className="watchlinks"
                    key={0}
                    id="will_watch"
                    onClick={this.handleWatchStatusClick.bind(
                      this,
                      "will_watch",
                      doc.id
                    )}
                    style={{ marginRight: "5vw", backgroundColor: "#ffffff" }}
                  >
                    WILL WATCH
                  </button>
                  <button
                    className="watchlinks"
                    key={1}
                    id="not_watch"
                    style={{ backgroundColor: "#ffffff" }}
                    onClick={this.handleWatchStatusClick.bind(
                      this,
                      "not_watch",
                      doc.id
                    )}
                  >
                    NOT INTERESTED
                  </button>
                </div>
                <div className="rate_it">
                  <p
                    style={{
                      fontWeight: "900",
                      fontSize: "6vw",
                      marginBottom: "2vw"
                    }}
                  >
                    Rate this movie
                  </p>
                  <p
                    style={{
                      fontWeight: "700",
                      fontSize: "5vw",
                      marginTop: "1vw",
                      marginRight: "2vw"
                    }}
                  >
                    Tell others what you think
                  </p>
                  <StarRatingComponent
                    rating={this.state.userRatedMovieRating}
                    starRatedColor="rgb(248, 190, 28)"
                    changeRating={this.onStarClick.bind(this, doc.id)}
                    numberOfStars={5}
                    name="rating"
                    isSelectable={true}
                    starHoverColor="rgb(208, 2, 27)"
                    starSelectingHoverColor="rgb(208, 2, 27)"
                    starDimension="6.5vw"
                    starSpacing="0.5vw"
                    starEmptyColor="rgb(141, 141, 141)"
                  />
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    fontWeight: "900",
                    fontSize: "6vw",
                    textAlign: "left",
                    width: "100vw",
                    borderBottom: "0.3vw solid #e6e6ee"
                  }}
                  onClick={this.handleMoreDropdownClick.bind(this)}
                >
                  <p style={{ width: "87vw", margin: 0, padding: "3vw" }}>
                    SYNOPSIS
                  </p>
                  <img
                  className="synopsis_arrow"
                    style={{
                      height: "18px",
                      width: "20px",
                      fontWeight: "900",
                      marginRight: "3vw",
                      marginTop: "3vw"
                    }}
                    src={require("../../../../imagesvg/down_arrow.svg")}
                    alt="more"
                  />
                </div>
                <div
                  className="synopsis_more_content"
                  style={{
                    width: "87vw",
                    height: "45vw",
                    margin: 0,
                    padding: "3vw",
                    display: "none",
                    overflow: "auto",
                    textAlign:"justify"
                  }}
                >
                  {ReactHtmlParser(doc.post_content)}
                </div>
              </div>
            </div>
          );
        });
        this.setState({ postDetail: postDetail });
      });
  };
  render() {
    let data;
    const { loading, celeb_id, backpresstab } = this.state;
    if (loading) {
      data = <div className="loader" />;
    } else {
      data = (
        <div>
          <div className="toolbar">
            <Toolbar callbackFromParent={"hidden"} />
          </div>
          <div
            className="movie_detail_section_container"
            style={{ background: "#f7f7f7" }}
          >
            <img
              className="movie_detail_section_more_arrow"
              src={require("../../../../imagesvg/left_red_arrow.svg")}
              style={{ marginLeft: "2.625vw" }}
              onClick={backArrowClick.bind(this, backpresstab, celeb_id)}
              alt="more"
            />
            <p
              className="gallery_detail_section_photos"
              onClick={this.handleBackArrow}
              style={{
                color: bottomNavigationColor,
                marginBottom: "0.625vw",
                marginLeft: "10vw",
                width: "fit-content"
              }}
            >
              REVIEW
            </p>
          </div>
          <div
            className="celeb_content"
            style={{
              position: "inherit",
              textAlign: "center",
              marginTop: " -2vw",
              height: "105vw"
            }}
          >
            {this.state.postDetail}
            {this.state.showPopup ? (
              <Popup
                text={this.state.popUpText}
                closePopup={this.togglePopup.bind(this)}
              />
            ) : null}
          </div>
          <div
            style={{
              position: "relative",
              marginTop: this.state.marginTopForPoll,
              height: "fit-content"
            }}
          >
            <section
              className="detail_tabs"
              style={{
                display: "flex",
                overflowX: "auto",
                background: "#ffffff",
                width: "100vw"
              }}
            >
              {this.state.detail_tabs}
            </section>
          </div>
          <div
            className="detail_content"
            style={{ position: "relative", marginTop: "5vw" }}
          >
            {this.state.detail_content}
          </div>
        </div>
      );
    }
    return <div style={{ backgroundColor: "#ffffff" }}>{data}</div>;
  }
}
export default MovieDetailItemApiParser;
