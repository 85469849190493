import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GalleryAllMoviePhotos, GalleryMoviePhotos, GalleryMoviePhotosBasedOnTitle } from '../../../../utils/apiUrls.js';
import Toolbar from '../../../../basecomponents/toolbar/toolbar.js';
import BaseImageComponent from '../../../../basecomponents/baseImageComponent.js';
import { backArrowClick } from '../../../../utils/commonClickActions.js';
import './galleryMoviePhotosSection.css';

class GalleryAllPhotosApiParser extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.imageRatio = "322x322";
        this.state = {
            loading: true,
            setOnce: false,
            doc_id: "",
            post_title: "",
            section_id: "",
            photos_id: "",
            detailsId: "",
            parentId: "",
            details_title: "",
            gallerySection: [],
            start: 0,
            limit: 10,
            error: false,
            hasMore: false,
            isLoading: false,
            totalCount: "",
            backpressTab: "",
            count:0
        };
    }

    getCategoryName() {
        switch (parseInt(this.state.section_id)) {
            case 6:
                return "Movie Stills";
            case 2:
                return "First Look";
            case 7:
                return "On The Sets";
            case 12:
                return "Parties And Events";
            case 8:
                return "Celeb Photos";
            case 3:
                return "Celeb Wallpapers";
            case 4:
                return "Movie Wallpapers";
            case 0:
                return "Wallpapers";
            default:
                return "null";
        }
    }

    handleBackpress(event) {
        backArrowClick(this.state.backpressTab, this.state.section_id, event);
    }

    handleItemClick(id, event) {
        if (this.state.section_id === "8") {
            window.location = '/galleryphoto?id=' + id + "&section=" + this.state.section_id + "&details_title=" + this.state.details_title + "&tab=galleryallphotos";
        } else {
            window.location = '/galleryphoto?id=' + id + "&section=" + this.state.section_id +
                "&detailsId=" + this.state.detailsId + "&parentId=" + this.state.parentId + "&tab=galleryallphotos";
        }
    }
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.loadMoviePhotos();
    };
    componentWillUnmount() {
        console.log('Component WILL UNMOUNT!')
        this._isMounted = false;
    }
    getImage(image_thumb) {
        var customimage = image_thumb;
        if (customimage === undefined || customimage === null) {
            return customimage;
        } else {
            if (this.state.section_id !== "2") {
                this.imageRatio = "354x199";
                customimage = image_thumb
                    .toString()
                    .replace("322x322", "354x199");
            } else {
                this.imageRatio = "306x393";
                customimage = image_thumb
                    .toString()
                    .replace("322x322", "306x393");
            }
            return customimage;
        }
    }

    loadMoviePhotos = () => {
        const params = new URL(window.location.href).searchParams;
        const id = params.get('id');
        const section_id = params.get("section");
        const detailsId = params.get("detailsId");
        const parentId = params.get("parentId");
        const tab = params.get("tab");
        console.log(tab)
        var details_title = "";
        if (section_id === "8") {
            details_title = params.get("details_title");
        }
        console.log(detailsId + " " + section_id + " " + parentId + " " + details_title)
        this._isMounted && this.setState(
            { section_id: section_id, photos_id: id, detailsId: detailsId, details_title: details_title, parentId: parentId, backpressTab: tab }
        );
        if (detailsId !== null && detailsId !== "undefined") {
            console.log("detailsId not null:  " +this.state.start)
            const finalUrl = GalleryAllMoviePhotos + "/" + section_id + "/" +
                detailsId + "/" + this.state.start + "/" + this.state.limit;

            console.log("finalUrl : " + finalUrl)
            fetch(finalUrl)
                .then(results => {
                    return results.json();
                })
                .then((data) => {
                    if (data === null && data === undefined) {
                        return;
                    }
                    const totalCount8 = data.photos.numFound;
                    var gallerySection = data
                        .photos
                        .docs
                        .map((doc, index) => {
                            var value = index;
                            var gridStyle = {
                                width: "91vw",
                                height: "117vw",
                                borderRadius: "2vw",
                                objectFit: "contain",
                                backgroundColor: " #000000",
                                backgroundRepeat: "no-repeat"
                            };
                            var row = 12;
                            if (this.state.section_id !== "2") {
                                if (index % 3 === 0) {
                                    row = 12;
                                    gridStyle.width = "91vw";
                                    gridStyle.height = "51.19vw";
                                    gridStyle.objectFit = "contain";
                                    gridStyle.borderRadius = "2vw";
                                    gridStyle.backgroundColor = " #efefef";
                                    gridStyle.backgroundRepeat = "no-repeat";
                                } else {
                                    row = 6;
                                    gridStyle.borderRadius = "2vw";
                                    gridStyle.width = "45vw";
                                    gridStyle.height = "24.75vw";
                                    gridStyle.objectFit = "contain";
                                    gridStyle.backgroundColor = " #efefef";
                                    gridStyle.backgroundRepeat = "no-repeat";
                                }
                            }
                            return (
                                <Grid item xs={row} key={Math.random()}>
                                    <div className="image" id={value} key={value}>
                                        <BaseImageComponent
                                            expectedRatio={this.imageRatio}
                                            image_thumb={this.getImage(doc.image_thumb[0])}
                                            customStyle={gridStyle}
                                            customClick={this
                                                .handleItemClick
                                                .bind(this, doc.id)} />
                                    </div>
                                </Grid>
                            )
                        });
                    console.log(
                        "finalUrl totalCount : " + JSON.stringify(this.state.gallerySection.length < totalCount8) +
                        " this.state.gallerySection " + this.state.gallerySection.length + " this.state" +
                        ".totalCount " + totalCount8
                    )
                    console.log(this._isMounted)
                    var id = 0;
                    if (!this.state.setOnce) {
                        this.setState({
                            post_title: data
                                .photos
                                .docs[0]
                                .post_title
                        });

                        id = data
                            .photos
                            .docs[0]
                            .id;
                        console.log("setting id up " + id);
                    } else {

                        id = Math.random();
                        console.log("setting id down " + id);
                    }

                    this._isMounted && this.setState({
                        setOnce: true,
                        doc_id: id,
                        totalCount: totalCount8,
                        loading: false,
                        start: (this.state.start + this.state.limit),
                        hasMore: (this.state.gallerySection.length < totalCount8),
                        isLoading: false,
                        gallerySection: [
                            ...this.state.gallerySection,
                            ...gallerySection
                        ]
                    });
                    console.log("section id 2:  " +(this.state.start))
                    console.log("gallery item "+gallerySection.length)
                    console.log("gallerySection.length " +this.state.gallerySection.length)
                    console.log("state total : "+this.state.totalCount)
                    console.log("totalCount " +totalCount8)
                    console.log("has more "+(this.state.gallerySection.length <= totalCount8))
                })
                .catch((err) => {
                    this._isMounted && this.setState({ error: err.message, isLoading: false });
                })
        } else if (detailsId === null && parentId === null && section_id === "8") {
            console.log("celeb photos : " + details_title)
            const finalUrl = GalleryMoviePhotosBasedOnTitle + "/" + section_id + "/" + details_title + "/" + this.state.start +
                "/" + this.state.limit;
            console.log(finalUrl)
            fetch(finalUrl)
                .then(results => {
                    return results.json();
                })
                .then(photos => {
                    var data = photos.doclist;
                    const totalCount = data.numFound;
                    var gallerySection = data
                        .docs
                        .map((doc, index) => {
                            console.log(JSON.stringify(doc))
                            var value = index;
                            var gridStyle = {
                                width: "91vw",
                                height: "117vw",
                                borderRadius: "2vw",
                                objectFit: "contain",
                                backgroundColor: " #000000",
                                backgroundRepeat: "no-repeat"
                            };
                            var row = 12;
                            if (this.state.section_id !== "2") {
                                if (index % 3 === 0) {
                                    row = 12;
                                    gridStyle.width = "91vw";
                                    gridStyle.height = "51.19vw";
                                    gridStyle.objectFit = "contain";
                                    gridStyle.backgroundColor = " #efefef";
                                    gridStyle.backgroundRepeat = "no-repeat";
                                } else {
                                    row = 6;
                                    gridStyle.width = "45vw";
                                    gridStyle.height = "24.75vw";
                                    gridStyle.objectFit = "contain";
                                    gridStyle.backgroundColor = " #efefef";
                                    gridStyle.backgroundRepeat = "no-repeat";
                                }
                            }
                            return (
                                <Grid item xs={row} key={Math.random()}>
                                    <div className="image" id={value} key={value}>
                                        <BaseImageComponent
                                            expectedRatio={this.imageRatio}
                                            image_thumb={this.getImage(doc.image_thumb[0])}
                                            customStyle={gridStyle}
                                            customClick={this
                                                .handleItemClick
                                                .bind(this, doc.id)} />
                                    </div>
                                </Grid>
                            )
                        });
                    var id = 0;
                    if (!this.state.setOnce) {
                        this.setState({
                            post_title: data
                                .docs[0]
                                .post_title
                        });

                        id = data
                            .docs[0]
                            .id;
                        console.log("setting id up " + id);
                    } else {

                        id = Math.random();
                        console.log("setting id down " + id);
                    }

                    console.log("section id 8:  " +this.state.start)
                    this._isMounted && this.setState({
                        details_title: details_title,
                        setOnce: true,
                        doc_id: id,
                        totalCount: totalCount,
                        loading: false,
                        start: (this.state.start + this.state.limit),
                        hasMore: (false),
                        isLoading: true,
                        gallerySection: [
                            ...this.state.gallerySection,
                            ...gallerySection
                        ]
                    });
                })
                .catch((err) => {
                    this._isMounted && this.setState({ error: err.message, isLoading: false });
                })
        }
        else {
            console.log("finalUrl:  " +this.state.start)
            const finalUrl = GalleryMoviePhotos + "/" + parentId + "/" + this.state.start +
                "/" + this.state.limit;
            console.log("finalUrl : " + finalUrl)
            fetch(finalUrl)
                .then(results => {
                    return results.json();
                })
                .then(data => {
                    // console.log(JSON.stringify(data))
                    const totalCount = data.numFound;
                    var gallerySection = data
                        .docs
                        .map((doc, index) => {
                            console.log(doc.image_thumb)
                            if(doc.image_thumb!==null&&doc.image_thumb!==undefined&&doc.image_thumb[0]!==null&& doc.image_thumb[0]!==undefined){
                            console.log(doc.image_thumb[0])
                                var value = index;
                            var gridStyle = {
                                width: "91vw",
                                height: "117vw",
                                borderRadius: "2vw",
                                objectFit: "contain",
                                backgroundColor: " #000000",
                                backgroundRepeat: "no-repeat"
                            };
                            var row = 12;
                            if (this.state.section_id !== "2") {
                                if (index % 3 === 0) {
                                    row = 12;
                                    gridStyle.width = "91vw";
                                    gridStyle.height = "51.19vw";
                                    gridStyle.objectFit = "contain";
                                    gridStyle.backgroundColor = " #efefef";
                                    gridStyle.backgroundRepeat = "no-repeat";
                                } else {
                                    row = 6;
                                    gridStyle.width = "45vw";
                                    gridStyle.height = "24.75vw";
                                    gridStyle.objectFit = "contain";
                                    gridStyle.backgroundColor = " #efefef";
                                    gridStyle.backgroundRepeat = "no-repeat";
                                }
                            }
                            return (
                                <Grid item xs={row} key={Math.random()}>
                                    <div className="image" id={value} key={value}>
                                        <BaseImageComponent
                                            expectedRatio={this.imageRatio}
                                            image_thumb={this.getImage(doc.image_thumb[0])}
                                            customStyle={gridStyle}
                                            customClick={this
                                                .handleItemClick
                                                .bind(this, doc.id)} />
                                    </div>
                                </Grid>
                            )
                        }else{
                           this.setState({count:this.state.count+1});
                            return (
                                <Grid item xs={row} key={Math.random()}>
                                    <div className="image" id={value} key={value}>
                                        {/* <BaseImageComponent
                                            expectedRatio={this.imageRatio}
                                            image_thumb={this.getImage("undefined")}
                                            customStyle={gridStyle}
                                            customClick={this
                                                .handleItemClick
                                                .bind(this, doc.id)} /> */}
                                    </div>
                                </Grid>
                            )
                        }
                        });
                    console.log(
                        "finalUrl totalCount : " + JSON.stringify(gallerySection.length < totalCount) +
                        " this.state.gallerySection " + gallerySection.length + " this.state.totalCount " + totalCount
                    )
                    var id = 0;
                    if (!this.state.setOnce) {
                        this.setState({
                            post_title: data
                                .docs[0]
                                .post_title
                        });

                        id = data
                            .docs[0]
                            .id;
                        console.log("setting id up " + id);
                    } else {

                        id = Math.random();
                        console.log("setting id down " + id);
                    }
                    console.log("has more", this.state.gallerySection.length < totalCount)
                    this._isMounted && this.setState({
                        setOnce: true,
                        doc_id: id,
                        totalCount: totalCount,
                        loading: false,
                        start: (this.state.start + this.state.limit),
                        hasMore: (this.state.gallerySection.length < totalCount),
                        isLoading: true,
                        gallerySection: [
                            ...this.state.gallerySection,
                            ...gallerySection
                        ]
                    });
                })
                .catch((err) => {
                    this._isMounted && this.setState({ error: err.message, isLoading: false });
                })

        }
    };
GalleryHasItems =(gallerySection)=>{
    console.log("inside GalleryHasItems")
    if(gallerySection.length>0){
        return (
            <Grid container spacing={8} key={Math.random()}>
            {gallerySection}
        </Grid>
        )
    }else{
      return(<div>No item to display.</div>)
    }
}
    render() {
        const {
            // error,
            hasMore,
            isLoading,
            gallerySection
        } = this.state;

        var data;
        const { loading} = this.state.loading;
        if (loading) {
            data = <div className="loader"></div>
        } else {
            data = <div>
                <div className="toolbar">
                    <Toolbar callbackFromParent={"hidden"} />
                </div>
                <div
                    className="gallery_movie_section_container"
                    style={{
                        background: "#F7F7F7"
                    }}>
                    <img
                        className="gallery_movie_section_more_arrow"
                        src={require('../../../../imagesvg/left_red_arrow.svg')}
                        onClick={this.handleBackpress
                            .bind(this)}
                        alt="more" />
                    <p
                        className="gallery_movie_section_photo_name"
                        onClick={this.handleBackpress
                            .bind(this)}>{this.getCategoryName()}</p>
                </div>
                <div className="movie_title">
                    <p className="gallery_title">{this.state.post_title}</p>
                    <p className="gallery_photo_count">{this.state.totalCount} photos</p>
                </div>
                <div className="movie_gallery_content">
                    {this.GalleryHasItems(gallerySection)}
                </div>
            </div>
        }
        console.log(this.state.count)
        if(this.state.count>=10){
            return (
                <div style={{
                    background: "#FFFFFF"
                }}>
                   data = <div>
                <div className="toolbar">
                    <Toolbar callbackFromParent={"hidden"} />
                </div>
                <div
                    className="gallery_movie_section_container"
                    style={{
                        background: "#F7F7F7"
                    }}>
                    <img
                        className="gallery_movie_section_more_arrow"
                        src={require('../../../../imagesvg/left_red_arrow.svg')}
                        onClick={this.handleBackpress
                            .bind(this)}
                        alt="more" />
                    <p
                        className="gallery_movie_section_photo_name"
                        onClick={this.handleBackpress
                            .bind(this)}>{this.getCategoryName()}</p>
                </div>
               <p className="movie_title" style={{textAlign:"center",verticalAlign:"center"}}>No item to display.</p>
            </div>
                </div>
            );
        }
        return (
            <div style={{
                background: "#FFFFFF"
            }}>
                <div>
                    <InfiniteScroll
                        dataLength={this.state.gallerySection.length}
                        initialLoad={false}
                        pageStart={this.state.start}
                        next={!isLoading ? this.loadMoviePhotos:() => {}}
                        hasMore={hasMore && !isLoading}
                        loader={this.state.totalCount > 10 && <div
                            className="loader"
                            style={{
                                textAlign: "center",
                                padding: "3.125vw",
                                fontWeight: "700"
                            }}
                            key={0}>Loading ...</div>}
                        useWindow={true}>
                        {data}
                    </InfiniteScroll>
                </div>
            </div>
        );
    };
};
export default GalleryAllPhotosApiParser;