import React from "react";
import "./itemCardView.css";
import LazyLoad from "react-lazy-load";
import ReactHtmlParser from "react-html-parser";
import BaseImageComponent from "../../../../basecomponents/baseImageComponent.js";
import {
  favouriteIconClick,
  shareClick
} from "../../../../utils/commonClickActions.js";
import { UserFavourites } from "../../../../utils/apiUrls.js";
import {
  blackColor,
  yellowCelebColor,
  pinkVideosColor,
  featureOrangeColor,
  whiteColor,
  newsCyanBlueColor,
  commonBackgroundColor
} from "../../../../utils/color.js";
import Popup from "../../../../utils/customPopup.js";
import { TrendingEvent } from "../../../../analytics_events/js/CustomEvents";

class ItemCardView extends React.Component {
  constructor(props) {
    super(props);
    this.imageRatio = "322x322";
    this.favs = JSON.parse(localStorage.getItem(UserFavourites));
    this.state = {
      failed: false,
      data: this.props.pic,
      favouriteIcon:
        this.favs !== null && this.favs.includes(this.props.pic.id)
          ? require("../../../../imagesvg/favourite_fill.svg")
          : require("../../../../imagesvg/favourite_white.svg"),
      IsFavouriteAddedOrRemoved:
        this.favs !== null && this.favs.includes(this.props.pic.id)
          ? "remove"
          : "add",
      showPopup: false,
      popUpText: ""
    };
  }
  togglePopup(text) {
    console.log(this.state.showPopup);
    this.setState({
      showPopup: !this.state.showPopup,
      popUpText: text
    });
  }
  handleCardClick = () => {
    window.location = "/postdetail?id=" + this.state.data.id + "&tab=stories";
  };

  getImage() {
    if (
      this.state.data.image_thumb === undefined ||
      this.state.data.image_thumb === null
    ) {
      return this.state.data.image_thumb;
    } else {
      var image = this.state.data.image_thumb;
      var customimage = image;
      if (this.state.data.category) {
        if (this.state.data.category === "Features") {
          this.imageRatio = "354x199";
          customimage = image.toString().replace("322x322", "354x199");
        } else if (this.state.data.category === "News") {
          this.imageRatio = "354x199";
          customimage = image.toString().replace("322x322", "354x199");
        } else if (
          this.state.data.category === "Celeb Interview" ||
          this.state.data.category === "CELEB INTERVIEW"
        ) {
          this.imageRatio = "165x165";
          customimage = image.toString().replace("322x322", "165x165");
        } else if (
          this.state.data.category === "videos" ||
          this.state.data.category === "video"
        ) {
          this.imageRatio = "354x199";
          customimage = image.toString().replace("322x322", "354x199");
        }
        return customimage;
      } else {
        if (
          this.state.data.type === "videos" ||
          this.state.data.type === "video"
        ) {
          this.imageRatio = "354x199";
          customimage = image.toString().replace("322x322", "354x199");
        }
        return customimage;
      }
    }
  }
  favouriteResonse = data => {
    console.log(JSON.stringify(data.favorite_count));
    if (data !== null && data !== undefined) {
      TrendingEvent("Trending_Like","Trending_Tab");
      if (data.success) {
        if (
          data.favorite_count > 0 &&
          this.state.IsFavouriteAddedOrRemoved === "add"
        ) {
          this.togglePopup("Marked Favourite!");
          if (this.favs !== null) {
            if (!this.favs.includes(this.state.data.id)) {
              localStorage.setItem(
                UserFavourites,
                JSON.stringify(this.favs + "," + this.state.data.id)
              );
            }
          } else {
            localStorage.setItem(
              UserFavourites,
              JSON.stringify(this.favs + "," + this.state.data.id)
            );
          }
          this.setState({
            IsFavouriteAddedOrRemoved: "remove",
            favouriteIcon: require("../../../../imagesvg/favourite_fill.svg")
          });
        } else {
          this.togglePopup("Removed Favourite!");
          if (
            this.favs !== null &&
            this.favs.includes("," + this.state.data.id)
          ) {
            var updated = this.favs.replace("," + this.state.data.id, "");
            localStorage.setItem(UserFavourites, JSON.stringify(updated));
          }
          this.setState({
            IsFavouriteAddedOrRemoved: "add",
            favouriteIcon: require("../../../../imagesvg/favourite_white.svg")
          });
        }
        // console.log(this.state.data.id);
        // console.log(this.favs);
        // console.log(JSON.parse(localStorage.getItem(UserFavourites)));
        // console.log(this.state.IsFavouriteAddedOrRemoved);
        // console.log(this.state.favouriteIcon);
      } else {
        this.togglePopup("Oops ! Please try again.");
      }
    } else {
      this.togglePopup("Oops ! Please try again.");
    }
  };
  render() {
    var tagStyle = {
      background: featureOrangeColor,
      color: blackColor
    };

    var thumb_imagesize = {
      width: "90vw",
      height: "90vw",
      borderRadius: "3vw",
      marginBottom: "-0.9375vw",
      backgroundColor: commonBackgroundColor,
      objectFit: "contain",
      backgroundRepeat: "no-repeat"
    };

    var getTypeName = () => {
      if (this.state.data.category) {
        if (this.state.data.category === "Features") {
          tagStyle.background = featureOrangeColor;
          tagStyle.color = blackColor;
          thumb_imagesize.height = "50.63vw";
        } else if (this.state.data.category === "News") {
          tagStyle.background = newsCyanBlueColor;
          tagStyle.color = whiteColor;
          thumb_imagesize.height = "50.63vw";
        } else if (
          this.state.data.category === "Celeb Interview" ||
          this.state.data.category === "CELEB INTERVIEW"
        ) {
          tagStyle.background = yellowCelebColor;
          tagStyle.color = blackColor;
        } else if (
          this.state.data.category === "videos" ||
          this.state.data.category === "video"
        ) {
          tagStyle.background = pinkVideosColor;
          tagStyle.color = whiteColor;
          thumb_imagesize.height = "50.63vw";
        }
        return ReactHtmlParser(this.state.data.category);
      } else {
        if (
          this.state.data.type === "videos" ||
          this.state.data.type === "video"
        ) {
          tagStyle.background = pinkVideosColor;
          tagStyle.color = whiteColor;
          thumb_imagesize.height = "50.63vw";
        }
        return ReactHtmlParser(this.state.data.type);
      }
    };
    return (
      <div className="card">
        <div className="card_action_icons_div">
          <img
            src={this.state.favouriteIcon}
            className="action_icons"
            onClick={favouriteIconClick.bind(
              this,
              this.state.data.id,
              this.state.IsFavouriteAddedOrRemoved,
              "movie",
              this.favouriteResonse
            )}
            alt="fav"
          />
          <img
            src={require("../../../../imagesvg/share_white.svg")}
            className="action_icons"
            onClick={shareClick.bind(
              this,
              "https://mobi.bollywoodhungama.com/postdetail?id=" +
                this.state.data.id +
                "&tab=stories"
            )}
            alt="share"
          />
        </div>
        <LazyLoad throttle={250} debounce={false} offsetVertical={3}>
          <BaseImageComponent
            expectedRatio={this.imageRatio}
            image_thumb={this.getImage()}
            customStyle={thumb_imagesize}
            customClick={this.handleCardClick}
          />
        </LazyLoad>
        <div className="container">
          <p className="Tag" id="category_type" style={tagStyle}>
            {getTypeName()}
          </p>
          <p className="type_title" onClick={this.handleCardClick}>
            {ReactHtmlParser(this.state.data.post_title)}
          </p>
        </div>
        {this.state.showPopup ? (
          <Popup
            text={this.state.popUpText}
            closePopup={this.togglePopup.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

export default ItemCardView;
