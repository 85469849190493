import React, { Component } from "react";
import Moment from "moment";
import "./detailMusicApiParser.css";
import Grid from "@material-ui/core/Grid";
import BaseImageComponent from "../../../../basecomponents/baseImageComponent.js";
import "../../../boxoffice_section/update/js/boxOfficeApiParse.css";
import Popup from "../../../../utils/customPopup.js";

class DetailMusicAPIParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: props.tag,
      type: props.type,
      section_name: [],
      bottomTabSelected: props.tab_selected,
      url: props.url,
      loading: true,
      section_musicvideos: [],
      playing: false,
      itemId: "",
      onlyMusic: [],
      showPopup: false,
      popUpText: ""
    };
    // console.log("bottomTabSelected : "+this.state.bottomTabSelected);
    //TODO:https://developers.google.com/web/tools/lighthouse/audits/no-js
    // "start_url": ".",
  }
  togglePopup(text) {
    console.log(this.state.showPopup);
    this.setState({
      showPopup: !this.state.showPopup,
      popUpText: text
    });
  }
  getCurrentDate(date) {
    //   console.log("moment"+Moment(date,'YYYYMMDDhms').format("MMM DD, YYYY - h:mm a")+" IST");
    var formatedDate =
      Moment(date, "YYYYMMDDhms").format("MMM DD, YYYY - h:mm a") + " IST";
    return formatedDate;
  }
  getTitle(doc) {
    if (doc != null) {
      return doc.post_title;
    }
  }
  getPlaybackSingerName(singerName) {
    // console.log(singerName)
    if (singerName != null) {
      var name = singerName.toString().split("|");
      if (name.length > 0) {
        return name[1];
      } else {
        return singerName;
      }
    } else {
      return "NA";
    }
  }
  onAudioPlayerError(event) {
    // console.log(event.type);
    // console.log(event.persist());
    if (event.persist()) {
      console.log(event.type);
    }
    // if(event.target.error!==undefined && event.target.error!==null && event.target.error!=="" ){
    //     this.togglePopup("Oop’s something went wrong");
    // }
  }
  handleLyricsClicked(doc) {
    // console.log("lyrics"+doc.post_content)
    window.location = "/musiclyrics?id=" + doc.id;
  }
  setUrl(url) {
    return url;
  }
  getFinalUrl(url) {
    // console.log(url);
    // var actualUrl =url;
    if (url === undefined || url === null) {
      console.log(url);
      return "";
    } else if (!url.toString().includes(".mp3")) {
      return "";
    } else {
      var finalUrl = "";
      if (url.toString().includes("https")) {
        finalUrl = url;
      } else {
        finalUrl = url.toString().replace("http", "https");
        console.log(finalUrl);
      }
      return finalUrl;
    }
  }
  playAudio(doc, event) {
    console.log(event);
    var id = doc.id;
    console.log(
      " video inside url : " +
        doc.details_audio_url +
        "    " +
        this.getFinalUrl(doc.details_audio_url) +
        "  id : " +
        id +
        " itemId : " +
        this.state.itemId
    );
    var myAudio = document.getElementById("myTune");
    if (
      this.getFinalUrl(doc.details_audio_url) !== undefined &&
      this.getFinalUrl(doc.details_audio_url) !== null &&
      this.getFinalUrl(doc.details_audio_url) !== ""
    ) {
      myAudio.src = this.getFinalUrl(doc.details_audio_url);
      this.setAudioPlayIcon(event, this.state.playing);
      if (this.state.playing === true) {
        this.setState({ playing: false });
        if (id === this.state.itemId) {
          myAudio.pause();
        } else {
          myAudio.pause();
          myAudio.load();
          myAudio.play();
        }
        this.setState({ itemId: id });
      } else {
        this.setState({ itemId: id });
        this.setState({ playing: true });
        myAudio.pause();
        myAudio.load();
        myAudio.play();
      }
    } else {
      this.togglePopup("Oop's Something went wrong!");
    }
  }
  handlePlayContainerClick(data, event) {
    window.location =
      "/eventvideodetail?id=" +
      data.id +
      "&tab=" +
      this.state.bottomTabSelected;
  }

  getImage(image_thumb) {
    var customimage = image_thumb;
    if (customimage === undefined || customimage === null) {
      return customimage;
    } else {
      customimage = image_thumb.toString().replace("322x322", "354x199");
      return customimage;
    }
  }
  setAudioPlayIcon(event, isPlaying) {
    console.log(event.currentTarget.src.includes("play_black"));
    if (event.currentTarget.src.includes("play_black")) {
      event.currentTarget.src = require("../../../../imagesvg/pause.svg");
    } else {
      event.currentTarget.src = require("../../../../imagesvg/play_black.svg");
    }
  }
  componentDidMount() {
    var finalurl;
    if (this.state.bottomTabSelected === "celeb") {
      finalurl = this.state.url + "/" + this.state.tag + "/" + this.state.type;
    } else {
      finalurl = this.state.url + "/" + this.state.tag;
    }
    fetch(finalurl)
      .then(results => {
        return results.json();
      })
      .then(data => {
        this.setState({ loading: false });
        if (this.state.bottomTabSelected === "celeb") {
          let music = data.articles.docs.map((doc, index) => {
            var value = doc.id;
            return (
              <div className="detail_music_container_data" key={value}>
                <audio id="myTune">
                  <source
                    src={this.getFinalUrl(doc.details_audio_url)}
                    ref={audio => {
                      this.audio = audio;
                    }}
                    onError={this.onAudioPlayerError}
                    type="audio/mpeg"
                  />
                </audio>
                <div className="music_action_icons_div">
                  <img
                    src={require("../../../../imagesvg/lyrics.svg")}
                    onClick={this.handleLyricsClicked.bind(this, doc)}
                    className="action_icons"
                    alt="lyrics"
                  />
                  <img
                    src={require("../../../../imagesvg/play_black.svg")}
                    key={value}
                    className="action_icons"
                    id="play_music"
                    alt="play"
                    onClick={this.playAudio.bind(this, doc)}
                  />
                </div>
                <p className="music_title">{this.getTitle(doc)}</p>
                <p className="music_playbacksingers">
                  {this.getPlaybackSingerName(doc.singerf)}
                </p>
              </div>
            );
          });
          this.setState({ onlyMusic: music });
        } else {
          let section_musicvideos = data.section_musicvideos.map(
            (section_musicvideo, index) => {
              this.setState({ section_name: section_musicvideo.section_name });
              // if (section_musicvideo.section_name === "Music") {

              // let music= section_musicvideo.music.articles.docs.map((doc,index)=>{
              //  var value = doc.id;
              // return (
              //     <div className="detail_music_container_data" key={value}>
              //         <audio id="myTune">
              //         <source src={this.getFinalUrl(doc.details_audio_url)}  ref={audio => {this.audio = audio}} onError={this.onAudioPlayerError} type="audio/mpeg"></source>
              //         </audio>
              //     <div className="music_action_icons_div">
              //     <img src={require("../../../../imagesvg/lyrics.svg")} onClick={this.handleLyricsClicked.bind(this,doc)} className="action_icons" alt="lyric"/>
              //     <img src={require("../../../../imagesvg/play_black.svg")} key={value} className="action_icons" id="play_music" alt="play" onClick={this.playAudio.bind(this,doc)} />
              //     </div>
              //     <p className="music_title">{this.getTitle(doc)}</p>
              //     <p className="music_playbacksingers" >{this.getPlaybackSingerName(doc.singerf)}</p>
              //     </div>
              // )
              // });
              // return(
              // <div className="section_header"  style={{ marginTop:"25vw"}} key={this.state.section_name}>
              //     <div className="section_title">
              //     <span className="section_name">{this.state.section_name}</span>
              //     </div>
              //     <div>
              //         {music}
              //     </div>
              // </div>
              // );}
              // else {
              if (section_musicvideo.section_name !== "Music") {
                var thumb_imagesize = {
                  width: "42vw",
                  height: "23.63vw",
                  borderRadius: "2vw",
                  padding: 0,
                  objectFit: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "#efefef"
                };
                let videos = section_musicvideo.videos.articles.docs.map(
                  doc => {
                    return (
                      <Grid key={doc.id} item>
                        {" "}
                        {
                          <div
                          style={{paddingRight:"1vw"}}
                            className="small_video_container_data"
                            onClick={this.handlePlayContainerClick.bind(
                              this,
                              doc
                            )}
                          >
                            <BaseImageComponent
                              expectedRatio={"354x199"}
                              image_thumb={this.getImage(doc.image_thumb)}
                              customStyle={thumb_imagesize}
                            />
                            <img
                              className="event_small_play_icon"
                              src={require("../../../../imagesvg/play_copy.svg")}
                              style={{
                                textAlign: "left",
                                position: "absolute",
                                marginBottom: "15.625vw"
                              }}
                              alt="play"
                            />
                            <p
                              className="event_video_title"
                              style={{ textAlign: "left" }}
                            >
                              {doc.post_title}
                            </p>
                          </div>
                        }{" "}
                      </Grid>
                    );
                  }
                );
                return (
                  <div
                    className="section_header"
                    style={{ marginTop: "3.125vw",width:"97%",textAlign:"-webkit-center" }}
                    key={this.state.section_name}
                  >
                    <div className="section_title">
                      <span className="section_name">
                        {this.state.section_name}
                      </span>
                    </div>
                    <Grid
                      container
                      className="musicgrid"
                      style={{width:"95%",textAlign:"center",marginRight:"2vw"}}
                      key={index}
                      justify="flex-start"
                      spacing={8}
                    >
                      {videos}
                    </Grid>
                  </div>
                );
              }
              return "";
            }
          );
          this.setState({ section_musicvideos: section_musicvideos });
        }
      });
  }
  render() {
    let data;
    const { loading } = this.state;
    if (loading) {
      data = <div className="subloader" />;
    } else {
      if (this.state.bottomTabSelected === "celeb") {
        data = (
          <div
            className="section_header"
            style={{ marginTop: "25vw", padding:0,width:"97%" }}
            key={"Music"}
          >
            <div className="section_title">
              <span className="section_name">Music</span>
            </div>
            <div>{this.state.onlyMusic}</div>
            {this.state.showPopup ? (
              <Popup
                text={this.state.popUpText}
                closePopup={this.togglePopup.bind(this)}
              />
            ) : null}
          </div>
        );
      } else {
        data = (
          <div key={this.state.section_musicvideos.results}>
            {this.state.section_musicvideos}
            {this.state.showPopup ? (
              <Popup
                text={this.state.popUpText}
                closePopup={this.togglePopup.bind(this)}
              />
            ) : null}
          </div>
        );
      }
    }
    return <div>{data}</div>;
  }
}
export default DetailMusicAPIParser;
