import React, { Component } from "react";
import "../apiparsercomponents/login/signinSection.css";
import {
backArrowClick,
setLoginInLocalStorage,
getAuthorizationHeader,
handleKeyDown
} from "../utils/commonClickActions.js";
import { LoginUser, LoginTypeValue, UserID,RegisterAuthorizationKey,UserProfileData } from "../utils/apiUrls.js";
import Popup from "../utils/customPopup.js";
import { UserRegisterORForgotPasswordEvent } from "../analytics_events/js/CustomEvents";

class SignUpPage extends Component {
constructor(props) {
super(props);
this.state = {
showPopup: false,
popUpText: ""
};
}
togglePopup(text) {
console.log(this.state.showPopup);
this.setState({
showPopup: !this.state.showPopup,
popUpText: text
});
}
componentDidMount() {
UserRegisterORForgotPasswordEvent("Forgot_Password","Forgot_Password");
document.addEventListener("emailbackpressed", () => {
this.props.history.push("/");
});
}
_handleTermsOfUseConditions() {
window.location = "https://mobi.bollywoodhungama.com/terms.html";
}

onSignupClicked = () => {
  var submit = document.querySelector("#submit");
  submit.disabled = true;
var username = document.querySelector("#username");
var password = document.querySelector("#password");
var cpassword = document.querySelector("#cpassword");
var email = document.querySelector("#email");

if (this.validateString(username) && this.validateString(password) && this.validateString(cpassword) && this.validatePasswords(password,cpassword) && this.validateEmail(email)) {
var bodyStr =
"action=register&username=" +
username.value +
"&password=" +
password.value +
"&cpassword=" +
cpassword.value +
"&email=" +
email.value;
fetch(LoginUser, {
method: "POST",
headers: {
"Accept":"Application/json",
"Authorization":getAuthorizationHeader(RegisterAuthorizationKey),
"Content-Type": "application/x-www-form-urlencoded",
},
body: bodyStr
})
.then(results => {
console.log(results);
return results.json();
})
.then(data => {
console.log(data);
submit.disabled = false;
if (data !== null && data !== undefined) {
if (data.success) {
this.togglePopup(data.message);
var loginResponse = {
display_name: data.user_login,
email: data.user_email,
picture: "",
id: data.ID,
accessToken: "",
expiresIn: ""
};
localStorage.setItem(UserID, data.ID);
setLoginInLocalStorage(
LoginTypeValue.RESIGTER,
JSON.stringify(loginResponse)
);
if(data.userdata!==null && data.userdata!==undefined){
var finaldata = JSON.parse(data.userdata);

localStorage.setItem(UserProfileData, JSON.stringify(finaldata));
}
// HA.HAController.getInstance().onUserLoggedIn(data.user_id,email.value,LoginTypeValue.RESIGTER);
window.location = "/";
} else {
this.togglePopup(
"Entered credentials are not valid or " + data.message
);
username.value = "";
password.value = "";
cpassword.value = "";
email.value = "";
}
}
});
} else {
submit.disabled = false;
this.togglePopup("Enter valid " + this.selectedField);
}
};

validateString = inputValue => {
this.selectedField = inputValue.id;
if (
inputValue.value !== null &&
inputValue.value !== undefined &&
inputValue.value !== ""
) {
console.log(inputValue.id);
return true;
} else {
return false;
}
};

validateEmail = inputValue => {
  this.selectedField = inputValue.id;
  if (
  inputValue.value !== null &&
  inputValue.value !== undefined &&
  inputValue.value !== ""
  ) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputValue.value))
    {
      return (true)
    }else{
      return (false)
    }
  } else {
  return false;
  }
  };

  validatePasswords = (inputValue1,inputValue2) => {
    this.selectedField = inputValue1.id;
    if (
      inputValue1.value !== null &&
      inputValue1.value !== undefined &&
      inputValue1.value !== "" &&
      inputValue2.value !== null &&
      inputValue2.value !== undefined &&
      inputValue2.value !== ""
    ) {
      console.log(inputValue1.value === inputValue2.value)
      if(inputValue1.value === inputValue2.value){

        return true;
      }else{
        return false;
      }
    } else {
    return false;
    }
    };

render() {
var height = window.innerHeight + "px";
return (
<div className="main">
  <div className="logindata" style={{ width: "100%", height: height }}>
    <div className="signin_section_container">
      <img className="Signin_section_back_arrow" src={require("../../components/imagesvg/left-arrow.svg")}
        onClick={backArrowClick.bind(this, "SignUpPage" )} alt="more" />
      <img src={require("../imagesvg/bh_logo.png")} style={{
                height: "9vw",
                width: "33vw",
                marginLeft: "13%",
                marginTop: "5%"
              }} alt="" />
    </div>
    <div style={{ textAlign: "center", paddingTop: "10%" }}>
      <p className="forgot_pass" style={{
        width:"40vw",
                textAlign: "left",
                textDecoration: "none",
                fontSize: "6vw",
                fontWeight: "700",
                marginLeft: "10vw",
                paddingTop: "5%"
              }}>
        Sign up
      </p>
      <p style={{
                fontSize: "4.25vw",
                fontWeight: "400",
                color: "#999999",
                marginTop: "-4%"
              }}>
        Sign up with Bollywood Hungama
      </p>
      <input type="text" id="username" name="username" tabIndex="1"
        onKeyDown={handleKeyDown.bind(this)} placeholder="Username" className="loginput" />
      <input type="password" id="password" tabIndex="2"
        onKeyDown={handleKeyDown.bind(this)} name="password" placeholder="Password" className="loginput" />
      <input type="password" id="cpassword" tabIndex="3"
        onKeyDown={handleKeyDown.bind(this)} name="cpassword" placeholder="Confirm Password" className="loginput" />
      <input type="email" id="email" tabIndex="4"
        onKeyDown={handleKeyDown.bind(this)} name="email" placeholder="Email" className="loginput" />
      <p className="forgot_pass" style={{
                textAlign: "center",
                textDecoration: "none",
                fontSize: "5.625vw",
                paddingTop: "5vw"
              }}>
        By creating an account you
        <br /> agree to abide by the
      </p>
      <p className="forgot_pass" style={{
                textAlign: "center",
                textDecoration: "underline",
                fontSize: "4.625vw",
                color: "#DF440D",
                fontWeight: "700",
                marginTop: "2vw"
              }} onClick={this._handleTermsOfUseConditions}>
        Bollywood Hungama Terms of use.
      </p>
      <input type="submit" id="submit" value="Sign up" tabIndex="5"
        onKeyDown={handleKeyDown.bind(this)} className="submit" onClick={this.onSignupClicked.bind(this)} />
    </div>
  </div>
  {this.state.showPopup ? (
  <Popup text={this.state.popUpText} closePopup={this.togglePopup.bind(this)} />
  ) : null}
</div>
);
}
}

export default SignUpPage;