import React from "react";
import StarRatingComponent from "react-star-rating-component";
import Grid from "@material-ui/core/Grid";
import {
  ratingSelectedColor,
  ratingDesectedColor
} from "../../../utils/color.js";
import BaseImageComponent from "../../../basecomponents/baseImageComponent.js";
import ItemCardView from "../../trending_section/stories/js/itemCardView.js";
import { MovieDetails } from "../../../utils/apiUrls.js";

class SearchResultItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataType: props.dataType,
      dataList: props.dataList,
      content_type: "video"
    };
  }

  getImage(image_thumb, expectedRatio) {
    var customimage = image_thumb;
    if (customimage === undefined || customimage === null) {
      return customimage;
    } else {
      customimage = image_thumb.toString().replace("322x322", expectedRatio);
      return customimage;
    }
  }

  handleMovieClickedForReviewDetail(id, event) {
    window.location = "/moviereviewdetail?id=" + id + "&tab=search";
  }

  handlePlayEventContainerClick(data, type, event) {
    if (type === "event") {
      console.log("event CLicked");
      window.location =
        "/galleryallphotos?id=" +
        data.id +
        "&section=12&detailsId=" +
        data.details_pid +
        "&parentId=" +
        data.id +
        "&tab=search";
    } else if (type === "wallpaper") {
      // window.location = '/galleryallphotos?id=' + data.id + "&section=8&details_title="+data.post_title+"&tab=search";

      const finalUrl = MovieDetails + "/" + data.id;
      fetch(finalUrl)
        .then(results => {
          return results.json();
        })
        .then(data => {
          console.log(JSON.stringify(data.movies_detail));
          console.log(data.movies_detail.docs[0].parent_id);
          var doc = data.movies_detail.docs[0];
          window.location =
            "/galleryallphotos?id=" +
            doc.id +
            "&section=0&detailsId=" +
            doc.details_pid +
            "&parentId=" +
            doc.parent_id +
            "&tab=search";
        });
    }
  }

  handlePlayContainerClick(data, event) {
    if (this.state.content_type === "video") {
      window.location = "/eventvideodetail?id=" + data.id + "&tab=search";
    } else if (this.state.content_type === "photos") {
      console.log(data);
      console.log("doc.data : " + data.category_id);
      console.log("data.details_pid : " + data.details_pid);
      console.log("data.parent_id : " + data.parent_id);
      console.log("data : " + JSON.stringify(data));
      console.log(data.category);
      if (
        data.category_id === undefined &&
        (this.state.category_id === 12 ||
          this.state.category_id === 3 ||
          this.state.category_id === 4)
      ) {
        console.log("data.parent_id inside : " + data.parent_id);
        window.location =
          "/galleryallphotos?id=" +
          data.id +
          "&section=" +
          this.state.category_id +
          "&detailsId=" +
          data.details_pid +
          "&parentId=" +
          data.parent_id +
          "&tab=search";
      } else if (this.state.category_id === 8) {
        window.location =
          "/galleryallphotos?id=" +
          data.id +
          "&section=" +
          this.state.category_id +
          "&details_title=" +
          data.details_title +
          "&tab=search";
      } else if (
        this.getCategoryId(data.category) !== 12 &&
        this.getCategoryId(data.category) !== 3 &&
        this.getCategoryId(data.category) !== 4
      ) {
        window.location =
          "/galleryallphotos?id=" +
          data.id +
          "&section=6&detailsId=" +
          data.details_pid +
          "&parentId=" +
          data.parent_id +
          "&tab=search";
      } else {
        window.location =
          "/galleryallphotos?id=" +
          data.id +
          "&section=" +
          data.category_id +
          "&detailsId=" +
          data.details_pid +
          "&parentId=" +
          data.parent_id +
          "&tab=search";
      }
      //TODO: provide detailsId in api response
      // var b = new Buffer(data.image_thumb);
      //     var datafinal = b.toString('base64');
      //  window.location='/galleryphoto?id='+data.id+"&section=" + this.getCategoryId(data.category) +"&detailsId="+data.details_pid;
      // window.location = '/gallerysectiondetailwithurl?id=' + datafinal;
    } else {
      window.location = "/postdetail?id=" + data.id + "&tab=search";
    }
  }
  getCategoryId(category) {
    switch (category) {
      case "Movie Stills":
        return 6;
      case "First Look":
        return 2;
      case "On The Sets":
        return 7;
      case "Parties And Events":
        return 12;
      case "Celeb Photos":
        return 8;
      case "Celeb Wallpapers":
        return 3;
      case "Movie Wallpapers":
        return 4;
      default:
        return 6;
    }
  }
  componentDidMount() {
    if (
      this.state.dataList !== null &&
      this.state.dataList !== undefined &&
      this.state.dataList.RESPONSE !== null &&
      this.state.dataList.RESPONSE !== undefined
    ) {
      switch (this.state.dataType) {
        case "movies":
          var movieImageStyle = {
            width: "44vw",
            height: "56.57vw",
            borderRadius: "2vw",
            backgroundColor: "#000000",
            objectFit: "contain",
            backgroundRepeat: "no-repeat"
          };
          var movieList;
          if (
            this.state.dataList.RESPONSE.movies !== null &&
            this.state.dataList.RESPONSE.movies !== undefined
          ) {
            movieList = this.state.dataList.RESPONSE.movies.map(
              (movie, index) => {
                return (
                  <Grid key={movie.id} item>
                    <div
                      className="movie_review_container_data"
                      onClick={this.handleMovieClickedForReviewDetail.bind(
                        this,
                        movie.id
                      )}
                    >
                      <BaseImageComponent
                        expectedRatio={"165x212"}
                        image_thumb={this.getImage(
                          movie.image_thumb,
                          "165x212"
                        )}
                        customStyle={movieImageStyle}
                      />
                      <div className="movie_review_title">
                        {movie.post_title}
                      </div>
                      <div className="movie_review_rating">
                        <StarRatingComponent
                          name="rating"
                          starCount={5}
                          value={movie.average_d}
                          starColor={ratingSelectedColor}
                          emptyStarColor={ratingDesectedColor}
                          fontSize="5.625vw"
                        />
                      </div>
                    </div>
                  </Grid>
                );
              }
            );
          } else {
            movieList = "";
          }
          this.setState({
            item: movieList
          });
          break;
        case "videos":
          var video_small_thumb_image = {
            width: "40vw",
            height: "22.50vw",
            objectFit: "contain",
            backgroundRepeat: "no-repeat",
            borderRadius: "2vw"
          };
          var videosList;
          if (
            this.state.dataList.RESPONSE.videos !== null &&
            this.state.dataList.RESPONSE.videos !== undefined
          ) {
            videosList = this.state.dataList.RESPONSE.videos.map(doc => {
              return (
                <Grid key={doc.id} item>
                  {" "}
                  {
                    <div
                      className="small_video_container_data"
                      key={doc.id}
                      style={{ paddingRight: "3.125vw" }}
                      onClick={this.handlePlayContainerClick.bind(this, doc)}
                    >
                      <BaseImageComponent
                        expectedRatio={"354x199"}
                        image_thumb={this.getImage(doc.image_thumb, "354x199")}
                        customStyle={video_small_thumb_image}
                      />
                      <img
                        className="event_small_play_icon"
                        src={require("../../../imagesvg/play_copy.svg")}
                        alt="play"
                      />
                      <p
                        className="event_video_title"
                        style={{ marginTop: "1vw" }}
                      >
                        {doc.post_title}
                      </p>
                    </div>
                  }
                </Grid>
              );
            });
          } else {
            videosList = "";
          }
          this.setState({
            item: videosList
          });
          break;
        case "images":
          var similar_thumb_imagesize = {
            width: "44vw",
            height: "44vw",
            borderRadius: "2vw",
            margin: "0vw",
            padding: "0vw",
            backgroundColor: "#efefef",
            objectFit: "contain",
            backgroundRepeat: "no-repeat"
          };

          var photosList;
          if (
            this.state.dataList.RESPONSE.images !== null &&
            this.state.dataList.RESPONSE.images !== undefined
          ) {
            photosList = this.state.dataList.RESPONSE.images.map(
              (detail, index) => {
                if (
                  detail.image_thumb !== null &&
                  detail.image_thumb !== undefined &&
                  (detail.details_pid !== undefined ||
                    detail.parent_id !== undefined)
                ) {
                  return (
                    <Grid key={index} item>
                        <div
                          className="similar_container_data"
                          key={detail.id}
                          onClick={this.handlePlayContainerClick.bind(
                            this,
                            detail
                          )}
                        >
                          <BaseImageComponent
                            expectedRatio={"165x165"}
                            image_thumb={this.getImage(
                              detail.image_thumb,
                              "165x165"
                            )}
                            customStyle={similar_thumb_imagesize}
                          />
                          <p
                            className="similar_title"
                            style={{ marginTop: "1vw", marginBottom: "0vw" }}
                          >
                            {detail.post_title}
                          </p>
                        </div>
                    </Grid>
                  );
                }else{
                    return ""
                }
              }
            );
          } else {
            photosList = "";
          }

          this.setState({ content_type: "photos", item: photosList });
          break;
        case "news":
          var newsList;
          if (
            this.state.dataList.RESPONSE.news !== null &&
            this.state.dataList.RESPONSE.news !== undefined
          ) {
            newsList = this.state.dataList.RESPONSE.news.map((doc, index) => {
              return <ItemCardView pic={doc} key={index} />;
            });
          } else {
            newsList = "";
          }
          this.setState({ item: newsList });
          break;
        case "features":
          var featuresList;
          if (
            this.state.dataList.RESPONSE.features !== null &&
            this.state.dataList.RESPONSE.features !== undefined
          ) {
            featuresList = this.state.dataList.RESPONSE.features.map(
              (doc, index) => {
                return <ItemCardView pic={doc} key={index} />;
              }
            );
          } else {
            featuresList = "";
          }
          this.setState({ item: featuresList });
          break;
        case "event":
          var event_small_thumb_image = {
            width: "44vw",
            height: "44vw",
            borderRadius: "2vw",
            margin: "0vw",
            padding: "0vw",
            backgroundColor: "#efefef",
            objectFit: "contain",
            backgroundRepeat: "no-repeat"
          };

          var eventsList;
          if (
            this.state.dataList.RESPONSE.event !== null &&
            this.state.dataList.RESPONSE.event !== undefined
          ) {
            eventsList = this.state.dataList.RESPONSE.event.map(
              (detail, index) => {
                return (
                  <Grid key={index} item>
                    {" "}
                    {
                      <div
                        className="similar_container_data"
                        key={detail.id}
                        onClick={this.handlePlayEventContainerClick.bind(
                          this,
                          detail,
                          "event"
                        )}
                      >
                        <BaseImageComponent
                          expectedRatio={"165x165"}
                          image_thumb={this.getImage(
                            detail.image_thumb,
                            "165x165"
                          )}
                          customStyle={event_small_thumb_image}
                        />
                        <p
                          className="similar_title"
                          style={{
                            marginTop: "1vw",
                            marginBottom: "1vw",
                            lineHeight: "1.2em"
                          }}
                        >
                          {detail.post_title}
                        </p>
                      </div>
                    }
                  </Grid>
                );
              }
            );
          } else {
            eventsList = "";
          }
          this.setState({
            content_type: "photos",
            item: eventsList
          });
          break;
        case "wallpapers":
          var wallpapers_thumb_imagesize = {
            width: "44vw",
            height: "44vw",
            borderRadius: "2vw",
            margin: "0vw",
            padding: "0vw",
            backgroundColor: "#efefef",
            objectFit: "contain",
            backgroundRepeat: "no-repeat"
          };
          var wallpapersList;
          if (
            this.state.dataList.RESPONSE.wallpapers !== null &&
            this.state.dataList.RESPONSE.wallpapers !== undefined
          ) {
            wallpapersList = this.state.dataList.RESPONSE.wallpapers.map(
              (detail, index) => {
                return (
                  <Grid key={index} item>
                    {" "}
                    {
                      <div
                        className="similar_container_data"
                        key={detail.id}
                        onClick={this.handlePlayEventContainerClick.bind(
                          this,
                          detail,
                          "wallpaper"
                        )}
                      >
                        <BaseImageComponent
                          expectedRatio={"165x165"}
                          image_thumb={this.getImage(
                            detail.image_thumb,
                            "165x165"
                          )}
                          customStyle={wallpapers_thumb_imagesize}
                        />
                        <p
                          className="similar_title"
                          style={{ marginTop: "1vw", marginBottom: "0vw" }}
                        >
                          {detail.post_title}
                        </p>
                      </div>
                    }
                  </Grid>
                );
              }
            );
          } else {
            wallpapersList = "";
          }
          this.setState({ content_type: "photos", item: wallpapersList });
          break;
        default:
          break;
      }
    } else {
      this.setState({
        item: ""
      });
    }
  }
  render() {
    var data;
    const { loading, item, dataType } = this.state;
    if (loading) {
      data = <div className="loader"></div>;
    } else {
      if (item !== null && item !== undefined && item !== "") {
        data = (
          <div
            key={dataType}
            className="gallery_detail_section_title"
            style={{
              background: "#FFFFFF",
              marginBottom: "7vw",
              marginTop: "2vw",
              marginLeft: "5vw"
            }}
          >
            <span
              className="section_name"
              style={{
                margin: "0vw",
                textTransform: "capitalize"
              }}
            >
              {dataType}
            </span>
            <Grid
              container
              style={{ marginTop: "5vw" }}
              className="grid"
              justify="flex-start"
              spacing={8}
            >
              {item}
            </Grid>
          </div>
        );
      } else {
        data = <div></div>;
      }
    }
    return <div>{data}</div>;
  }
}
export default SearchResultItems;
