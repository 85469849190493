import React, {
    Component
} from "react";
import  Moment from "moment";
import BaseImageComponent from '../../../basecomponents/baseImageComponent.js';
import '../../boxoffice_section/update/js/boxOfficeApiParse.css';

class DetailPhotosAPIParser extends Component {
constructor(props) {
super(props);
this.state = {
    celeb_id:props.celeb_id,
    tag:props.tag,
    type:props.type,
    details : [],
    bottomTabSelected:props.tab_selected,
    url:props.url,
    loading: true,
};
}

getCurrentDate(date){
    var formatedDate =Moment(date,'YYYYMMDDhms').format("MMM DD, YYYY - h:mm a")+" IST";
    return formatedDate;
}
getTitle(doc){
if(doc!=null){
    return doc.post_title;
}
}

getCategoryName(id){
switch(id){
    case 6:
    return "Movie Stills";
    case 2:
    return "First Look";
    case 7:
    return "On The Sets";
    case 12:
    return "Parties And Events";
    case 8:
    return "Celeb Photos";
    case 3:
    return "Celeb Wallpapers";
    case 4:
    return "Movie Wallpapers";
    default: return "Trending";
}
}
handleMoreClicked(article){
    console.log("article.groupValue : "+article.groupValue);
window.location ="/celebmorephotos?id="+article.groupValue+"&tags="+this.state.tag+"&back="+this.state.celeb_id;
}
getImage(image_thumb) {
    var customimage = image_thumb;
    if(customimage === undefined || customimage === null){
      return customimage;
    }else{
      customimage = image_thumb.toString().replace("322x322","354x199");
      return customimage;
    }
}
getRowData(data){
    var thumb_imagesize ={
        width:"81.25vw",
        height:"45.56vw",
        borderRadius: "2vw",
        objectFit: "contain",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#efefef",
      }

let top_events = data.map((photo,index)=>{
    var data = photo;
    return (                          
        <div className="video_container_data" key={index} style={{paddingRight:"3.125vw",margin:0,paddingBottom:"0"}}>  
         <BaseImageComponent expectedRatio={"354x199"} image_thumb={this.getImage(data.image_thumb)} customStyle={thumb_imagesize}/>
        <p className="similar_title" style={{textAlign:"left",marginTop:"2vw"}}>{data.post_title}</p>
        </div>
    )
});
return(top_events);
}

componentDidMount(){
    var finalurl = this.state.url+"/"+this.state.tag+"/"+this.state.type;
        fetch(finalurl)
        .then(results=>{
            return results.json();
        }).then(data =>{
            this.setState({loading:false});
                let sections =data.articles.map((article)=>{
                    console.log(article.groupValue);
                    if(article.groupValue !== null && article.groupValue !== undefined){
                        return (                         
                            <div className="section_headers" key={article.groupValue} style={{height:"fit-content"}}>
                            <div className="section_title"style={{display:"flex",overflow:"auto", width:"100%",marginBottom:"0"}}>
                            <span className="section_name" style={{width:"65%",marginLeft:"3vw",marginBottom:"1vw"}}>
                            {this.getCategoryName(article.groupValue)}
                            </span>
                                <div style={{display:"flex",overflow:"auto",right:0,textAlign:"right"}}>
                                <p style={{textAlign:"right",margin:0,fontSize:"4.375vw",fontWeight:"normal",paddingRight:"3.125vw",color:"#999999"}} >{article.doclist.numFound} Photos</p>
                                <p style={{textAlign:"right",margin:0,marginRight:"9.375vw",fontSize:"3.75vw",fontWeight:"700",color:"#0e94ee"}} onClick={this.handleMoreClicked.bind(this,article)} >MORE</p>
                                </div>
                            </div>
                            <div key={article.doclist.docs} className="section--content"style={{display:"flex",overflow:"auto"}}>
                            {this.getRowData(article.doclist.docs)} 
                            </div>
                            </div>
                        )
                    }
                    return "";
                })
                this.setState({details:sections});
        });
};
render()  {

let data;
const { loading } = this.state;
    if(loading) {
    data =<div className="subloader"></div>
    }
    else{     
        data =
    <div key={this.state.details.results}>
    {this.state.details}
    </div>
}
return(
    
    <div>
        {data}
    </div>
    );

};
}
export default DetailPhotosAPIParser