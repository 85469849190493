import React from "react";
class Terms extends React.Component {

    backArrowClick =()=>{
        window.location="/signup?id=0";
    }    
render() {
return (
<div className="terms">
    <title>Terms And Conditions</title>
    <div id="content" className="site-content">
        <div className="white-bg clearfix overflow">
            <div className="row bh-full-width-page">
                <div id="primary" className="content-area large-12 column">
                    <main id="main" style={{marginTop:"15vw"}} className="site-main" role="main">
                        <article id="post-629939" className="post-629939 page type-page status-publish hentry">
                            <header className="entry-header" style={{display:"flex",marginLeft:"3vw"}}>
                                <img  src={require('../imagesvg/left_red_arrow.svg')}  onClick={this.backArrowClick} alt="more" />
                                <h1  style={{marginLeft:"3vw"}} className="entry-title name">Terms And
                                                            Conditions</h1>
                            </header>
                            <div className="entry-content clearfix" itemProp="text">
                                <div>
                                    <table  width="100%" cellSpacing="8" cellPadding="8" border="0">
                                        <tbody>
                                            <tr>
                                                <td style={{border:"none",paddingBottom:"12vw"}} className="big" width="100%">
                                                    <p style={{textAlign: "justify"}}>By clicking on the [Submit] button,
                                                        you are agreeing to use the Services in a manner consistent with
                                                        the terms and conditions of this Agreement and with all
                                                        applicable laws and regulations.</p>
                                                    <h4 style={{textAlign: "justify"}}>1. Modification of Terms of
                                                        Service:</h4>
                                                    <p style={{textAlign: "justify"}}>IndiaFM.com may change the terms and
                                                        conditions of the Service upon which IndiaFM.com will notify you
                                                        of the changes. IndiaFM.com may at its sole discretion from time
                                                        to time offer you variety of features and facilities for the
                                                        service. IndiaFM.com however reserves its right to discontinue
                                                        to offer such features and facilities for the service at anytime
                                                        without prior notice.</p>
                                                    <h4 style={{textAlign: "justify"}}><strong>2. Subscriber
                                                            Conduct:</strong></h4>
                                                    <p style={{textAlign: "justify"}}>You represent and warrant the
                                                        content you provide to IndiaFM forums (a) does not infringe on
                                                        any third party&#8217;s intellectual property or proprietary
                                                        rights or rights of publicity or privacy; (b) does not violate
                                                        any law, statute, ordinance or regulation; (c) is not
                                                        defamatory, trade libelous, pornographic or obscene; or (d) does
                                                        not contain viruses or other similar harmful or deleterious
                                                        programming routines. If you violate this provision you will be
                                                        penalised up to and including termination of your rights to use
                                                        the service and any other remedies available to IndiaFM.com by
                                                        law.</p>
                                                    <h4 style={{textAlign: "justify"}}><strong>3. Registration
                                                            Data:</strong></h4>
                                                    <p style={{textAlign: "justify"}}>In consideration for the Service,
                                                        you agree to provide IndiaFM current, complete, and accurate
                                                        registration information (the &#8220;Registration Data&#8221;)
                                                        as prompted to do by the Service and to maintain and update this
                                                        information as required to keep it current, complete and
                                                        accurate. You understand the IndiaFM is advertising supported
                                                        and you grant IndiaFM.com the right to disclose the aggregate
                                                        Registration Data to third parties in connection with marketing
                                                        the Services. Furthermore, IndiaFM.com may itself use your
                                                        individual Registration Data to provide targeting of
                                                        advertisements and other Service offers.</p>
                                                    <h4 style={{textAlign: "justify"}}>4. Privacy:</h4>
                                                    <p style={{textAlign: "justify"}}>Except as provided in Section 2
                                                        above, IndiaFM.com will not intentionally monitor or disclose
                                                        any private e-mail message or address information unless
                                                        required by law, enforcement agencies, regulation or court
                                                        order. You agree to respect the privacy and confidentiality of
                                                        others.</p>
                                                    <h4 style={{textAlign: "justify"}}>5. Personal Use &amp; Security:
                                                    </h4>
                                                    <p style={{textAlign: "justify"}}>You acknowledge your right to use
                                                        the Service is personal to you. You agree not to resell or make
                                                        any commercial use of the Service without the express consent of
                                                        IndiaFM.com. You are entirely responsible for not maintaining
                                                        the confidentiality of your password and account. You shall be
                                                        fully liable for all use of your account, including any
                                                        unauthorized use of your account by any third-party.</p>
                                                    <h4 style={{textAlign: "justify"}}>6. Indemnification:</h4>
                                                    <p style={{textAlign: "justify"}}>You agree to defend, indemnify and
                                                        hold harmless IndiaFM, its employees, directors, officers,
                                                        agents and their successors and assigns from and against any and
                                                        all liabilities, damages, losses, costs and expenses, including
                                                        attorney&#8217;s fees, caused by or arising out of claims based
                                                        upon the use of your account, including any claim of libel,
                                                        defamation, violation of rights of privacy or publicity, loss of
                                                        service by other subscribers and infringement of intellectual
                                                        property or other rights.</p>
                                                    <h4 style={{textAlign: "justify"}}>7. Terms:</h4>
                                                    <p style={{textAlign: "justify"}}>This Agreement shall continue in
                                                        full force and effect for so long as you are using the Services.
                                                        Either you or IndiaFM.com may terminate the service at any time,
                                                        with or without cause. The provisions of Sections 2 and 6 shall
                                                        survive any such termination.</p>
                                                    <p style={{textAlign: "justify"}}>IndiaFM.com reserves the right, in
                                                        its sole discretion, to terminate your access to the services
                                                        and the related services or any portion thereof at any time,
                                                        without notice.</p>
                                                    <p style={{textAlign: "justify"}}>IndiaFM.com may also terminate or
                                                        suspend your access to the services for inactivity, which is
                                                        defined as failing to log into a particular service for an
                                                        extended period of time, as determined by IndiaFM.com.</p>
                                                    <p style={{textAlign: "justify"}}>The amount of time that IndiaFM.com
                                                        currently considers as an &#8220;inactive&#8221; period of time
                                                        is 60 (sixty) days.</p>
                                                    <h4 style={{textAlign: "justify"}}>8. Disclaimer of Warranties:</h4>
                                                    <p style={{textAlign: "justify"}}>YOU EXPRESSLY AGREE THE USE OF THE
                                                        SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN
                                                        &#8220;AS IS&#8221; AND &#8220;AS AVAILABLE&#8221; BASIS.
                                                        INDIAFM.COM EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                                                        WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE
                                                        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                                                        PURPOSE AND NON-INFRINGEMENT. INDIAFM.COM MAKES NO WARRANTY THE
                                                        SERVICES WILL MEET YOUR REQUIREMENTS, OR THE SERVICES WILL BE
                                                        UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE; NOR DOES
                                                        INDIAFM.COM MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
                                                        OBTAINED THROUGH THE SERVICE OR THAT DEFECTS IN THE SOFTWARE
                                                        WILL BE CORRECTED. YOU UNDERSTAND AND AGREE ANY MATERIAL AND/OR
                                                        DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
                                                        SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE
                                                        SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEMS OR
                                                        LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL
                                                        AND/OR DATA..</p>
                                                    <p style={{textAlign: "justify"}}>YOU ACKNOWLEDGE THAT INDIAFM.COM
                                                        SITE MAY CONTAIN LINKS TO OTHER WEB SITES OPERATED BY THIRD
                                                        PARTIES (&#8220;LINKED SITES&#8221;). YOU ACKNOWLEDGE THAT, WHEN
                                                        YOU CLICK ON A LINK TO VISIT A LINKED SITE, A FRAME MAY APPEAR
                                                        THAT CONTAINS THE INDIAFM LOGO, ADVERTISEMENTS AND/OR OTHER
                                                        CONTENT SELECTED BY INDIAFM.COM. YOU ACKNOWLEDGE THAT
                                                        INDIAFM.COM AND ITS SPONSORS NEITHER ENDORSE NOR ARE AFFILIATED
                                                        WITH THE LINKED SITE AND ARE NOT RESPONSIBLE FOR ANY CONTENT OF
                                                        ANY LINKED SITE OR ANY LINK CONTAINED IN A LINK SITE, OR ANY
                                                        CHANGES OR UPDATES TO SUCH SITES. YOU ALSO ACKNOWLEDGE THAT
                                                        INDIAFM IS PROVIDING THESE LINKS TO YOU ONLY AS A CONVENIENCE.
                                                    </p>
                                                    <p style={{textAlign: "justify"}}>INDIAFM.COM DOES NOT ENDORSE IN
                                                        ANYWAY ANY ADVERTISERS/ CONTENTS OF ADVERTISERS ON ITS WEBPAGES.
                                                        PLEASE THEREFORE VERIFY THE VERACITY OF ALL INFORMATION ON YOUR
                                                        OWN BEFORE UNDERTAKING RELIANCE AND ACTIONING THEREUPON.
                                                        INDIAFM.COM SHALL NOT BE RESPONSIBLE NOR LIABLE FOR ANY
                                                        CONSEQUENTIAL DAMAGES ARISING ON ACCOUNT OF YOUR RELYING ON THE
                                                        CONTENTS OF THE ADVERTISEMENT.</p>
                                                    <h4 style={{textAlign: "justify"}}>9. Limitation of Liability:</h4>
                                                    <p style={{textAlign: "justify"}}>INDIAFM.COM SHALL NOT BE LIABLE FOR
                                                        ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR
                                                        FOR INTERRUPTED COMMUNICATIONS, LOST DATA OR LOST PROFITS
                                                        ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, EVEN IF
                                                        INDIAFM.COM HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. SOME
                                                        JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY SO SOME
                                                        OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
                                                    <h4 style={{textAlign: "justify"}}>10. Miscellaneous:</h4>
                                                    <p style={{textAlign: "justify"}}>This agreement will be governed by
                                                        and construed in accordance with the laws of India without
                                                        giving effect to principles of conflict of laws. If any
                                                        provision of this Agreement is held to be invalid or
                                                        unenforceable, such provision shall be struck and the remaining
                                                        provisions shall be enforced. IndiaFM.com&#8217;s failure to act
                                                        with respect to a breach by you or others does not waive its
                                                        right to act with respect to subsequent or similar breaches.
                                                        This Agreement sets forth the entire understanding and agreement
                                                        between you and IndiaFM.com.</p>
                                                    <p> You hereby confirm that you shall abide to these Terms &#038;
                                                        Conditions and shall also abide to all the applicable laws of
                                                        the land.</p>
                                                    <p>Content on this website may occasionally contain strong language
                                                        (which may be unsuitable for children), unusual humor (which may
                                                        be unsuitable for adults), nudity, violence, and imitable
                                                        behavior etc. Viewers are requested to exercise discretion when
                                                        listening to and / or viewing such content.</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <footer className="entry-footer"></footer>
                        </article>
                    </main>
                </div>
            </div>
        </div> <span id="comments"></span>
    </div>
</div>
)
}
}

export default Terms;