import React, { Component } from "react";
import "../apiparsercomponents/login/signinSection.css";
import { LoginUser,SigninAuthorizationKey, IsDeviceIOS } from "../utils/apiUrls.js";
import { backArrowClick,getAuthorizationHeader,handleKeyDown } from "../utils/commonClickActions.js";
import Popup from "../utils/customPopup.js";
import {UserRegisterORForgotPasswordEvent} from '../analytics_events/js/CustomEvents.js';

class ForgotPasswordPage extends Component {
constructor(props) {
super(props);
this.state = {
showPopup: false,
popUpText: ""
};
}
togglePopup(text) {
console.log(this.state.showPopup);
this.setState({
showPopup: !this.state.showPopup,
popUpText: text
});
}

componentDidMount() {
UserRegisterORForgotPasswordEvent("User_Registration","User_Registration");
document.addEventListener("emailbackpressed", () => {
this.props.history.push("/");
});
}

onForgotPasswordClicked = () => {
// var username = document.querySelector('#username');
var emailaddress = document.querySelector("#emailaddress");
// console.log(JSON.stringify(username.value))//this.validateString(username) || +username.value
console.log(JSON.stringify(emailaddress.value));

if (this.validateString(emailaddress)) {
var bodyStr =
"action=forgot_password&user_name=&user_email=" + emailaddress.value;
fetch(LoginUser, {
method: "POST",
headers: {
"Accept":"Application/json",
"Authorization": getAuthorizationHeader(SigninAuthorizationKey),
"Content-Type": "application/x-www-form-urlencoded",
},
body: bodyStr
})
.then(results => {
console.log(results);
return results.json();
})
.then(data => {
console.log(data);
if (data !== null && data !== undefined) {
if (data.success) {
  if(IsDeviceIOS){
  alert(data.message)
  var path = window.location = "/signin?id=" + 0;
  setInterval(path, 1500)
  }else{
this.togglePopup(data.message);
window.location = "/signin?id=" + 0;
  }

} else {
this.togglePopup(
"Entered username/email are not valid or blank" + data.message
);
emailaddress.value = "";
}
}
});
} else {
emailaddress.value = "";
this.togglePopup("Enter Valid Email Address");
}
};

validateString = inputValue => {
this.selectedField = inputValue.id;
if (
inputValue.value !== null &&
inputValue.value !== undefined &&
inputValue.value !== ""
) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputValue.value))
  {
    return (true)
  }else{
    return (false)
  }
} else {
return false;
}
};

render() {
var height = window.innerHeight + "px";
return (
<div className="main">
  <div className="logindata" style={{ width: "100%", height: height }}>
    <div className="signin_section_container">
      <img className="Signin_section_back_arrow" src={require("../../components/imagesvg/left-arrow.svg")}
        onClick={backArrowClick.bind(this, "ForgotPassword" )} alt="more" />
      <img src={require("../imagesvg/bh_logo.png")} style={{
                height: "9vw",
                width: "33vw",
                marginLeft: "13%",
                marginTop: "5%"
              }} alt="" />
    </div>
    <div style={{ textAlign: "center", width: "100vw", paddingTop: "35%" }}>
      <p className="forgot_pass" style={{
                textAlign: "left",
                textDecoration: "none",
                fontSize: "7.5vw",
                marginLeft: "10vw",
                fontWeight: "400"
              }}>
        Forgot Password
      </p>
      <p style={{
                fontSize: "5vw",
                fontWeight: "400",
                color: "#999999",
                marginTop: "-4%",
                marginLeft: "-6vw"
              }}>
        Enter your Registered E-mail ID.
      </p>
      <input type="email" id="emailaddress" tabIndex="1"
        onKeyDown={handleKeyDown.bind(this)} name="emailaddress" placeholder="Email Address" className="loginput" />
      {/* <p className="forgot_pass" style={{textAlign:"center",textDecoration:"none",fontSize:"6vw",fontWeight:"900"}}>
        OR</p>
      <input type="text" id="username" name="username" placeholder="Username" className="loginput" /> */}
      <input type="submit"  value="Submit" className="submit" onFocus={this.onForgotPasswordClicked.bind(this)} onClick={this.onForgotPasswordClicked.bind(this)} />
    </div>
    {this.state.showPopup ? (
    <Popup text={this.state.popUpText} closePopup={this.togglePopup.bind(this)} />
    ) : null}
  </div>
</div>
);
}
}

export default ForgotPasswordPage;