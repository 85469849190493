import React from 'react';
import IconButton from 'material-ui/IconButton';
import './toolBar.css'
import {searchClick,profileClick} from '../../utils/commonClickActions.js';
class Toolbar extends React.Component {
    constructor(props) {
        super(props);
       // console.log("this.props.callbackFromParent : "+this.props.callbackFromParent);
        this.state = {
            showSearchField :"hidden"
        }
    };
        render() {
            return (
                 <div>
                    <header className="toolbar">
                    <nav className="toolbar__navigation">
                        <div className="toolbar__title"><a href="/">Trending</a></div>
                        <div className="spacer"></div>
                        <div className="toolbar_navigation_items">
                        <ul>
                            <li>
                                <IconButton  className ="toolbar_search" id="user" touch={true} onClick={profileClick.bind(this)}>
                                <img src={require("../../imagesvg/user.svg")}  alt="user_profile"/>
                                </IconButton>
                            </li>
                            <li>
                                <IconButton  className ="toolbar_search" id="search" touch={true} onClick={searchClick.bind(this)}>
                                <img src={require("../../imagesvg/search.svg")} alt="search" />
                                </IconButton>
                            </li>
                        </ul>
                        </div>
                    </nav>
                    </header>
                 </div>)
            }
        }
        export default Toolbar;