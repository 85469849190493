import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import {initializeFirebase} from './components/notification/pushNotification.js';
// import {HAController} from './dist/index.d.ts';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
// console.log(HAController)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// initializeFirebase();
serviceWorker.register();
console.log(" indexjs");