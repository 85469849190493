import React, { Component } from "react";
import { MovieDetails } from "../../../../utils/apiUrls.js";
require("./detailLyricsApiParser.css");

class DetailLyricsApiParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      lyrics_title: props.title,
      lyrics_id: props.id,
      lyricist_name: props.lyricist,
      lyrics_content: props.post_content,
      lyrics_detail: []
    };
  }

  handleBackArrow() {
    console.log("back arrow");
    window.location = "/movies";
  }
  getLyricistName(name) {
    var lyricist_name = name;
    if (name !== null && name !== undefined) {
      var sub_name = name.toString().split("|");
      if (sub_name.length === 2) {
        lyricist_name = sub_name[1];
        //console.log("name 1 "+sub_name[1]);
      } else {
        lyricist_name = sub_name[0];
        // console.log("name 0 "+sub_name[0]);
      }
    }
    return lyricist_name;
  }
  componentDidMount() {
    const params = new URL(window.location.href).searchParams;
    const id = params.get("id");
    const finalUrl = MovieDetails + "/" + id;
    fetch(finalUrl)
      .then(results => {
        return results.json();
      })
      .then(data => {
        this.setState({ loading: false });
        let lyrics = data.movies_detail.docs.map((doc, index) => {
          return (
            <div
              className="lyrics_data_detail_container_data"
              id={doc.id}
              key={doc.id}
            >
              <div className="lyrics_header_container">
                <p className="lyrics_title" style={{ float: "left" }}>
                  {doc.post_title}
                </p>
                <img
                  className="lyrics_close"
                  src={require("../../../../imagesvg/close.svg")}
                  onClick={this.handleBackArrow.bind(this)}
                  alt="close"
                />
              </div>
              <div>
                <p className="lyricist">
                  Lyricist: {this.getLyricistName(doc.lyricistf)}
                </p>
                <div
                  className="lyrics_content"
                  style={{
                    marginTop: "6.25vw",
                    textAlign: "left",
                    float: "left"
                  }}
                >
                  <pre>{doc.post_content}</pre>
                </div>
              </div>
            </div>
          );
        });
        this.setState({ loading: false, lyrics_detail: lyrics });
      });
  }
  render() {
    let data;
    const { loading } = this.state;
    if (loading) {
      data = <div className="loader" />;
    } else {
      data = <div>{this.state.lyrics_detail}</div>;
    }
    return <div>{data}</div>;
  }
}
export default DetailLyricsApiParser;
