var LoginEvent = (action, label) => {
    window.gtag('event', action, {
        'event_category': "PWA_Login",
        'event_label': label
    });
}

var UserRegisterORForgotPasswordEvent = (action, label) => {
    window.gtag('event', action, {
        'event_category': "PWA_User_Email ",
        'event_label': label
    });
}

var SearchEvent = (action, label,value) => {
    if(value!==""){
        window.gtag('event', action, {
            'event_category': "PWA_Search",
            'event_label': label,
            'value':value
        });
    }else{
        window.gtag('event', action, {
            'event_category': "PWA_Search",
            'event_label': label
        });
    }
}

var BottomNavEvent = (action, label) => {
    window.gtag('event', action, {
        'event_category': "PWA_Bottom_Nav",
        'event_label': label,
    });
}

var TrendingEvent = (action, label) => {
    window.gtag('event', action, {
        'event_category': "PWA_Trending_Tab",
        'event_label': label,
    });
}

var MovieEvent = (action, label) => {
    window.gtag('event', action, {
        'event_category': "PWA_Movies_Tab",
        'event_label': label
    });
}
var VideoEvent = (action, label) => {
    window.gtag('event', action, {
        'event_category': "PWA_Videos_Tab",
        'event_label': label
    });
}
var BoxOfficeEvent = (action, label) => {
    window.gtag('event', action, {
        'event_category': "PWA_Boxoffice_Tab",
        'event_label': label
    });
}
var CelebEvent = (action, label) => {
    window.gtag('event', action, {
        'event_category': "PWA_Celebs_Tab",
        'event_label': label
    });
}

export{
    LoginEvent,
    UserRegisterORForgotPasswordEvent,
    SearchEvent,
    BottomNavEvent,
    TrendingEvent,
    MovieEvent,
    VideoEvent,
    BoxOfficeEvent,
    CelebEvent
}