import React from 'react';
class BaseImageComponent extends React.Component {
constructor(props) {
    super(props);
    this.state = {
        image_thumb:this.props.image_thumb,
        customStyle:this.props.customStyle,
        customClick:this.props.customClick,
        expectedRatio:this.props.expectedRatio,
        count:0
    }
};

_onError(imageUrl,ev){
    // console.log(imageUrl+"  ratio : "+this.state.expectedRatio);
        if(imageUrl!==undefined || imageUrl!==null){
            if(this.state.expectedRatio ==="322x322"){
                // console.log("! 322x322");
                ev.target.src = require('../imagesvg/placeholder.png');
            }else{
                // console.log("322x322");
                if(this.state.count===0){
                    var previousImage= imageUrl.toString().replace(this.state.expectedRatio,"322x322");
                    ev.target.src = previousImage;
                    this.setState({count:1})
                }else{
                    ev.target.src = require('../imagesvg/placeholder.png'); 
                }
                
            }
        }else{
            // console.log("null");
            ev.target.src = require('../imagesvg/placeholder.png');
        }
}
    
_getImage() {
    var actualImage =this.state.image_thumb;
if(actualImage === undefined || actualImage === null){
    return require('../imagesvg/placeholder.png');
}else{
    var finalImage = require('../imagesvg/placeholder.png');
    if(actualImage.toString().includes("png") || actualImage.toString().includes("jpg")){
      
        if (actualImage.toString().includes("https")) {
            finalImage= actualImage;
        }else{
            finalImage= actualImage.toString().replace("http", "https");
        }
    }else{
          finalImage= actualImage;
    }
    
    // console.log("image path : "+finalImage);
return finalImage;
}};

render() {
    return (
        <img className="thumb_image" src={this._getImage()} onError={this._onError.bind(this,this._getImage())} style={this.state.customStyle} alt="thumb_image" onClick={this.state.customClick} />
    )};
}

export default BaseImageComponent;