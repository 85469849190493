import React from "react";
import {
  shareClick,
  favouriteIconClick
} from "../../../utils/commonClickActions.js";
import { UserFavourites } from "../../../utils/apiUrls.js";
import BaseImageComponent from "../../../basecomponents/baseImageComponent.js";
import "./celebsApiParser.css";
import Popup from "../../../utils/customPopup.js";

class CelebsCardView extends React.Component {
  constructor(props) {
    super(props);
    this.imageRatio = "322x322";
    this.favs = JSON.parse(localStorage.getItem(UserFavourites));
    this.state = {
      data: this.props.data,
      thumb_imagesize: this.props.thumb_imagesize,
      favouriteIcon:
        this.favs !== null && this.favs.includes(this.props.data.id)
          ? require("../../../imagesvg/favourite_fill.svg")
          : require("../../../imagesvg/favourite_white.svg"),
      IsFavouriteAddedOrRemoved:
        this.favs !== null && this.favs.includes(this.props.data.id)
          ? "remove"
          : "add",
      showPopup: false,
      popUpText: ""
    };
  }
  togglePopup(text) {
    console.log(this.state.showPopup);
    this.setState({
      showPopup: !this.state.showPopup,
      popUpText: text
    });
  }
  getImage(image_thumb) {
    var customimage = image_thumb;
    if (customimage === undefined || customimage === null) {
      return customimage;
    } else {
      customimage = image_thumb.toString().replace("322x322", "354x199");
      return customimage;
    }
  }

  handleItemClick(id, event) {
    console.log(id);
    window.location = "/celebdetail?id=" + id;
  }
  favouriteResonse = data => {
    console.log(this.favs);
    if (data !== null && data !== undefined) {
      if (data.success) {
        if (this.state.IsFavouriteAddedOrRemoved === "add") {
          this.togglePopup("Marked Favourite!");
          if (this.favs !== null) {
            if (!this.favs.includes(this.state.data.id)) {
              localStorage.setItem(
                UserFavourites,
                JSON.stringify(this.favs + "," + this.state.data.id)
              );
            }
          } else {
            localStorage.setItem(
              UserFavourites,
              JSON.stringify(this.favs + "," + this.state.data.id)
            );
          }
          this.setState({
            IsFavouriteAddedOrRemoved: "remove",
            favouriteIcon: require("../../../imagesvg/favourite_fill.svg")
          });
        } else {
          this.togglePopup("Removed Favourite!");
          if (
            this.favs !== null &&
            this.favs.includes("," + this.state.data.id)
          ) {
            var updated = this.favs.replace("," + this.state.data.id, "");
            localStorage.setItem(UserFavourites, JSON.stringify(updated));
          }
          this.setState({
            IsFavouriteAddedOrRemoved: "add",
            favouriteIcon: require("../../../imagesvg/favourite_white.svg")
          });
        }
      } else {
        this.togglePopup("Oops ! Please try again.");
      }
    } else {
      this.togglePopup("Oops ! Please try again.");
    }
  };
  render() {
    var doc = this.state.data;
    return (
      <div className="imgrel" key={doc.id + Math.random()}>
        <div
          className="celeb_container_data"
          id={doc.id}
          onClick={this.handleItemClick.bind(this, doc.id)}
          key={doc.id}
          target="_blank"
        >
          <BaseImageComponent
            expectedRatio={"354x199"}
            image_thumb={this.getImage(doc.image_thumb)}
            customStyle={this.state.thumb_imagesize}
          />
        </div>
        <div className="celeb_data">
          <p className="post_title">{doc.post_title}</p>
          <div className="action_icons_div">
            <img
              src={this.state.favouriteIcon}
              className="action_icons"
              onClick={favouriteIconClick.bind(
                this,
                doc.id,
                this.state.IsFavouriteAddedOrRemoved,
                "celebrity",
                this.favouriteResonse
              )}
              alt="fav"
            />
            <img
              src={require("../../../imagesvg/share_white.svg")}
              onClick={shareClick.bind(
                this,
                "https://mobi.bollywoodhungama.com/celebdetail?id=" + doc.id
              )}
              className="action_icons"
              alt="share"
            />
          </div>
        </div>
        {this.state.showPopup ? (
          <Popup
            text={this.state.popUpText}
            closePopup={this.togglePopup.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}
export default CelebsCardView;
