import React, { Component } from "react";
import BaseImageComponent from "../../../../basecomponents/baseImageComponent.js";
import "./galleryPhoto.css";

class GalleryPhotoWithUrlApiParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: props.data,
      postDetail: []
    };
  }

  handleBackArrow() {
    window.location = "/";
  }
  _onError(ev) {
    ev.target.src = require("../../../../imagesvg/placeholder.png");
  }
  downloadImageToDevice(imageUrl) {
    var finalImage = imageUrl;
    if (imageUrl.toString().includes("https")) {
      finalImage = imageUrl;
    } else {
      finalImage = imageUrl.toString().replace("http", "https");
    }
    console.log(finalImage);
    var myInit = { method: "GET", mode: "cors", cache: "default" };

    var myRequest = new Request(finalImage, myInit);

    fetch(myRequest).then(function(response) {
      console.log(response);
      console.log(response.type);
      response.blob().then(function(myBlob) {
        var objectURL = URL.createObjectURL(myBlob);
        const link = document.createElement("a");
        link.href = objectURL;
        link.setAttribute("download", "file.jpg"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    });
  }
  componentDidMount() {
    const params = new URL(window.location.href).searchParams;
    const encoded = params.get("id");
    var b = new Buffer(encoded, "base64");
    var decodedString = b.toString();
    this.setState({ loading: false, postDetail: decodedString });
  }
  render() {
    let displaydata;
    const { loading } = this.state;
    if (loading) {
      displaydata = <div className="loader" />;
    } else {
      var thumb_imagesize = {
        width: "fit-content",
        height: "fit-content",
        borderRadius: "2vw",
        marginTop: "31.25vw",
        objectFit: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#efefef",
        textAlign: "center"
      };
      displaydata = (
        <div style={{ textAlign: "center" }}>
          <div className="gallery_photo_container_data" id={0} key={0}>
            <div className="photo_action_top_icons_div">
              <img
                src={require("../../../../imagesvg/left-arrow.svg")}
                className="action_icons_back"
                alt="back_arrow"
                onClick={() => this.handleBackArrow()}
                style={{ left: 0, position: "absolute" }}
              />
            </div>
            <BaseImageComponent
              image_thumb={this.state.postDetail}
              customStyle={thumb_imagesize}
            />
            <div className="photo_action_bottom_icons_div">
              <img
                src={require("../../../../imagesvg/favourite_white.svg")}
                className="action_icons_fav"
                alt="favourite"
              />
              <img
                src={require("../../../../imagesvg/download_white.svg")}
                className="action_icons_download"
                alt="download"
                onClick={() =>
                  this.downloadImageToDevice(this.state.postDetail)
                }
              />
              <img
                src={require("../../../../imagesvg/share_white.svg")}
                className="action_icons_share"
                alt="share"
              />
            </div>
          </div>
        </div>
      );
    }
    return <div style={{ background: "#ffffff" }}>{displaydata}</div>;
  }
}
export default GalleryPhotoWithUrlApiParser;
