import React from 'react';
import './detailcardView.css'
import BaseImageComponent from '../../../../basecomponents/baseImageComponent.js';

class DetailCardView extends React.Component {
    constructor(props) {
        super(props);
        this.imageRatio = "322x322";
        this.state = {
            loading: true,
            pictures: [],
            data: this.props.data,
            onClickListener: props.onClick,
            id: props.id,
            section_id: "",
            details_title:""
        };
    }
    handleCardClick = (data, event) => {
        console.log(
            "final this.state.data.category " + JSON.stringify(this.state.section_id)
        );
        //old implementation window.location='/galleryphoto?id='+id;
        console.log("data.details_pid : " + data.details_pid);
        console.log("data.parent_id : " + data.parent_id);
        console.log("data : " + JSON.stringify(data));
        if (data.category_id === undefined && (this.state.section_id === 12 || this.state.section_id === 3 || this.state.section_id === 4)) {
            console.log("data.parent_id inside : " + data.parent_id);
            window.location = '/galleryallphotos?id=' + data.id + "&section=" + this.state.section_id +
                    "&detailsId=" + data.details_pid + "&parentId=" + data.parent_id+"&tab=gallery";
        } else if (this.state.section_id === 8) {
            window.location = '/galleryallphotos?id=' + data.id + "&section=" + this.state.section_id+"&details_title="+data.details_title+"&tab=gallery";
        } else {
            window.location = '/galleryallphotos?id=' + data.id + "&section=" + data.category_id +
                    "&detailsId=" + data.details_pid + "&parentId=" + data.parent_id+"&tab=gallery";
        }
    };

    handleClick(e) {
        console.log("inside detail card" + e);
        this
            .props
            .onClick(e.target);
    }

    getImageStyle() {
        var thumb_imagesize = {
            width: "fit-content",
            height: "fit-content",
            maxWidth: "60vw",
            maxHeight: "60vw",
            objectFit: "contain",
            backgroundColor: " #efefef",
            backgroundRepeat: "no-repeat",
            borderRadius: "2vw"
        }
        let id = this.state.id;
        if (id !== undefined && id !== null) {
            switch (id) {
                case "6":
                    thumb_imagesize.width = "60vw";
                    thumb_imagesize.height = "60vw";
                    return thumb_imagesize;
                case "2":
                    thumb_imagesize.width = "40vw";
                    thumb_imagesize.height = "51.43vw";
                    thumb_imagesize.backgroundColor = "#000000";
                    // thumb_imagesize.borderRadius ="0vw";
                    return thumb_imagesize;
                case "7":
                    thumb_imagesize.width = "60vw";
                    thumb_imagesize.height = "33.75vw";
                    return thumb_imagesize;
                case "12":
                    thumb_imagesize.width = "90vw";
                    thumb_imagesize.height = "90vw";
                    return thumb_imagesize;
                case "8":
                    thumb_imagesize.width = "40vw";
                    thumb_imagesize.height = "51.43vw";
                    // thumb_imagesize.borderRadius ="0vw";
                    return thumb_imagesize;
                case "3":
                    thumb_imagesize.width = "60vw";
                    thumb_imagesize.height = "60vw"
                    return thumb_imagesize;
                case "4":
                    thumb_imagesize.width = "60vw";
                    thumb_imagesize.height = "60vw"
                    return thumb_imagesize;
                default:
                    thumb_imagesize.width = "60vw";
                    thumb_imagesize.height = "60vw"
                    return thumb_imagesize;
            }
        }
    }

    getImage(image_thumb) {
        var customimage = image_thumb;
        if (customimage === undefined || customimage === null) {
            return customimage;
        } else {
            if (this.state.id === "7") {
                this.imageRatio = "354x199";
                customimage = image_thumb
                    .toString()
                    .replace("322x322", "354x199");
            } else if (this.state.id === "2" || this.state.id === "8") {
                this.imageRatio = "165x212";
                customimage = image_thumb
                    .toString()
                    .replace("322x322", "165x212");
            } else {
                return image_thumb;
            }
            return customimage;
        }
    }
    componentDidMount() {
        let pictures;
        if (this.state.data !== undefined && this.state.data !== null) {
            if (this.state.data.photos === undefined && this.state.data.photos !== null) {
                pictures = this
                    .state
                    .data.retVal
                    .map((photo) => {
                        this.setState({loading: false, section_id: 12});
                        var data = photo.data[0];
                        var value = data.id;
                        return (
                            <div
                                className="story_detail_container_data"
                                style={{
                                    paddingRight: "3.125vw"
                                }}
                                id={value}
                                key={value}
                                onClick={this.props.onClick}>
                                <BaseImageComponent
                                    expectedRatio={this.imageRatio}
                                    image_thumb={this.getImage(data.image_thumb)}
                                    customStyle={this.getImageStyle()}
                                    customClick={this
                                        .handleCardClick
                                        .bind(this, data)}/>
                                <p className="story_detail_data_title">{data.post_title}</p>
                                <p className="story_detail_data_photos">{photo.total_image_count} Photos</p>
                            </div>
                        )
                    })
            } else {
                // console.log("inside photos else" +JSON.stringify(this.state.data));
                pictures = this
                    .state
                    .data
                    .photos
                    .map((photo) => {
                        this.setState({loading: false, section_id: this.state.data.section_id});
                        var data = photo.data;
                        var value = data.id;
                        return (
                            <div
                                className="story_detail_container_data"
                                style={{
                                    paddingRight: "3.125vw",
                                    paddingBottom:"3.625vw"
                                }}
                                id={value}
                                key={value}
                                onClick={this.props.onClick}>
                                <BaseImageComponent
                                    expectedRatio={this.imageRatio}
                                    image_thumb={this.getImage(data.image_thumb)}
                                    customStyle={this.getImageStyle()}
                                    customClick={this
                                        .handleCardClick
                                        .bind(this, data)}/>
                                <p className="story_detail_data_title">{data.post_title}</p>
                                <p className="story_detail_data_photos">{photo.total_image_count} Photos</p>
                            </div>
                        )
                    })
            }
        }
        this.setState({pictures: pictures});
    }

    render() {

        let data;
        const {loading} = this.state;
        if (loading) { // if your component doesn't have to wait for an async action, remove this block
            data = <div className="loader"></div>
        } else {
            data = <section
                className="section_card"
                style={{
                    backgroundColor: "#FFFFFF"
                }}>
                {this.state.pictures}
            </section>
        }
        return (<div>
            {data}
        </div>)
    };
}

export default DetailCardView;