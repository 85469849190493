import React, { Component } from "react";

import "../../trending_section/gallery/js/RowView.css";
import "../js/celebsApiParser.css";
import {
  CelebArticlesBasedOnTagAndType,
  CelebOrMovieDetails,
  CelebFilmography
} from "../../../utils/apiUrls.js";
import DetailArticleApiParser from "../../movies_section/reviews/views/detailArticleApiParser.js";
import DetailFilmographyAPIParser from "./detailFilmography.js";
import DetailPhotosAPIParser from "./detailPhotosApiParser.js";
import DetailVideosAPIParser from "../../movies_section/reviews/views/detailVideosApiParser.js";
// import DetailMusicAPIParser from '../../movies_section/reviews/views/detailMusicApiParser.js';
import CelebsPostItemView from "./celebsPostItemView.js";

class CelebDetailPostApiParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post_name: "",
      post_id: "",
      postDetail: [],
      detail_tabs: [],
      detail_content: [],
      active: null,
      averageRating: 0,
      userRating: 0
    };
  }
  getDetailTabs() {
    let detailTabs = ["ARTICLE", "FILMOGRAPHY", "PHOTOS", "VIDEOS"]; //,"MUSIC" TODO:Second phase
    let tabs = detailTabs.map((tab, index) => {
      return (
        <button
          className="tablinks"
          key={index}
          id={tab}
          onClick={this.handleSubTabClick.bind(this, index)}
        >
          {tab}
        </button>
      );
    });
    this.setState({ detail_tabs: tabs });
  }

  getDetailCOntent() {
    let listTabs = this.state.detail_tabs;
    let tab = listTabs[0];
    // console.log("tab insede : "+tab.props.id);
    if (tab.props.id === "ARTICLE") {
      let ele = document.getElementById(tab.props.id);
      // ele.style.backgroundColor="rgba(216,216,216,0.00)";
      ele.style.color = "#D0021B";
      ele.style.opacity = "1";
      ele.style.fontWeight = "900";
      ele.style.borderBottom = "0.625vw solid #D0021B";
    }
    var article = (
      <DetailArticleApiParser
        tab_selected={"celebs"}
        url={CelebArticlesBasedOnTagAndType}
        tag={this.state.post_name}
        type="news"
      />
    );
    this.setState({ detail_content: article, active: 0 });
  }

  setStyle(index) {
    let listTabs = this.state.detail_tabs;
    // console.log("detail_Tabs : "+JSON.stringify(listTabs));
    let tab = listTabs[index];
    let ele = document.getElementById(tab.props.id);

    listTabs.map(item => {
      let ele = document.getElementById(item.props.id);
      ele.style.color = "#000000";
      // ele.style.backgroundColor="rgba(216,216,216,0.00)";
      ele.style.borderBottom = "0.125vw solid #cccccc";
      ele.style.opacity = "0.5";
      ele.style.fontWeight = "900";
      return "";
    });
    if (ele !== undefined) {
      ele.style.color = "#D0021B";
      ele.style.borderBottom = "0.625vw solid #D0021B";
      ele.style.opacity = "1";
      ele.style.fontWeight = "900";
    }
  }

  handleSubTabClick(index, event) {
    this.setState({ active: index });
    this.setStyle(index);
    if (
      event !== null &&
      event.currentTarget !== undefined &&
      event.currentTarget.id !== undefined
    ) {
      let container_id = event.currentTarget.id;
      this.setState({ subtab_selected: container_id });
      if (container_id === "ARTICLE") {
        var article = (
          <DetailArticleApiParser
            tab_selected={"celebs"}
            url={CelebArticlesBasedOnTagAndType}
            tag={this.state.post_name}
            type="news"
          />
        );
        this.setState({ detail_content: article });
      } else if (container_id === "FILMOGRAPHY") {
        var filmography = (
          <DetailFilmographyAPIParser
            tab_selected={"celebs"}
            url={CelebFilmography}
            celeb_name={this.state.post_name}
            celeb_id={this.state.post_id}
            type="news"
          />
        );
        this.setState({ detail_content: filmography });
      } else if (container_id === "PHOTOS") {
        var photos = (
          <DetailPhotosAPIParser
            tab_selected={"celebs"}
            url={CelebArticlesBasedOnTagAndType}
            tag={this.state.post_name}
            celeb_id={this.state.post_id}
            type="photos"
          />
        );
        this.setState({ detail_content: photos });
      } else if (container_id === "VIDEOS") {
        var videos = (
          <DetailVideosAPIParser
            tab_selected={"celebs"}
            url={CelebArticlesBasedOnTagAndType}
            tag={this.state.post_name}
            type="videos"
          />
        );
        this.setState({ detail_content: videos });
      }
      // else{
      //     var music =  <DetailMusicAPIParser tab_selected ={"celebs"} url ={CelebArticlesBasedOnTagAndType} tag={this.state.post_name} type="music" />;
      //     this.setState({detail_content:music});
      // }
    }
  }

  componentDidMount() {
    this.getDetailTabs();
    const params = new URL(window.location.href).searchParams;
    const id = params.get("id");
    const finalUrl = CelebOrMovieDetails + "/" + id;

    fetch(finalUrl)
      .then(results => {
        return results.json();
      })
      .then(data => {
        this.setState({ loading: false });
        let postDetail = data.movies_detail.docs.map(doc => {
          this.setState({
            post_name: doc.post_title,
            post_id: doc.id,
            averageRating: doc.rating_celeb,
            userRating: doc.rating_celeb
          });
          this.getDetailCOntent();

          return (
            <CelebsPostItemView
              data={doc}
              userRating={doc.rating_celeb}
              key={doc.id}
            />
          );
        });
        this.setState({ postDetail: postDetail });
      });
  }

  render() {
    let data;
    const { loading } = this.state;
    if (loading) {
      data = <div className="subloader" style={{ top: "10vw" }} />;
    } else {
      data = (
        <div>
          <div className="celeb_content" style={{ textAlign: "center" }}>
            {this.state.postDetail}

            <div
              style={{
                width: "100vw",
                height: "fit-content",
                marginTop: "5vw"
              }}
            >
              <section
                className="detail_tabs"
                style={{ display: "flex", overflowX: "auto" }}
              >
                {this.state.detail_tabs}
              </section>
            </div>
          </div>
          <div className="detail_content" style={{ marginTop: "4vw" }}>
            {this.state.detail_content}
          </div>
        </div>
      );
    }
    return <div>{data}</div>;
  }
}
export default CelebDetailPostApiParser;
