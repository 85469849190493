import React, { Component } from "react";
import "../apiparsercomponents/login/signinSection.css";
// import { LoginTypeValue, UserID, UserProfileData } from "../utils/apiUrls.js";
import {
  backArrowClick,
  // setLoginInLocalStorage,
  // RegisterSocialLoginInWordPress,
  handleKeyDown
} from "../utils/commonClickActions.js";
import Popup from "../utils/customPopup.js";
// import { LoginEvent } from "../analytics_events/js/CustomEvents";

class MobileNumberLoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      popUpText: "",
      inputMobileNumber: 0,
      firebase:this.props.firebase,
      signInWithPhoneNumber:this.props.signInWithPhoneNumber
    };
  }
  togglePopup(text) {
    console.log(this.state.showPopup);
    this.setState({
      showPopup: !this.state.showPopup,
      popUpText: text
    });
  }
  componentDidMount() {
    document.addEventListener("emailbackpressed", () => {
      this.props.history.push("/");
    });
  }

  validateString = inputValue => {
    if (inputValue.toString().length === 10) {
      console.log(inputValue.toString().length);
      this.setState({ inputMobileNumber: inputValue });
      return true;
    }else{
      // alert("Please Enter Valid Mobile Number.")
      return false;
    }
    // if (
    //   inputValue !== null &&
    //   inputValue !== undefined &&
    //   inputValue !== "" &&
    //   inputValue.toString().length >= 10
    // ) {
    //   console.log("value set " + this.state.inputMobileNumber);
    //   console.log(inputValue);
    //   // inputValue.preventDefault();
    // } else {
    //   return true;
    // }
  };

  getMobileNumbar = () => {
    var ele = document.querySelector("#mobile_number");
    if (ele !== null && ele !== undefined && ele.value !== undefined) {
      return ele.value.toString();
    } else {
      return 0;
    }
  };

  signInWithPhoneAuth=()=>{
    var phoneNumber =this.getMobileNumbar();
    var validPhoneNumber = this.validateString(phoneNumber);
    console.log(validPhoneNumber);
    if(validPhoneNumber){
      var appVerifier = new this.state.firebase.auth.RecaptchaVerifier('recaptcha-container');
      console.log(appVerifier)
      this.state.firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
          .then(function (confirmationResult) {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
          }).catch(function (error) {
            // Error; SMS not sent
            // ...
          });
    }else{
      alert("Please Enter Valid Phone number.")
    }
  }



  render() {
    var height = window.innerHeight + "px";
    return (
      <div className="main">
        <div className="logindata" style={{ width: "100%", height: height }}>
          <div className="signin_section_container">
            <img
              className="Signin_section_back_arrow"
              src={require("../../components/imagesvg/left-arrow.svg")}
              onClick={backArrowClick.bind(this, "MobileLogin")}
              alt="more"
            />
            <img
              src={require("../imagesvg/bh_logo.png")}
              style={{
                height: "9vw",
                width: "33vw",
                marginLeft: "13%",
                marginTop: "5%"
              }}
              alt=""
            />
          </div>
          <div
            style={{ textAlign: "center", width: "100vw", paddingTop: "20%" }}
          >
            <p
              className="forgot_pass"
              style={{
                textAlign: "center",
                textDecoration: "none",
                fontSize: "7vw",
                fontWeight: "400"
              }}
            >
              Enter your mobile number
            </p>
            <p
              style={{
                fontSize: "5vw",
                fontWeight: "400",
                color: "#ffffff",
                marginTop: "3vw",
                paddingLeft: "4vw",
                paddingRight: "4vw",
                paddingBottom: "2vw"
              }}
            >
              Tap Next to get a SMS confirmation from Account Kit powered by
              Facebook. Hungama uses Facebook technology to help you sign in,
              but you don't need a Facebook account. Message and data rate may
              apply. Learn how Facebook uses your info.
            </p>

            <div
              style={{
                display: "flex",
                padding: "3vw",
                textAlign: "center",
                marginLeft: "10vw"
              }}
            >
              <input
                type="text"
                id="mobile_number_code"
                name="mobile_number_code"
                placeholder="+91"
                value="+91"
                readOnly={true}
                className="mobile_number_code"
              />
              <input
                autoComplete="false"
                size={10}
                maxLength={10}
                type="number"
                id="mobile_number"
                name="mobilenumber"
                placeholder=""
                className="loginput"
                tabIndex="1"
                onKeyPress={this.validateString.bind(this)}
                onKeyDown={handleKeyDown.bind(this)}
              />
            </div>
            <div id ="recaptcha-container" style={{textAlign:"center",marginLeft:"3vw"}}></div>
            <button
              style={{
                height: "15vw",
                width: "fit-content",
                textAlign: "center",
                marginTop: "6vw",
                backgroundColor: "#DF440D",
                color: "#ffffff",
                borderRadius: "10vw",
                fontSize: "5vw",
                paddingLeft: "10vw",
                paddingRight: "10vw",
                border: "none"
              }}
              onClick={this.signInWithPhoneAuth.bind(this)}
            >
              Send OTP
            </button>
          </div>
        </div>
        {this.state.showPopup ? (
          <Popup
            text={this.state.popUpText}
            closePopup={this.togglePopup.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

export default MobileNumberLoginPage;
