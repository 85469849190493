import React from "react";
import {
  setUserRating,
  favouriteIconClick,
  shareClick,
  searchClick,
  backArrowClick
} from "../../../utils/commonClickActions.js";
import BaseImageComponent from "../../../basecomponents/baseImageComponent.js";
import StarRatingComponent from "react-star-ratings";
import "../../trending_section/gallery/js/RowView.css";
import "../js/celebsApiParser.css";
import { UserFavourites } from "../../../utils/apiUrls.js";
import Popup from "../../../utils/customPopup.js";

class CelebsPostItemView extends React.Component {
  constructor(props) {
    super(props);
    this.favs = JSON.parse(localStorage.getItem(UserFavourites));
    this.state = {
      data: this.props.data,
      favouriteIcon:
        this.favs !== null && this.favs.includes(this.props.data.id)
          ? require("../../../imagesvg/favourite_fill_black_border.svg")
          : require("../../../imagesvg/favourite_black.svg"),
      IsFavouriteAddedOrRemoved:
        this.favs !== null && this.favs.includes(this.props.data.id)
          ? "remove"
          : "add",
      userRating: this.props.userRating,
      showPopup: false,
      popUpText: ""
    };
  }
  togglePopup(text) {
    console.log(this.state.showPopup);
    this.setState({
      showPopup: !this.state.showPopup,
      popUpText: text
    });
  }
  favouriteResonse = data => {
    if (data !== null && data !== undefined) {
      if (data.success) {
        if (this.state.IsFavouriteAddedOrRemoved === "add") {
          this.togglePopup("Marked Favourite!");
          if (this.favs !== null) {
            if (!this.favs.includes(this.state.data.id)) {
              localStorage.setItem(
                UserFavourites,
                JSON.stringify(this.favs + "," + this.state.data.id)
              );
            }
          } else {
            localStorage.setItem(
              UserFavourites,
              JSON.stringify(this.favs + "," + this.state.data.id)
            );
          }
          this.setState({
            IsFavouriteAddedOrRemoved: "remove",
            favouriteIcon: require("../../../imagesvg/favourite_fill_black_border.svg")
          });
        } else {
          this.togglePopup("Removed Favourite!");
          if (
            this.favs !== null &&
            this.favs.includes("," + this.state.data.id)
          ) {
            var updated = this.favs.replace("," + this.state.data.id, "");
            localStorage.setItem(UserFavourites, JSON.stringify(updated));
          }
          this.setState({
            IsFavouriteAddedOrRemoved: "add",
            favouriteIcon: require("../../../imagesvg/favourite_black.svg")
          });
        }
      } else {
        this.togglePopup("Oops ! Please try again.");
      }
    } else {
      this.togglePopup("Oops ! Please try again.");
    }
  };
  onStarClick(id, rating) {
    console.log(rating + "  " + id);
    this.setState({ userRating: rating });
    setUserRating(id, rating, "post");
  }
  render() {
    var thumb_imagesize = {
      width: "100vw",
      height: "100.625vw",
      objectFit: "contain",
      backgroundRepeat: " no-repeat",
      WebkitMaskImage:
        "linear-gradient(180deg, #FFFFFF 60%, rgba(247,247,247,0) 100%)"
      // WebkitMaskImage: '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
      // MaskImage:'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
    };
    var doc = this.state.data;
    return (
      <div className="celeb_detail_container_data" id={doc.id} key={doc.id}>
        <BaseImageComponent
          image_thumb={doc.image_thumb}
          customStyle={thumb_imagesize}
        />
        <div className="celeb_detail_action_top_icons_div">
          <img
            src={require("../../../imagesvg/left-arrow_black.svg")}
            className="action_icons_back"
            alt="back_arrow"
            onClick={backArrowClick.bind(this, "CelebsDetailPost")}
            style={{
              left: "3.125vw",
              position: "absolute",
              marginTop: "5.25vw"
            }}
          />
          <img
            src={require("../../../imagesvg/search.svg")}
            className="action_icons_search"
            alt="search"
            onClick={searchClick}
            style={{
              right: "6.25vw",
              position: "absolute",
              marginTop: "5.25vw"
            }}
          />
        </div>
        <div className="celeb_details">
          <p className="celeb_detail_post_title">{doc.post_title}</p>
          <p className="celeb_detail_rate">Rate it</p>
          <div className="ratingbar">
            <StarRatingComponent
              rating={this.state.userRating}
              starRatedColor="rgb(248, 190, 28)"
              changeRating={this.onStarClick.bind(this, doc.id)}
              numberOfStars={5}
              name="rating"
              isSelectable={true}
              starHoverColor="rgb(208, 2, 27)"
              starSelectingHoverColor="rgb(208, 2, 27)"
              starDimension="6vw"
              starSpacing="0.5vw"
              starEmptyColor="rgb(141, 141, 141)"
            />
          </div>
          <div className="celeb_detail_action_icons_div" style={{ zIndex: 99 }}>
            <img
              src={this.state.favouriteIcon}
              onClick={favouriteIconClick.bind(
                this,
                doc.id,
                this.state.IsFavouriteAddedOrRemoved,
                "celebrity",
                this.favouriteResonse
              )}
              className="action_icons"
              alt="favourite"
              style={{ width: "6vw" }}
            />
            <img
              src={require("../../../imagesvg/share_black.svg")}
              onClick={shareClick.bind(
                this,
                "https://mobi.bollywoodhungama.com/celebdetail?id=" + doc.id
              )}
              className="action_icons"
              alt="share"
              style={{ width: "4.625vw" }}
            />
          </div>
        </div>
        {this.state.showPopup ? (
          <Popup
            text={this.state.popUpText}
            closePopup={this.togglePopup.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}
export default CelebsPostItemView;
