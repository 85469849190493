import React, { Component } from "react";
import Moment from "moment";
import { TableBody } from "@material-ui/core";
import { CelebCollectionByTypeAndId } from "../../../../utils/apiUrls.js";
require("./CelebCollectionDetail.css");

class CelebInternationalCollectionDetailApiParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collection_type: props.collection_type,
      celeb_id: props.celeb_id,
      detail_content: []
    };
    // console.log(" constructor called : "+this.state.collection_type);
  }
  getCurrentDate(date) {
    var formatedDate = Moment(date, "YYYYMMDD").format("DD MMM, YYYY");
    return formatedDate;
  }
  getCollection(doc) {
    var collection;
    if (this.state.collection_type === "domestic") {
      collection = "&#x20B9;" + doc.movie_collection_d + " Cr.";
    } else {
      collection = "$" + doc.overseas_collection_d + " M";
    }
    return collection;
  }
  handleMovieClickedForReviewDetail(id, event) {
    window.location =
      "/moviereviewdetail?id=" +
      id +
      "&tab=startcollectiondetail&celeb_id=" +
      this.state.celeb_id;
  }
  componentDidMount() {
    const finalUrl =
      CelebCollectionByTypeAndId +
      "/" +
      this.state.collection_type +
      "/" +
      this.state.celeb_id;
    // console.log("url : "+finalUrl);
    fetch(finalUrl)
      .then(results => {
        return results.json();
      })
      .then(data => {
        this.setState({ loading: false });
        let postDetail = data.top_celebs.docs.map(doc => {
          var value = doc.id;
          return (
            <div
              className="celeb_collection_container_data"
              id={value}
              key={value}
            >
              <table className="collection_table">
                <TableBody
                  style={{ borderBottom: "0.3125vw solid #ccc" }}
                  onClick={this.handleMovieClickedForReviewDetail.bind(
                    this,
                    value
                  )}
                >
                  <tr className="celeb_collection_sub">
                    <td colSpan="2" className="celeb_title">
                      {doc.post_title}
                    </td>
                    {/* <td className="boxofficeheading">Box Office</td> */}
                  </tr>
                  <tr className="celeb_collection_sub">
                    <td className="celebmoviedate">
                      {this.getCurrentDate(doc.releasedate)}
                    </td>
                    <td rowSpan="2" className="celebmoviecollection">
                      {this.getCollection(doc)}
                    </td>
                  </tr>
                  {/* <tr className="celeb_collection_sub">
                                    <td className="celebmoviedate">{doc.lang}</td>
                                </tr> */}
                </TableBody>
              </table>
            </div>
          );
        });
        this.setState({ postDetail: postDetail });
      });
  }
  render() {
    let data;
    const { loading } = this.state;
    if (loading) {
      data = <div className="loader" />;
    } else {
      data = (
        <div className="celeb_content" style={{ textAlign: "center" }}>
          {this.state.postDetail}
        </div>
      );
    }
    return <div>{data}</div>;
  }
}
export default CelebInternationalCollectionDetailApiParser;
