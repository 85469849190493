import React, { Component } from "react";
import Moment from "moment";
import { createBrowserHistory } from "history";
import Grid from "@material-ui/core/Grid";
import BaseImageComponent from "../../../../basecomponents/baseImageComponent.js";
import "../../../trending_section/gallery/js/RowView.css";
import { IndexDB } from "../../../../utils/apiUrls.js";
import { MovieEvent } from "../../../../analytics_events/js/CustomEvents.js";

class MovieReleaseDateApiParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      section_name: "",
      movies: [],
      month_movies: [],
      month_movies_data: [],
      bottomTabSelected: props.tab_selected,
      url: props.url
    };
  }
  getCurrentDate(date) {
    var formatedDate = Moment(date, "YYYYMMDD").format("DD MMMM, YYYY");
    return formatedDate;
  }
  getMonthTag(doc) {
    var formatedDate = Moment(doc.data[0].releasedate, "YYYYMMDD").format(
      "MMM' YY"
    );
    return formatedDate;
  }
  getSectionName() {
    return "Upcoming Movies";
  }

  handleMovieClickedForReviewDetail(id, event) {
    window.location = "/moviereviewdetail?id=" + id + "&tab=movies";
  }
  getImage(image_thumb) {
    // console.log(image_thumb);
    var customimage = image_thumb;
    if (customimage === undefined || customimage === null) {
      return customimage;
    } else {
      customimage = image_thumb.toString().replace("322x322", "165x212");
      return customimage;
    }
  }
  componentWillMount() {
    const history = createBrowserHistory();

    // Get the current location.
    const location = history.location;
    console.log(location);
    const unlisten = history.listen((location, action) => {
      // location is an object like window.location
      console.log(action, location.pathname, location.state);
    });

    // Use push, replace, and go to navigate around.
    history.push({ pathname: "/movies" });
    window.onpopstate = () => {
      window.location = "/movies";
    };
    unlisten();
    MovieEvent("Movies_Tab_Release_Date ", "Movie Tab");
    var currentTimeStamp = new Date().getTime();
    IndexDB.movie_release_date_api
      .get(this.state.url)
      .then(data => {
        if (data === undefined) {
          fetch(this.state.url)
            .then(results => {
              return results.json();
            })
            .then(data => {
              this.createUIComponent(data);
              IndexDB.movie_release_date_api.put({
                ID: this.state.url,
                data: data,
                timestamp: currentTimeStamp
              });
            });
        } else {
          var difference = (currentTimeStamp - data.timestamp) / 60000;
          console.log(difference);
          if (difference >= 30) {
            fetch(this.state.url)
              .then(results => {
                return results.json();
              })
              .then(async data => {
                var updated = await IndexDB.movie_release_date_api.update(
                  this.state.url,
                  {
                    ID: this.state.url,
                    data: data,
                    timestamp: currentTimeStamp
                  }
                );
                console.log(updated);
                if (updated && this !== undefined && this !== null) {
                  this.createUIComponent(data);
                }
              });
          } else {
            this.createUIComponent(data.data);
          }
        }
      })
      .catch(function(e) {
        console.log("Error: " + (e.stack || e));
      });
  }
  createUIComponent = data => {
    var thumb_imagesizeMonth = {
      width: "44vw",
      height: "56.57vw",
      borderRadius: "2vw",
      backgroundColor: "#000000",
      objectFit: "contain",
      backgroundRepeat: "no-repeat"
    };
    var thumb_imagesize = {
      width: "40vw",
      height: "51.43vw",
      borderRadius: "2vw",
      backgroundColor: "#000000",
      objectFit: "contain",
      backgroundRepeat: "no-repeat"
    };
    this.setState({ loading: false });
    data.section_movies.map(section_movies => {
      this.setState({ section_name: section_movies.section_name });
      // console.log("section_movies.section_name : "+section_movies.section_name)
      if (this.state.section_name === "Most Awaiting Movie") {
        let movies = section_movies.movies.docs.map(doc => {
          return (
            <div
              className="container_data"
              key={doc.id}
              style={{ marginRight: "3.125vw", background: "#ffffff" }}
              onClick={this.handleMovieClickedForReviewDetail.bind(
                this,
                doc.id
              )}
            >
              <BaseImageComponent
                expectedRatio={"165x212"}
                image_thumb={this.getImage(doc.image_thumb)}
                customStyle={thumb_imagesize}
              />
              <p
                style={{
                  textAlign: "left",
                  fontSize: "6.25vw",
                  paddingBottom: "2vw",
                  fontWeight: "700",
                  whiteSpace: "nowrap",
                  width: "40vw",
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  margin: "0vw"
                }}
              >
                {doc.post_title}
              </p>
              <div
                style={{
                  color: "#999999",
                  textAlign: "left",
                  fontSize: "4.375vw",
                  fontWeight: "400"
                }}
              >
                {/* <p style={{marginBottom:"1.5625vw",marginTop:"1.5625vw"}}>Release Date:</p> */}
                <p style={{ marginBottom: "3.125vw", marginTop: "0vw" }}>
                  {this.getCurrentDate(doc.releasedate)}
                </p>
              </div>
            </div>
          );
        });
        this.setState({ movies: movies });
      } else {
        var month_tag_bg = require("../../../../imagesvg/month_tag.png");
        let month_movies = section_movies.movies.map((movie, index) => {
          return (
            <div className="month_data" key={index}>
              <div
                style={{
                  width: "30vw",
                  height: "6.25vw",
                  padding: "3.125vw",
                  backgroundImage: "url(" + month_tag_bg + ")",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  marginTop: "3.125vw",
                  marginBottom: "3.125vw",
                  fontWeight: 700,
                  color: "#FFFFFF",
                  fontSize: "5.625vw"
                }}
              >
                {this.getMonthTag(movie)}
              </div>
              <div>
                <Grid
                  container
                  className="grid"
                  justify="flex-start"
                  style={{ padding: "5vw" }}
                  spacing={8}
                >
                  {movie.data.map(doc => (
                    <Grid key={doc.id} item>
                      {" "}
                      {
                        <div
                          className="container_data"
                          style={{ background: "#ffffff" }}
                          onClick={this.handleMovieClickedForReviewDetail.bind(
                            this,
                            doc.id
                          )}
                        >
                          <BaseImageComponent
                            expectedRatio={"165x212"}
                            image_thumb={this.getImage(doc.image_thumb)}
                            customStyle={thumb_imagesizeMonth}
                          />
                          <p
                            style={{
                              textAlign: "left",
                              fontSize: "6.25vw",
                              paddingBottom: "2vw",
                              fontWeight: "700",
                              whiteSpace: "nowrap",
                              width: "40vw",
                              overflowX: "hidden",
                              textOverflow: "ellipsis",
                              margin: "0vw"
                            }}
                          >
                            {doc.post_title}
                          </p>
                          <div
                            style={{
                              color: "#999999",
                              textAlign: "left",
                              fontSize: "4.375vw",
                              fontWeight: "400"
                            }}
                          >
                            {/* <p style={{marginBottom:"1.5625vw",marginTop:"1.5625vw"}}>Release Date:</p> */}
                            <p
                              style={{
                                marginBottom: "3.125vw",
                                marginTop: "0vw"
                              }}
                            >
                              {this.getCurrentDate(doc.releasedate)}
                            </p>
                          </div>
                        </div>
                      }
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          );
        });
        this.setState({ month_movies: month_movies });
      }
      return "";
    });
  };
  render() {
    let data;
    const { loading } = this.state;
    if (loading) {
      data = <div className="loader" />;
    } else {
      data = (
        <div className="section_header" style={{ margin: "0", padding: "0" }}>
          <div className="section_title" style={{ marginLeft: "5vw" }}>
            <span className="section_name">{this.getSectionName()}</span>
          </div>
          <div
            key={Math.random()}
            className="upcoming"
            style={{
              overflowX: "auto",
              display: "flex",
              background: "#ffffff",
              marginLeft: "5vw"
            }}
          >
            {this.state.movies}
          </div>
          <div>{this.state.month_movies}</div>
        </div>
      );
    }
    return (
      <div style={{ backgroundColor: "#FFFFFF", width: "100%" }}>{data}</div>
    );
  }
}
export default MovieReleaseDateApiParser;
