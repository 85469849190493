import React, {
    Component
} from "react";

import Grid from '@material-ui/core/Grid';
import {IndexDB,TopPartiesAndEventsSection} from '../../../../utils/apiUrls.js';
import BaseImageComponent from '../../../../basecomponents/baseImageComponent.js';
import './videoAllApiParser.css';

class VideosAllSectionApiParser extends Component {
constructor(props) {
    super(props);
    this.state = {
        section_name :"",
        watched_section_name:"",
        top_videos : [],
        watch_now_videos:[],
        most_liked_videos:[],
        data:props.data,
        video_section:[],
        start :10,
        limit :10,
        error: false,
        hasMore: true,
        isLoading: false,
    };
    // Binds our scroll event handler
    window.onscroll = () => {
        const {
           loadTopVideos,
            state: {
            error,
            isLoading,
            hasMore,
          },
        } = this;
  
        // Bails early if:
        // * there's an error
        // * it's already loading
        // * there's nothing left to load
        if (error || isLoading || !hasMore) return;
        // console.log("loadTopVideos window.innerHeight"+window.innerHeight);
        // console.log("loadTopVideos document.documentElement.scrollTop"+document.documentElement.scrollTop);
        // console.log("loadTopVideos document.documentElement.offsetHeight"+document.documentElement.offsetHeight);
        // Checks that the page has scrolled to the bottom
        if (
          window.innerHeight + document.documentElement.scrollTop
          >= document.documentElement.offsetHeight -200
        ) {
         setTimeout(loadTopVideos(),1000);
        }
      };
}
getTitle(doc){
    if(doc!=null){
        return doc.post_title;
    }
}
handlePlayContainerClick(data,event){
window.location="/eventvideodetail?id="+data.id+"&tab=video";
}

createUIComponent(data){
    var video_small_thumb_imagesize ={
      width: "42vw",
      height: "23.63vw",
      borderRadius: "2vw",
      backgroundColor: "#efefef",
      objectFit: "contain",
      backgroundRepeat: "no-repeat",
    }
  
    const totalCount = data.photos.numFound;
    let next_extra_events =data.photos.docs.map((photo)=>{
      var data = photo
          return (
              <Grid key={data.id} item> {
                  <div className="small_video_container_data" key={data.id} style={{paddingRight:"3.125vw"}} onClick={this.handlePlayContainerClick.bind(this,data)}>
                  <img className="event_small_play_icon" src={require('../../../../imagesvg/play_copy.svg')} alt="play"/>
                  <BaseImageComponent expectedRatio={"354x199"} image_thumb={this.getImage(data.image_thumb[0])} customStyle={video_small_thumb_imagesize}/>
                  <div className="event_video_title">{data.post_title}</div>
                  </div>  
              }</Grid>
          )                         
    });
  this.setState({
    start:(this.state.start +this.state.limit),
    hasMore: (this.state.most_liked_videos.length < totalCount),
    isLoading: false,
    most_liked_videos: [
      ...this.state.most_liked_videos,
      ...next_extra_events,
    ],
  });
  }

  loadTopVideos = () => {
    var currentTimeStamp = new Date().getTime();
  
    this.setState({ isLoading: true }, () => {
        var finalUrl =TopPartiesAndEventsSection +"/trending/"+this.state.start+"/"+this.state.limit;
        console.log(this.state.start)
        if(this.state.start === 10){
          IndexDB.home_top_music_api.get(finalUrl).then((data)=>{
            if(data === undefined){
              fetch(finalUrl)
              .then(results=>{
                return results.json();
              })
              .then((data) => {
                this.createUIComponent(data);
                IndexDB.home_top_music_api.put({ID:finalUrl,data:data,timestamp:currentTimeStamp});
              }).catch((err) => {
                this.setState({
                  error: err.message,
                  isLoading: false,
                  });
              });
            }else{
              var difference = (currentTimeStamp-data.timestamp)/60000;
              console.log(difference);
              if(difference>=30){
                fetch(finalUrl)
                .then(results=>{
                  return results.json();
              })
              .then(async(data) => {
                console.log("inside data");
                var updated= await IndexDB.home_top_music_api.update(finalUrl, {ID:finalUrl,data:data,timestamp:currentTimeStamp})
                if(updated&& this !== undefined && this!== null){
                  this.createUIComponent(data)
                }
              });
              }else{
                this.createUIComponent(data.data);
              }
            }
          }).catch(function (e) {
            console.log("Error: " + (e.stack || e));
        });
        }else{
          fetch(finalUrl)
              .then(results=>{
                return results.json();
              })
              .then((data) => {
                this.createUIComponent(data);
              }).catch((err) => {
                this.setState({
                  error: err.message,
                  isLoading: false,
                  });
              });
        }
        
    });
    }
getImage(image_thumb) {
    var customimage = image_thumb;
    if(customimage === undefined || customimage === null){
      return customimage;
    }else{
      customimage = image_thumb.toString().replace("322x322","354x199");
      return customimage;
    }
  }
componentDidMount(){
  var video_section = this.state.data.section_videos.map((section_video)=>{
    var event_video_large_thumb_image ={
        width: "85vw",
        height: "47.81vw",
        backgroundRepeat:"no-repeat",
        objectFit: "contain",
        backgroundColor:"#efefef",
        borderRadius: "2vw",
      }
    var video_medium_thumb_image ={
        width: "62.5vw",
        height: "35.125vw",
        backgroundRepeat:"no-repeat",
        borderRadius: "2vw",
        objectFit: "contain",
        backgroundColor:"#efefef",
    }

    var video_small_thumb_image ={
        width: "42vw",
        height: "23.63vw",
        backgroundRepeat:"no-repeat",
        borderRadius: "2vw",
        objectFit: "contain",
        backgroundColor:"#efefef",
    }
        if(section_video.section_name === "Top Videos"){
        let top_videos= section_video.videos.RESPONSE.map((doc)=>{
                    return (
                        <div className="video_container_data" style={{paddingRight:"3.125vw"}} key={"top"+doc.id+Math.random()} onClick={this.handlePlayContainerClick.bind(this,doc)}>
                         <BaseImageComponent  expectedRatio={"354x199"} image_thumb={this.getImage(doc.image_thumb)} customStyle={event_video_large_thumb_image}/>
                        <img className="event_play_icon" src={require('../../../../imagesvg/play_copy.svg')} alt="play"/>
                        <div className="eventCardtopVideoTitle">{this.getTitle(doc)}</div>
                        </div>
                    )
                })
                this.setState({top_videos:top_videos});
                return top_videos;
        }else if(section_video.section_name === "Videos Being Watched"){
            this.setState({watched_section_name:section_video.section_name});
            let watch_now_videos= section_video.videos.RESPONSE.map((doc)=>{
                return (
                        <div className="video_container_data" style={{paddingRight:"3.125vw"}}key={"watched"+doc.id} onClick={this.handlePlayContainerClick.bind(this,doc)}>
                        <BaseImageComponent  expectedRatio={"354x199"} image_thumb={this.getImage(doc.image_thumb)} customStyle={video_medium_thumb_image}/>
                        <img className="event_medium_play_icon" src={require('../../../../imagesvg/play_copy.svg')} alt="play"/>
                        <p className="event_medium_video_title">{this.getTitle(doc)}</p>
                        </div>  
                )                         
        });
        this.setState({watch_now_videos:watch_now_videos})
        return watch_now_videos;
        }else{
            console.log("section_name : "+section_video.section_name);
            this.setState({section_name:section_video.section_name});
            if(section_video.videos.data!==undefined && section_video.videos.data!==null && section_video.videos.data.length>0){
                let most_liked_videos= section_video.videos.data.map((doc)=>{
                    return (
                        
                            <Grid key={doc.id} item> {
                                <div className="small_video_container_data" style={{paddingRight:"3.125vw"}} key={doc.id} onClick={this.handlePlayContainerClick.bind(this,doc)}>
                                <BaseImageComponent  expectedRatio={"354x199"} image_thumb={this.getImage(doc.image_thumb)} customStyle={video_small_thumb_image}/>
                                <img className="event_small_play_icon" src={require('../../../../imagesvg/play_copy.svg')} alt="play"/>
                                <p className="event_video_title">{this.getTitle(doc)}</p>
                                </div>
                                }
                            </Grid>
                            
                    )                         
            });
            this.setState({most_liked_videos:most_liked_videos})
            return most_liked_videos;
        }
        }
        return "";
});
this.setState({video_section:video_section});
};
render()  {    
    const {
        error,
        hasMore,
        isLoading,
      } = this.state;   
return(  <div className="header" style={{marginLeft:"4.25vw",marginTop:"3.625vw",marginBottom:"10vw"}}>
        <div>
            <section className="section_card" key={"videosAll"+Math.random()}  style={{display:"flex",overflowX: "auto",paddingRight:"3.125vw",marginBottom:"6.25vw"}}>
            {this.state.top_videos}
            </section>
        </div>
        <div>
            <div className="section_title" >
                <span className="section_name">{this.state.watched_section_name}
                {/* <img className="video_section_more_arrow" src={require('../imagesvg/right-arrow.svg')} alt="more"/> */}
                </span>
            </div>
            <div key={Math.random()+"v"} >
                <section className="section_card" key={Math.random()+"videos"}  style={{display:"flex",overflowX: "auto",paddingRight:"3.125vw",marginBottom:"6.25vw"}}>
            {this.state.watch_now_videos}
            </section>
        </div>
    </div>
    <div id="ATD_BH_300x250_P17"></div>
    <div>
        <div className="section_title">
            <span className="section_name">{this.state.section_name}
            {/* <img className="video_section_more_arrow" src={require('../imagesvg/right-arrow.svg')} alt="more"/> */}
            </span>
        </div>
        <div key={Math.random()} >
        <Grid container className="grid" justify="flex-start" spacing={8}>
        {this.state.most_liked_videos}
        </Grid>
        {error &&
          <div style={{ color: '#900',textAlign:"center",padding:"3.125vw"  }}>
            {error}
          </div>
        }
        {isLoading &&
          <div style={{textAlign:"center",padding:"3.125vw",fontWeight:"700"}}>Loading...</div>
        }
        {!hasMore &&
          <div style={{textAlign:"center",padding:"3.125vw"}}>You did it! You reached the end!</div>
        }
        </div>
    </div>
</div>      
)}
};
export default VideosAllSectionApiParser;