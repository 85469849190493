import React from 'react';
import './RowView.css'
import DetailCardView from './detailCardView.js';

class RowView extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
           data:this.props.pic,
           count:this.props.count
        };
    }
    getCategoryName(){
       switch(parseInt(this.state.count)){
           case 6:
           return "Movie Stills";
           case 2:
           return "First Look";
           case 7:
           return "On The Sets";
           case 12:
           return "Parties And Events";
           case 8:
           return "Celeb Photos";
           case 3:
           return "Celeb Wallpapers";
           case 4:
           return "Movie Wallpapers";
           default: return "null";
       }
    }
    handleSectionClick(id){
      window.location='/gallerysectiondetail?id='+id;
     
    }
  render() {
    return (
        <div className="section_header" style={{margin:0,marginLeft:"1.625vw",width:"98vw"}}>
        <div className="section_title" style={{marginLeft:"3.625vw"}} >
        <span className="section_name" onClick={this.handleSectionClick.bind(this,this.state.count)} >{this.getCategoryName()}
        <img className="row_section_more_arrow" src={require('../../../../imagesvg/right_arrow_red.svg')}  alt="more"/>
        </span>
        </div>
        <div key={this.state.data} className="section--content" style={{backgroundColor:"#FFFFFF",marginBottom:"4.25vw"}}>
        <DetailCardView data={this.state.data} id={this.state.count}/>
        </div>
        </div>
    );
  }
}

export default RowView;