import React, { Component } from "react";
import { TopVideos } from "../utils/apiUrls.js";
import VideoAllApiParser from "../apiparsercomponents/video_section/topvideo/js/videoAllApiParser.js";

class VideoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="main">
        <div className="data" style={{ textAlign: "center" }}>
          <VideoAllApiParser tab_selected={"videos"} url={TopVideos} />
        </div>
      </div>
    );
  }
}

export default VideoPage;
