import React from "react";
import { UserFavourites,IsDeviceIOS } from "../../../../utils/apiUrls.js";
import {
  shareClick,
  favouriteIconClick
} from "../../../../utils/commonClickActions.js";
import BaseImageComponent from "../../../../basecomponents/baseImageComponent.js";
import "../../../../../App.css";
import "./galleryPhoto.css";

class GalleryPhotoView extends React.Component {
  constructor(props) {
    super(props);
    this.imageRatio = "322x322";
    this.favs = JSON.parse(localStorage.getItem(UserFavourites));
    this.state = {
      sectionId: this.props.sectionId,
      detailsId: this.props.detailsId,
      parentId: this.props.parentId,
      data: this.props.data,
      favouriteIcon:
        this.favs !== null && this.favs.includes(this.props.data.id)
          ? require("../../../../imagesvg/favourite_fill.svg")
          : require("../../../../imagesvg/favourite_white.svg"),
      IsFavouriteAddedOrRemoved:
        this.favs !== null && this.favs.includes(this.props.data.id)
          ? "remove"
          : "add",
          popupClick:props.popupClick,
        showDownload:IsDeviceIOS?"hidden":"visible"
    };
  }

  favouriteResonse = data => {
    if (data !== null && data !== undefined) {
      if (data.success) {
        console.log(this.state.IsFavouriteAddedOrRemoved);
        console.log(this.state.IsFavouriteAddedOrRemoved === "add");
        if (this.state.IsFavouriteAddedOrRemoved === "add") {
          // alert("Marked Favourite!");
          this.state.popupClick("Marked Favourite!");
          if (this.favs !== null) {
            if (!this.favs.includes(this.state.data.id)) {
              localStorage.setItem(
                UserFavourites,
                JSON.stringify(this.favs + "," + this.state.data.id)
              );
            }
          } else {
            localStorage.setItem(
              UserFavourites,
              JSON.stringify(this.favs + "," + this.state.data.id)
            );
          }
          console.log("inside add "+this.favs)
          this.setState({
            IsFavouriteAddedOrRemoved: "remove",
            favouriteIcon: require("../../../../imagesvg/favourite_fill.svg")
          });
        } else {
          this.state.popupClick("Removed Favourite!");
          if (
            this.favs !== null &&
            this.favs.includes("," + this.state.data.id)
          ) {
            
            var updated = this.favs.replace("," + this.state.data.id, "");
            console.log("inside "+updated)
            localStorage.setItem(UserFavourites, JSON.stringify(updated));
          }
          this.setState({
            IsFavouriteAddedOrRemoved: "add",
            favouriteIcon: require("../../../../imagesvg/favourite_white.svg")
          });
        }
      } else {
        this.state.popupClick("Oops ! Please try again.");
      }
    } else {
      this.state.popupClick("Oops ! Please try again.");
    }
  };

  getImage(doc) {
    if (doc.image_thumb === undefined || doc.image_thumb === null) {
      return require("../../../../imagesvg/placeholder.png");
    } else {
      var finalImage = doc.image_thumb[0];
      if (doc.image_thumb[0].toString().includes("https")) {
        finalImage = doc.image_thumb[0];
      } else {
        finalImage = doc.image_thumb[0].toString().replace("http", "https");
      }
      console.log(finalImage)
      return finalImage;
    }
  }
  downloadImageToDevice=(doc)=>{
    var imageURL = this.getImage(doc);
    console.log(imageURL)
    var myInit = {
      method: "GET",
      cache: "default"
    };

    var myRequest = new Request(imageURL, myInit);

    fetch(myRequest).then(function(response) {
      console.log(response);
      console.log(response.type);
      response.blob()
      .then(function(myBlob) {
        console.log(myBlob)
        var objectURL = URL.createObjectURL(myBlob);
      
        const link = document.createElement("a");
        link.href = objectURL;

        console.log(JSON.stringify(objectURL))
        link.setAttribute("id", "download");
        link.setAttribute("download", "file.jpg"); //or any other extension
        document.body.appendChild(link);
     
        link.click();
        document.body.removeChild(link);
      });
    });
  }

  render() {
    var thumb_imagesize = {
      width: "100%",
      height: "100%",
      borderRadius: "2vw",
      marginTop: "31.25vw",
      objectFit: "cover",
      backgroundRepeat: "no-repeat",
      backgroundColor: "#efefef",
      textAlign: "center"
    };
    var doc = this.state.data;
    var shareUrl;
    if (this.state.sectionId === "8") {
      shareUrl =
        "https://mobi.bollywoodhungama.com/galleryphoto?id=" +
        doc.id +
        "&section=" +
        this.state.sectionId +
        "&details_title=" +
        doc.details_title +
        "&tab=galleryphoto";
    } else {
      shareUrl =
        "https://mobi.bollywoodhungama.com/galleryphoto?id=" +
        doc.id +
        "&section=" +
        this.state.sectionId +
        "&detailsId=" +
        this.state.detailsId +
        "&parentId=" +
        this.state.parentId +
        "&tab=galleryphoto";
    }
    // console.log(shareUrl);
    return (
      <div
        style={{
          textAlign: "center"
        }}
      >
        <BaseImageComponent
          image_thumb={this.getImage(doc)}
          customStyle={thumb_imagesize}
        />
        <div className="photo_action_bottom_icons_div">
          <img
            src={this.state.favouriteIcon}
            className="action_icons_fav"
            onClick={favouriteIconClick.bind(
              this,
              doc.id,
              this.state.IsFavouriteAddedOrRemoved,
              "movie",
              this.favouriteResonse
            )}
            alt="favourite"
          />
          <img
            src={require("../../../../imagesvg/download_white.svg")}
            className="action_icons_download"
            alt="download"
            id="download_icon"
            style={{visibility:this.state.showDownload}}
            onClick={() => this.downloadImageToDevice(doc)}
          />
          <img
            src={require("../../../../imagesvg/share_white.svg")}
            className="action_icons_share"
            onClick={shareClick.bind(this, shareUrl)}
            alt="share"
          />
        </div>
      </div>
    );
  }
}
export default GalleryPhotoView;
