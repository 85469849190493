import React, { Component } from "react";
import { IndexDB } from "../../../../utils/apiUrls.js";
import RowView from "./RowView.js";
import { TrendingEvent } from "../../../../analytics_events/js/CustomEvents.js";

class GalleryHomeApiParser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      sections: [],
      bottomTabSelected: props.data,
      url: props.url,
      isMounted: props.isMounted
    };

    window.onpopstate = () => {
      window.location = "/";
    };
  }
  componentDidMount() {
    TrendingEvent("Trending_Tab_Gallery","Trending Tab")
    // console.log("inside gallery")
    var currentTimeStamp = new Date().getTime();
    // console.log("currentTimeStamp : "+currentTimeStamp);
    IndexDB.home_gallery_api
      .get(this.state.url)
      .then(data => {
        // console.log(data);
        if (data === undefined) {
          fetch(this.state.url)
            .then(results => {
              return results.json();
            })
            .then(data => {
              console.log("data");
              this.createUIComponent(data);
              IndexDB.home_gallery_api.put({
                ID: this.state.url,
                data: data,
                timestamp: currentTimeStamp
              });
            });
        } else {
          var difference = (currentTimeStamp - data.timestamp) / 60000;
          // console.log(difference);
          if (difference >= 30) {
            fetch(this.state.url)
              .then(results => {
                return results.json();
              })
              .then(async data => {
                console.log("inside data");
                var updated = await IndexDB.home_gallery_api.update(
                  this.state.url,
                  {
                    ID: this.state.url,
                    data: data,
                    timestamp: currentTimeStamp
                  }
                );
                if (updated && this !== undefined && this !== null) {
                  this.createUIComponent(data);
                }
              });
          } else {
            this.createUIComponent(data.data);
          }
        }
      })
      .catch(function(e) {
        alert("Error: " + (e.stack || e));
      });
  }

  createUIComponent = data => {
    this.setState({ loading: false });
    var adsCount = 4;
    let sections = data.section_photos.map((section_photo, index) => {
      if (index > 1 && index % 2 === 0) {
        ++adsCount;
        console.log(adsCount);
        return (
          <div key={"gallery" + Math.random()}>
            <div id={"ATD_BH_300x250_P" + adsCount} />
            <RowView
              pic={section_photo.data}
              key={section_photo.section_id}
              count={section_photo.section_id}
            />
          </div>
        );
      } else {
        return (
          <RowView
            pic={section_photo.data}
            key={section_photo.section_id}
            count={section_photo.section_id}
          />
        );
      }
    });
    this.setState({ sections: sections });
  };

  render() {
    let data;
    const { loading } = this.state;
    if (loading) {
      data = <div className="loader" />;
    } else {
      data = <div style={{ background: "#ffffff",marginBottom:"13vw" }}>{this.state.sections}</div>;
    }
    return <div style={{ background: "#ffffff" }}>{data}</div>;
  }
}
export default GalleryHomeApiParser;
